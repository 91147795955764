import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import { PropsWithChildren } from 'react'
import { Alert } from 'react-bootstrap'
import { ErrorBoundary, withErrorBoundary } from 'react-error-boundary'

const ErrorFallback = () => {
  return <Alert variant="danger">{DEFAULT_ERROR_MESSAGE}</Alert>
}

export const DefaultErrorBoundary = ({ children }: PropsWithChildren<{}>) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
)

export function withDefaultErrorBoundary<P>(component: React.ComponentType<P>) {
  return withErrorBoundary<P>(component, {
    FallbackComponent: ErrorFallback
  })
}
