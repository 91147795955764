import { rmsAuthModel } from 'common/rmsGlobal'
import { isEmpty } from 'lodash'
import { IVehicleInfo } from 'types/vehicleTypes'

export const buildMMRUrl = (mmrHost: string, vehicleInfo: IVehicleInfo) => {
  let mmrLink = `${mmrHost ?? 'https://mmr.manheim.com'}/?popup=true`

  if (isEmpty(vehicleInfo)) return mmrLink

  mmrLink += `&vin=${vehicleInfo.VIN}`
  const properties = vehicleInfo.Properties.filter((x) => x.ID === 1)

  // Mileage (Odometer)
  let prop = properties.find((p) => p.UIConstantName === 'MILEAGE')?.Description
  if (!isEmpty(prop)) mmrLink += `&mileage=${parseFloat(prop.replace(/,/g, ''))}`

  // Condition Grade
  prop = properties.find((p) => p.UIConstantName === 'DD_CONDITION')?.Description
  if (!isEmpty(prop)) mmrLink += `&condition=${prop}`

  mmrLink += '&country=US'

  return mmrLink
}

const enableReactLinks = Boolean(+rmsAuthModel.systemSettings.ENABLE_REACT_LINKS)
export const getVdpUrl = (vehicleInstanceId: number) => {
  return enableReactLinks ? `/vehicle/${vehicleInstanceId}` : `/vehicles#detail/${vehicleInstanceId}`
}

export const getWatchlistUrl = () => (enableReactLinks ? '/watchlist' : '/vehicles#watchlist')
export const getCurrentBidsUrl = () => (enableReactLinks ? '/managebids' : '/vehicles#currentBids')
export const getSearchUrl = () => (enableReactLinks ? '/search' : '/vehicles')
