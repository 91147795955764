import { ArrowDown } from 'icons/ArrowDown'
import { ArrowUp } from 'icons/ArrowUp'
import { groupBy } from 'lodash'
import { useRef, useState, useEffect } from 'react'
import { Button, Overlay, Popover } from 'react-bootstrap'
import { ISortBy, SortOrder } from 'types/baseTypes'

import { StyledSort, StyledSortPopover } from './Sort.styled'

interface IProps {
  currentSort: ISortBy
  sortOptions: ISortBy[]
  onChange?: (selectedSort: ISortBy) => void
}

const parseSortName = (sortName: string) => {
  return sortName?.slice(0, sortName.indexOf(' -')) // Remove ' -' from option's name
}

export const Sort = ({ currentSort: currentSortProp, sortOptions, onChange }: IProps) => {
  const [currentSort, setCurrentSort] = useState<ISortBy>(currentSortProp)
  const [sortName, setSortName] = useState(currentSortProp?.Name ?? '')
  const [show, setShow] = useState(false)
  const sortRef = useRef(null)
  const containerRef = useRef(null)
  const sortFields = groupBy(
    sortOptions?.map((so) => ({ ...so, Name: parseSortName(so.Name) })),
    'Name'
  )

  const handleSortChange = (sortValue: string) => {
    if (sortValue !== currentSort.Name) {
      const selectedSort = sortOptions.find((so) => so.Value === sortValue)
      setCurrentSort(selectedSort)
      setShow(false)
      onChange?.(selectedSort)
    }
  }

  useEffect(() => {
    const name =
      currentSort.Name ??
      sortOptions?.find((o) => o.DBFieldName === currentSort.DBFieldName && o.Value === currentSort.Value)?.Name ??
      ''
    setSortName(name)
  }, [currentSort, sortOptions])

  return (
    <>
      {currentSort && (
        <div ref={containerRef}>
          <StyledSort>
            <span className="sort-label text-primary">Sort by</span>
            <Button className="btn-current-sort" variant="light" onClick={() => setShow(!show)} ref={sortRef}>
              <span>{parseSortName(sortName)}</span>
              {currentSort.SortOrder === SortOrder.ASC ? <ArrowUp /> : <ArrowDown />}
            </Button>
          </StyledSort>
          <Overlay
            rootClose={true}
            target={sortRef.current}
            container={containerRef.current}
            onHide={() => setShow(false)}
            show={show}
            placement="bottom"
            transition={false}
          >
            <StyledSortPopover id="popover-sort" show={show}>
              <Popover.Content>
                {Object.keys(sortFields).map((sortFieldName) => (
                  <div key={sortFieldName} className="sort-item">
                    <div>{sortFieldName}</div>
                    <div className="sort-button-group">
                      {sortFields[sortFieldName].map((sortOption, i) => (
                        <Button
                          key={i}
                          variant="light"
                          onClick={() => handleSortChange(sortOption.Value)}
                          className={`btn-sort ${sortOption.Value === currentSort.Value ? 'active' : ''}`}
                        >
                          {sortOption.SortOrder === SortOrder.ASC ? <ArrowUp /> : <ArrowDown />}
                        </Button>
                      ))}
                    </div>
                  </div>
                ))}
              </Popover.Content>
            </StyledSortPopover>
          </Overlay>
        </div>
      )}
    </>
  )
}
