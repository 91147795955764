export const formatCurrency = (value?: number, options?: Intl.NumberFormatOptions): string => {
  if (!value && value !== 0) {
    return ''
  }

  const formatOptions = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    ...options
  }

  const format = new Intl.NumberFormat('en-US', formatOptions)

  return format.format(value)
}

export const formatNumber = (value: number, options?: Intl.NumberFormatOptions) => {
  if (!value && value !== 0) {
    return ''
  }

  const formatOptions = {
    minimumFractionDigits: 0,
    ...options
  }

  const format = new Intl.NumberFormat('en-US', formatOptions)

  return format.format(value)
}

export function parseJson<T extends object>(json: string, defaultValue?: T) {
  try {
    return JSON.parse(json)
  } catch (error) {
    return defaultValue
  }
}
