import { MultiLanguageCode } from 'common/languageCodes'
import Searchable, { ISearchListItem } from 'components/Searchable'
import { groupBy, keys, mapValues } from 'lodash'
import { useMemo, useRef, useState, ChangeEvent } from 'react'
import { Overlay } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { FilterProps } from 'react-table'
import { ColumnFilterButton, FilterDropdown, FilterActions } from './SelectColumnFilter.styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'

export function filterMultiSelect(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id]
    return filterValue?.includes(rowValue)
  })
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter<D extends object>({
  column: { filterValue, setFilter, preFilteredRows, id }
}: FilterProps<D>) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const { options, counts } = useMemo(() => {
    const _groups = groupBy(preFilteredRows, (item) => item.values[id])
    return {
      options: keys(_groups),
      counts: mapValues(_groups, (item) => item.length)
    }
  }, [id, preFilteredRows])

  const currentFilters = filterValue || options
  const searchableOptions = useMemo(
    () =>
      options.map(
        (item) =>
          ({
            label: item,
            value: item,
            checked: !filterValue || filterValue.includes(item),
            count: counts[item]
          } as ISearchListItem)
      ),
    [options, counts, filterValue]
  )
  const target = useRef(null)
  const [show, setShow] = useState(false)

  const handleFilter = ({ target }: ChangeEvent) => {
    const checked = (target as HTMLInputElement).checked
    const value = (target as HTMLInputElement).value
    const filterIdx = currentFilters.indexOf(value)
    if (!checked && filterIdx >= 0) {
      const updatedState = [...currentFilters]
      updatedState.splice(filterIdx, 1)
      setFilter(updatedState)
    }

    if (checked && filterIdx < 0) {
      setFilter([...currentFilters, value])
    }
  }

  // Render a multi-select box
  return (
    <span>
      <ColumnFilterButton
        active={filterValue && filterValue.length < options.length}
        ref={target}
        onClick={(event) => {
          setShow(!show)
          event.stopPropagation()
        }}
      >
        <FontAwesomeIcon icon={faFilter} />
      </ColumnFilterButton>
      <Overlay
        target={() => target.current}
        show={show}
        placement="bottom-start"
        rootClose
        onHide={() => setShow(false)}
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <FilterDropdown
            {...props}
            style={{
              ...props.style
            }}
          >
            <FilterActions>
              <button className="btn btn-sm btn-link" onClick={() => setFilter(options)}>
                <FormattedMessage id={MultiLanguageCode.SELECT_ALL} />
              </button>
              <button className="btn btn-sm btn-link" onClick={() => setFilter([])}>
                <FormattedMessage id={MultiLanguageCode.CLEAR_ALL} />
              </button>
            </FilterActions>
            <Searchable data={searchableOptions} onChange={handleFilter} />
          </FilterDropdown>
        )}
      </Overlay>
    </span>
  )
}
