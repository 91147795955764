import { isEmpty } from 'lodash'
import { IVehicleSearchDocument, ISearchFilterResult } from 'types/vehicleTypes'
import { BaseVehicleSearchCriteriaColumn } from './constants'

// Common
export const stripHtml = (htmlStr: string): string => {
  return new DOMParser()
    .parseFromString(htmlStr, 'text/html')
    .documentElement.textContent.replace(/\s{2,}|\t{1,}/g, ' ')
    .trim()
}

// AvailableInventory
export const isAnyChilrenFiltersSelected = (filters: ISearchFilterResult[], filterFieldName: string) => {
  return filters.find((ft) => ft.FieldName === filterFieldName)?.ChildrenFilter?.some((cft) => cft.Selected)
}

export const isAnyChildrenFilterExist = (
  filters: ISearchFilterResult[],
  filterFieldName: string,
  childrenFilterFieldName: string
) => {
  return filters
    .find((ft) => ft.FieldName === filterFieldName)
    ?.ChildrenFilter?.some((cft) => cft.FieldName === childrenFilterFieldName)
}

export const hasAnyChilrenFilter = (filters: ISearchFilterResult[], filterFieldName: string) => {
  return filters.find((ft) => ft.FieldName === filterFieldName)?.ChildrenFilter?.length > 0
}

export const processVehicleSearchDocumentResult = (
  result: IVehicleSearchDocument[],
  filters: ISearchFilterResult[],
  filterFieldName: string,
  filterField: (r: IVehicleSearchDocument) => string
) => {
  const processFilters = (childrenFilters: ISearchFilterResult[]) =>
    childrenFilters.filter((cft: ISearchFilterResult) => cft.Selected).map((cft: ISearchFilterResult) => cft.Value)

  if (isAnyChilrenFiltersSelected(filters, filterFieldName)) {
    return result.filter((r: IVehicleSearchDocument) =>
      processFilters([...filters.find((ft) => ft.FieldName === filterFieldName)?.ChildrenFilter]).includes(
        filterField(r)
      )
    )
  }

  return result
}

export const resetChildrenFilters = (filters: ISearchFilterResult[], ...filterFieldNames: string[]) => {
  if (!isEmpty(filterFieldNames)) {
    return filterFieldNames?.forEach((filterFieldName) =>
      filters.find((ft) => ft.FieldName === filterFieldName)!.ChildrenFilter.forEach((cft) => (cft.Selected = false))
    )
  }

  return filters?.forEach((ft) => ft.ChildrenFilter.forEach((cft) => (cft.Selected = false)))
}

export const selectChildrenFilter = (
  filters: ISearchFilterResult[],
  filterFieldName: string,
  ...selectedValues: string[]
) => {
  return filters.forEach((filter) => {
    if (filter.FieldName === filterFieldName) {
      selectedValues?.forEach((selectedValue) => {
        if (filter.ChildrenFilter.find((childFilter) => childFilter.Value === selectedValue)) {
          filter.ChildrenFilter.find((childFilter) => childFilter.Value === selectedValue).Selected = true
        }
      })
    }
  })
}
export const addDummyChildrenFilter = (
  filters: ISearchFilterResult[],
  fieldId: BaseVehicleSearchCriteriaColumn,
  fieldName: string,
  dbFieldName: string,
  value: string,
  selected: boolean = true
) => {
  filters
    .find((ft) => ft.FieldName === fieldName)
    ?.ChildrenFilter?.push({
      FieldId: fieldId,
      FieldName: fieldName,
      DBFieldName: dbFieldName,
      FriendlyValue: value,
      Value: value,
      Selected: selected,
      MultiSelect: true,
      Operation: 1
    })
}
