import { DefaultTheme } from 'styled-components'

export const theme: DefaultTheme = {
  colors: {
    primary: '#173B68',
    primaryDarken: '#0e233e',
    secondary: '#E3E4E5',
    tableStriped: '#edf8fb',
    tableBorder: '#dee2e6',
    linkColor: '#4270d2',
    linkColorLight: '#0083c6',
    inputBorder: '#d4d4d4',
    fontColor: '#5b5b5b',
    inputBackgroundColor: '#ffffff',
    mobileMenuBackground: '#f4f4f4',
    sectionContentBackgroundColor: '#f6f6f6',
    borderInput: '1px solid #d4d4d4',
    infoColor: '#6a8c9c',
    successColor: '#26ad77',
    warningColor: '#f90',
    dangerColor: '#b11926',
    ribbonInfoBackgroundColor: '#dae2e6',
    ribbonSuccessBackgroundColor: '#c9eadd',
    ribbonWarningBackgroundColor: '#ffe5bf',
    ribbonDangerBackgroundColor: '#ebc5c9',
    backgroundText: 'white',
    colorTextModal: '#f2dede',
    colorButtonDisabled: '#444',
    switchBackgroundColor: '#6f6f6f',
    headerFontColor: '#494949',
    defaultDealerDeactive: '#A94442',
    incentiveHeaderColor: '#3d6a3c',
    dropdownHoverColor: '#deebff',
    incentiveHeaderHoverColor: '#39a937',
    modalHeader: '#818181',
    carfaxBackground: '#3777bc',
    goodTireColor: '#406a3b',
    normalTireColor: '#e3c35e',
    badTireColor: '#ba0000',
    issueColor: '#b11926'
  },
  logo: {
    left: 'vendor-main-logo.png',
    right: 'site_title-new.png?version=2'
  },
  layout: {
    header: {
      leftLogo: '',
      rightLogo: ''
    }
  },
  fonts: {
    fontBase: 'BMWGroup Condensed',
    fontRegular: 'BMWGroup Regular'
  }
}
