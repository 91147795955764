import { BaseVehicleSearchCriteriaColumn as DBFieldId, PROXIMITY_FILTERS } from 'common/constants'
import Select from 'components/Select/Select'
import { Slider } from 'components/Slider/Slider'
import produce from 'immer'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useGlobalStore } from 'store/useGlobalStore'
import { formatNumber } from 'utils/numberUtils'
import { formatDistanceLabel } from 'utils/utils'

import { ISearchFilterResult, ISearchResponseData } from '../../types/vehicleTypes'
import {
  StyledCarfaxFilterItemContainer,
  StyledCarfaxFilterItemContent,
  StyledCarfaxFilterItemHeader,
  StyledMultiSelectFilterSection,
  StyledSliderFilterSection
} from './BuyerSearch.styled'

const ANY_DISTANCE_OPTION = '99999'

interface IProps {
  filter: ISearchResponseData | ISearchFilterResult
  onChange?: (filter: ISearchResponseData | ISearchFilterResult) => void
}

export const SearchFilter = ({ filter, onChange }: IProps) => {
  const {
    userClaims: { CurrentBuyerPostCode }
  } = useGlobalStore()

  const [internalFilter, setInternalFilter] = useState<ISearchResponseData | ISearchFilterResult>(filter)
  const NO_FILTER_APPLICABLE = `No ${internalFilter.FieldName} filters applicable`

  useEffect(() => {
    setInternalFilter(filter)
  }, [filter])

  const onMultiSelectChange = (
    childPredicate: (filter: ISearchFilterResult) => boolean,
    isSelected: boolean,
    resetFirst?: boolean
  ) => {
    const result = produce(internalFilter, (draft) => {
      draft.ChildrenFilter.forEach((cf) => {
        if (resetFirst) cf.Selected = false
        if (childPredicate(cf)) cf.Selected = isSelected
      })
    })

    onChange?.(result)
  }

  const onMultiValueSliderChange = (minVal: number, maxVal: number, values: number[]) => {
    const result = produce(internalFilter, (draft) => {
      draft.Value = values[0].toString()
      draft.Value3 = values[1].toString()
      draft.Selected = true
      draft.FriendlyValue = `${values[0]} - ${values[1]}`

      if ((values[0] === 0 && values[1] === 0) || values[0] < minVal || values[1] > maxVal) {
        draft.Value = ''
        draft.Value3 = ''
        draft.Selected = false
      }
    })

    onChange?.(result)
  }

  const onProximityChange = (value: number) => {
    let selectedChild = null
    const result = produce(internalFilter, (ft) => {
      ft.ChildrenFilter.forEach((cf) => {
        if (cf.Selected) {
          selectedChild = cf
        }
      })

      ft.Value = value.toString()
      ft.FriendlyValue = formatDistanceLabel(ft.Value, ft?.Value2 ?? selectedChild?.Value2)
      ft.Selected = true
    })

    onChange?.(result)
  }

  const onZipCodeChange = (value: string) => {
    let selectedChild: ISearchFilterResult = null
    const result = produce(internalFilter, (ft) => {
      ft.ChildrenFilter.forEach((cf) => {
        if (cf.Selected) {
          cf.Value2 = value
          selectedChild = cf
        }
      })

      if (value !== CurrentBuyerPostCode) {
        if (ft.ChildrenFilter.find((item) => item.Selected) === undefined) ft.ChildrenFilter[0].Selected = true
      } else {
        ft.ChildrenFilter[0].Selected = false
      }

      ft.Value = ft.Value || selectedChild?.Value
      ft.Value2 = value
      ft.FriendlyValue = formatDistanceLabel(!ft.Value ? ANY_DISTANCE_OPTION : ft.Value, ft.Value2)
      ft.Selected = true
    })

    onChange?.(result)
  }

  const onConditionGradeChange = (minVal: number, maxVal: number, values: number[]) => {
    const result = produce(internalFilter, (draft) => {
      draft.Value = formatNumber(values[0], { minimumFractionDigits: 1 })
      draft.Value3 = formatNumber(values[1], { minimumFractionDigits: 1 })
      draft.Selected = true
      draft.FriendlyValue = `${draft.Value} - ${draft.Value3}`

      if ((values[0] === 0 && values[1] === 0) || values[0] < minVal || values[1] > maxVal) {
        draft.Value = ''
        draft.Value3 = ''
        draft.Selected = false
      }
    })

    onChange?.(result)
  }

  if (internalFilter.DBFieldName.startsWith('carfax')) {
    return (
      <StyledCarfaxFilterItemContainer>
        <StyledCarfaxFilterItemHeader>{internalFilter.FieldName}</StyledCarfaxFilterItemHeader>

        <StyledCarfaxFilterItemContent>
          {isEmpty(internalFilter.ChildrenFilter) && <>{NO_FILTER_APPLICABLE}</>}
          {!isEmpty(internalFilter.ChildrenFilter) && (
            <StyledMultiSelectFilterSection>
              {internalFilter.ChildrenFilter.map((cf, idx) => (
                <Form.Check
                  key={`${cf.FilterKey}_${idx}`}
                  inline
                  custom
                  id={cf.FilterKey}
                  name={cf.FieldName}
                  value={cf.FilterKey}
                  defaultChecked={cf.Selected}
                  label={
                    <span>
                      {cf.FriendlyValue}{' '}
                      <span className="vehicle-count text-primary font-weight-bold">({cf.VehicleCount})</span>
                    </span>
                  }
                  onChange={(e) => onMultiSelectChange((ft) => ft.FilterKey === cf.FilterKey, e.currentTarget.checked)}
                />
              ))}
            </StyledMultiSelectFilterSection>
          )}
        </StyledCarfaxFilterItemContent>
      </StyledCarfaxFilterItemContainer>
    )
  }

  if (internalFilter.FieldId === DBFieldId.VIN) {
    const options = []
    const selectedOptions = []
    internalFilter.ChildrenFilter.forEach((cf) => {
      options.push({ value: cf.Value, label: cf.FriendlyValue })
      if (cf.Selected) selectedOptions.push({ value: cf.Value, label: cf.FriendlyValue })
    })

    return (
      <Select
        aria-label="vin-filter"
        options={options}
        isSearchable={true}
        placeholder="Enter VIN"
        isMulti={true}
        aria-autocomplete="both"
        defaultValue={selectedOptions}
        value={selectedOptions}
        closeMenuOnSelect={false}
        onChange={(options: { value: string; label: string }[]) =>
          onMultiSelectChange((ft) => options.some((opt) => opt.value === ft.Value), true, true)
        }
        noOptionsMessage={() => null}
      />
    )
  }

  if (isEmpty(internalFilter.ChildrenFilter)) {
    return <>{NO_FILTER_APPLICABLE}</>
  }

  if (internalFilter.MultiSelect) {
    return (
      <StyledMultiSelectFilterSection>
        {internalFilter.ChildrenFilter.map((cf, idx) => (
          <Form.Check
            key={`${cf.FilterKey}_${idx}`}
            inline
            custom
            id={cf.FilterKey}
            name={cf.FieldName}
            value={cf.FilterKey}
            checked={cf.Selected}
            label={
              <span>
                {cf.FriendlyValue}{' '}
                <span className="vehicle-count text-primary font-weight-bold">({cf.VehicleCount})</span>
              </span>
            }
            onChange={(e) => onMultiSelectChange((ft) => ft.FilterKey === cf.FilterKey, e.currentTarget.checked)}
          />
        ))}
      </StyledMultiSelectFilterSection>
    )
  }

  if (internalFilter.FieldId === DBFieldId.Proximity) {
    return (
      <StyledSliderFilterSection>
        <Form.Group as={Row} noGutters controlId="proximity-zipcode">
          <Form.Label column sm="3">
            Enter Zip Code
          </Form.Label>
          <Col sm="9">
            <Form.Control
              placeholder="Enter Zip Code"
              defaultValue={internalFilter.Value2}
              onChange={(e) => onZipCodeChange(e.currentTarget.value)}
            />
          </Col>
        </Form.Group>
        <Row noGutters>
          <Col>
            <Slider
              marks
              min={0}
              max={6}
              // @ts-ignore
              defaultValue={PROXIMITY_FILTERS.findIndex(
                (x) => x.toString() === internalFilter.ChildrenFilter.find((cf) => cf.Selected)?.Value
              )}
              textRenderFn={(value) =>
                // Ref from `AzureSearchManager.cs:873`
                ['Any', '25mi', '50mi', '100mi', '200mi', '500mi', '1000mi'][value]
              }
              onChange={(value) => {
                // Ref from `AzureSearchManager.cs:899`
                onProximityChange(PROXIMITY_FILTERS[value])
              }}
            />
          </Col>
        </Row>
      </StyledSliderFilterSection>
    )
  }

  if (internalFilter.FieldId === DBFieldId.OdometerCustomRange) {
    return (
      <StyledSliderFilterSection>
        <Slider
          // Ref from `AzureSearchManager.cs:915`
          min={0}
          max={150000}
          // @ts-ignore
          defaultValue={
            internalFilter.ChildrenFilter.filter((cf) => cf.Selected).length === 0
              ? [0, 150000]
              : internalFilter.ChildrenFilter.filter((cf) => cf.Selected).reduce((acc, obj) => {
                  acc.push(obj.Value)
                  acc.push(obj.Value3)

                  return acc
                }, [])
          }
          step={10000}
          textRenderFn={(value) => formatNumber(value)}
          onChange={(value: number[]) => onMultiValueSliderChange(0, 150000, value)}
        />
      </StyledSliderFilterSection>
    )
  }

  if (internalFilter.FieldId === DBFieldId.VehicleConditionCode) {
    return (
      <StyledSliderFilterSection>
        <StyledSliderFilterSection>
          <Slider
            // Ref from `AzureSearchManager.cs:962`
            min={0.0}
            max={5.0}
            // @ts-ignore
            defaultValue={
              internalFilter.ChildrenFilter.filter((cf) => cf.Selected).length === 0
                ? [0.0, 5.0]
                : internalFilter.ChildrenFilter.filter((cf) => cf.Selected).reduce((acc, obj) => {
                    acc.push(obj.Value)
                    acc.push(obj.Value3)

                    return acc
                  }, [])
            }
            step={0.1}
            textRenderFn={(value) =>
              formatNumber(value, {
                minimumFractionDigits: 1
              })
            }
            onChange={(value: number[]) => onConditionGradeChange(0.0, 5.0, value)}
          />
        </StyledSliderFilterSection>
      </StyledSliderFilterSection>
    )
  }
}
