import { gridBreakPoints } from 'common/theme'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'

export const StyledHomeSlideShow = styled.div`
  margin: 0 auto;

  .carousel-item:not(.active) {
    .carousel-caption {
      opacity: 0;
    }
  }

  .carousel-item {
    img {
      aspect-ratio: 4/1;
      object-fit: cover;
      object-position: center;
      margin: auto;
      animation: skeleton-loading 1s linear infinite alternate;
    }
    img.clickable {
      cursor: pointer;
    }
    @keyframes skeleton-loading {
      0% {
        background: rgba(0, 0, 0, 0.1);
      }
      100% {
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }

  .carousel-inner {
    overflow: visible;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background: none;
    height: unset;
    width: unset;
  }
  .carousel-control-prev {
    left: -4.4rem;
    width: 0;
  }
  .carousel-control-next {
    right: -4.4rem;
    width: 0;
  }
  .carousel-control-next-icon::after {
    content: '›';
    font-size: 7rem;
    font-weight: 500;
    color: #0070d2;
  }
  .carousel-control-prev-icon::before {
    content: '‹';
    font-size: 7rem;
    font-weight: 500;
    color: #0070d2;
  }
  .carousel-indicators {
    bottom: 0;
  }
  .carousel-indicators li {
    background-color: #808080;
    width: 4rem;
    border-radius: 0.2rem / 1.2rem;
  }
  .carousel-indicators .active {
    background-color: ${({ theme }) => theme.colors.primary};
  }
  .carousel-caption {
    position: initial;
    font-size: 1.6rem;
  }
`
export const StyledPromotion = styled.div`
  display: inline-block;
  position: relative;
  bottom: -4.5rem;
  color: #101010;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0 2.4rem;
  padding-bottom: 2rem;
  span {
    word-wrap: break-word;
  }

  @media (max-width: ${gridBreakPoints.sm}) {
    flex-direction: column;
    span {
      width: 100%;
    }
  }
`
export const StyledSearch = styled(Button)`
  width: 10rem;
  font-size: 1.4rem;
  margin-left: 2.5rem;
`
