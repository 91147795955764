import { Alert } from 'react-bootstrap'
import styled from 'styled-components'

export const StyledUnwatchPlaceholderAlert = styled(Alert).attrs({
  variant: 'warning'
})`
  min-height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
`

export const StyledAddWatchListSpan = styled.span`
  color: ${({ theme }) => theme.colors.linkColor};
  cursor: pointer;
`

export const StyledRemovedWatchListPlaceHolder = styled.div``
