import { faChevronRight, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment, PropsWithChildren, useMemo } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { useGlobalStore } from 'store/useGlobalStore'
import { IMenuInfo } from 'types/userTypes'
import { findMenuParent } from 'utils/menuUtils'
import shallow from 'zustand/shallow'
import { StyledBreadCrumbICon, StyledBreadCrumbMenu } from './BreadcrumbMenu.styled'

export const menus: IMenuInfo[] = [
  { path: '/terms', text: 'Terms and Condition', description: 'Terms and Condition' },
  { path: '/privacy', text: 'Privacy Policy', description: 'Privacy Policy' },
  { path: '/contactUs', text: 'Contact Us', description: 'Contact Us' },
  { path: '/faqs', text: 'Frequently Asked Questions', description: 'Frequently Asked Questions' }
]

export const BreadcrumbMenu = ({ children }: PropsWithChildren<any>) => {
  const routematch = useRouteMatch()
  const breadcrumbs = findMenuParent(menus, routematch.path)
  const userClaims = useGlobalStore((state) => state.userClaims, shallow)
  const isShow = useMemo(
    () => ((userClaims.IsTermAccepted && userClaims.Username) || !userClaims.Username ? true : false),
    [userClaims.Username, userClaims.IsTermAccepted]
  )

  return (
    <>
      {isShow && (
        <StyledBreadCrumbMenu>
          <StyledBreadCrumbICon>
            <Link to={!userClaims.Username ? '/login' : '/'}>
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </StyledBreadCrumbICon>
          {breadcrumbs?.map((item, index) => (
            <Fragment key={index}>
              <StyledBreadCrumbICon>
                <FontAwesomeIcon icon={faChevronRight} />
              </StyledBreadCrumbICon>
              {item.path ? <Link to={item.path}>{item.text}</Link> : <span>{item.text}</span>}
            </Fragment>
          ))}
        </StyledBreadCrumbMenu>
      )}
    </>
  )
}
