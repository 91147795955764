import { getContactUsDropDown, getValidatedVin, sendContactUsEmail } from 'apis/userApis'
import { MultiLanguageCode } from 'common/languageCodes'
import { LinkNameModal } from 'modules/ContactUs/LinkNameModal'
import { HTMLText } from 'components/Share/HtmlText'
import { useDtmAnalytics } from 'hooks/useDtmAnalytics'
import { useFetch } from 'hooks/useFetch'
import parse from 'html-react-parser'
import { BreadcrumbMenu } from 'layouts/BreadcrumbMenu'
import { Layout } from 'layouts/Layout'
import { LinkBaseStyled } from 'layouts/LoginLayout.styled'
import { useEffect, useRef, useState } from 'react'
import { Alert, Col, Form, Row } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { useGlobalStore } from 'store/useGlobalStore'
import { IContactReason, IContactUsData } from 'types/contactTypes'
import { validateVin } from 'utils/utils'
import shallow from 'zustand/shallow'
import {
  CancelButton,
  ContactBody,
  ContactHeader,
  ContactUsBody,
  FormBody,
  FormContainer,
  GroupButtonStyled,
  LinkNameStyled,
  LinkNameText,
  SubmitButton,
  Title
} from './ContactUs.styled'

interface IAlertData {
  textAlert: string
  result: boolean
  isDisplay: boolean
}

export const ContactUs = () => {
  const { data: contactReasons } = useFetch(() => getContactUsDropDown(vendor?.ID))
  const intl = useIntl()
  const [validated, setValidated] = useState(false)
  const [contactReasonOptions, setContactReasonOptions] = useState<IContactReason[]>([])
  const [contactReason, setContactReason] = useState<IContactReason>()
  const [userClaims, getLocalText, vendor] = useGlobalStore(
    (state) => [state.userClaims, state.getLocalText, state.vendor],
    shallow
  )
  const [displayVin, setDisplayVin] = useState(false)
  const [linkName, setLinkName] = useState('')
  const [linkText, setLinkText] = useState('')
  const [showModal, setShowModal] = useState(false)
  const handleClose = () => setShowModal(false)
  const handleShow = () => setShowModal(true)
  const [alertModel, setAlertModel] = useState<IAlertData>({ result: false, textAlert: '', isDisplay: false })
  const vinRef = useRef()
  const { endPageLoad } = useDtmAnalytics()

  useEffect(() => {
    setContactReasonOptions(contactReasons?.ContactReasons)
    endPageLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactReasons])

  const handleSubmit = async (event) => {
    event.preventDefault()
    let vinDomElement = vinRef.current as any
    setValidated(true)
    const form = event.currentTarget
    const isValidVin = !contactReason?.IsArbitrate || (await checkVinValidity(vinDomElement))
    if (form.checkValidity() && isValidVin) {
      let request: IContactUsData = {
        contactReason: contactReason,
        firstName: form.firstName.value,
        lastName: form.lastName.value,
        email: form.email.value,
        phone: form.phone?.value,
        company: form.company?.value,
        message: form.message.value,
        vin: form.Vin?.value,
        vendorId: vendor?.ID
      }

      const result = await sendContactUsEmail(request)

      if (result) {
        setAlertModel({ result, textAlert: getLocalText('CONTACTUS_SUCCESS', 'CONTACTUS_SUCCESS'), isDisplay: true })
      } else {
        let data: IAlertData = {
          result: false,
          textAlert: getLocalText('EMAIL_FAILED', 'EMAIL_FAILED'),
          isDisplay: true
        }
        setAlertModel(data)
      }
      resetFormField(form)
    }
    return
  }

  const selectOptionChange = (e: any) => {
    let data = contactReasonOptions.find((x) => x.Reason === e.target.value)
    setContactReason(data)
    setLinkName(data?.LinkName)
    setLinkText(data?.LinkText)
    setDisplayVin(data?.IsArbitrate ? true : false)
  }

  const checkVinValidity = async (el: any) => {
    let vin = el?.value
    let isValid = false

    if (validateVin(vin)) {
      isValid = await getValidatedVin(vendor.ID, vin)
    }

    if (isValid) {
      el.setCustomValidity('')
    } else {
      el.setCustomValidity('invalid')
    }
    return isValid
  }

  const vinOnChange = async () => {
    let vinDomElement = vinRef.current as any
    if (validated && vinDomElement) {
      await checkVinValidity(vinDomElement)
    }

    return
  }

  const resetFormField = (form: any) => {
    setValidated(false)

    form.firstName.value = ''
    form.lastName.value = ''
    form.email.value = ''
    form.phone.value = ''
    form.company.value = ''
    form.message.value = ''
    setContactReason({ ...contactReason, Reason: '' })

    if (contactReason?.IsArbitrate) {
      form.Vin.value = ''
    }
  }
  return (
    <Layout title="Contact Us">
      {showModal && <LinkNameModal show handleClose={handleClose} bodyText={linkText}></LinkNameModal>}
      <ContactUsBody>
        <Row>
          <Col>
            <BreadcrumbMenu />
          </Col>
        </Row>
        <Row>
          <Col>
            <Title>
              <HTMLText value={parse(intl.formatMessage({ id: MultiLanguageCode.CONTACT_US }))} />
            </Title>
          </Col>
        </Row>
        <FormContainer>
          <Col md={6}>
            <Row>
              <Col>
                <ContactHeader>
                  <HTMLText value={parse(intl.formatMessage({ id: MultiLanguageCode.CONTACTUS_HEADER }))} />
                </ContactHeader>
              </Col>
            </Row>
            <Row>
              <Col lg={10}>
                <ContactBody>
                  <HTMLText value={parse(intl.formatMessage({ id: MultiLanguageCode.CONTACTUS_BODY }))} />
                </ContactBody>
              </Col>
            </Row>

            <FormBody className="mb-3">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col} sm={12} xs={12} lg={10}>
                    {alertModel?.isDisplay && (
                      <Alert
                        variant={alertModel?.result ? 'success' : 'danger'}
                        onClose={() => setAlertModel({ ...alertModel, isDisplay: false })}
                        dismissible
                      >
                        <p>{alertModel?.textAlert}</p>
                      </Alert>
                    )}
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-3">
                  <Form.Group as={Col} sm={12} xs={12} lg={5} controlId="exampleForm.SelectCustomSizeLg">
                    <Form.Label>Contact Reason</Form.Label>
                    <Form.Control
                      as="select"
                      size="lg"
                      custom
                      onChange={(e) => selectOptionChange(e)}
                      value={contactReason?.Reason}
                      required
                    >
                      {contactReasonOptions?.map((option) => {
                        return (
                          <option key={option.Reason} value={option.Reason ?? ''}>
                            {option.Reason}
                          </option>
                        )
                      })}
                    </Form.Control>
                  </Form.Group>
                  {linkName && (
                    <LinkNameStyled as={Col}>
                      <LinkNameText onClick={handleShow}>{linkName}</LinkNameText>
                    </LinkNameStyled>
                  )}
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} sm={12} xs={12} lg={5} controlId="firstName">
                    <Form.Label>First Name*</Form.Label>
                    <Form.Control name="firstName" type="text" required />
                    <Form.Control.Feedback type="invalid">Please enter a first name</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} sm={12} xs={12} lg={5} controlId="lastName">
                    <Form.Label>Last Name*</Form.Label>
                    <Form.Control name="lastName" type="text" required />
                    <Form.Control.Feedback type="invalid">Please enter a last name</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} sm={12} xs={12} lg={5} controlId="email">
                    <Form.Label>Email*</Form.Label>
                    <Form.Control name="email" type="email" required />
                    <Form.Control.Feedback type="invalid">Please enter an email</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={12} xs={12} lg={5} controlId="company">
                    <Form.Label>Company</Form.Label>
                    <Form.Control name="company" type="text" />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  {displayVin && (
                    <Form.Group as={Col} sm={12} xs={12} lg={5} controlId="Vin">
                      <Form.Label>VIN*</Form.Label>
                      <Form.Control
                        name="Vin"
                        type="text"
                        placeholder="VIN #"
                        ref={vinRef}
                        onChange={vinOnChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">Please enter a Vin</Form.Control.Feedback>
                    </Form.Group>
                  )}

                  <Form.Group as={Col} sm={12} xs={12} lg={5} controlId="phone">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control name="phone" type="text" />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} sm={12} xs={12} lg={10} controlId="message">
                    <Form.Label>Message*</Form.Label>
                    <Form.Control
                      name="message"
                      as="textarea"
                      rows={4}
                      placeholder="Enter your message here"
                      required
                    />
                    <Form.Control.Feedback type="invalid">Please enter a message</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <GroupButtonStyled className="text-right" as={Col} sm={12} xs={12} lg={10}>
                    <CancelButton>
                      <LinkBaseStyled to={!userClaims.Username ? '/login' : '/'}>
                        <FormattedMessage id={MultiLanguageCode.CANCEL_BUTTON} />
                      </LinkBaseStyled>
                    </CancelButton>
                    <SubmitButton type="submit" size="sm">
                      <FormattedMessage id={MultiLanguageCode.SUBMIT} />
                    </SubmitButton>
                  </GroupButtonStyled>
                </Form.Row>
              </Form>
            </FormBody>
          </Col>
        </FormContainer>
      </ContactUsBody>
    </Layout>
  )
}
