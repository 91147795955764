import { largeScreen } from 'common/theme'
import styled from 'styled-components'

export const StyledRibbon = styled.span<{ backgroundColor: string; textColor: string }>`
  position: relative;
  line-height: 2rem !important;

  .ribbon {
    background-color: ${(props) => props.backgroundColor};
    position: relative;
    padding: 0 0.5rem;
    display: inline-block;
    line-height: 1rem;
    margin-left: 0.8rem;
  }

  .ribbon-title {
    line-height: 2rem;
    color: #fff;
    white-space: nowrap;

    color: ${(props) => props.textColor};
    font-weight: bold;
  }

  .ribbon-tail {
    position: absolute;
    top: 0;
    right: -1rem;
  }

  .ribbon-tail:after,
  .ribbon-tail:before {
    content: '';
    width: 0;
    height: 0;
    display: block;
  }

  .ribbon-tail:after {
    border-right: 1rem solid transparent;
    border-bottom: 1rem solid ${(props) => props.backgroundColor};
  }

  .ribbon-tail:before {
    border-right: 1rem solid transparent;
    border-top: 1rem solid ${(props) => props.backgroundColor};
  }

  @media (max-width: ${largeScreen}) {
    .ribbon {
      margin-left: 0;
    }
  }

  @media print {
    text-decoration: none;
    .ribbon {
      background-color: ${(props) => props.backgroundColor};
      -webkit-print-color-adjust: exact;
    }
    .ribbon-tail {
      position: absolute;
      top: 0;
      right: -1rem;
    }
  }
  &&.clickable {
    cursor: pointer;
  }
`
