import { Col, FormGroup } from 'react-bootstrap'
import styled from 'styled-components'

export const StyledForgotPassword = styled(Col)`
  max-width: 36rem;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.fontColor};
  .form {
    max-width: 100%;
    width: inherit;
  }

  .forgot-user-id {
    margin-bottom: 0.1rem !important;
  }
`

export const StyledForgotUserLink = styled.a`
  display: flex;
  float: right;
  margin-bottom: 2rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.linkColor};
`

export const LoginLinkWrapper = styled.div`
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: 3rem;
  font-weight: bold;
  margin-bottom: 2rem;
`
export const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 2rem;
`
export const StyledResetPassword = styled.div`
  justify-content: center;
  min-height: 30rem;
`
export const StyledLable = styled.div`
  margin-bottom: 2rem;
`
