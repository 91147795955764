import { fetchAsync, postAsync } from 'common/fetch'
import { IBaseResponse } from 'types/baseTypes'
import {
  GetBidVehiclesRequest,
  IGetBidVehiclesResponse,
  ISaveWatchlistRequest,
  ISearchVehicleResponse
} from 'types/vehicleTypes'
import { CarouselImage } from 'types/baseTypes'
import { IModelGroupImages } from 'types/IModelFigure'

export const getBidVehicles = async (request: GetBidVehiclesRequest) => {
  return postAsync<IGetBidVehiclesResponse>(`/api/dashboard/GetBidVehicles`, {
    body: request
  })
}

export const getWatchListVehicles = async (request: GetBidVehiclesRequest) => {
  return postAsync<IGetBidVehiclesResponse>(`/api/dashboard/GetWatchListVehicles`, {
    body: request
  })
}

export const saveVehicleLevelWatchList = async (request: ISaveWatchlistRequest) => {
  return postAsync<IBaseResponse<number>>(`/api/dashboard/SaveVehicleToWatchList`, {
    body: request
  })
}

export const getDirectSaleVehicles = async (request: GetBidVehiclesRequest) => {
  return postAsync<IGetBidVehiclesResponse>(`/api/dashboard/GetDirectSaleVehicles`, {
    body: request
  })
}

export const getCarouselImages = async (): Promise<CarouselImage[]> => {
  return fetchAsync(`/api/dashboard/GetCarouselImages`)
}

export const getModelGroupImages = () => {
  return fetchAsync<IModelGroupImages[]>('/api/dashboard/GetModelGroupImages')
}

export const saveWatchlist = async (request: ISaveWatchlistRequest) => {
  return postAsync<ISearchVehicleResponse>('/api/dashboard/SaveVehicleToWatchList', {
    body: request
  })
}
