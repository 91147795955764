import { MultiLanguageCode } from 'common/languageCodes'
import { AUTH_ERROR, AUTH_ERROR_2 } from 'common/messages'
import { FormInput, TextDanger } from 'components/Forms'
import { HTMLText } from 'components/Share/HtmlText'
import { useFormik } from 'formik'
import parse from 'html-react-parser'
import { LoginFormFooter, LoginLayout } from 'layouts/LoginLayout'
import { ClickHereLink, ForgotLink, FormLoginBody, LoginTitle } from 'layouts/LoginLayout.styled'
import GenericMessageWarning from 'modules/GenericMessageWarning'
import { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { Form, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { resetSRPFilter } from 'utils/menuUtils'
import { getUrlParameter } from 'utils/urlParams'
import * as Yup from 'yup'
import { login } from '../apis/userApis'
import { StyledCol, StyledFormContainer, StyledSubTitle, StyledSubmitButton } from './Login.styled'

export const Login = () => {
  const intl = useIntl()
  const loginForm = useRef<HTMLFormElement>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [errorCount, setErrorCount] = useState<number>(0)
  const [wasInitiallyAutofilled, setWasInitiallyAutofilled] = useState(false)

  useLayoutEffect(() => {
    const checkAutofilled = () => {
      try {
        const autofilled = !!document.getElementById('username')?.matches('*:-webkit-autofill')
        setWasInitiallyAutofilled(autofilled)
      } catch {}
    }

    setTimeout(checkAutofilled, 100)
    setTimeout(checkAutofilled, 500)
    setTimeout(checkAutofilled, 1000)
  }, [])

  const validate = () => {
    setWasInitiallyAutofilled(false)
  }

  const loginHandler = async (values, { setSubmitting }) => {
    try {
      setErrorMessage('')
      const data = await login(values.username.toString(), values.password.toString())
      if (data.isFirstLogin.toLocaleLowerCase() === 'true') {
        window.location.href = '/account?fl=1'
        return
      }
      const redirectLink = getUrlParameter(window.location.href, 'returnUrl', true, true)
      resetSRPFilter()
      window.location.href = decodeURIComponent(redirectLink || '/')
    } catch (error) {
      setErrorCount(errorCount + 1)
      setErrorMessage(errorCount > 2 ? AUTH_ERROR_2 : AUTH_ERROR)
    } finally {
      setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    onSubmit: loginHandler,
    validate,
    validationSchema: Yup.object({
      username: Yup.string().required('Please enter a User ID'),
      password: Yup.string().required('Please enter a password')
    })
  })

  const disableSubmitButton = useMemo(
    () =>
      (!(formik.values.username !== '' && formik.values.password !== '') && !wasInitiallyAutofilled) ||
      formik.isSubmitting,
    [formik, wasInitiallyAutofilled]
  )

  return (
    <LoginLayout>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <StyledFormContainer>
        <StyledCol>
          <Row>
            <div>
              <LoginTitle>
                <HTMLText value={parse(intl.formatMessage({ id: MultiLanguageCode.SITE_TITLE }))} />
              </LoginTitle>
            </div>
            <FormLoginBody>
              <Form noValidate ref={loginForm} onSubmit={formik.handleSubmit}>
                <StyledSubTitle>
                  <span>Don't have an account? </span>
                  <ClickHereLink
                    to={{ pathname: 'https://support.bmwgroupdirect.com/registration#/auth' }}
                    target="_blank"
                  >
                    Click here.
                  </ClickHereLink>
                </StyledSubTitle>

                <FormInput
                  name="username"
                  type="text"
                  placeholder="Enter User ID"
                  error={formik.errors.username}
                  label="User ID"
                  onChange={formik.handleChange}
                  value={formik.values.username}
                  isInvalid={!!formik.errors.username}
                />
                <FormInput
                  name="password"
                  type="password"
                  placeholder="Enter Password"
                  error={formik.errors.password}
                  label={MultiLanguageCode.PASSWORD}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  isInvalid={!!formik.errors.password}
                  className="text-input-password"
                />

                <ForgotLink>
                  <Link to={'/forgot'}>Forgot User ID or Password?</Link>
                </ForgotLink>

                <StyledSubmitButton type="submit" disabled={disableSubmitButton}>
                  <FormattedMessage id={MultiLanguageCode.LOGIN} />
                </StyledSubmitButton>

                {errorMessage && <TextDanger>{errorMessage}</TextDanger>}

                <LoginFormFooter />
              </Form>
            </FormLoginBody>
          </Row>
        </StyledCol>
      </StyledFormContainer>
      <GenericMessageWarning isAlert={true} />
    </LoginLayout>
  )
}
