import { MultiLanguageCode } from 'common/languageCodes'
import { HTMLText } from 'components/Share/HtmlText'
import { useIntl } from 'react-intl'
import parse from 'html-react-parser'
import { TermConditionBody, Title } from './TermCondition.styled'
import { Col, Row } from 'react-bootstrap'
import { Layout } from 'layouts/Layout'
import { BreadcrumbMenu } from 'layouts/BreadcrumbMenu'

import { TERM_AND_CONDITION } from 'common/constants'

export const TermCondition = () => {
  const intl = useIntl()

  return (
    <Layout title={TERM_AND_CONDITION}>
      <TermConditionBody>
        <Row>
          <Col>
            <BreadcrumbMenu />
          </Col>
        </Row>
        <Row>
          <Col>
            <Title>
              <HTMLText value={parse(intl.formatMessage({ id: MultiLanguageCode.TERMS_LINK }))} />
            </Title>
          </Col>
        </Row>
        <HTMLText value={parse(intl.formatMessage({ id: MultiLanguageCode.TERMS_TEXT }))} />
      </TermConditionBody>
    </Layout>
  )
}
