import { IconProps } from 'core/typing/IconType'
import { ThemedSvg } from './ThemedSvg.styled'

export const Door = ({ width = '16px', height = '16px', color = 'currentColor' }: IconProps) => {
  return (
    <ThemedSvg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 188 222">
      <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
        <path
          d="M955 1986 c-107 -34 -219 -119 -481 -363 -115 -107 -351 -357 -426
-453 l-43 -54 -3 -301 c-2 -165 2 -350 7 -412 13 -132 49 -279 85 -351 l26
-52 820 0 c554 0 828 3 847 11 94 35 152 172 188 444 22 167 29 425 15 525 -8
49 -15 263 -19 510 -6 398 -7 428 -26 465 l-20 40 -465 2 c-353 1 -475 -1
-505 -11z m875 -471 l0 -325 -677 2 -678 3 -3 90 c-2 50 -1 99 2 111 6 24 199
204 341 317 168 135 130 127 608 127 l407 0 0 -325z m-1450 -270 l0 -55 -35 0
c-39 0 -70 34 -59 64 7 19 51 45 77 46 14 0 17 -9 17 -55z m1505 -165 c0 -13
-107 -15 -889 -18 -531 -1 -894 2 -899 7 -30 30 14 31 896 29 785 -3 892 -5
892 -18z m-106 -120 c34 -9 81 -46 81 -62 0 -4 -70 -8 -155 -8 -155 0 -156 0
-145 21 26 47 138 72 219 49z m81 -218 c0 -15 -41 -53 -68 -64 -15 -5 -55 -10
-90 -10 -68 1 -113 20 -140 60 l-14 22 156 0 c86 0 156 -4 156 -8z m-25 -472
c0 -13 -103 -15 -852 -18 -849 -2 -853 -2 -853 18 0 20 4 20 853 18 749 -3
852 -5 852 -18z"
        />
      </g>
    </ThemedSvg>
  )
}
