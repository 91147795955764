import { saveDisclosure } from 'apis/vehicleApis'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { IDocumentSignInfor, IVehicleInfo } from 'types/vehicleTypes'
import { StyledAuctionButton } from './VehicleAuction.styled'
import { StyleDisclosureWarningSpan } from './VehicleDisclosureModal.styled'
import styled from 'styled-components'

const StyledDisclosureModalBody = styled(Modal.Body)`
  & > * + * {
    margin-top: 1.5rem;
  }
`

interface IBidsHistoryProps {
  vehicleInfo: IVehicleInfo
  documentSignInfo: IDocumentSignInfor
  handleSubmitSuccess: () => void
  onClose: () => void
}

export const VehicleDisclosureModal = ({
  vehicleInfo,
  documentSignInfo,
  handleSubmitSuccess,
  onClose
}: IBidsHistoryProps) => {
  const [enableDisclosureButtons, setEnableDisclosureButtons] = useState(false)
  const [displayDisclosureError, setDisplayDisclosureError] = useState(false)
  const enableDisclosureControl = () => {
    setEnableDisclosureButtons(true)
    window.open(
      '/resource/GetVehicleInstanceDocumentByID?documentID=' + documentSignInfo.VehicleInstanceDocumentsID,
      "_parent'"
    )
  }

  const acceptDisclosure = async () => {
    setDisplayDisclosureError(false)
    try {
      const response = await saveDisclosure(documentSignInfo)
      if (response.Value === 0) {
        handleSubmitSuccess()
      } else {
        setDisplayDisclosureError(true)
        setEnableDisclosureButtons(true)
      }
      onClose()
    } catch (error) {
      console.log('SAVE DISCLOSURE ERROR: ' + error)
      setDisplayDisclosureError(true)
    }
  }
  return (
    <>
      <Modal.Header closeButton>Vehicle Disclosure Information</Modal.Header>
      <StyledDisclosureModalBody>
        <h5>
          <span>VIN: {vehicleInfo.VIN}</span>
        </h5>
        <h5>
          <span>Document: </span>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="document-link" href="#" onClick={() => enableDisclosureControl()}>
            View Disclosure Document
          </a>
        </h5>
        <StyleDisclosureWarningSpan>
          Prior Use Disclosure: By clicking "Accept", I acknowledge I have received and reviewed the above listed
          disclosure document and forfeit any right to arbitrate any dispute based upon the condition stated in this
          disclosure.
        </StyleDisclosureWarningSpan>
        <StyleDisclosureWarningSpan>
          Note: Users can only proceed with the bidding/buying process after reviewing and accepting the disclosure
          document
        </StyleDisclosureWarningSpan>
        {displayDisclosureError && (
          <StyleDisclosureWarningSpan>Request failed. Please try again.</StyleDisclosureWarningSpan>
        )}
      </StyledDisclosureModalBody>
      <Modal.Footer>
        <StyledAuctionButton disabled={!enableDisclosureButtons} onClick={() => acceptDisclosure()}>
          <span>Accept</span>
        </StyledAuctionButton>
        <StyledAuctionButton onClick={() => onClose()}>
          <span>Decline</span>
        </StyledAuctionButton>
      </Modal.Footer>
    </>
  )
}
