import { ButtonPopup, ConfirmationGroupButton, ModalSelectView } from 'components/AlertDialog/AlertDialog.styled'
import React from 'react'
import { Container, Modal, ModalProps, Row } from 'react-bootstrap'

interface IProps extends ModalProps {
  title?: string
  description?: string
  onHide: () => void
  onSave?: (request: any) => Promise<void>
  isConfirmation?: boolean
}

export const AlertDialog = ({ title, description, isConfirmation = false, onHide, onSave, ...props }: IProps) => {
  return (
    <ModalSelectView {...props} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>{description}</Row>
          <Row>
            {isConfirmation ? (
              <ConfirmationGroupButton>
                <ButtonPopup onClick={onHide} size="sm">
                  No
                </ButtonPopup>
                <ButtonPopup onClick={onSave} size="sm">
                  Yes
                </ButtonPopup>
              </ConfirmationGroupButton>
            ) : (
              <ButtonPopup onClick={onHide} size="sm">
                OK
              </ButtonPopup>
            )}
          </Row>
        </Container>
      </Modal.Body>
    </ModalSelectView>
  )
}
