import { getBidVehicles, getWatchListVehicles } from 'apis/dashboardApis'
import { searchVehicles } from 'apis/vehicleApis'
import { BUY_VEHICLE_NO_ACTIVITY, SessionStorageKey } from 'common/constants'
import { AlertDialog } from 'components/AlertDialog/AlertDialog'
import { BuyerSearch } from 'modules/SRP/BuyerSearch'
import { CurrentBidsCount } from 'modules/Watchlist/CurrentBidsCount'
import { WatchListCount } from 'modules/Watchlist/WatchListCount'
import { FileEarMarkArrowDown } from 'images/icon/FileEarMarkArrowDown'
import { Layout } from 'layouts/Layout'
import { parseInt } from 'lodash'
import { BiddingSidePanel } from 'modules/BiddingSidePanel'
import { VehicleList } from 'modules/VehicleList'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { useGlobalStore } from 'store/useGlobalStore'
import { GetBidVehiclesRequest, IGetBidVehiclesResponse, ISearchVehiclesRequest } from 'types/vehicleTypes'
import { setListingBreadcrumb } from 'utils/breadcrumbUtils'
import { downloadURI } from 'utils/exportFileUtil'
import { useFetch } from '../hooks/useFetch'
import { SearchFilterColumn, StyledSearchResultsPage, StyledTopPaging } from './SearchResultsPage.styled'
import { VehicleListContext, VehicleListContextProvider } from 'modules/VehicleList/VehicleListContext'
import { Paging } from 'components/Paging'
import { useDtmAnalytics } from 'hooks/useDtmAnalytics'
import { DefaultErrorBoundary } from 'modules/DefaultErrorBoundary'
import { SlideOutSearchFilter } from 'modules/SRP/SlideOutSearchFilter'
import { FilterSearchContext, FilterSearchContextProvider } from 'contexts/FilterSearchContext'
import { SearchFilterButton } from 'modules/SRP/SearchFilterButton'

export interface IAlertProps {
  message: string
  modalShow: boolean
}

export const SearchResultsPageContent = () => {
  const location = useLocation()
  const { setVehiclesState, totalPages, currentPage } = useContext(VehicleListContext)
  const { queryRequest, showSlideOutFilterSearch, closeShowSlideOutFilterSearch } = useContext(FilterSearchContext)

  // Hooks & Contexts
  const [getLocalText, getSystemSetting] = useGlobalStore((state) => [state.getLocalText, state.getSystemSetting])
  const pageTitle = getLocalText('SEARCH_RESULTS', 'Search Results')
  const noVehicleText = getLocalText('NO_VEHICLE_FOUND_BODY', BUY_VEHICLE_NO_ACTIVITY.SEARCH_RESULTS)
  const showRetailViewOpt = (getSystemSetting('SHOW_SRP_RETAIL_VIEW_OPTION') as string) === '1'
  const [alertMessage, setAlertMessage] = useState<IAlertProps>({ message: '', modalShow: false })

  const { endPageLoad } = useDtmAnalytics()
  // WL/CB data
  const { data: { TotalRecords: watchListCount } = {} as IGetBidVehiclesResponse, loading: watchListCountLoading } =
    useFetch(() => getWatchListVehicles(new GetBidVehiclesRequest()))
  const { data: { TotalRecords: currentBidsCount } = {} as IGetBidVehiclesResponse, loading: currentBidsCountLoading } =
    useFetch(() => getBidVehicles(new GetBidVehiclesRequest()))

  const getSRPRetailViewReport = (selectedVehicles) => {
    const maxVehiclesNumberSRPRetail = parseInt(getSystemSetting('SRP_RETAIL_VIEW_MAX_VEHICLES_SELECTED') as string)

    if (selectedVehicles?.length > 0 && selectedVehicles?.length <= maxVehiclesNumberSRPRetail) {
      const url = `resource/GetSRPRetailViewReport?vehicleInstanceIds=${selectedVehicles.join()}`
      downloadURI(url)
      setVehiclesState((state) => {
        state.selectedVehicles = []
      })
    } else if (selectedVehicles?.length > maxVehiclesNumberSRPRetail) {
      let warningText = getLocalText('SRP_RETAIL_VIEW_WARNING', 'You cannot select more than %NUMBER% vehicles')
      warningText = warningText.replace('%NUMBER%', maxVehiclesNumberSRPRetail as never)
      setAlertMessage({
        message: warningText,
        modalShow: true
      })
    } else {
      setAlertMessage({
        message: 'Please select one or more vehicles.',
        modalShow: true
      })
    }
  }

  const handleSearch = useCallback(async (request: ISearchVehiclesRequest) => {
    const response = await searchVehicles(request)
    endPageLoad(response.Items, {
      searchData: {
        sortBy: response.SortBy,
        sortOptions: response.SortOptions,
        filters: request?.SearchQuery?.SearchFilters,
        TotalRecords: response.TotalRecords,
        PageSize: response.PageSize
      }
    })

    sessionStorage.setItem(SessionStorageKey.PAGE_SELECTED, response.PageNumber.toString())
    sessionStorage.setItem(SessionStorageKey.PAGE_SIZE, response.PageSize.toString())
    sessionStorage.setItem(SessionStorageKey.SESSION_SORT, JSON.stringify(response.SortBy))

    setTimeout(() => {
      scrollToVin()
    }, 500)

    return response
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const scrollToVin = () => {
    var vin = sessionStorage.getItem(SessionStorageKey.SCROLL_TO_VIN)
    let topPosition = 0
    if (vin && vin !== '') {
      let elementDiv = document.getElementById('vehicle_' + vin)
      let adjustmentPosition = 0

      if (sessionStorage.getItem(SessionStorageKey.VIEW_VEHICLE_LIST) === 'grid') adjustmentPosition = 220

      if (elementDiv && elementDiv.parentElement && elementDiv.parentElement.parentElement) {
        topPosition = elementDiv.parentElement.parentElement.offsetTop + adjustmentPosition
      }
      sessionStorage.removeItem(SessionStorageKey.SCROLL_TO_VIN)
    }

    window.scrollTo({
      top: topPosition,
      behavior: 'auto'
    })
  }

  useEffect(() => {
    setListingBreadcrumb(pageTitle, location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTitle])
  return (
    <StyledSearchResultsPage>
      <Layout title={pageTitle}>
        {showRetailViewOpt && (
          <AlertDialog
            title="Retail View"
            description={alertMessage?.message}
            show={alertMessage?.modalShow}
            onHide={() => setAlertMessage({ ...alertMessage, modalShow: false })}
          />
        )}
        <StyledTopPaging>
          {totalPages > 0 && (
            <Paging
              showRecordsPerPage={false}
              currentPage={currentPage}
              totalPages={totalPages}
              handleChangedPage={(pageNumber) =>
                setVehiclesState((state) => {
                  state.currentPage = pageNumber - 1
                })
              }
            />
          )}
        </StyledTopPaging>
        <Row noGutters>
          <SearchFilterColumn xs={3}>
            <BuyerSearch />
          </SearchFilterColumn>
          <Col>
            <VehicleList
              title={
                <>
                  <SearchFilterButton showFilterSearch /> {pageTitle}
                </>
              }
              showCheckbox={showRetailViewOpt}
              bottomPagination
              queryFunction={handleSearch}
              queryRequest={queryRequest}
              navigationLink={() => (
                <>
                  <CurrentBidsCount loading={currentBidsCountLoading} count={currentBidsCount} />
                  <WatchListCount loading={watchListCountLoading} count={watchListCount} />
                </>
              )}
              checkboxAction={
                !showRetailViewOpt
                  ? null
                  : (selectedVehicles) => (
                      <Link className="navigation-link" to="#" onClick={() => getSRPRetailViewReport(selectedVehicles)}>
                        <span className="right-action">
                          <FileEarMarkArrowDown />
                          <span className="export-view">{getLocalText('SRP_RETAIL_VIEW', 'Retail View')}</span>
                        </span>
                      </Link>
                    )
              }
              noActivityMessage={noVehicleText}
              gridLayoutColProps={{ xs: 12, lg: 4, md: 6 }}
            />
          </Col>
        </Row>
        <DefaultErrorBoundary>
          <BiddingSidePanel isDealerShipSelectable={false}></BiddingSidePanel>
        </DefaultErrorBoundary>
        <SlideOutSearchFilter
          isOpen={showSlideOutFilterSearch}
          closeFilter={closeShowSlideOutFilterSearch}
        ></SlideOutSearchFilter>
      </Layout>
    </StyledSearchResultsPage>
  )
}

export const SearchResultsPage = () => {
  return (
    <VehicleListContextProvider>
      <FilterSearchContextProvider>
        <SearchResultsPageContent />
      </FilterSearchContextProvider>
    </VehicleListContextProvider>
  )
}
