import { SessionStorageKey } from 'common/constants'

export const setListingBreadcrumb = (pageTitle: string, url: string) => {
  sessionStorage.removeItem(SessionStorageKey.DETAIL_BREADCRUMB)

  url = url.replace('?clearSearch=true', '')
  const filterIndex = url.indexOf('?filters')
  if (filterIndex > -1) {
    url = url.substring(0, filterIndex)
  }

  sessionStorage.setItem(
    SessionStorageKey.LIST_BREADCRUMB,
    JSON.stringify({
      description: pageTitle,
      url: url
    })
  )
}
