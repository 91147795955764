import CaretDownFill from 'images/icon/CaretDownFill'
import CaretRightFill from 'images/icon/CaretRightFill'
import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { ReactNode } from 'react'
import {
  StyledHomeSectionHeaderTitle,
  StyledHomeSectionHeader,
  StyledHomeSectionHeaderBanner
} from './HomeSectionHeader.styled'

export interface IHomeSectionHeaderProps {
  sectionTitle: ReactNode
  onClick?: any
  isExpand?: boolean
  displayCollapse?: boolean
  itemCount?: number
  leftActions?: React.ReactNode
  rightActions?: React.ReactNode
  leftActionSpan?: number
  rightActionSpan?: number
  className?: string
}

export const HomeSectionHeader = ({
  sectionTitle,
  className,
  onClick,
  isExpand,
  displayCollapse,
  itemCount,
  leftActions,
  rightActions,
  leftActionSpan = 7,
  rightActionSpan = 2
}: IHomeSectionHeaderProps) => (
  <StyledHomeSectionHeader className={`${className} header-container`}>
    <Row className="header-row">
      <Col md lg={3} className="p-0 header-col">
        <StyledHomeSectionHeaderTitle className="header-toggle" onClick={onClick} clickable={!!onClick}>
          {displayCollapse ?? (isExpand ? <CaretDownFill /> : <CaretRightFill />)}
          {sectionTitle}
          {itemCount >= 0 && ` (${itemCount})`}
        </StyledHomeSectionHeaderTitle>
      </Col>
      {leftActions && (
        <Col md lg={leftActionSpan}>
          <StyledHomeSectionHeaderBanner className="header-section">{leftActions}</StyledHomeSectionHeaderBanner>
        </Col>
      )}
      {rightActions && (
        <Col md lg={rightActionSpan} className="right-action-col">
          <StyledHomeSectionHeaderBanner className="header-section justify-content-center justify-content-lg-end">
            {rightActions}
          </StyledHomeSectionHeaderBanner>
        </Col>
      )}
    </Row>
  </StyledHomeSectionHeader>
)
