import { Button } from 'react-bootstrap'
import styled from 'styled-components'

export const StyledSpinnerButton = styled(Button)`
  .spinner-border {
    margin-left: 5px;
    width: 1em;
    height: 1em;
  }
`
