import { rmsAuthModel } from './rmsGlobal'

export const SystemSettingsCode = {
  ENABLE_DTM_ANALYTICS_TRACE: 'ENABLE_DTM_ANALYTICS_TRACE',
  DTM_ANALYTICS_ENVIRONMENT: 'DTM_ANALYTICS_ENVIRONMENT',
  SHOW_TRUE_360: 'SHOW_TRUE_FRAME_ICON'
}

export const isSystemSettingsEnabled = (code: string) => {
  return rmsAuthModel?.systemSettings[code] === '1'
}

export const getSystemSetting = (code: string) => {
  return rmsAuthModel?.systemSettings[code]
}
