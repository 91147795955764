import { NavLink } from 'components/Share/Link'
import { useContext } from 'react'
import { IVehicleData } from 'types/vehicleTypes'
import { setCustomClick } from 'utils/analyticsUtils'
import { getVdpUrl } from 'utils/urlUtils'
import { VehicleContext } from './VehicleContext'
import { StyledTitleSection, TitleVDP } from './VehicleDetails.styled'
import { VehicleRibbons } from './VehicleRibbons'
import { SessionStorageKey } from 'common/constants'
interface IProps {
  vehicleData: IVehicleData
}

export const VehicleTitleSection = ({ vehicleData }: IProps) => {
  const { isVDP, viewStyle } = useContext(VehicleContext)
  const detailLink = getVdpUrl(vehicleData.vehicle.InstanceID)
  return (
    <StyledTitleSection className="justify-content-between">
      {isVDP ? (
        <TitleVDP>{vehicleData.vehicle.Title}</TitleVDP>
      ) : (
        <NavLink
          onClick={() => {
            setCustomClick(vehicleData.vehicle.Title, {
              usePageName: true
            })
            sessionStorage.setItem(SessionStorageKey.VIEW_VEHICLE_LIST, viewStyle.toString())
          }}
          to={detailLink}
        >
          {vehicleData.vehicle.Title}
        </NavLink>
      )}
      <VehicleRibbons vehicleInfo={vehicleData.vehicle} />
    </StyledTitleSection>
  )
}
