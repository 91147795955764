import { ChangeEvent, useMemo, useState } from 'react'
import {
  StyledToggleSwitchWrapper,
  StyledToggleInput,
  StyledToggleSwitch,
  StyledToggleStatus
} from './ToggleSwitch.styled'

import { uniqueId } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { IToggleSwitchProps } from '../../types/IToggleSwitchProps'

export const ToggleSwitch = ({
  label,
  statusText = {
    true: 'ON\u00a0',
    false: 'OFF'
  },
  showIcon,
  onToggle,
  checked,
  ...props
}: IToggleSwitchProps) => {
  const [id] = useState(uniqueId('sw-'))
  const isLeftRight = useMemo(
    () => statusText && typeof statusText === 'object' && statusText.left && statusText.right,
    [statusText]
  )
  const isTrueFalse = useMemo(
    () => statusText && typeof statusText === 'object' && statusText.true && statusText.false,
    [statusText]
  )

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (props?.disabled) return

    onToggle && onToggle(e.target.checked)
  }

  return (
    <StyledToggleSwitchWrapper>
      {label && <span>{label}</span>}
      {typeof statusText === 'object' && isLeftRight && (
        <StyledToggleStatus htmlFor={id} className={'ml-3'}>
          {statusText.left}
        </StyledToggleStatus>
      )}
      <StyledToggleInput id={id} onChange={handleChange} checked={checked} {...props} />
      <StyledToggleSwitch htmlFor={id}>
        <span>{showIcon && <FontAwesomeIcon icon={checked ? faCheck : faTimes}></FontAwesomeIcon>}</span>
      </StyledToggleSwitch>
      {statusText && (
        <StyledToggleStatus htmlFor={id} className={'with-color'}>
          {typeof statusText === 'object'
            ? isLeftRight
              ? statusText.right
              : isTrueFalse
              ? checked
                ? statusText.true
                : statusText.false
              : '' /* Fallback */
            : statusText}
        </StyledToggleStatus>
      )}
    </StyledToggleSwitchWrapper>
  )
}
