import { Button } from 'react-bootstrap'
import styled from 'styled-components'

export const AcceptenceBody = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundText};
  margin-left: 2.4rem;
  margin-right: 2.4rem;
  margin-bottom: 2.4rem;
  flex: 1;
  font-size: 1.6rem;

  p {
    background: inherit !important;
  }

  span {
    font-family: inherit !important;
    font-size: inherit !important;
  }

  div:not([class]) {
    margin-left: 3rem;
  }

  .form-check {
    margin-left: 3rem;
  }

  .form-check-label {
    margin-left: 1rem;
  }

  input[type='checkbox'] {
    position: relative;
  }
`

export const ContinueButton = styled(Button)`
  height: 3rem;
  font-size: 1.4rem;
  margin-top: 1rem;
  line-height: 0.5;
  width: 15rem;
  font-size: 1.6rem;
`

export const DocumentList = styled.div`
  margin-left: 3rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

export const Document = styled.div`
  display: block;
`
