import { useGlobalStore } from 'store/useGlobalStore'
import { IAuctionInfo } from 'types/auctionTypes'
import { IVehicleInfo } from 'types/vehicleTypes'
import shallow from 'zustand/shallow'
import winningBid from '../../images/winning-bid.png'
import {
  StyleBidsHistory,
  StyleBidsHistoryNoteSpan,
  StyledBidsHistoryTable,
  StyledLabel,
  StyledModalContent,
  StyleModalHeader
} from './BidsHistoryModal.styled'

import { DateTime } from 'luxon'
import { ComponentProps } from 'react'
import { Modal } from 'react-bootstrap'

interface IBidsHistoryProps extends ComponentProps<typeof Modal> {
  auctionInfo: IAuctionInfo
  vehicleInfo: IVehicleInfo
}

export const BidsHistoryModal = ({ auctionInfo, vehicleInfo, ...props }: IBidsHistoryProps) => {
  const getLocalText = useGlobalStore((state) => state.getLocalText, shallow)

  const vehicleVin = vehicleInfo.VIN
  const vehicleTitle = vehicleInfo.Title
  const bidHistories = auctionInfo.BidHistory

  return (
    <StyleBidsHistory {...props}>
      <StyleModalHeader closeButton>Bid History</StyleModalHeader>
      <StyledModalContent>
        <div>
          <h5>
            <span>{vehicleTitle}</span>
          </h5>
          <h5>
            <span>
              <StyledLabel>VIN: </StyledLabel>
            </span>
            {vehicleVin}
          </h5>
        </div>
        <StyledBidsHistoryTable responsive="md" hover size="sm">
          <thead>
            <tr>
              <th>Dealership</th>
              <th>Date</th>
              <th>Time</th>
              <th>Bid Amount</th>
              <th>Type of Bid</th>
            </tr>
          </thead>
          <tbody>
            {bidHistories &&
              bidHistories.map((item, index) => (
                <tr key={index} className={item.IsHighestBid ? 'highest-row' : ''}>
                  <td>{item.DealerShipName}</td>
                  <td>{DateTime.fromISO(item.CreatedDate).toFormat('ccc LLL dd yyyy', { locale: 'en-US' })}</td>
                  <td>{DateTime.fromISO(item.CreatedDate).toFormat('hh:mm:ss a', { locale: 'en-US' })}</td>
                  <td>{item.BidAmountString}</td>
                  <td className="type-of-bid">
                    {item.IsProxyBid && <span className="proxy-bid">Proxy Bid</span>}
                    {!item.IsProxyBid && <span>Manual Bid</span>}
                    {item.IsHighestBid && <img className="winning-bid-icon" src={winningBid} alt="WINNING" />}
                  </td>
                </tr>
              ))}
          </tbody>
        </StyledBidsHistoryTable>
        <StyleBidsHistoryNoteSpan>
          <span>TIME ZONE {getLocalText('VendorTimeZoneAbbreviation', 'None')}</span>
          <span>Note: If two users bid the same amount, the first bid takes priority.</span>
        </StyleBidsHistoryNoteSpan>
      </StyledModalContent>
    </StyleBidsHistory>
  )
}
