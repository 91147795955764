import { Form } from 'react-bootstrap'
import { StyledSlideOutElement } from './SlideOut.styled'

interface IProps {
  label: string
  inputText: string
}
export const SlideOutReadOnlyInput = ({ label, inputText }: IProps) => {
  return (
    <StyledSlideOutElement>
      <Form.Group controlId={label}>
        <Form.Label>{label}</Form.Label>
        <Form.Control className="read-only-input" type="text" placeholder={inputText} aria-label={inputText} readOnly />
      </Form.Group>
    </StyledSlideOutElement>
  )
}
