import styled from 'styled-components'

interface IColumnFilterButtonProps {
  active?: boolean
}

export const ColumnFilterButton = styled.span<IColumnFilterButtonProps>`
  margin-left: 4px;
  z-index: 99999;
  ${({ active, theme }) => active && `color: ${theme.colors.primary};`}

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }
`

export const FilterDropdown = styled.div`
  background: white;
  border-radius: 4px;
  padding: 2px 10px;
  box-shadow: 0px 0px 4px 1px #ccc;
  z-index: 2;
  .prism-sandbox {
    .searchable-list__heading {
      display: none;
    }

    .check-list__item {
      align-items: center;

      .checkbox-label {
        font-size: 12px;
      }

      .checkbox--custom input[type='checkbox'] {
        height: 16px;
        min-width: 16px;
      }

      .chip {
        font-size: 12px;
        height: 16px;
        min-width: 30px;
        margin: 0;
        margin-left: 4px;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
`

export const FilterActions = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FilterDropdownAction = styled.div`
  text-align: right;
  padding-bottom: 8px;
`
