import { StyledRibbon } from './Ribbon.styled'
import { useTheme } from 'styled-components'
import { useMemo } from 'react'
import classNames from 'classnames'

interface IProps {
  label: string
  backgroundColor?: string
  textColor?: string
  className?: string
  variant?: 'info' | 'success' | 'warning' | 'danger' | 'custom'
  title?: string
  onClick?: () => void
}

export const Ribbon = ({ label, className, variant, backgroundColor, textColor, title, onClick }: IProps) => {
  const theme = useTheme()
  const bgColor = useMemo(
    () =>
      variant === 'info'
        ? theme.colors.ribbonInfoBackgroundColor
        : variant === 'success'
        ? theme.colors.ribbonSuccessBackgroundColor
        : variant === 'warning'
        ? theme.colors.ribbonWarningBackgroundColor
        : variant === 'danger'
        ? theme.colors.ribbonDangerBackgroundColor
        : backgroundColor,
    [
      variant,
      theme.colors.ribbonInfoBackgroundColor,
      theme.colors.ribbonSuccessBackgroundColor,
      theme.colors.ribbonWarningBackgroundColor,
      theme.colors.ribbonDangerBackgroundColor,
      backgroundColor
    ]
  )
  const txtColor = useMemo(
    () =>
      variant === 'info'
        ? theme.colors.infoColor
        : variant === 'success'
        ? theme.colors.successColor
        : variant === 'warning'
        ? theme.colors.warningColor
        : variant === 'danger'
        ? theme.colors.dangerColor
        : textColor,
    [
      variant,
      theme.colors.infoColor,
      theme.colors.successColor,
      theme.colors.warningColor,
      theme.colors.dangerColor,
      textColor
    ]
  )

  return (
    <StyledRibbon
      backgroundColor={bgColor}
      textColor={txtColor}
      className={classNames(className, { clickable: Boolean(onClick) })}
      title={title}
      onClick={onClick}
    >
      <div className="ribbon">
        <span className="ribbon-title">{label}</span>
        <div className="ribbon-tail"></div>
      </div>
    </StyledRibbon>
  )
}
