import { extraLargeScreen } from 'common/theme'
import useLockedBody from 'hooks/useLockedBody'
import useWindowSize from 'hooks/useWindowSize'
import React, { createContext, useLayoutEffect, useState } from 'react'
export interface ILayoutContext {
  showMenuSidebar: boolean
  setShowMenuSidebar: (value: boolean) => void
  isSticky: boolean
  setIsSticky: (sticky: boolean) => void
}

export const LayoutContext = createContext<ILayoutContext>({} as ILayoutContext)

export const LayoutContextProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [showMenuSidebar, setShowMenuSidebar] = useState<boolean>(false)
  const [isSticky, setIsSticky] = useState<boolean>(false)
  const { width } = useWindowSize()

  useLayoutEffect(() => {
    if (width >= Number(extraLargeScreen.replace(/\D*/g, ''))) {
      setShowMenuSidebar(false)
    }
  }, [width])

  useLockedBody(showMenuSidebar)

  return (
    <LayoutContext.Provider
      value={{
        showMenuSidebar,
        setShowMenuSidebar,
        isSticky,
        setIsSticky
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}
