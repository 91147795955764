import { DEFAULT_PAGE_SIZE } from 'common/constants'

export enum SortOrder {
  ASC = 1,
  DESC = 2
}

export enum Operation {
  EqualTo = 1,
  LessThan = 2,
  GreaterThan = 3,
  LessThanEqualTo = 4,
  GreaterThanEqualTo = 5,
  Between = 6,
  Contains = 7
}
export interface ISortBy {
  Name: string
  SortOrder?: SortOrder
  Value: string
  DBFieldName?: string
}

export interface IFilterOptions {
  IsReserveMetVehicles: boolean
  IsRecentlyAddedVehicles: boolean
}
export interface IBaseResponse<T> {
  Success: boolean
  ErrorMessages: string[]
  Value: T
}

export interface IBaseCollectionResponse<T> {
  TotalPages: number
  TotalRecords: number
  Success: boolean
  PageNumber: number
  PageSize: number
  SortOptions: ISortBy[]
  SortBy: ISortBy
  ErrorMessages: string[]
  Items: T[]
  AllItems: T[]
}

export interface IBaseCollectionRequest {
  PageNumber: number
  PageSize: number
  SortBy?: ISortBy
  FilterOptions?: IFilterOptions
}
export class BaseCollectionRequest implements IBaseCollectionRequest {
  PageNumber: number = 0
  PageSize: number = DEFAULT_PAGE_SIZE
  SortBy?: ISortBy
  FilterOptions: IFilterOptions
}
export interface DealershipDropdownData extends IDropdownData {
  BuyerTypeID: number
}
export interface IDropdownData {
  Code?: string
  Description: string
  FieldDescription: string
  ID: number
}

export interface Buyer extends IDropdownData {}

export interface ILoadingModel {
  isLoading: boolean
  errorMessage: string
}

export interface IBaseRequest {
  ID?: number
}

export const DtmPageDetails = {
  VDP: 'VDP',
  SRP: 'SRP'
}
export const DtmPageRollup = {
  HomePage: 'HomePage'
}

export interface IFilterOptions {
  IsReserveMetVehicles: boolean
  IsRecentlyAddedVehicles: boolean
}

export interface IAuctionHistoryRequest extends IBaseCollectionRequest {
  Registration: string
  FromDate: Date
  ToDate: Date
  VIN: string
}
export interface IAuctionHistory {
  VehicleInstanceID: number
  Title: string
  Registration: string
  UserHeighestBid: number
  HeighestBid: number
  StatusID: number
  StatusDescription: string
  BidHistoryStatus: string
  AuctionEndDate: Date
  AuctionEndDateString: string
  VendorTradingName: string
  SaleChannelTypeID: number
  SaleChannelType: string
  SalesSessionStepID: number
  VIN: string
  IsUpstream: boolean
  VehicleStatusID: number
}

export interface IBaseCollectionRequest {
  PageNumber: number
  PageSize: number
  SortBy?: ISortBy
  FilterOptions?: IFilterOptions
}

export interface IBaseCollectionResponse<T> {
  TotalPages: number
  TotalRecords: number
  Success: boolean
  PageNumber: number
  PageSize: number
  SortOptions: ISortBy[]
  SortBy: ISortBy
  ErrorMessages: string[]
  Items: T[]
  AllItems: T[]
}

export class FilterOptions implements IFilterOptions {
  IsReserveMetVehicles: boolean = false
  IsRecentlyAddedVehicles: boolean = false
}

export interface IApiState<T> {
  data?: T
  error?: any
  loading?: boolean
  refetch?: () => void
}

export const enum AdvertType {
  NonPromotional = 'Non Promotional',
  Promotional = 'Promotional',
  Document = 'Document'
}

export interface CarouselImage {
  ImageURL: string
  AdvertType?: string
  Order?: number
  Promotion?: string
  DocumentName?: string
  Description?: string
  DocumentURL?: string
}

export interface FaqItem {
  QuestionGroupID: number
  QuestionID: number
  QuestionGroupName: string
  Question: string
  Content: string
  QuestionGroupOrder: number
  QuestionOrder: number
}

export interface GroupedFaq {
  QuestionGroupID: number
  QuestionGroupName: string
  Questions: FaqItem[]
}
export interface IOption<T> {
  value?: any
  label?: any
  key?: any
  item?: T
}

export interface ISystemSetting {
  Value: string
  ValueString: string
}

export enum DocumentType {
  /// <summary>
  /// Complaints
  /// </summary>
  Complaints = 1,
  /// <summary>
  /// Delivery
  /// </summary>
  Delivery = 2,
  /// <summary>
  /// Emissions
  /// </summary>
  Emissions = 3,
  /// <summary>
  /// European COCCVO document
  /// </summary>
  EuropeanCOCCVOdocument = 4,
  /// <summary>
  /// Finance
  /// </summary>
  Finance = 5,
  /// <summary>
  /// Handover Certificate
  /// </summary>
  HandoverCertificate = 6,
  /// <summary>
  /// Inspection
  /// </summary>
  Inspection = 7,
  /// <summary>
  /// Other
  /// </summary>
  Other = 8,
  /// <summary>
  /// Service
  /// </summary>
  Service = 9,
  /// <summary>
  /// Vehicle Registration
  /// </summary>
  VehicleRegistration = 10,
  /// <summary>
  /// VSC
  /// </summary>
  VSC = 11,
  /// <summary>
  /// The vehicle return receipt
  /// </summary>
  VehicleReturnReceipt = 12,
  /// <summary>
  /// The vehicle purchase receipt
  /// </summary>
  VehiclePurchaseReceipt = 13,
  /// <summary>
  /// The gate release pass
  /// </summary>
  GateReleasePass = 14,
  /// <summary>
  /// The purchase confirmation
  /// </summary>
  PurchaseConfirmation = 15,
  /// <summary>
  /// The bill of sale
  /// </summary>
  BillOfSale = 16,
  PayoffAuth = 18,
  Disclosure = 19,
  AcceptedDisclosure = 20,
  EWTDisclosure = 21,
  ExternalNoteAttachment = 22
}
