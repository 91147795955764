import { Rules } from 'common/rules'
import { SlideShow } from 'components/SlideShow/SlideShow'
import { useDtmAnalytics } from 'hooks/useDtmAnalytics'
import { useFetch } from 'hooks/useFetch'
import { Layout } from 'layouts/Layout'
import { AvailableInventory } from 'modules/AvailableInventory'
import { BuyerActivity } from 'modules/BuyerActivity'
import { DefaultErrorBoundary } from 'modules/DefaultErrorBoundary'
import { PropsWithChildren, useEffect, useLayoutEffect } from 'react'
import { RouteProps, useLocation } from 'react-router-dom'
import { useGlobalStore } from 'store/useGlobalStore'
import shallow from 'zustand/shallow'
import { StyledDashboardPlaceholder, StyledDashboardSlide } from './Dashboard.styled'
import { useSrpFiltersStore } from '../store/useSrpFiltersStore'
import { SessionStorageKey } from '../common/constants'
import { setListingBreadcrumb } from 'utils/breadcrumbUtils'

interface IAuthenticatedRouteProps extends PropsWithChildren<RouteProps> {
  allowedRules?: Rules[]
}

const AuthorizedComponent = ({ allowedRules, children }: IAuthenticatedRouteProps) => {
  const isInRule = useGlobalStore((state) => state.isInRule, shallow)

  return <div className="page-content">{allowedRules?.some((rule) => isInRule(rule)) && children}</div>
}

export const Dashboard = () => {
  const { endPageLoad } = useDtmAnalytics()
  const { fetchFilters } = useSrpFiltersStore((state) => state, shallow)
  const { loading } = useFetch(() => fetchFilters())
  const location = useLocation()

  useLayoutEffect(() => {
    window.sessionStorage.removeItem(SessionStorageKey.SEARCH_FILTERS)
    window.sessionStorage.removeItem(SessionStorageKey.PURCHASE_HISTORY_SEARCH_FILTER)
    window.sessionStorage.removeItem(SessionStorageKey.VEHICLE_INSTANCES)
    setListingBreadcrumb('Dashboard', location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!loading) {
      endPageLoad()
    }
  }, [loading, endPageLoad])

  return (
    <Layout title="Dashboard">
      <StyledDashboardPlaceholder>
        <AuthorizedComponent allowedRules={[Rules.VIEW_SEARCH_RESULT_PAGE, Rules.VIEW_ADVANCED_SEARCH_PAGE]}>
          <DefaultErrorBoundary>
            <StyledDashboardSlide>
              <SlideShow isFilterLoading={loading} />
            </StyledDashboardSlide>
          </DefaultErrorBoundary>
          <DefaultErrorBoundary>
            <AvailableInventory isFilterLoading={loading} />
          </DefaultErrorBoundary>
          <DefaultErrorBoundary>
            <BuyerActivity />
          </DefaultErrorBoundary>
        </AuthorizedComponent>
      </StyledDashboardPlaceholder>
    </Layout>
  )
}
