import { useRef, useState } from 'react'
import { FormControl as Input, Button, Overlay, Pagination } from 'react-bootstrap'

import { StyledPopOverPagination } from './Paging.styled'

interface IOverlayProps {
  totalPages: number
  onChangedPageValue: (pageSizeSelected: number) => void
  toPage: number
  handleChangedPage: (pageNum: number) => void
}

export const OverlayEllipsis = ({ handleChangedPage, onChangedPageValue, toPage, totalPages }: IOverlayProps) => {
  const [showGotoPage, setShowGotoPage] = useState<boolean>(false)

  const paginationRef = useRef(null)

  const onChangePage = () => {
    handleChangedPage(toPage)
    setShowGotoPage(false)
  }

  return (
    <>
      <Overlay
        rootClose={true}
        target={paginationRef.current}
        onHide={() => setShowGotoPage(false)}
        show={showGotoPage}
        placement="top-end"
        transition={false}
      >
        <StyledPopOverPagination id="popover-pagination" show={showGotoPage}>
          <label>Go to page:</label>
          <Input
            type="number"
            value={toPage}
            onChange={(e) => {
              onChangedPageValue(Number(e.target.value))
            }}
            max={totalPages}
          />
          <Button onClick={onChangePage}>Go</Button>
        </StyledPopOverPagination>
      </Overlay>
      <div ref={paginationRef}>
        <Pagination.Ellipsis onClick={() => setShowGotoPage(true)} />
      </div>
    </>
  )
}
