import { zIndex } from 'common/constants'
import { gridBreakPoints } from 'common/theme'
import styled, { css, keyframes } from 'styled-components'
import { StyledMainNav, StyledNavBar } from './NavBar.styled'

export interface StickyProp {
  isSticky?: boolean
}
export const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const StyledHeader = styled.div<StickyProp>`
  width: 100%;
  background-color: ${({ isSticky, theme }) => (isSticky ? theme.colors.primary : null)};
  ${({ isSticky }) =>
    isSticky &&
    css`
      top: 0;
      z-index: ${zIndex.stickyHeader};
      max-height: 7.2rem;
    `}
  display: flex;
  align-items: center;
  ${({ isSticky }) => (isSticky ? headerAnimation : null)};
`

const curtainEffect = keyframes`
  from {
    transform: translateY(-15rem);
  }
`
export const headerAnimation = css`
  animation: ${curtainEffect} 0.6s ease-in-out;
`
export const StyledBranding = styled.div<StickyProp>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  max-height: 12rem;
  @media (max-width: ${gridBreakPoints.lg}) {
    border-bottom: unset;
  }

  /*
   * Set a fixed height to prevent the logo from resizing
   * when transitioning between sticky and normal.
   */
  img.fixed-height {
    max-height: 4.25rem;
  }

  ${({ isSticky }) =>
    isSticky
      ? css`
          img {
            max-height: 4.25rem;
          }
          padding: 1rem 3rem;
          border-bottom: none;
          @media (max-width: ${gridBreakPoints.lg}) {
            justify-content: center;
          }
        `
      : css`
          padding: 2.75rem 2.75rem 1.75rem;
          border-bottom: 0.1rem solid ${({ theme }) => theme.colors.secondary};
        `}
`
export const StyledMenuHeader = styled.div.attrs({
  className: 'container-fluid'
})`
  font-family: ${({ theme }) => theme.fonts.fontRegular};
  display: flex;
  justify-content: space-between;
  padding: 0 3rem;

  @media (min-width: ${gridBreakPoints.lg}) {
    ${StyledNavBar} {
      ${StyledMainNav} {
        .nav .nav-item:first-child > .nav-link {
          padding-left: 0;
          margin-left: 0;
        }
      }
    }
  }

  @media (max-width: ${gridBreakPoints.lg}) {
    position: absolute;
    top: 0;
  }
`

export const StyledBody = styled.div`
  flex-grow: 1;
`

export const StyledBurgerButton = styled.div<StickyProp>`
  width: 3.6rem;
  height: 3.6rem;
  display: none;
  margin-right: 2rem;

  z-index: 100;
  button {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    svg {
      fill: ${({ isSticky }) => (isSticky ? '#e5e6e4' : 'rgba(0, 0, 0, 0.2)')};
    }
    &:hover > svg {
      transition: all 200ms ease-in;
      fill: ${({ isSticky }) => (isSticky ? '#fff' : 'rgba(0, 0, 0, 0.5)')};
    }
  }

  @media (max-width: ${gridBreakPoints.lg}) {
    display: block;
  }
`
