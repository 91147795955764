import { ComponentProps, PropsWithChildren } from 'react'
import { Button as ReactBootStrapButton } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import { StyledSpinnerButton } from './SpinnerButton.styled'

export interface IButtonProps extends ComponentProps<typeof ReactBootStrapButton> {
  isLoading: boolean
}

export const SpinnerButton = ({ isLoading, disabled, children, ...props }: PropsWithChildren<IButtonProps>) => {
  return (
    <StyledSpinnerButton {...props} disabled={isLoading || disabled}>
      {children}
      {isLoading && <Spinner data-testid="spinner" size="sm" as="span" animation="border" />}
    </StyledSpinnerButton>
  )
}
export default SpinnerButton
