import styled from 'styled-components'

export const StyledSelectedSearchFilters = styled.div`
  border-bottom: 1px solid #e5e5e5;
  padding: 1.6rem 0;
  max-height: 25rem;
  overflow: auto;

  .selected-filter-label {
    padding-left: 1.6rem;
    color: #666;
    text-transform: uppercase;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  ::-webkit-scrollbar {
    width: 0.4rem;
  }
`

export const StyledTagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 1.2rem;

  .tag-icon,
  .show-more {
    cursor: pointer;
  }
  .show-more {
    background-color: #ffffff;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }
`
