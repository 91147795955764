import { Tooltip } from 'react-bootstrap'
import styled from 'styled-components'

export const StyledVehicleNote = styled.div`
  .note-form {
    display: flex;
    align-items: center;
    gap: 0.375rem;
    .note-input {
      width: 30%;
    }
  }
  .vehicle-note {
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
    max-width: 30rem;
  }
  .save-vehicle-note {
    display: contents;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
  }
`
export const StyledToolTip = styled(Tooltip)`
  .arrow {
    display: none;
  }
  .tooltip-inner {
    white-space: pre-wrap;
    background-color: #fff;
    color: black;
    border: 0.1rem solid #ddd;
    border-radius: 0.5rem;
    max-width: 30rem;
    text-align: start;
  }
`
