import {
  BaseVehicleSearchCriteriaColumn as DBFieldId,
  carfaxIcon,
  DEFAULT_EXPAND_FILTERS_NUMBER,
  RANGE_FIELD_ID,
  SessionStorageKey
} from 'common/constants'
import { Rules } from 'common/rules'
import { CustomAccordion } from 'components/CustomAccordion/CustomAccordion'
import { StyledHomeSectionHeaderTitle } from 'components/Header/HomeSectionHeader.styled'
import { Ribbon } from 'components/Ribbon/Ribbon'
import produce from 'immer'
import { isEmpty } from 'lodash'
import { useEffect, useState, useCallback, useContext } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useGlobalStore } from '../../store/useGlobalStore'
import { ISearchResponseData } from '../../types/vehicleTypes'
import {
  StyledFilterSection,
  StyledBuyerSearch,
  StyledBuyerSearchAction,
  StyledBuyerSearchContent,
  StyledBuyerSearchHeader,
  StyledCarfaxAccordion,
  StyledMultiSelectFilterSection,
  StyledRibbonLabel,
  BUYER_SEARCH_OFFSET_HEIGHT
} from './BuyerSearch.styled'
import { SaveSearch } from './SaveSearch'
import { SearchFilter } from './SearchFilter'
import classNames from 'classnames'
import { useDtmAnalytics } from 'hooks/useDtmAnalytics'
import { SelectedSearchFilters } from './SelectedSearchFilters'
import useElementSize from 'hooks/useElementSize'
import { FilterSearchContext } from 'contexts/FilterSearchContext'

export const BuyerSearch = () => {
  const vehicleEventFilters = [DBFieldId.NewInventory, DBFieldId.DecreasedPrice, DBFieldId.DisclosurePresent]

  // This is a clone of `filterData`, use as data when "Search" button is clicked
  const [appliedFilters, setAppliedFilters] = useState<ISearchResponseData[]>([])
  const [seeMoreFilter, setSeeMoreFilter] = useState(false)
  const [saveSearchOpen, setSaveSearchOpen] = useState(false)

  const { userInteraction } = useDtmAnalytics()
  const { isInRule, getSystemSetting } = useGlobalStore()

  const viewCarfaxAdvantage = isInRule(Rules.VIEW_CARFAX_ADVANCED)
  const { filtersData, updateFilters } = useContext(FilterSearchContext)

  const searchCarfaxData = filtersData?.filter((item) => item.DBFieldName.startsWith('carfax'))
  const isNormalFilter = (filter: ISearchResponseData) =>
    filter.FieldId !== DBFieldId.VIN &&
    !filter.DBFieldName.startsWith('carfax') &&
    !vehicleEventFilters.some((id) => id === filter.FieldId)

  // -2 because always show Vin & Vehicle Events filters. Should always be >= 0
  const defaultExpandLimit = Math.max(
    (+getSystemSetting('SRP_FILTER_PRIORITIZE_FIELD_LIMIT') || DEFAULT_EXPAND_FILTERS_NUMBER) - 2,
    0
  )

  const { getLocalText } = useGlobalStore()

  const onSearchFilterChange = (filter: ISearchResponseData) => {
    setAppliedFilters((filters) => [...filters.filter((f) => f.FieldId !== filter.FieldId), filter])
  }

  const hasAnySelectedChild = useCallback(
    (filter?: ISearchResponseData) => (!!filter ? filter.ChildrenFilter.some((cf) => cf.Selected) : false),
    []
  )

  const onMultiSelectChange = (filter: ISearchResponseData, filterKey: string, isChecked: boolean) => {
    const result = produce(
      appliedFilters.filter((f) => vehicleEventFilters.some((id) => id === f.FieldId)),
      (filters) => {
        filters.forEach((f) =>
          f.ChildrenFilter.forEach((cf) => (cf.FilterKey === filterKey ? (cf.Selected = isChecked) : cf.Selected))
        )
      }
    )

    result.forEach((r) => onSearchFilterChange(r))
  }

  const hasAnySelectedVehicleEvents = (filtersData ?? [])
    .filter((f) => vehicleEventFilters.some((id) => id === f.FieldId))
    .some((f) => hasAnySelectedChild(f))
  const hasAnySelectedVin = hasAnySelectedChild((filtersData ?? []).find((f) => f.FieldId === DBFieldId.VIN))

  const accordionKey = `${[...(filtersData ?? []).map((f) => f.FieldId)].join('-')}`

  const renderVinAccordion = () =>
    filtersData
      .filter((f) => f.FieldId === DBFieldId.VIN)
      .map((f) => (
        <CustomAccordion
          key={`VIN_SEARCH ${accordionKey}`}
          sectionTitle="VIN Search"
          className={classNames({ 'selected-filter': hasAnySelectedVin })}
        >
          <SearchFilter
            filter={appliedFilters?.find((ap) => ap.FieldId === f.FieldId) ?? f}
            onChange={onSearchFilterChange}
          />
        </CustomAccordion>
      ))

  const renderVehicleEventAccordion = () =>
    filtersData
      .filter((f) => vehicleEventFilters.some((id) => id === f.FieldId))
      .some((f) => f.ChildrenFilter.length > 0) && (
      <CustomAccordion
        key={`VEHICLE_EVENTS ${accordionKey}`}
        sectionTitle="Vehicle Events"
        className={classNames({
          'selected-filter': hasAnySelectedVehicleEvents
        })}
      >
        <StyledMultiSelectFilterSection noOfCols={2}>
          {filtersData
            .filter((f) => vehicleEventFilters.some((id) => id === f.FieldId))
            .map(
              (f) =>
                !isEmpty(f.ChildrenFilter) && (
                  <Form.Check
                    key={`${f.ChildrenFilter[0].FilterKey}`}
                    inline
                    custom
                    id={f.ChildrenFilter[0].FilterKey}
                    name={f.FieldName}
                    value={f.ChildrenFilter[0].FilterKey}
                    defaultChecked={
                      (appliedFilters?.find((ap) => ap.FieldId === f.FieldId) ?? f).ChildrenFilter[0]?.Selected
                    }
                    label={
                      <StyledRibbonLabel>
                        <Ribbon
                          variant={
                            f.FieldId === DBFieldId.NewInventory
                              ? 'success'
                              : f.FieldId === DBFieldId.DecreasedPrice
                              ? 'danger'
                              : f.FieldId === DBFieldId.DisclosurePresent
                              ? 'warning'
                              : 'info'
                          }
                          label={f.FieldId === DBFieldId.DecreasedPrice ? 'Price Reduction' : f.FieldName}
                          key={f.ChildrenFilter[0].FilterKey}
                        />
                        <span className="vehicle-count text-primary font-weight-bold">
                          {`(${f.ChildrenFilter[0].VehicleCount})`}
                        </span>
                      </StyledRibbonLabel>
                    }
                    onChange={(e) => onMultiSelectChange(f, e.currentTarget.value, e.currentTarget.checked)}
                  />
                )
            )}
        </StyledMultiSelectFilterSection>
      </CustomAccordion>
    )

  const doSearch = (filters) => {
    updateFilters(filters)
  }

  const onClearAllClick = () => {
    sessionStorage.setItem(SessionStorageKey.QUERY_FILTERS, '')
    const resettedData = produce(filtersData, (filters) => {
      filters.forEach((f) => {
        f.ChildrenFilter.forEach((cf) => {
          cf.Selected = false
          cf.Value = ''
        })
        return f
      })
    })
    sessionStorage.setItem(SessionStorageKey.SEARCH_FILTERS, JSON.stringify(resettedData))
    sessionStorage.setItem(SessionStorageKey.SEARCH_DESCRIPTION, '')
    userInteraction(`BuyerSearch: ClearAll`)
    setAppliedFilters([])
    doSearch([])
  }

  const deleteTagHandler = (fieldId, filterKey) => {
    const filter = appliedFilters.find((f) => f.FieldId === fieldId)
    const result = produce(filter, (ft) => {
      ft.Selected = false
      ft.Value = ''
      ft.ChildrenFilter.forEach((cf) => {
        if (cf.FilterKey === filterKey) {
          cf.Selected = false
        }
      })
    })
    updateFilters?.([...appliedFilters.filter((f) => f.FieldId !== result.FieldId), result])
    sessionStorage.setItem(SessionStorageKey.SEARCH_DESCRIPTION, '')
  }

  // Handle when filterData changed
  useEffect(() => {
    if (!filtersData) return

    setAppliedFilters(
      produce(filtersData, (drafts) => {
        drafts.forEach((x) => {
          let selectedChild = x.ChildrenFilter.find(
            (x) => x.Selected && x.Value !== '' && RANGE_FIELD_ID.includes(x.FieldId)
          )

          if (selectedChild) {
            x.Selected = true
            x.Value = selectedChild.Value
            x.Value2 = selectedChild.Value2
            x.Value3 = selectedChild.Value3
            x.FriendlyValue = selectedChild.FriendlyValue
          }
        })
      })
    )
  }, [filtersData])

  const [headerRef, { height }, handleSize] = useElementSize()

  return (
    <StyledBuyerSearch>
      <StyledBuyerSearchHeader ref={headerRef}>
        <StyledHomeSectionHeaderTitle className="header-toggle mr-4" clickable={false}>
          Buyer Search
        </StyledHomeSectionHeaderTitle>
        <StyledBuyerSearchAction className="filter-action">
          <div>
            {filtersData?.some((f) => f.ChildrenFilter.some((cf) => cf.Selected)) && (
              <SelectedSearchFilters
                filters={filtersData?.flatMap((x, index) => x.ChildrenFilter).filter((f) => f.Selected === true)}
                onRemoveFilter={deleteTagHandler}
                onCollapsed={handleSize}
              ></SelectedSearchFilters>
            )}
          </div>
          <SaveSearch
            filtersData={appliedFilters}
            onClearFilterClicked={onClearAllClick}
            onToggleSaveSearch={setSaveSearchOpen}
          />
          {!saveSearchOpen && (
            <div className="d-flex justify-content-center">
              <Button variant="outline-primary" className="w-50" block onClick={() => doSearch(appliedFilters)}>
                {getLocalText('SEARCH', 'Search')}
              </Button>
            </div>
          )}
        </StyledBuyerSearchAction>
      </StyledBuyerSearchHeader>
      <StyledBuyerSearchContent>
        {!!filtersData && (
          <StyledFilterSection style={{ maxHeight: `calc(100vh - ${height + BUYER_SEARCH_OFFSET_HEIGHT}px)` }}>
            {renderVinAccordion()}
            {renderVehicleEventAccordion()}

            {filtersData
              .filter((f) => isNormalFilter(f))
              .slice(0, seeMoreFilter ? filtersData?.length : defaultExpandLimit)
              .map((f, idx) => (
                <CustomAccordion
                  key={`${f.FieldId} ${accordionKey}`}
                  sectionTitle={f.FieldName}
                  className={classNames({ 'normal-title': f.FieldId === DBFieldId.DriveTrain })}
                >
                  <SearchFilter
                    filter={appliedFilters?.find((ap) => ap.FieldId === f.FieldId) ?? f}
                    onChange={onSearchFilterChange}
                  />
                </CustomAccordion>
              ))}

            {/* Carfax filters group*/}
            {seeMoreFilter && viewCarfaxAdvantage && searchCarfaxData?.length > 0 && (
              <StyledCarfaxAccordion
                key={`CARFAX-Filter ${accordionKey}`}
                sectionTitle={<img src={carfaxIcon.carfaxTitleLogo} alt="CARFAX Logo" />}
              >
                {searchCarfaxData.map((f) => (
                  <SearchFilter
                    filter={appliedFilters?.find((ap) => ap.FieldId === f.FieldId) ?? f}
                    onChange={onSearchFilterChange}
                    key={`f.FieldId ${accordionKey}`}
                  />
                ))}
              </StyledCarfaxAccordion>
            )}

            <Button className="see-more" variant="link" block onClick={() => setSeeMoreFilter((s) => !s)}>
              {`See ${seeMoreFilter ? 'Less' : 'More'} Filters`}
            </Button>
          </StyledFilterSection>
        )}
      </StyledBuyerSearchContent>
    </StyledBuyerSearch>
  )
}
