import Image from 'react-bootstrap/Image'
import { IModelFigure, IModelYear } from '../../types/IModelFigure'
import { OverlayTrigger, Popover, Col, Row, Button } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { BodyStyled, StyledModelFigure, StyledModelFigureCaption, StyledPopover } from './ModelFigure.styled'
import defaultModel from 'images/bmw-logo-new.png'

export const ModelFigure = ({ imageUrl, modelType, make, onRedirect, total, ...props }: IModelFigure) => {
  const [yearFilter, setYearFilter] = useState<IModelYear[]>()
  const [showtooltip, setShowtooltip] = useState(false)

  useEffect(() => {
    setYearFilter(props.modelYears)
  }, [props.modelYears])

  return (
    <StyledModelFigure>
      <Col>
        <Row
          className="flex-grow-1 justify-content-center"
          onMouseEnter={() => setShowtooltip(true)}
          onMouseLeave={() => setShowtooltip(false)}
        >
          <OverlayTrigger
            key={`Overlay-${modelType}`}
            placement="bottom"
            show={showtooltip && yearFilter?.length > 0}
            overlay={
              <StyledPopover id="popover-basic">
                <Popover.Content onMouseEnter={() => setShowtooltip(true)} onMouseLeave={() => setShowtooltip(false)}>
                  <div className="car-tooltip-header">
                    <span>{make}</span>
                    <span className="model-type">
                      <strong>{modelType}</strong>
                    </span>
                  </div>
                  <BodyStyled>
                    {yearFilter?.map((yearModel, index) => (
                      <div key={index}>
                        {yearModel.year}&nbsp;
                        <span>
                          <Button
                            variant="link"
                            as={'a'}
                            type={null}
                            onClick={props?.handleRedirectYearAndModel.bind(null, yearModel.year, modelType)}
                          >
                            <strong>{`(${yearModel.total})`}</strong>
                          </Button>
                        </span>
                      </div>
                    ))}
                  </BodyStyled>
                </Popover.Content>
              </StyledPopover>
            }
          >
            {imageUrl ? <Image src={imageUrl} /> : <Image src={defaultModel} className="w-50" />}
          </OverlayTrigger>
        </Row>
        <StyledModelFigureCaption className={`figure-caption-model ${imageUrl ? '' : 'default-img'}`}>
          {modelType}&nbsp;
          <Button variant="link" as={'a'} type={null} onClick={onRedirect}>
            {`(${total})`}
          </Button>
        </StyledModelFigureCaption>
      </Col>
    </StyledModelFigure>
  )
}
