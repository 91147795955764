import styled from 'styled-components'
import { renderToStaticMarkup } from 'react-dom/server'
import { IconSvg } from '@prism/icon'

const svgString = encodeURIComponent(renderToStaticMarkup(<IconSvg glyph="calendar" />))

export const StyledDatePicker = styled.div`
  .react-datepicker {
    font-size: 1em;
    font-family: ${({ theme }) => theme.fonts.fontRegular};
  }
  .react-datepicker__header {
    padding-top: 0.8em;
  }
  .react-datepicker__month {
    margin: 0.4em 1em;
  }

  .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
  }

  .react-datepicker__current-month {
    font-size: 1em;
  }

  .react-datepicker__day-names {
    margin-right: 20px;
  }

  .react-datepicker__day-name {
    margin-left: 19px;
  }

  .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
  }

  .react-datepicker__tab-loop {
    display: inline-flex;
  }

  .date-picker-container {
    display: inline-flex;
  }

  .react-datepicker__input-container input {
    padding-left: 8px;
    padding-right: 25px;
    width: 100%;
    height: 30px;
  }

  .date-picker-container svg {
    margin-left: -30px;
    transform: scale(0.8);
    opacity: 0.7;
    margin-top: 1px;
    line-height: 1.9em;
  }

  .date-picker-input {
    background: url('data:image/svg+xml,${svgString}') no-repeat 94% 50%;
    background-size: 20px;
    border: 1px solid ${({ theme }) => theme.colors.borderInput};
    border-radius: 0.25rem;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`
