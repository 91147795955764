import { IBaseCollectionRequest } from './baseTypes'

export interface IPurchaseHistoryRequest extends IBaseCollectionRequest {
  Vin?: string
  FromDate?: Date
  ToDate?: Date
  Deferred?: any
  Id?: number
  Ids?: number[]
}

export interface IOrderHubStatusRequest {
  Vin?: string
  VehicleInstanceID: number
}

export interface IAddress {
  AddressTypeId: number
  AddressType: string
  AddressId: number
  AddressLineOne: string
  AddressLineTwo: string
  BuildingNbrNm: string
  Town: string
  CountryId: number
  County: string
  CountryName: string
  PostCode: string
  AccountId: number
  Code: string
  StateId: number
  StateCode: string
  StateDescription: string
  ToDetailHTMLString: string
  ToDetailString: string
  ToLimitedString: string
}
export interface IPurchaseHistoryResult {
  RetailDate: Date
  RetailCustomerName: string
  BuyerID: number
  BuyerName: string
  BuyerCode: string
  Company: string
  VehicleInstanceID: number
  Registration: string
  Vin: string
  Title: string
  NetPrice: number
  GrossPrice: number
  VatPrice: number
  Price: number
  PreAdjustmentPrice: number
  PreAdjustmentPriceString: string
  PurchaseDate: Date
  CampaignBulkName: string
  PurchaseScheme: string
  PaymentTypeID: number
  PaymentType: string
  LocationID: number
  InvoiceNumber: number
  CancellationReason: string
  BuyerVehiclePurchaseID: number
  SaleTypeID: number
  SaleChannel: string
  ReferenceInvoice: string
  Location: string
  ModifiedDate: Date
  DeliveryAddress: IAddress
  MovementType: string
  RegistrationDate: string
  Documents: Array<IDocumentDetails>
  ExternalNoteAttachmentDocuments: Array<IDocumentDetails>
  ImageURL: string
  ThumbImageURL: string
  PurchaseType: string
  Mileage: Number
  VehicleCondition: string
  ChargeDescription: string
  TrackingNumber: string
  TrackingURL: string
  CarrierName: string
  ExternalDeliveryStatus: string
  ShowDeliveryInfo: boolean
  OrderHubStatuses: IOrderHubStatus[]
  OrderHubLatestStatus: string
  OrderHubStatusesLength: number
  ModifiedDateString: string
  IsReadyLogistics: boolean
  TotalPriceString: string
  Charges: IVehicleCharges[]
}

export interface ICustomerRetailInfo extends IBaseCollectionRequest {
  VehicleInstanceId: number
  VendorId: number
  RetailDate: Date
  RetailCustomerName: string
  CreatedUserName: string
  BuyerVehiclePurchaseId: number
  Deferred?: any
  Id?: number
  Ids?: number[]
}

export interface IVehicleCharges {
  VehicleChargeTypeDescription: string
  PriceString: string
}

export interface IOrderHubStatus {
  ID: number
  OrderNumber: string
  OrderNumberKey: string
  SourceEventDate: Date
  SourceEventDateString: string
  EstimatedPickupDate: Date
  EstimatedDeliveryDate: Date
  ActualPickupDate: Date
  ActualDeliveryDate: Date
  ServiceProvider: string
  AdditionalInfo: string
  OrderSourceStatus: string
  ReceivedStatus: string
  MappedStatus: string
  Description: string
  OrderHubStatuses: IOrderHubStatusResponse[]
}

export interface IOrderHubStatusResponse {
  VehicleOrderHubStatusID: number
  OrderNumber: string
  OrderNumberKey: string
  SourceEventDate: Date
  SourceEventDateString: string
  EstimatedPickupDate: Date
  EstimatedPickupDateString: string
  EstimatedDeliveryDate: Date
  EstimatedDeliveryDateString: string
  ActualPickupDate: Date
  ActualPickupDateString: string
  ActualDeliveryDate: Date
  ActualDeliveryDateString: string
  ServiceProvider: string
  AdditionalInfo: string
  OrderSourceStatus: string
  ReceivedStatus: string
  MappedStatus: string
  Description: string
  VehicleInstanceID: number
  Vin: string
  Title: string
  OrderHubStatusID: number
  VehiclePurchaseDate: Date
  VehiclePurchaseDateString: string
  VehicleImageURL: string
}

export interface IDocumentDetails {
  documentID: number
  display: string
  DocumentURL: string
}

export interface VehicleDocument {
  ID: number
  FileName: string
  DocumentURL: string
  DocumentDescription?: string
  VehicleInstanceID: number
}

export enum DocumentFileType {
  PDF = 'PDF',
  Image = 'Image',
  Video = 'Video'
}
