import { Layout } from 'layouts/Layout'
import { Alert } from 'react-bootstrap'

interface IProps {
  message: string
}

export const ErrorPage = ({ message }: IProps) => {
  return (
    <Layout>
      <Alert variant="danger">
        <p className="m-auto text-center">{message}</p>
      </Alert>
    </Layout>
  )
}
