import { CollapseProps, Collapse as BootstrapCollapse } from 'react-bootstrap'

const Collapse = ({ children, ...restProps }: CollapseProps) => {
  return (
    <BootstrapCollapse {...restProps}>
      <div>{children}</div>
    </BootstrapCollapse>
  )
}

export default Collapse
