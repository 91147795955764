import { getWatchListVehicles } from 'apis/dashboardApis'
import { DEFAULT_SMALL_PAGE_SIZE } from 'common/constants'
import NoActivity from 'modules/BuyerActivity/NoActivity'
import VehicleAuction from 'modules/BuyerActivity/VehicleAuction'
import { VehicleContextProvider } from 'modules/DealerVehicleManagement/VehicleContext'
import { Loader } from 'components/Loader'
import { Link } from 'components/Share/Link'
import { useDtmAnalytics } from 'hooks/useDtmAnalytics'
import { useFetch } from 'hooks/useFetch'
import { useVehicles } from 'hooks/useVehicles'
import CaretDownFill from 'images/icon/CaretDownFill'
import CaretRightFill from 'images/icon/CaretRightFill'
import { useEffect } from 'react'
import { Collapse } from 'react-bootstrap'
import { useGlobalStore } from 'store/useGlobalStore'
import { GetBidVehiclesRequest, IGetBidVehiclesResponse } from 'types/vehicleTypes'
import { DtmComponentName, setCustomClick } from 'utils/analyticsUtils'
import { getWatchlistUrl } from 'utils/urlUtils'
import shallow from 'zustand/shallow'
import { StyledActivityTitle } from './BuyerActivity.styled'

interface IProps {
  isExpand?: boolean
  setExpand?: any
}

export const WatchList = ({ isExpand, setExpand }: IProps) => {
  const { data: { Items: items, TotalRecords: totalRecords } = {} as IGetBidVehiclesResponse, loading } = useFetch(() =>
    getWatchListVehicles(new GetBidVehiclesRequest(DEFAULT_SMALL_PAGE_SIZE))
  )
  const { vehicles } = useVehicles(items)
  const getLocalText = useGlobalStore((state) => state.getLocalText, shallow)
  const { endComponentLoad } = useDtmAnalytics()

  useEffect(() => {
    if (!loading) {
      endComponentLoad(DtmComponentName.DashboardWatchList, items)
    }
  }, [loading, endComponentLoad, items])

  const watchlistUrl = getWatchlistUrl()

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <StyledActivityTitle onClick={setExpand}>
        {isExpand ? <CaretDownFill /> : <CaretRightFill />}
        <span>Watch List: </span>
        <Link
          className="total"
          to={watchlistUrl}
          onClick={(event) => {
            setCustomClick('Dashboard: BuyerActivity: WatchList', { usePageName: false })
            event.stopPropagation()
          }}
        >
          ({totalRecords ?? 0})
        </Link>
      </StyledActivityTitle>
      <Collapse in={isExpand}>
        <div>
          {vehicles?.length > 0 ? (
            vehicles.map((item) => (
              <VehicleContextProvider vehicleData={item} key={item.vehicle.InstanceID}>
                <VehicleAuction vehicleShowOn="WatchList" vehicleData={item} />
              </VehicleContextProvider>
            ))
          ) : (
            <NoActivity
              message={getLocalText(
                'NO_WATCHLIST_VEHICLES',
                'Once you add vehicles to your watch list, they will appear here.'
              )}
            />
          )}
        </div>
      </Collapse>
    </>
  )
}
