import { screen_xxl, zIndex } from 'common/theme'
import styled from 'styled-components'

interface IStyledDataTableProps {
  maxHeight?: number
  striped?: boolean
}

export const StyledDataTable = styled.div<IStyledDataTableProps>`
  .table-container {
    max-height: ${({ maxHeight }) => `${maxHeight}px`};
    border-radius: 8px;
    border: none;
    box-shadow: 0px 2px 4px 1px #ccc;
  }

  .table {
    color: #555;
    z-index: 0;
  }

  ${({ striped, theme }) =>
    striped &&
    `tr.table-row:nth-child(even) {
        background: ${theme.colors.tableStriped};
    }
    tr.table-row:nth-child(odd) {
        background-color: #fff;
    }`}

  &.prism-sandbox {
    .table {
      th,
      td {
        padding-left: 4px;
        padding-right: 4px;

        &:first-child {
          padding-left: 12px;
        }

        &:last-child {
          padding-right: 12px;
        }
      }
    }
    .table th {
      font-weight: 600;
      z-index: ${zIndex.elevated};
      vertical-align: middle;
    }

    .table td {
      border-top-color: ${({ theme }) => theme.colors.tableBorder};
      vertical-align: middle;
    }
  }
`

export const DataTableHeader = styled.div`
  padding-top: 8px;
  padding-bottom: 5px;
  padding-left: 2px;
  padding-right: 2px;
  text-transform: uppercase;
  font-size: 1.5rem;
`

export const DataTableCell = styled.div`
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 2px;
  padding-right: 2px;
  position: relative;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  @media (min-width: ${screen_xxl}) {
    font-size: 1.5rem;
  }
  .form-control.focus input[type='text']:focus,
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #2e76d0;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 59, 104, 0.25);
  }
`
