import styled from 'styled-components'

export const StyledSelect = styled.div`
  .react-select {
    &__dropdown-indicator {
      padding: 0 8px;
    }

    &__control {
      min-height: 29px;
    }

    .check-option &__option {
      color: #000;
    }

    .check-option &__option:hover {
      background-color: ${({ theme }) => theme.colors.dropdownHoverColor};
    }

    &__menu .check-option .react-select__option--is-selected {
      background-color: transparent;
      color: #000;
      &:hover {
        background-color: ${({ theme }) => theme.colors.dropdownHoverColor};
      }
    }
  }
`

export const StyledSelectAll = styled.div`
  &:hover {
    background-color: ${({ theme }) => theme.colors.dropdownHoverColor};
  }
  input {
    margin-right: 4px;
  }
`
