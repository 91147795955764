import { Col } from 'react-bootstrap'
import {
  StyledVehicleAuctionInfo,
  StyledAuctionCardRow,
  StyledAuctionImage,
  StyledAuctionTitle,
  StyledAuctionText
} from './VehicleAuction.styled'
import { IVehicleData, IVehicleInfo } from 'types/vehicleTypes'
import { IAuctionInfo } from 'types/auctionTypes'
import { PropsWithChildren, useEffect } from 'react'
import { setCustomClick } from 'utils/analyticsUtils'
import { StyledVehicleBidBuyInformation } from './VehicleAuction.styled'
import { VehiclePriceSection } from 'modules/DealerVehicleManagement/VehiclePriceSection'
import { VehicleTags } from 'modules/DealerVehicleManagement/VehicleTags'
import { VehicleBidHistoryLink } from 'modules/DealerVehicleManagement/VehicleBidHistoryLink'
import { VehicleTimer } from 'modules/DealerVehicleManagement/VehicleTimer'
import { getVdpUrl } from 'utils/urlUtils'
import { Link } from 'components/Share/Link'
import { useVehicleStore } from 'store/useVehicleStore'
import { useCountdown } from 'hooks/useCountdown'

interface IProps {
  vehicleData: IVehicleData
  vehicleShowOn?: string
}

interface IVehicleLinkProps {
  vehicle: IVehicleInfo
  auctionInfo?: IAuctionInfo
  customName: string
  vehicleShowOn?: string
}

export const VehicleLink = ({
  customName,
  vehicle,
  auctionInfo,
  children,
  vehicleShowOn
}: PropsWithChildren<IVehicleLinkProps>) => {
  const handleClick = () => {
    setCustomClick(`Dashboard: BuyerActivity:${vehicleShowOn}:IndividualVehicle`, { usePageName: false })
  }

  const detailUrl = getVdpUrl(vehicle.InstanceID)

  return (
    <Link to={detailUrl} onClick={handleClick}>
      {children}
    </Link>
  )
}

export const VehicleAuction = ({ vehicleData, vehicleShowOn }: IProps) => {
  const { getBaseVehicle } = useVehicleStore()

  const { vehicle: vehicleInfo, auctionInfo } = vehicleData
  const { seconds: timeOut } = useCountdown(auctionInfo?.EndDate)

  const color = vehicleInfo.Properties?.find((item) => item.Code === 'Exterior Color' && item.ID === 1)
  const mileage = vehicleInfo.Properties?.find((item) => item.Code === 'Odometer' && item.ID === 1)

  useEffect(() => {
    if (timeOut === 0 && vehicleData.auctionInfo?.IsHighestBidder) {
      getBaseVehicle(vehicleData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeOut, vehicleData.auctionInfo?.IsHighestBidder])

  return (
    <StyledVehicleAuctionInfo>
      <StyledAuctionCardRow>
        <Col sm={3}>
          <StyledAuctionImage>
            <VehicleLink
              customName="Image"
              vehicleShowOn={vehicleShowOn}
              auctionInfo={auctionInfo}
              vehicle={vehicleInfo}
            >
              <img src={vehicleInfo.ImageURL} alt="" />
            </VehicleLink>
          </StyledAuctionImage>
        </Col>
        <Col sm={5} xs={12}>
          <StyledAuctionTitle>
            <VehicleLink
              customName="Title"
              vehicleShowOn={vehicleShowOn}
              auctionInfo={auctionInfo}
              vehicle={vehicleInfo}
            >
              {vehicleInfo.Title}
            </VehicleLink>
          </StyledAuctionTitle>
          <StyledAuctionText>{color?.Description}</StyledAuctionText>
          <StyledAuctionText>{mileage?.Description} miles</StyledAuctionText>
        </Col>
        <Col sm={4} xs={12}>
          <StyledVehicleBidBuyInformation>
            <div>
              <VehiclePriceSection />
              <VehicleTags />
            </div>
            <div className="dashboard-reserver-bid">
              <VehicleBidHistoryLink />
              <VehicleTimer />
            </div>
          </StyledVehicleBidBuyInformation>
        </Col>
      </StyledAuctionCardRow>
    </StyledVehicleAuctionInfo>
  )
}

export default VehicleAuction
