import { useEffect, useState } from 'react'
import { StyledBidAmountButton, StyledSlideOutBidAmountInput } from './SlideOutBidAmountInput.styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { Form } from 'react-bootstrap'
import { useFormikContext } from 'formik'
import { BidAgainFormValues } from 'types/formikTypes'
import { EnterKeyCharCode } from 'common/constants'
import { ValidationErrorMessage } from 'components/Forms'
import { formatNumber, roundUpToNearest } from 'utils/numberUtils'
import { parseNumberWithCommas } from 'utils/stringUtils'

interface IProps {
  bidIncreasement: number
  bidIncreasementString: string
  nextBidAmountString: string
  nextBidAmount: number
  disabled?: boolean
}
export const SlideOutBidAmountInput = ({
  bidIncreasement,
  bidIncreasementString,
  nextBidAmountString,
  nextBidAmount,
  disabled = false
}: IProps) => {
  const formik = useFormikContext<BidAgainFormValues>()
  const [bidAmountText, setBidAmountText] = useState(formatNumber(nextBidAmount))

  function updateBidAmount(bidAmount: number) {
    formik?.setFieldValue('BidAmount', bidAmount, false)
    setBidAmountText(formatNumber(bidAmount))
  }

  const handleBidAmountChange = (bidAmount: number) => {
    if (bidAmount > nextBidAmount) {
      updateBidAmount(bidAmount)
    } else {
      // reset to original value
      updateBidAmount(nextBidAmount)
    }
  }

  useEffect(() => {
    setBidAmountText(formatNumber(formik?.values.BidAmount))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values.BidAmount])

  return (
    <StyledSlideOutBidAmountInput>
      <div className="amount-input mb-2">
        <Form.Control
          data-testid="bidAmountInput"
          disabled={disabled}
          className="d-inline w-auto flex-grow-1 currency-input"
          onChange={(e) => setBidAmountText(e.target.value)}
          onBlur={(e) => {
            const bidAmount = parseNumberWithCommas(e.target.value)
            handleBidAmountChange(roundUpToNearest(bidAmount, formik.values.BidAmount, bidIncreasement))
          }}
          value={bidAmountText}
          onKeyPress={(event) => {
            if (event.charCode === EnterKeyCharCode) {
              const bidAmount = parseNumberWithCommas(bidAmountText)
              handleBidAmountChange(roundUpToNearest(bidAmount, formik.values.BidAmount, bidIncreasement))
            }
          }}
        />

        <StyledBidAmountButton
          data-testid="btnIncrease"
          variant="light"
          className="ml-1"
          disabled={disabled}
          onClick={() => {
            handleBidAmountChange(formik.values.BidAmount + bidIncreasement)
          }}
        >
          <FontAwesomeIcon icon={faAngleUp} />
        </StyledBidAmountButton>

        <StyledBidAmountButton
          data-testid="btnDecrease"
          variant="light"
          className="ml-1"
          disabled={disabled || parseNumberWithCommas(bidAmountText) <= nextBidAmount}
          onClick={() => {
            handleBidAmountChange(formik.values.BidAmount - bidIncreasement)
          }}
        >
          <FontAwesomeIcon icon={faAngleDown} />
        </StyledBidAmountButton>
        <span className="dollar">$</span>
      </div>

      {formik.errors.BidAmount && formik.touched.BidAmount && (
        <ValidationErrorMessage>{formik.errors.BidAmount}</ValidationErrorMessage>
      )}
      <small className="w-100">
        {nextBidAmountString} or Higher (Increment {bidIncreasementString})
      </small>
    </StyledSlideOutBidAmountInput>
  )
}
