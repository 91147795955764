import { IUserClaimReponse } from 'types/userTypes'

export const addDefaultAttributes = (data: IUserClaimReponse) => {
  if (typeof newrelic === 'object') {
    newrelic.setCustomAttribute('userid', data.UserId?.toString())
    newrelic.setCustomAttribute('username', data.Username)
    newrelic.setCustomAttribute('buyercode', data.CurrentBuyerCode)
    newrelic.setCustomAttribute('buyername', data.DealershipName)
  }
}
