import { addVehicleNotes } from 'apis/vehicleApis'
import { Formik } from 'formik'
import { useClickOutSide } from 'hooks/useClickOutSide'
import { useContext, useEffect, useRef, useState } from 'react'
import { Form, OverlayTrigger, Spinner } from 'react-bootstrap'
import { StyledInfoLabel } from './VehicleDetails.styled'
import { StyledToolTip, StyledVehicleNote } from './VehicleNote.styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { VehicleContext } from './VehicleContext'
import { useDtmAnalytics } from 'hooks/useDtmAnalytics'
import produce from 'immer'
import { useVehicleStore } from 'store/useVehicleStore'

export const VehicleNote = () => {
  const {
    editingNote,
    setEditingNote,
    vehicleData,
    vehicleData: {
      vehicle: { VehicleNote, InstanceID, NoteId }
    }
  } = useContext(VehicleContext)
  const editingFormRef = useRef<HTMLFormElement>(null)
  useClickOutSide(editingFormRef, () => setEditingNote(false))
  const [loading, setLoading] = useState(false)
  const updateVehicleData = useVehicleStore((state) => state.updateVehicleData)

  const { userInteraction } = useDtmAnalytics()

  const inputNote = useRef(null)
  useEffect(() => {
    if (inputNote && inputNote.current) inputNote.current.focus()
  }, [editingNote])

  const addVehicleNote = async (note: string) => {
    try {
      setLoading(true)
      await addVehicleNotes({
        VehicleInstanceID: InstanceID,
        NotesId: NoteId,
        Notes: note
      })

      updateVehicleData(
        produce(vehicleData, (draft) => {
          draft.vehicle.VehicleNote = note
        })
      )
      setEditingNote(false)
    } catch {
    } finally {
      setLoading(false)
    }

    userInteraction(
      `${window.webData.pageName} : save note`,
      [{ auctionInfo: vehicleData.auctionInfo, VIN: vehicleData.vehicle.VIN }],
      { userNote: note }
    )
  }

  return (
    <StyledVehicleNote>
      {editingNote ? (
        <div>
          <Formik
            initialValues={{ VehicleNote: VehicleNote }}
            onSubmit={(values) => addVehicleNote(values.VehicleNote)}
          >
            {(props) => (
              <form className="note-form" onSubmit={props.handleSubmit} ref={editingFormRef}>
                <StyledInfoLabel>
                  <FontAwesomeIcon className="icon" icon={faPencilAlt} />
                </StyledInfoLabel>
                <Form.Control
                  className="note-input"
                  type="text"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.VehicleNote}
                  name="VehicleNote"
                  maxLength={250}
                  ref={inputNote}
                />
                {loading ? (
                  <Spinner animation="border" role="status" />
                ) : (
                  <button className="save-vehicle-note" type="submit">
                    Save
                  </button>
                )}
              </form>
            )}
          </Formik>
        </div>
      ) : (
        <>
          {VehicleNote && !loading && (
            <div className={`vehicle-note`}>
              <StyledInfoLabel>
                <FontAwesomeIcon className="icon" icon={faPencilAlt} />
              </StyledInfoLabel>
              <OverlayTrigger
                placement="bottom-start"
                overlay={
                  <StyledToolTip id="tooltip" placement="left-start">
                    <span>{VehicleNote}</span>
                  </StyledToolTip>
                }
              >
                <span>{` ${VehicleNote}`}</span>
              </OverlayTrigger>
            </div>
          )}
        </>
      )}
    </StyledVehicleNote>
  )
}
