import { colorDanger, textColorLight } from 'common/theme'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

export const StyleDisclosureModal = styled(Modal)`
  padding: 1rem 1.5rem;
  font-size: 1.6rem;
  .modal-content {
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.15);
    border-radius: 0.3rem;
  }
  button {
    margin: 0.5rem;
    align-content: center;
  }
  .modal-header {
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
    font-size: 1.8rem;
    .close span {
      font-size: 2rem;
      font-weight: normal;
      color: white;
    }
  }
  .modal-body {
    color: ${textColorLight};
    .document-link {
      color: ${({ theme }) => theme.colors.linkColorLight};
    }
  }
  .modal-footer {
    border: none;
    justify-content: center;
  }
`
export const StyleDisclosureWarningSpan = styled.p`
  font-size: 1.6rem;
  color: ${colorDanger};
  font-weight: bold;
`
