import { IHomeSectionHeaderProps } from 'components/Header/HomeSectionHeader'
import { LayoutContext } from 'contexts/LayoutContext'
import { PropsWithChildren, useContext } from 'react'
import { StyledVehicleStickyBar } from './VehicleStickyBar.styled'

interface IVehicleStickyBarProps extends IHomeSectionHeaderProps {}

export const VehicleStickyBar = ({ children, ...props }: PropsWithChildren<IVehicleStickyBarProps>) => {
  const { isSticky } = useContext(LayoutContext)
  return <StyledVehicleStickyBar isSticky={isSticky} className="sticky-header d-print-none" {...props} />
}
