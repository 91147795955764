import { acceptNews } from 'apis/userApis'
import { CONTINUE } from 'common/constants'
import { MultiLanguageCode } from 'common/languageCodes'
import { rmsAuthModel } from 'common/rmsGlobal'
import { HTMLText } from 'components/Share/HtmlText'
import parse from 'html-react-parser'
import { Col, Form, Image, Row } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useGlobalStore } from 'store/useGlobalStore'
import { AcceptenceBody, ContinueButton, Document, DocumentList } from './AcceptenceTerm.styled'
import { Title } from '../pages/TermCondition.styled'

export const AcceptenceTerm = () => {
  const intl = useIntl()
  const getLocalText = useGlobalStore((state) => state.getLocalText)

  const newsHandler = async (markAccept) => {
    let newsID = rmsAuthModel?.newsModel?.NewsID

    if (newsID && newsID !== -1) {
      await acceptNews(newsID, markAccept)
      window.location.reload()
    }
  }

  const requiresAccept = rmsAuthModel?.newsModel?.RequiresAcceptance

  return (
    <>
      <AcceptenceBody>
        <Row>
          <Col>
            <Title>
              <HTMLText value={parse(intl.formatMessage({ id: MultiLanguageCode.TERMS_LINK }))} />
            </Title>
          </Col>
        </Row>
        <HTMLText value={parse(intl.formatMessage({ id: MultiLanguageCode.TERMS_TEXT }))} />
        <DocumentList>
          {rmsAuthModel?.newsModel?.newsDocuments?.map((x) => {
            let document: string[] = x.split('|')
            return (
              <Document>
                <a id="lnkImageDoc" href={`/resource/GetDownloadByID?documentID=${document[0]}`}>
                  <Image src="content/img/download.png" alt="Download" />
                  <span>{document[1]}</span>
                </a>
              </Document>
            )
          })}
        </DocumentList>
        {requiresAccept ? (
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label={parse(getLocalText('termsAccept', ''))}
                  onChange={() => newsHandler(true)}
                />
              </Form.Group>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col md={{ span: 4, offset: 8 }} xs={{ span: 4, offset: 1 }}>
              <ContinueButton onClick={() => newsHandler(false)} size="lg">
                {CONTINUE}
              </ContinueButton>
            </Col>
          </Row>
        )}
      </AcceptenceBody>
    </>
  )
}
