import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setBuyer } from 'apis/serviceApis'
import { iconDict } from 'common/menuIcons'
import { OverlayLoader } from 'components/Loader'
import ChevronDown from 'icons/ChevronDown'
import Person from 'icons/Person'
import { useEffect, useMemo, useState } from 'react'
import { Dropdown, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useGlobalStore } from 'store/useGlobalStore'
import { IPortalMenuResponse } from 'types/userTypes'
import { handlePortalMenuItemClick } from 'utils/menuUtils'
import shallow from 'zustand/shallow'

import { RightActionContainer, StyledCurrentDealership, StyledDealerDropdown, StyledUserMenu } from './UserMenu.styled'

interface IProps {
  showInMobile: boolean
}

export const UserMenu = (props: IProps) => {
  const {
    userClaims: {
      Username: userName,
      DealershipName: dealerShipName,
      AssociatedBuyers: associatedBuyers,
      IsSSO: isSSO,
      Menu: menu
    },
    isInRule
  } = useGlobalStore(
    (state) => ({
      userClaims: state.userClaims,
      isInRule: state.isInRule
    }),
    shallow
  )

  // TODO: Use ID instead of name
  const dealer = associatedBuyers?.find((item) => item.Description === dealerShipName)
  const [currentBuyerId, setCurrentBuyerId] = useState(dealer?.ID)
  const [userFunctionalities, setUserFunctionalities] = useState<IPortalMenuResponse[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isOveUser = useMemo(() => isInRule('OVE Colisting'), [])
  useEffect(() => {
    const logoutLink: IPortalMenuResponse = {
      MenuItem: {
        ID: 0,
        Text: 'Logout',
        URL: '/login',
        Name: 'Logout',
        OrderNo: 0,
        ParentName: ''
      },
      URL: '/login', // login endpoint will clear the session, meaning: logout
      IsWebMenu: true,
      Children: []
    }
    const myAccountLink = menu?.Children.map((m) => m.Children)
      .flat()
      .find((m) => m.MenuItem.Name === 'RDP_MyProfile')
    setUserFunctionalities([myAccountLink, logoutLink])
  }, [menu])

  const handleChangeCurrentBuyer = async (value: number) => {
    // If user select the same buyer, we won't need to do anything
    if (currentBuyerId === value) return

    try {
      setIsLoading(true)
      setCurrentBuyerId(value)
      await setBuyer(value)
      window.location.reload()
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  if (!menu) {
    return null
  }

  return (
    <RightActionContainer show={props.showInMobile}>
      <Nav as={'div'}>
        <Nav.Item as="div">
          <Nav.Link as={Link} to={'/faqs'}>
            <FontAwesomeIcon icon={faQuestionCircle} />
            {` FAQs`}
          </Nav.Link>
        </Nav.Item>
      </Nav>

      <StyledUserMenu show={props.showInMobile}>
        {isLoading && <OverlayLoader />}

        <StyledDealerDropdown>
          <Dropdown
            alignRight
            defaultValue={currentBuyerId}
            onSelect={(value) => handleChangeCurrentBuyer(value as never)}
          >
            <Dropdown.Toggle as="div" className="current-user">
              <div className="username">
                <span>
                  <Person />
                  {userName}
                </span>
                <ChevronDown />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dealership-select">
              <div className="associated-dealerships">
                {associatedBuyers.map((item) => (
                  <Dropdown.Item
                    className={`dealership-item ${currentBuyerId === item.ID && 'active'}`}
                    key={item.ID}
                    eventKey={item.ID}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                    {item.Description}
                  </Dropdown.Item>
                ))}
              </div>
              {!isSSO && (
                <div className="dealership-actions">
                  <hr />
                  {isOveUser && (
                    <Dropdown.Item className="dealership-item show-icon functionality" href="/account#settings">
                      <FontAwesomeIcon icon={faPlus} />
                      {'Add Dealership(s)'}
                    </Dropdown.Item>
                  )}
                  {userFunctionalities?.map(
                    (item) =>
                      !!item && (
                        <Dropdown.Item
                          className="dealership-item show-icon functionality"
                          key={item.MenuItem.Name}
                          eventKey={item.MenuItem.Name}
                          href={item.URL}
                          onClick={() => handlePortalMenuItemClick(item)}
                        >
                          {iconDict[item.MenuItem.Name]}
                          {item.MenuItem.Text}
                        </Dropdown.Item>
                      )
                  )}
                </div>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </StyledDealerDropdown>
        <StyledCurrentDealership>{dealerShipName}</StyledCurrentDealership>
      </StyledUserMenu>
    </RightActionContainer>
  )
}
