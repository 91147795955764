import { gridBreakPoints } from 'common/theme'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

export const StyledWarningModal = styled(Modal)<{ isAlert?: boolean }>`
  .modal-content {
    background-color: ${({ isAlert, theme }) => (isAlert ? theme.colors.dangerColor : '#fff')};
    color: ${({ isAlert, theme }) => (isAlert ? theme.colors.colorTextModal : theme.colors.defaultDealerDeactive)};
    font-size: 1.6rem;

    @media (max-width: ${gridBreakPoints.sm}) {
      width: 98%;
    }

    @media (min-width: ${gridBreakPoints.xxl}) {
      width: 67rem;
    }
  }
  .modal-header {
    border-bottom: none;
  }
  .modal-body {
    text-align: center;
    padding-bottom: 3rem;
  }
`
