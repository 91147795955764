// import { colorPrimary, textMenuColor } from 'common/theme'
import { gridBreakPoints } from 'common/theme'
import styled from 'styled-components'

export const StyledSubMenu = styled.div`
  box-shadow: 0 0.3rem 0.4rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3rem;
  background: white;
  position: absolute;
  font-size: 1.4rem;
  opacity: 0;
  transform: translateY(4rem);
  pointer-events: none;
  transition: all 167ms ease-in;

  &:hover {
    display: block;
  }

  svg {
    margin-right: 1rem;
  }
  .nav-item .nav-link {
    font-size: 1.6rem;
    margin-left: 0;
    margin-right: 0;
  }

  li {
    height: 4.2rem;
    transition: 167ms ease-in;
    border-bottom: 0.2rem solid transparent;
    line-height: 2.2;
  }

  li:hover {
    border-bottom: 0.2rem solid ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: ${gridBreakPoints.lg}) {
    box-shadow: none;
    border-radius: unset;
    transition: none;
    transform: unset;
    opacity: unset;
    position: unset;
    background: transparent;
    li:hover {
      border-bottom: 0.2rem solid transparent;
    }
  }
`

export const StyledSubMenuItem = styled.li`
  white-space: nowrap;
  a.nav-link,
  a.nav-link:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
  a.nav-link.active:after {
    border-bottom-color: transparent !important;
  }
`
