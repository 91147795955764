import { merge } from 'lodash'
import { DefaultTheme } from 'styled-components'
import { theme as defaultTheme } from '../BMW/theme'

export const theme: DefaultTheme = merge({}, defaultTheme, {
  colors: {
    primary: '#c3002f',
    tableStriped: '#edf8fb',
    tableBorder: '#dee2e6'
  },
  logo: {
    left: 'vendor-main-logo.png',
    right: 'site_title-new.png?version=2'
  }
})
