import { Popover, Row } from 'react-bootstrap'
import styled from 'styled-components'
export const StyledModelFigureCaption = styled.p`
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.fontColor};

  & a {
    display: contents;
    font-size: 1.8rem;
    font-weight: 600;
    margin-left: 0.5rem;

    &:focus,
    &.focus {
      box-shadow: none;
      text-decoration: none;
    }
  }
`

export const StyledModelFigure = styled(Row).attrs(({ className }) => ({
  className: `h-100 ${className ?? ''}`.trim()
}))`
  display: flex;
  justify-content: center;

  & > .col {
    display: flex;
    flex-direction: column;
  }

  img {
    object-fit: contain;
    /*
     * Make size a little bit smaller to prevent overlap
     * to siblings
     */
    width: 90%;
  }
`

export const StyledPopover = styled(Popover)`
  box-shadow: 0 0.2rem 0.5rem rgb(0 0 0 / 25%);
  z-index: 1000;
  .car-tooltip-header {
    margin-bottom: 1rem;

    .model-type {
      margin-top: -0.75rem;
      font-size: 1.6rem;
    }
  }

  .car-tooltip-header span {
    display: block;
  }

  .popover-body {
    font-size: 1.4rem;
  }
`

export const BodyStyled = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1.25rem;

  div > span {
    a {
      font-size: 1.4rem;
      display: contents;
      padding: 0;
      margin: 0;
    }

    display: contents;
    color: ${({ theme }) => theme.colors.primary};
  }
`
