import { StyledNoActivity } from './NoActivity.styled'
import NoActivityImg from 'images/no-activity.jpg'
import { StyledVehicleAuctionInfo } from './VehicleAuction.styled'

interface IProps {
  message: string
}

const NoActivity = ({ message }: IProps) => {
  return (
    <StyledVehicleAuctionInfo>
      <StyledNoActivity>
        <img src={NoActivityImg} alt="no activity" />
        <div>
          <span>{message}</span>
        </div>
      </StyledNoActivity>
    </StyledVehicleAuctionInfo>
  )
}

export default NoActivity
