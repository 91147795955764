import { Carousel } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { AdvertType, CarouselImage } from '../../types/baseTypes'
import { StyledHomeSlideShow, StyledPromotion, StyledSearch } from './SlideShow.styled'
import { Loader } from 'components/Loader'
import { BaseVehicleSearchCriteriaColumn, SessionStorageKey } from 'common/constants'
import { ISearchFilterResult } from 'types/vehicleTypes'
import { useFetch } from 'hooks/useFetch'
import { addDummyChildrenFilter, selectChildrenFilter } from 'common/helpers'
import { saveAs } from 'file-saver'
import { useSrpFiltersStore } from 'store/useSrpFiltersStore'
import { getCarouselImages } from 'apis/dashboardApis'
import shallow from 'zustand/shallow'
import { isAnyChildrenFilterExist } from '../../common/helpers'
import { useHistory } from 'react-router-dom'
import { AppRoutes } from 'common/routes'
import { resetSRPFilter } from 'utils/menuUtils'

interface IProps {
  isFilterLoading: boolean
}

export const SlideShow = ({ isFilterLoading }: IProps) => {
  const { filters } = useSrpFiltersStore((state) => state, shallow)
  const { loading, data } = useFetch(() => getCarouselImages())
  const history = useHistory()

  const handleRedirect = (ft?: ISearchFilterResult[]) => {
    resetSRPFilter()
    sessionStorage.setItem(SessionStorageKey.SEARCH_FILTERS, ft ? JSON.stringify(ft) : null)

    history.push(AppRoutes.SearchResult)
  }

  const clickHandler = (e: MouseEvent, item: CarouselImage) => {
    e.preventDefault()
    if (item.AdvertType === AdvertType.NonPromotional) return
    if (item.AdvertType === AdvertType.Promotional) {
      if (isAnyChildrenFilterExist(filters, 'Promotion', item.Promotion)) {
        selectChildrenFilter(filters, 'Promotion', item.Promotion)
      } else {
        addDummyChildrenFilter(
          filters,
          BaseVehicleSearchCriteriaColumn.Promotion,
          'Promotion',
          'promotion',
          item.Promotion
        )
      }
      handleRedirect(filters)
    } else {
      if (item.DocumentURL) saveAs(item.DocumentURL, item.DocumentName)
    }
  }

  return (
    <StyledHomeSlideShow>
      <Carousel interval={3000} fade={true} controls={false} indicators={data?.length > 1}>
        {!loading ? (
          data?.map((item, index) => (
            <Carousel.Item key={index}>
              <LazyLoadImage
                placeholder={<img alt=""></img>}
                onClick={(e) => clickHandler(e, item)}
                className={`d-block w-100 h-100 ${item.AdvertType !== AdvertType.NonPromotional && 'clickable'}`}
                src={item.ImageURL}
              />
              <Carousel.Caption
                style={
                  item.AdvertType !== AdvertType.NonPromotional ? { marginTop: '-3rem', paddingBottom: '0' } : null
                }
              >
                {item.AdvertType !== AdvertType.NonPromotional && (
                  <StyledPromotion style={{ paddingTop: '-1rem' }}>
                    <span>{item.AdvertType === AdvertType.Promotional ? item.Promotion : item.Description}</span>
                    <StyledSearch onClick={(e) => clickHandler(e, item)}>
                      {item.AdvertType === AdvertType.Promotional ? 'Click Here' : 'Learn More'}
                    </StyledSearch>
                  </StyledPromotion>
                )}
              </Carousel.Caption>
            </Carousel.Item>
          ))
        ) : (
          <Carousel.Item>
            <img className="d-block w-100" alt={''} />
            <Loader overlay />
          </Carousel.Item>
        )}
      </Carousel>
    </StyledHomeSlideShow>
  )
}
