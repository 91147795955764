import { IconSvg } from '@prism/icon'
import { useContext } from 'react'
import { Spinner } from 'react-bootstrap'
import { StyledWatchListLink } from './WatchlistLink.styled'
import { VehicleContext } from 'modules/DealerVehicleManagement/VehicleContext'

export const WatchlistLink = () => {
  const {
    vehicleData: {
      vehicle: { WatchListID }
    },
    vehicleWatchListState: { saveWatchList, isSaving }
  } = useContext(VehicleContext)

  return (
    <StyledWatchListLink>
      {isSaving ? (
        <Spinner animation="border" role="status" />
      ) : (
        <div onClick={saveWatchList}>
          {!WatchListID || WatchListID <= 0 ? (
            <span>
              <IconSvg glyph="eye" color="primary" />
              Watch
            </span>
          ) : (
            <span>
              <IconSvg glyph="eye-blocked" color="primary" />
              Unwatch
            </span>
          )}
        </div>
      )}
    </StyledWatchListLink>
  )
}
