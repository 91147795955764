import styled from 'styled-components'
const StyledDateTimePicker = styled.div`
  width: 100%;
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker {
    font-size: 1.3rem;
  }
  .react-datepicker__day.react-datepicker__day--keyboard-selected {
    background: none;
    color: black;
  }
  .react-datepicker__current-month {
    font-size: 1.5rem;
  }

  .react-datepicker__header {
    padding-top: 6px;
  }

  .react-datepicker__navigation {
    top: 13px;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    margin: 0.5rem;
  }
`

export default StyledDateTimePicker
