import styled from 'styled-components'
import { Col, Row } from 'react-bootstrap'
import { LeftFooterContainer } from './LoginLayout.styled'
import { gridBreakPoints } from 'common/theme'

export const StyledMainNavItem = styled.div`
  width: 25%;
`
export const StyledFooter = styled.div`
  font-family: ${({ theme }) => theme.fonts.fontRegular};
  background: ${({ theme }) => theme.colors.primary};
  font-size: 1.4rem;
  padding: 2.4rem 0 1.6rem;
  width: 100%;

  nav {
    justify-content: flex-end;
  }

  .nav-list {
    display: flex;
  }

  ul {
    list-style: none;
    padding-inline-start: 0;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  .subnav li {
    padding-top: 0.375rem;
  }

  .nav-link {
    padding: 0;
  }
`

export const StyledFooterRow = styled(Row)`
  margin: 0;

  @media (min-width: ${gridBreakPoints.md}) {
    margin-top: -1.5rem;
  }
`
export const StyledNavItem = styled.a`
  font-weight: 800;
  display: inline-block;
  margin-bottom: 1.5rem;
`
export const StyledFooterNav = styled.li`
  margin-top: 0.4rem;
`

export const StyledFooterLogout = styled.li`
  margin-top: 2.75rem;
  font-weight: 600;
`

export const StyledLogoContainer = styled(Col)`
  align-self: flex-end;
  padding-right: 2.75rem;
`

export const StyledSubNavSmallScreen = styled.div`
  ul {
    display: flex;
    justify-content: center;

    li {
      padding: 0 1rem;

      &:last-child {
        border-left: 0.1rem solid ${({ theme }) => theme.colors.fontColor};
      }
    }
  }
`
export const LayoutLeftFooterContainer = styled(LeftFooterContainer)`
  color: #fff;
  display: flex;
  align-items: flex-end;
  padding-left: 2.75rem;
  font-size: 1.1rem;
`

export const StyledFooterLogo = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0;
  > img {
    max-height: 5rem;
    filter: brightness(0) invert(1);
  }

  @media print {
    > img {
      filter: none;
    }
  }
`
