import { chunk } from 'lodash'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, useEffect, useContext } from 'react'
import { Carousel, Col, Row } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { IVehicleImage } from 'types/vehicleTypes'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { StyledVehicleDetailCarousel, StyledVehicleDetailImage } from './VehicleDetailImage.style'
import BMWIconNew from 'images/icon/BMWIconNew'
import { SliderContext } from 'contexts/SliderContext'
interface IProps {
  mainImageUrl: string
  images?: IVehicleImage[]
  vehicleInstance: number
  isDSI?: boolean
}

const pageSize = 4

export const VehicleDetailImage = ({ mainImageUrl, images, vehicleInstance, isDSI = false }: IProps) => {
  const { setDisplaySlider, setSliderData, selectedImageID, selectedVehicle } = useContext(SliderContext)
  const [pageNumber, setPageNumber] = useState(0)
  const [imageUrl, setImageUrl] = useState('')
  const [canLoadImage, setCanLoadImage] = useState(true)

  const handleSelect = (selectedIndex: number) => {
    setPageNumber(selectedIndex)
  }
  const pages = chunk(images, pageSize)

  const handlImageChange = (imageUrl) => {
    setImageUrl(imageUrl)
    setCanLoadImage(true)
  }

  const handleBrokenImageURL = (event) => {
    setCanLoadImage(false)
    event.target.error = null
  }

  useEffect(() => {
    if (mainImageUrl) {
      setImageUrl(mainImageUrl)
    }
  }, [mainImageUrl])

  useEffect(() => {
    if (selectedImageID && selectedVehicle === vehicleInstance) {
      let imgIndex = images.findIndex((x) => x.ID === selectedImageID)
      imgIndex = imgIndex === -1 ? 0 : imgIndex
      setImageUrl(images[imgIndex]?.LocalThumbImageURL || images[imgIndex]?.LocalImageURL)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImageID, images])

  return (
    <StyledVehicleDetailImage>
      <span>
        {images?.length <= 0 || !canLoadImage ? (
          <BMWIconNew />
        ) : (
          <img
            src={imageUrl || mainImageUrl}
            alt=""
            onError={handleBrokenImageURL}
            onClick={() => {
              if (images) {
                setSliderData(images.map((image) => ({ ...image, Description: isDSI ? '' : image.Description })))
                setDisplaySlider(
                  !!imageUrl,
                  images.find((x) => x.LocalThumbImageURL === imageUrl || x.ImageURL === imageUrl)?.ID,
                  vehicleInstance
                )
              }
            }}
          />
        )}
      </span>
      {images?.length > 1 && (
        <StyledVehicleDetailCarousel
          activeIndex={pageNumber}
          onSelect={handleSelect}
          indicators={false}
          interval={null}
          nextIcon={<FontAwesomeIcon icon={faChevronRight} size="2x" />}
          prevIcon={<FontAwesomeIcon icon={faChevronLeft} size="2x" />}
        >
          {pages.map((currentPage, index) => (
            <Carousel.Item key={index}>
              <Row className="mx-0">
                {currentPage.map((item, index) => (
                  <Col key={index} className="pr-2 carousel-item-img">
                    <LazyLoadImage
                      src={item.LocalThumbImageURL || item.ImageURL}
                      effect="blur"
                      alt={item.Description}
                      onClick={() => (images ? handlImageChange(item.LocalThumbImageURL || item.ImageURL) : null)}
                    />
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
          ))}
        </StyledVehicleDetailCarousel>
      )}
    </StyledVehicleDetailImage>
  )
}

export default VehicleDetailImage
