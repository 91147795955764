import { extraLargeScreen, largeScreen, smallScreen } from 'common/theme'
import { Popover } from 'react-bootstrap'
import styled from 'styled-components'

const minWidth = '20rem'

export const StyledSort = styled.div`
  & {
    display: flex;
    align-items: center;
  }
  .sort-label {
    font-weight: 600;
    margin-right: 0.3rem;

    display: inline-block;
    @media (max-width: ${extraLargeScreen}) {
      display: none;
    }
  }

  .btn-current-sort {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;

    font-size: 1.6rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary};

    background: none;
    border: none;
  }

  .btn.btn-current-sort:hover,
  .btn.btn-current-sort:focus,
  .btn.btn-current-sort:active {
    color: ${({ theme }) => theme.colors.primaryDarken};
    background: none;
    border: none;
    box-shadow: none;
  }

  @media (max-width: ${smallScreen}) {
    display: inline-flex;
  }

  @media (max-width: ${largeScreen}) {
    float: right;
    align-items: center;
  }
`
export const StyledSortPopover = styled(Popover)`
  & {
    min-width: ${minWidth};
  }
  .sort-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 1.2rem;
    font-size: 1.5rem;
  }

  .btn-sort {
    color: ${({ theme }) => theme.colors.primary};
  }

  .sort-button-group .btn-sort.active {
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
  }
`
