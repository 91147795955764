import { getBaseVehicle, getDetailedDeliveryInfo, getGenericPremium } from 'apis/vehicleApis'
import { BidAmountTypeOptions, SelfArrangedTransportProviderId } from 'common/constants'
import { SlideOutHeading } from 'components/SlideOut/SlideOutHeading'
import { SlideOutDropdown } from 'components/SlideOut/SlideOutDropdown'
import { SlideOutReadOnlyInput } from 'components/SlideOut/SlideOutReadOnlyInput'
import { useState, useMemo, useEffect, useCallback } from 'react'
import { useBiddingSidePanel } from 'store/useBiddingSidePanelStore'
import { IOption, IDropdownData } from 'types/baseTypes'
import { DeliveryInformation, GetBaseVehicleRequest, IDeliveryQuote, IVehicleFeeRequest } from 'types/vehicleTypes'
import shallow from 'zustand/shallow'
import { StyledSlideOutElement } from 'components/SlideOut/SlideOut.styled'
import { SlideOutBidAmountInput } from 'components/SlideOut/SlideOutBidAmountInput'
import { StyledSlideOutBidAmountInput } from 'components/SlideOut/SlideOutBidAmountInput.styled'
import { Form } from 'react-bootstrap'
import { useGlobalStore } from 'store/useGlobalStore'
import { SlideOutPricingDetails } from 'components/SlideOut/SlideOutPricingDetails'
import { useFormikContext } from 'formik'
import { BidAgainFormValues } from 'types/formikTypes'
import { useVehicleStore } from 'store/useVehicleStore'
import { debounce } from 'lodash'
import { useSidePanelRealTimeUpdateDealer } from 'hooks/useSidePanelRealTimeUpdateDealer'
import { IAccountInfo } from 'types/accountTypes'

interface IBiddingSidePanelFormProps {
  isDealerShipSelectable: boolean
  setFetchLoading: (loading: boolean) => void
}
export const BiddingSidePanelFormContent: React.FC<IBiddingSidePanelFormProps> = ({
  isDealerShipSelectable,
  setFetchLoading
}) => {
  const formik = useFormikContext<BidAgainFormValues>()
  const [biddingVehicleInstanceId] = useBiddingSidePanel((state) => [state.biddingVehicleInstanceId], shallow)
  const biddingVehicle = useVehicleStore((state) =>
    state.vehicles?.find((vehicleData) => vehicleData.vehicle.InstanceID === biddingVehicleInstanceId)
  )

  const { AssociatedBuyers, CurrentBuyerID } = useGlobalStore((state) => state.userClaims)

  const [feeLoading, setFeeLoading] = useState(false)
  const [buyerFee, setBuyerFee] = useState(0)
  const [buyerFeeString, setBuyerFeeString] = useState('N/A')
  const [hasAnyChargeRange, setHasAnyChargeRange] = useState(false)
  const [deliveryInformation, setDeliveryInformation] = useState<DeliveryInformation>(null)
  const [vehicleInfo, setVehicleInfo] = useState(biddingVehicle?.vehicle)

  const dealershipOptions: IOption<IDropdownData>[] = useMemo(
    () =>
      AssociatedBuyers.filter((dealership) =>
        biddingVehicle?.auctionInfo.SalesSessionStepBuyerTypeIds.some(
          (sessionStepBuyerType) => sessionStepBuyerType === dealership.BuyerTypeID
        )
      ).map((dealership) => ({
        item: dealership,
        label: dealership.Description,
        value: dealership.ID
      })),

    [AssociatedBuyers, biddingVehicle?.auctionInfo.SalesSessionStepBuyerTypeIds]
  )

  const buyerAccountOptions: IOption<IDropdownData>[] = useMemo(
    () =>
      deliveryInformation?.CurrentBuyerAlternateIdentifiers?.map((acc: IAccountInfo) => ({
        item: acc,
        label: acc.Description,
        value: acc.Description
      })),
    [deliveryInformation?.CurrentBuyerAlternateIdentifiers]
  )

  const paymentTypes: IOption<IDropdownData>[] = useMemo(() => {
    return deliveryInformation?.PaymentTypes?.map((paymentType) => ({
      item: paymentType,
      label: paymentType.Description,
      value: paymentType.ID
    }))
  }, [deliveryInformation?.PaymentTypes])

  const selfArrangeTransportationProvider: IDropdownData = useMemo(
    () => deliveryInformation?.TransportProviders.find((provider) => provider.ID === SelfArrangedTransportProviderId),
    [deliveryInformation?.TransportProviders]
  )

  const estimatedGrossPrice = useMemo(() => {
    if (!biddingVehicle?.auctionInfo) {
      return undefined
    }

    const totalCharges =
      vehicleInfo?.Charges?.reduce((accumulator, currentValue) => accumulator + currentValue.Price, 0) || 0

    const promotions =
      vehicleInfo?.Incentives?.reduce((accumulator, currentValue) => accumulator + currentValue.Amount, 0) || 0

    let total = 0
    const deliveryPrice = deliveryInformation?.QuotePrice || 0 // for now this almost always 0 because we default to self-arranged

    // for now this fee only applicable for Nissan
    const titleOptionFee = deliveryInformation?.TitleDeliveryCharge || 0
    const nextBidAmount = biddingVehicle.auctionInfo.NextBidAmount
    if (vehicleInfo?.IsIncentiveAccumulate) {
      total = buyerFee + nextBidAmount + deliveryPrice + titleOptionFee + totalCharges
    } else {
      total = buyerFee + nextBidAmount + deliveryPrice + titleOptionFee + totalCharges + promotions
    }
    return total
  }, [
    biddingVehicle.auctionInfo,
    buyerFee,
    deliveryInformation?.QuotePrice,
    deliveryInformation?.TitleDeliveryCharge,
    vehicleInfo?.Charges,
    vehicleInfo?.Incentives,
    vehicleInfo?.IsIncentiveAccumulate
  ])

  const fetchDetailedDeliveryInfo = useCallback(
    async (biddingDealerID?: number) => {
      if (biddingVehicle?.vehicle && biddingVehicle?.auctionInfo) {
        const vehicle = biddingVehicle.vehicle

        const salePrice = biddingVehicle.auctionInfo.NextBidAmount || vehicle.FinalPrice

        const deliveryQuote: IDeliveryQuote = {
          ChannelID: vehicle.SaleChannelID,
          InterestID: vehicle.OfferID,
          InterestStatusID: vehicle.OfferStatusID,
          LocationID: vehicle.LocationID,
          PaymentTypeID: 0,
          SalePrice: salePrice,
          SalesSessionStepId: vehicle.SalesSessionStepID,
          VehicleInstanceID: vehicle.InstanceID
        }
        const deliveryInformation = await getDetailedDeliveryInfo(
          deliveryQuote,
          biddingDealerID || biddingVehicle.auctionInfo.BuyerID
        )

        formik.setFieldValue('PaymentTypeID', deliveryInformation.PaymentTypeID, false)
        formik.setFieldValue('BuyerAccountReference', deliveryInformation.CurrentBuyerAccountRef, false)
        setDeliveryInformation(deliveryInformation)
        setBuyerFee(deliveryInformation.BuyerFee)
        setBuyerFeeString(deliveryInformation.BuyerFeeString)
        setHasAnyChargeRange(deliveryInformation.HasAnyChargeRange)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [biddingVehicle?.auctionInfo, biddingVehicle?.vehicle]
  )

  const fetchVehicleInformation = async (biddingDealerID?: number) => {
    const payload = new GetBaseVehicleRequest([biddingVehicle])
    payload.IncludeAuctionData = true
    payload.IncludeBaseData = true
    payload.IncludeInspectionReport = false
    const {
      Items: [vehicleData]
    } = await getBaseVehicle(payload, biddingDealerID)
    setVehicleInfo(vehicleData.vehicle)
  }
  const handleDealerChange = async (dealershipId?: number) => {
    dealershipId = dealershipId || biddingVehicle.auctionInfo.BuyerID
    try {
      setFetchLoading(true)
      await Promise.all([fetchVehicleInformation(dealershipId), fetchDetailedDeliveryInfo(dealershipId)])
    } catch (error) {
    } finally {
      setFetchLoading(false)
    }
  }
  useSidePanelRealTimeUpdateDealer(formik, handleDealerChange)

  const requestGenericPremiumOnBidMountChange = debounce(async (bidAmount: number, biddingDealerId: number) => {
    if (hasAnyChargeRange) {
      try {
        const feeRequest: IVehicleFeeRequest = {
          VehicleInstanceId: biddingVehicle?.vehicle.InstanceID,
          LocationId: biddingVehicle?.vehicle.LocationID,
          SaleChannelId: biddingVehicle?.vehicle.SaleChannelID,
          Price: bidAmount
        }
        setFeeLoading(true)
        const feeResponse = await getGenericPremium(feeRequest, biddingDealerId)

        setHasAnyChargeRange(feeResponse.HasAnyChargeRange)
        setBuyerFee(feeResponse.Premium)
        setBuyerFeeString(feeResponse.PremiumString)
      } catch (error) {
        console.error(error)
      } finally {
        setFeeLoading(false)
      }
    }
  }, 300)

  useEffect(() => {
    requestGenericPremiumOnBidMountChange(formik.values.BidAmount, formik.values?.BiddingDealerID)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.BidAmount, formik.values?.BiddingDealerID])

  return (
    <>
      <SlideOutHeading vehicleTitle={biddingVehicle?.vehicle.Title} vin={biddingVehicle?.vehicle.VIN}></SlideOutHeading>
      <SlideOutDropdown
        required
        label="Payment Method"
        options={paymentTypes}
        fallbackValue={paymentTypes?.[0]}
        name="PaymentTypeID"
      ></SlideOutDropdown>

      <SlideOutDropdown
        alertMessage={
          formik.values.BiddingDealerID !== biddingVehicle?.auctionInfo?.BuyerID
            ? `By selecting "Current Bid Amount" and clicking "Update", you'll be transferring your previously entered bid to the selected dealership.\nBy selecting "Update Bid Amount" and clicking "Update", you'll be creating a new bid.`
            : null
        }
        disabled={!isDealerShipSelectable}
        label="Bidding Dealership"
        onChange={handleDealerChange}
        options={dealershipOptions}
        fallbackValue={CurrentBuyerID}
        name="BiddingDealerID"
      ></SlideOutDropdown>

      {deliveryInformation?.CurrentBuyerAlternateIdentifiers?.length > 1 && (
        <SlideOutDropdown
          label="Buyer Account"
          options={buyerAccountOptions}
          fallbackValue={
            deliveryInformation?.CurrentBuyerAlternateIdentifiers?.find(
              (account) => account.Description === deliveryInformation?.CurrentBuyerAccountRef
            )?.Description
          }
          name="BuyerAccountReference"
        ></SlideOutDropdown>
      )}

      <SlideOutReadOnlyInput
        label="Transportation Provider"
        inputText={selfArrangeTransportationProvider?.Description}
      ></SlideOutReadOnlyInput>

      {/* bid again */}
      {biddingVehicle?.auctionInfo?.HasPreviousBid ? (
        <>
          <StyledSlideOutBidAmountInput>
            <Form.Check
              name="BidAmountType"
              type="radio"
              id="current-bid-amount"
              label="Current Bid Amount"
              value={BidAmountTypeOptions.CurrentBidAmount}
              checked={formik.values.BidAmountType === BidAmountTypeOptions.CurrentBidAmount}
              onChange={(event) => formik.setFieldValue('BidAmountType', event.target.value)}
            />
            <Form.Control
              className="read-only-input"
              type="text"
              readOnly
              placeholder={biddingVehicle?.auctionInfo?.UserMaxBidString}
              disabled={formik.values.BidAmountType !== BidAmountTypeOptions.CurrentBidAmount}
            />
          </StyledSlideOutBidAmountInput>

          <StyledSlideOutElement>
            <Form.Check
              name="BidAmountType"
              type="radio"
              id="update-bid-amount"
              label="Update Bid Amount"
              checked={formik.values.BidAmountType === BidAmountTypeOptions.UpdateBidAmount}
              value={BidAmountTypeOptions.UpdateBidAmount}
              onChange={(event) => formik.setFieldValue('BidAmountType', event.target.value)}
            />

            <SlideOutBidAmountInput
              disabled={formik.values.BidAmountType !== BidAmountTypeOptions.UpdateBidAmount}
              nextBidAmount={biddingVehicle?.auctionInfo?.NextBidAmount}
              bidIncreasement={biddingVehicle?.auctionInfo?.BidIncrement}
              bidIncreasementString={biddingVehicle?.auctionInfo?.BidIncrementString}
              nextBidAmountString={biddingVehicle?.auctionInfo?.NextBidAmountString}
            ></SlideOutBidAmountInput>
          </StyledSlideOutElement>
        </>
      ) : (
        // first bid
        <StyledSlideOutElement>
          <Form.Group controlId="bid-again">
            <Form.Label>Enter Bid Amount</Form.Label>
            <SlideOutBidAmountInput
              bidIncreasement={biddingVehicle?.auctionInfo?.BidIncrement}
              bidIncreasementString={biddingVehicle?.auctionInfo?.BidIncrementString}
              nextBidAmountString={biddingVehicle?.auctionInfo?.NextBidAmountString}
              nextBidAmount={biddingVehicle?.auctionInfo?.NextBidAmount}
            ></SlideOutBidAmountInput>
          </Form.Group>
        </StyledSlideOutElement>
      )}

      <SlideOutPricingDetails
        feeLoading={feeLoading}
        buyerFeeString={buyerFeeString}
        deliveryCharge={deliveryInformation?.QuotePrice}
        vehicleCharges={vehicleInfo?.Charges}
        isIncentiveAccumulate={vehicleInfo?.IsIncentiveAccumulate}
        incentives={vehicleInfo?.Incentives}
        estimatedGrossPrice={
          estimatedGrossPrice + (formik.values.BidAmount - biddingVehicle?.auctionInfo?.NextBidAmount)
        }
      ></SlideOutPricingDetails>
    </>
  )
}
