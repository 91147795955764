import styled from 'styled-components'
import { extraLargeScreen, largeScreen, mediumScreen, smallScreen } from 'common/theme'
import { StyledHomeSectionHeaderBanner } from 'components/Header/HomeSectionHeader.styled'
import { StyledNoActivity } from 'modules/BuyerActivity/NoActivity.styled'
import { StyledRibbonContainer } from 'modules/DealerVehicleManagement/VehicleDetails.styled'
import { Row } from 'react-bootstrap'
import { StyledVehicleAction } from 'modules/DealerVehicleManagement/VehicleActions'

export const StyledVehicleList = styled.div`
  padding: 2.4rem;

  background-color: #f8f8f8;

  .grid-card-col {
    display: flex;
  }

  ${StyledNoActivity} {
    @media (min-width: ${mediumScreen}) {
      img {
        width: 21rem;
        height: 20rem;
      }
    }
  }
`
StyledVehicleList.displayName = 'StyledVehicleList'

export const StyledSelectAll = styled.div`
  margin: 0.5rem 1.5rem;
`

export const StyledStickyHeaderPage = styled.div`
  ${StyledHomeSectionHeaderBanner} {
    white-space: nowrap;
    @media (max-width: ${smallScreen}) {
      .navigation-link {
        display: none;
      }
    }
  }
`
export const StyledHeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  font-size: 1.6rem;

  .right-action,
  .prism-icon-svg {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: ${extraLargeScreen}) {
    gap: 1rem;
  }

  margin-right: 2.5rem;

  .view-icons {
    gap: 1.2rem;
    .prism-icon-svg {
      opacity: 0.5;

      &.active,
      &:hover {
        opacity: 1;
      }
    }
  }

  .right-action,
  .prism-icon-svg {
    color: ${({ theme }) => theme.colors.primary};
  }

  .right-action:hover,
  .btn-current-sort:hover {
    opacity: 0.5;
  }

  .right-action,
  .view-icons {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  @media (max-width: ${smallScreen}) {
    .view-icons {
      display: none;
    }
    .export-view {
      display: none;
    }
  }

  .dropdown-toggle:after {
    display: none;
  }

  .chevron-down-icon {
    margin-top: 0.5rem;
  }

  .dropdown-item {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
    height: 2.5rem;
    display: flex;
    align-items: center;
    transition: all 167ms ease-in;
    position: relative;
    background-color: transparent;

    &:hover {
      color: white;
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }

  .dropdown-menu.show {
    transform: translate(30px, 26px) !important;
  }
`

export const StyledVehicleDetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const StyledVehicleDetailsHeaderRow = styled(Row)`
  flex-wrap: unset;
  justify-content: space-between;
  @media (max-width: ${largeScreen}) {
    flex-wrap: wrap;
    ${StyledVehicleAction} {
      width: 100%;
    }
    ${StyledRibbonContainer} {
      width: 100%;
    }
  }
`
export const StyledVehicleDetailsBody = styled.div``
