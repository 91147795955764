import { ComponentProps } from 'react'
import ReactSelect from 'react-select'
import { useTheme } from 'styled-components'
import { StyledSelect } from './Select.styled'
import { zIndex } from 'common/constants'

export const Select = ({ ...props }: ComponentProps<typeof ReactSelect>) => {
  const {
    colors: { primary }
  } = useTheme()

  return (
    <StyledSelect>
      <ReactSelect
        styles={{ menu: (base) => ({ ...base, zIndex: zIndex.floatingControls }) }}
        classNamePrefix="react-select"
        {...props}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: primary
          }
        })}
      />
    </StyledSelect>
  )
}

export default Select
