import styled from 'styled-components'

export const TermConditionBody = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundText};
  margin-left: 2.4rem;
  margin-right: 2.4rem;
  margin-bottom: 2.4rem;
  flex: 1;

  p {
    background: inherit !important;
  }

  span {
    font-family: inherit !important;
    font-size: inherit !important;
  }

  div:not([class]) {
    margin-left: 3rem;
  }
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  font-size: 30ps;
  margin-bottom: 1.2rem;
`
