import { zIndex } from 'common/constants'
import { mediumScreen, smallScreen } from 'common/theme'
import { HomeSectionHeader } from 'components/Header/HomeSectionHeader'
import { headerAnimation, StickyProp } from 'layouts/Layout.styled'
import styled, { css } from 'styled-components'

export const StyledVehicleStickyBar = styled(HomeSectionHeader)<StickyProp>`
  position: sticky;
  z-index: ${({ isSticky }) => (isSticky ? zIndex.stickyHeader : zIndex.pageHeader)};
  background: #fff;

  ${({ isSticky }) => (isSticky ? headerAnimation : null)};
  ${({ isSticky, theme }) =>
    isSticky &&
    css`
      top: 7.2rem;

      @media (max-width: ${smallScreen}) {
        top: 6rem;
      }

      .header-row {
        height: 4.2rem;
        white-space: nowrap;
      }
      .filter-icon {
        color: ${theme.colors.primary};
      }
      .header-toggle {
        background: #fff;
        color: ${theme.colors.primary};
        box-shadow: none;
        justify-content: flex-start;
        margin-left: 1rem;
      }

      @media (max-width: ${mediumScreen}) {
        .header-section {
          padding: 0;
          margin: 0;
        }
        .header-col,
        .right-action-col {
          flex-basis: 0;
          flex-grow: 1;
        }
      }
    `};
`
