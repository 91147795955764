import { Popover } from 'react-bootstrap'
import styled from 'styled-components'

export const AttachFilePopoverStyled = styled(Popover)`
  box-shadow: 0 0.2rem 0.5rem rgb(0 0 0 / 25%);

  .popover-body {
    margin-top: 0.5rem;
    font-size: 1.4rem;
    padding: 1rem;
  }
  &.popover {
    min-width: 20%;
    max-width: 30%;
  }
  .popover-header {
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    font-size: 1.6rem;
  }
  .arrow {
    display: none;
  }

  .popover-header::before {
    display: none;
  }

  .download-link {
    color: ${({ theme }) => theme.colors.linkColorLight};
    cursor: pointer;
    font-size: 1.6rem;
  }

  .attachments-list {
    padding-top: 1rem;
    .attachment-item {
      display: block;
      padding-bottom: 0.3rem;
      margin-top: 0.5rem;
    }
  }
`
