import { largeScreen } from 'common/theme'
import { StyledPagination } from 'components/Paging'
import { StyledVehicleList } from 'modules/VehicleList'
import { StyledVehicleStickyBar } from 'modules/VehicleList/VehicleStickyBar.styled'
import { Col } from 'react-bootstrap'
import styled from 'styled-components'

export const StyledSearchResultsPage = styled.div`
  ${StyledVehicleStickyBar} {
    border-radius: 0;
  }

  ${StyledVehicleList} {
    padding: 2rem;
  }
`

export const SearchFilterColumn = styled(Col)`
  @media (max-width: ${largeScreen}) {
    display: none;
  }
  border-right: 1px solid #e5e5e5;
`

export const StyledTopPaging = styled.div`
  ${StyledPagination} {
    justify-content: flex-end;
    margin-right: 3rem;
  }
  @media (max-width: ${largeScreen}) {
    display: none;
  }
`
