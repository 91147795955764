import Select from 'components/Select/Select'
import { ValidationErrorMessage } from 'components/Forms'
import { useFormikContext } from 'formik'
import { Alert, Form } from 'react-bootstrap'
import { IDropdownData, IOption } from 'types/baseTypes'
import { StyledSlideOutElement } from './SlideOut.styled'

interface IProps {
  label: string
  name: string
  options: IOption<IDropdownData>[]
  onChange?: (dropdownValue: any) => void
  fallbackValue: any
  required?: boolean
  disabled?: boolean
  alertMessage?: string
}
// eslint-disable-next-line prettier/prettier
export const SlideOutDropdown = ({
  alertMessage,
  label,
  name,
  options,
  onChange,
  disabled = false,
  fallbackValue,
  required = false
}: IProps) => {
  const formik = useFormikContext()
  const selectedValue =
    options?.find((option) => option.value === formik?.values?.[name]) ||
    options?.find((option) => option.value === fallbackValue)

  return (
    <StyledSlideOutElement>
      <Form.Label htmlFor={name} className={required && 'required'}>
        {label}
      </Form.Label>
      <Select
        inputId={name}
        name={name}
        isDisabled={disabled}
        options={options}
        value={options ? selectedValue : ''}
        onChange={(option: IOption<IDropdownData>) => {
          formik?.setFieldValue(name, option.value)
          onChange?.(option.value)
        }}
      ></Select>
      {alertMessage && (
        <Alert variant="danger" className="dropdown-alert">
          <Alert.Heading>{alertMessage}</Alert.Heading>
        </Alert>
      )}

      {formik.errors[name] && formik.touched[name] && (
        <ValidationErrorMessage>{formik.errors[name]}</ValidationErrorMessage>
      )}
    </StyledSlideOutElement>
  )
}
