import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { largeScreen, smallScreen } from 'common/theme'

interface IShowProps {
  show: boolean
}
export const StyledSearchFilterButton = styled(Button)<IShowProps>`
  display: none;
  flex-wrap: wrap;
  color: #fff;
  font-size: 1.6rem;
  background: none;
  border: none;

  @media (max-width: ${largeScreen}) {
    display: flex;
  }

  .filter-text {
    @media (max-width: ${smallScreen}) {
      display: none;
    }
  }
`
