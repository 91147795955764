import { FilterSearchContext } from 'contexts/FilterSearchContext'
import FilterIcon from 'images/icon/FilterIcon'
import { useContext } from 'react'
import { StyledSearchFilterButton } from './SearchFilterButton.styled'

interface IProps {
  showFilterSearch: boolean
}
export const SearchFilterButton = ({ showFilterSearch }: IProps) => {
  const { setShowSlideOutFilterSearch } = useContext(FilterSearchContext)
  const handlerClickSearch = () => {
    setShowSlideOutFilterSearch(true)
  }

  return (
    <StyledSearchFilterButton className="filter-icon" show={showFilterSearch} onClick={handlerClickSearch}>
      <FilterIcon />
    </StyledSearchFilterButton>
  )
}
