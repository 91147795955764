import { bidOnVehicle } from 'apis/vehicleApis'
import { BidAmountTypeOptions, BuyItNowPrice, SelfArrangedTransportProviderId, VehicleStatuses } from 'common/constants'
import { SlideOut } from 'components/SlideOut/SlideOut'
import { useEffect, useState } from 'react'
import { useBiddingSidePanel } from 'store/useBiddingSidePanelStore'
import { IBidOnVehicleRequest } from 'types/vehicleTypes'
import shallow from 'zustand/shallow'
import { Formik, FormikHelpers } from 'formik'
import { BidAgainFormValues } from 'types/formikTypes'
import { useVehicleStore } from 'store/useVehicleStore'
import { number, object, ObjectSchema, string } from 'yup'
import { useSignalRStore } from 'store/useSignalRStore'
import { BiddingSidePanelFormContent } from './BiddingSidePanelFormContent'
import { Alert, Button, Form } from 'react-bootstrap'
import SpinnerButton from 'components/Buttons/SpinnerButton'
import { StyledSlideOutElement } from 'components/SlideOut/SlideOut.styled'
import { DismissableAlert } from 'components/DismissableAlert/DismissableAlert'
import { CountDownWithColor } from 'modules/BuyerActivity/CountDownWithColor'
import { useCountdown } from 'hooks/useCountdown'
import { useDtmAnalytics } from 'hooks/useDtmAnalytics'
import { useLocation } from 'react-router-dom'

const bidAgainValidationSchema: ObjectSchema<BidAgainFormValues> = object({
  VehicleInstanceId: number().required().positive().integer(),
  BidAmount: number().required('Required').positive('Required').integer(),
  PaymentTypeID: number().required().positive('Required').integer(),
  BiddingDealerID: number().required().positive().integer(),
  BidAmountType: string()
    .required()
    .oneOf(['CurrentBidAmount', 'UpdateBidAmount'] as const),
  TransportCompanyID: number().required().positive().integer(),
  BuyerAccountReference: string()
})
interface IBiddingSidePanelProps {
  isDealerShipSelectable: boolean
}
export const BiddingSidePanel: React.FC<IBiddingSidePanelProps> = ({ isDealerShipSelectable }) => {
  const updateVehicleAuction = useVehicleStore((state) => state.updateVehicleAuction)
  const [isOpen, biddingVehicleInstanceId, setBiddingVehicleInstaceId] = useBiddingSidePanel(
    (state) => [state.isOpen, state.biddingVehicleInstanceId, state.setBiddingVehicleInstanceId],
    shallow
  )
  const biddingVehicle = useVehicleStore((state) =>
    state.vehicles?.find((vehicleData) => vehicleData.vehicle.InstanceID === biddingVehicleInstanceId)
  )

  const { userInteraction } = useDtmAnalytics()
  const location = useLocation()
  useEffect(() => {
    setBiddingVehicleInstaceId(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const connectionID = useSignalRStore((state) => state.connectionID)
  const [isBidSuccess, setIsBidSuccess] = useState(false)
  const [bidResponseMessage, setBidResponseMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const { seconds: timeOut } = useCountdown(biddingVehicle?.auctionInfo?.EndDate)
  const showBidButton =
    biddingVehicle?.auctionInfo?.CanBid && timeOut > 0 && biddingVehicle?.vehicle?.StatusID === VehicleStatuses.OnSale

  const biddingHandler = async (values: BidAgainFormValues, formikHelpers: FormikHelpers<BidAgainFormValues>) => {
    if (!biddingVehicle) return

    const bidRequest: IBidOnVehicleRequest = {
      VehicleInstanceId: values.VehicleInstanceId,
      BidAmount:
        values.BidAmountType === BidAmountTypeOptions.CurrentBidAmount
          ? biddingVehicle?.auctionInfo.UserMaxBid
          : +values.BidAmount,
      BuyItNowPrice: BuyItNowPrice,
      QuickBid: false,
      ConnectionId: connectionID,
      ReferenceNumber: values.BuyerAccountReference,
      SalesSessionStepId: biddingVehicle.vehicle.SalesSessionStepID,
      DeliveryQuote: {
        PaymentTypeID: values.PaymentTypeID,
        TransportCompanyID: values.TransportCompanyID,
        ChannelID: biddingVehicle.vehicle.SaleChannelID,
        SalePrice: values.BidAmount || biddingVehicle.vehicle.FinalPrice,
        SalesSessionStepId: biddingVehicle.vehicle.SalesSessionStepID,
        VehicleInstanceID: biddingVehicle.vehicle.InstanceID
      }
    }

    try {
      setLoading(true)
      const vehicleDataResponse = await bidOnVehicle(bidRequest, values.BiddingDealerID)

      // update auctionInfo from response
      if (vehicleDataResponse.Items?.[0]?.auctionInfo) {
        formikHelpers.setFieldValue('BidAmount', vehicleDataResponse.Items[0].auctionInfo.NextBidAmount)
        updateVehicleAuction({
          auctionInfo: vehicleDataResponse.Items[0].auctionInfo,
          vehicleInstanceID: vehicleDataResponse.Items[0].vehicle.InstanceID
        })
      }

      if (bidRequest.ReferenceNumber) {
        formikHelpers.setFieldValue('BuyerAccountReference', bidRequest.ReferenceNumber)
      }

      // show response message
      setBidResponseMessage(
        vehicleDataResponse.Items?.[0]?.auctionInfo.ResponseMessage || vehicleDataResponse.ErrorMessages[0]
      )
      setIsBidSuccess(vehicleDataResponse.Success)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
    //analytics
    userInteraction(`${window.webData.pageName} : Place Bid`, [
      { auctionInfo: biddingVehicle.auctionInfo, VIN: biddingVehicle.vehicle.VIN }
    ])
  }

  const resetSidePanel = () => {
    setBiddingVehicleInstaceId(null)
    setBidResponseMessage('')
  }

  if (!isOpen()) return null
  return (
    <SlideOut
      isOpen={isOpen()}
      onCloseButtonClick={() => {
        resetSidePanel()
      }}
    >
      <Formik
        data-testid="form"
        initialValues={{
          VehicleInstanceId: biddingVehicle?.vehicle.InstanceID,
          BidAmount: biddingVehicle?.auctionInfo?.NextBidAmount,
          PaymentTypeID: 0,
          BiddingDealerID: biddingVehicle?.auctionInfo?.BuyerID,
          BidAmountType: BidAmountTypeOptions.UpdateBidAmount,
          TransportCompanyID: SelfArrangedTransportProviderId,
          BuyerAccountReference: null
        }}
        onSubmit={biddingHandler}
        validationSchema={bidAgainValidationSchema}
      >
        {(props) => (
          <Form className="form" onSubmit={props.handleSubmit}>
            <BiddingSidePanelFormContent
              isDealerShipSelectable={isDealerShipSelectable}
              setFetchLoading={setLoading}
            ></BiddingSidePanelFormContent>

            <DismissableAlert
              variant={isBidSuccess ? 'success' : 'danger'}
              message={bidResponseMessage}
              onClose={() => setBidResponseMessage('')}
              autoDismissable
            ></DismissableAlert>

            <Alert show={biddingVehicle?.auctionInfo.NextBidAmount > props.values.BidAmount} variant="danger">
              Current bid amount has changed
            </Alert>

            <StyledSlideOutElement className="action-buttons">
              {showBidButton && (
                <CountDownWithColor auctionInfo={biddingVehicle.auctionInfo} vehicleInfo={biddingVehicle.vehicle} />
              )}
              <div className="button-group">
                {showBidButton && (
                  <SpinnerButton
                    data-testid={'bidButton'}
                    isLoading={loading}
                    type="submit"
                    className="mr-3 form-btn"
                    variant="primary"
                  >
                    {biddingVehicle?.auctionInfo.HasPreviousBid ? 'Update' : 'Bid'}
                  </SpinnerButton>
                )}

                <Button variant="light form-btn" onClick={() => resetSidePanel()}>
                  Cancel
                </Button>
              </div>
            </StyledSlideOutElement>
          </Form>
        )}
      </Formik>
    </SlideOut>
  )
}
