import { hideVehicleBidRow } from 'apis/vehicleApis'
import { VehicleStatuses } from 'common/constants'
import { AlertDialog } from 'components/AlertDialog/AlertDialog'
import {
  BuyItNowButtonContainer,
  BuyItNowButtonGroup,
  StyledAuctionButton,
  StyledAuctionText
} from 'modules/BuyerActivity/VehicleAuction.styled'
import { VehicleDisclosureModal } from 'modules/BuyerActivity/VehicleDisclosureModal'
import { StyleDisclosureModal } from 'modules/BuyerActivity/VehicleDisclosureModal.styled'
import { TextDanger } from 'components/Forms'
import { useCountdown } from 'hooks/useCountdown'
import { useContext, useEffect, useState } from 'react'
import { useBiddingSidePanel } from 'store/useBiddingSidePanelStore'
import { useGlobalStore } from 'store/useGlobalStore'
import { useVehicleStore } from 'store/useVehicleStore'
import { VehicleAction } from 'types/vehicleTypes'
import { VehicleContext } from './VehicleContext'

export const VehicleBidBuyActions = () => {
  const { getBaseVehicle } = useVehicleStore()

  const setBiddingVehicleInstanceId = useBiddingSidePanel((state) => state.setBiddingVehicleInstanceId)
  const {
    vehicleData: { vehicle: vehicleInfo, auctionInfo, documentSignInfo },
    vehicleData,
    onRemoveBid,
    showRemoveBid,
    errorMessage
  } = useContext(VehicleContext)

  const [showVehicleDisclosureConfirm, setShowVehicleDisclosureConfirm] = useState(false)
  const [bidButtonAction, setBidButtonAction] = useState<VehicleAction>(null)
  const [modalShow, setModalShow] = useState(false)

  const getLocalText = useGlobalStore((state) => state.getLocalText)
  const { seconds: timeOut } = useCountdown(auctionInfo?.EndDate)
  const getSystemSettings = useGlobalStore((state) => state.getSystemSetting)
  const [showErrorMsg, setShowErrorMsg] = useState<boolean>(false)
  const { acceptVehicleDocumentSignInfo } = useVehicleStore((state) => state)

  useEffect(() => {
    if (timeOut === 0 && auctionInfo?.IsHighestBidder) {
      getBaseVehicle(vehicleData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeOut, auctionInfo?.IsHighestBidder])

  const isShowBuyItNowPrice =
    auctionInfo && !auctionInfo.IsDirectSale && vehicleInfo.BuyItNowPrice > auctionInfo.CurrentPrice

  const handleDisclosureAccepted = () => {
    setShowVehicleDisclosureConfirm(false)
    acceptVehicleDocumentSignInfo({ vehicleInstanceID: vehicleInfo?.InstanceID, documentSignInfo: documentSignInfo })
    if (bidButtonAction === VehicleAction.BID) {
      setBiddingVehicleInstanceId(vehicleInfo.InstanceID)
    } else {
      window.open('/vehicles#buyItNow/' + vehicleInfo.InstanceID, '_self')
    }
  }

  const haveToAcceptDisclosure = vehicleInfo.DisclosurePresent && documentSignInfo && !documentSignInfo?.IsAccepted

  const removeBidVehicle = async (auctionInfo: any) => {
    try {
      const response = await hideVehicleBidRow(auctionInfo)

      if (response?.Success) {
        onRemoveBid?.()
      } else {
        setShowErrorMsg(true)
      }
    } catch (error) {
      setShowErrorMsg(true)
    } finally {
      setModalShow(false)
    }
  }

  if (vehicleInfo.StatusID === VehicleStatuses.SoldNotFunded || !auctionInfo) {
    return null
  }

  return (
    <>
      <AlertDialog
        title="Confirmation"
        description="Are you sure you want to remove this vehicle?"
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSave={() => removeBidVehicle(auctionInfo)}
        isConfirmation={true}
      />

      <StyleDisclosureModal
        show={showVehicleDisclosureConfirm}
        onHide={() => setShowVehicleDisclosureConfirm(false)}
        size="md"
        centered
      >
        <VehicleDisclosureModal
          vehicleInfo={vehicleInfo}
          documentSignInfo={documentSignInfo}
          handleSubmitSuccess={() => handleDisclosureAccepted()}
          onClose={() => {
            setShowVehicleDisclosureConfirm(false)
          }}
        />
      </StyleDisclosureModal>
      {auctionInfo?.CanBid && timeOut > 0 && vehicleInfo.StatusID === VehicleStatuses.OnSale && (
        <>
          {showRemoveBid &&
            auctionInfo.HasPreviousBid &&
            !auctionInfo.IsHighestBidder &&
            getSystemSettings('ADD_OR_REMOVE_BID_BUTTON_ON_BIDS_SCREEN') === '1' && (
              <StyledAuctionButton variant="danger" onClick={() => setModalShow(true)}>
                <span>{getLocalText('REMOVE_VEHICLE', 'Remove This Vehicle')}</span>
              </StyledAuctionButton>
            )}
          <StyledAuctionButton
            onClick={() => {
              if (!auctionInfo.HasPreviousBid && haveToAcceptDisclosure) {
                setBidButtonAction(VehicleAction.BID)
                setShowVehicleDisclosureConfirm(true)
              } else {
                setBiddingVehicleInstanceId(vehicleInfo.InstanceID)
              }
            }}
          >
            <span>
              {auctionInfo.HasPreviousBid ? getLocalText('BID_AGAIN', 'Bid Again') : getLocalText('BID', 'Bid')}
            </span>
          </StyledAuctionButton>

          <BuyItNowButtonContainer>
            {auctionInfo?.CanBuy && (
              <BuyItNowButtonGroup>
                {isShowBuyItNowPrice && (
                  <StyledAuctionText>
                    <span>Buy It Now Price:</span> {auctionInfo.BuyItNowPriceString}
                  </StyledAuctionText>
                )}

                {!auctionInfo.IsDirectSale && (
                  <StyledAuctionButton
                    onClick={() => {
                      if (haveToAcceptDisclosure) {
                        setBidButtonAction(VehicleAction.BUY)
                        setShowVehicleDisclosureConfirm(true)
                      } else {
                        window.open('/vehicles#buyItNow/' + vehicleInfo.InstanceID, '_self')
                      }
                    }}
                  >
                    <span>Buy It Now</span>
                  </StyledAuctionButton>
                )}
              </BuyItNowButtonGroup>
            )}
          </BuyItNowButtonContainer>

          {showErrorMsg && <TextDanger>{errorMessage}</TextDanger>}
        </>
      )}
    </>
  )
}
