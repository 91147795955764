import { StyledSlideOutHeading } from './SlideOutHeading.styled'

interface IProps {
  vehicleTitle: string
  vin: string
}
export const SlideOutHeading = ({ vehicleTitle, vin }: IProps) => {
  return (
    <StyledSlideOutHeading>
      <h1 className="title">{vehicleTitle}</h1>
      <h2 className="vin">{vin}</h2>
    </StyledSlideOutHeading>
  )
}
