import { useEffect } from 'react'
import { useSignalRStore } from 'store/useSignalRStore'
import { useVehicleStore } from 'store/useVehicleStore'

const useNotification = () => {
  const { connectionID, connect, subscribe, susbcribeEvents } = useSignalRStore((state) => state)
  const { getBaseVehicle } = useVehicleStore((state) => state)
  const vehicleIds = useVehicleStore((state) => state.vehicles.map((item) => item.vehicle.InstanceID))
  useEffect(() => {
    connect()
    susbcribeEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!connectionID) {
      return
    }

    subscribe(vehicleIds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionID])

  useEffect(() => {
    async function subscribeUpdateVehicle() {
      try {
        $.connection.notificationHub.off('updateVehicle').on('updateVehicle', getBaseVehicle)
      } catch (error) {
        console.error('SignalR: Error trying to subscribe to notification hub')
      }
    }

    if (connectionID) {
      subscribeUpdateVehicle()
    }

    return () => {
      $.connection.notificationHub.off('updateVehicle')
    }
    // We use dispatch so the callback is always a constant, so we don't need to watch for changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionID])
}

export default useNotification
