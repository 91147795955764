import styled from 'styled-components'

export const StyledVDPHistory = styled.div`
  font-size: 1.4rem;
  color: #494949;
  margin-top: 3rem;

  .item {
    margin-bottom: 0.4rem;

    > label {
      font-weight: 700;
      padding-right: 0.3rem;
    }
  }
`
