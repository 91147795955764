import { StyledActivityTitle } from 'modules/BuyerActivity.styled'
import { getBidVehicles } from 'apis/dashboardApis'
import { GetBidVehiclesRequest, IGetBidVehiclesResponse } from 'types/vehicleTypes'
import { useFetch } from 'hooks/useFetch'
import Collapse from 'components/Collapse'
import { useVehicles } from 'hooks/useVehicles'
import CaretDownFill from 'images/icon/CaretDownFill'
import CaretRightFill from 'images/icon/CaretRightFill'
import { DEFAULT_SMALL_PAGE_SIZE } from 'common/constants'
import NoActivity from 'modules/BuyerActivity/NoActivity'
import { VehicleAuction } from 'modules/BuyerActivity/VehicleAuction'
import { Loader } from 'components/Loader'
import { useDtmAnalytics } from 'hooks/useDtmAnalytics'
import { useEffect } from 'react'
import { DtmComponentName, setCustomClick } from 'utils/analyticsUtils'
import { VehicleContextProvider } from 'modules/DealerVehicleManagement/VehicleContext'
import { getCurrentBidsUrl } from 'utils/urlUtils'
import { Link } from 'components/Share/Link'

interface IProps {
  isExpand?: boolean
  setExpand?: any
}
export const CurrentBids = ({ isExpand, setExpand }: IProps) => {
  const { data: { Items: itemBids, TotalRecords: totalBids } = {} as IGetBidVehiclesResponse, loading: loadingBid } =
    useFetch(() => getBidVehicles(new GetBidVehiclesRequest(DEFAULT_SMALL_PAGE_SIZE)))
  const { endComponentLoad } = useDtmAnalytics()
  const { vehicles: vehicleBids } = useVehicles(itemBids)

  useEffect(() => {
    if (!loadingBid) {
      endComponentLoad(DtmComponentName.DashboardCurrentBids, itemBids)
    }
  }, [loadingBid, endComponentLoad, itemBids])

  const manageBidsUrl = getCurrentBidsUrl()

  if (loadingBid) {
    return <Loader />
  }

  return (
    <>
      <StyledActivityTitle onClick={setExpand}>
        {isExpand ? <CaretDownFill /> : <CaretRightFill />}
        <span>Your Current Bids: </span>
        <Link
          className="total"
          to={manageBidsUrl}
          onClick={(event) => {
            setCustomClick('Dashboard: BuyerActivity: CurrentBids', { usePageName: false })
            event.stopPropagation()
          }}
        >
          ({totalBids ?? 0})
        </Link>
      </StyledActivityTitle>
      <Collapse in={isExpand}>
        <div>
          {vehicleBids?.length > 0 ? (
            vehicleBids.map((item) => (
              <VehicleContextProvider vehicleData={item} key={item.vehicle.InstanceID}>
                <VehicleAuction vehicleShowOn="CurrentBids" vehicleData={item} key={item.vehicle.InstanceID} />
              </VehicleContextProvider>
            ))
          ) : (
            <NoActivity message="You currently have no bids." />
          )}
        </div>
      </Collapse>
    </>
  )
}
