import { smallScreen } from '../../common/theme'
import styled from 'styled-components'
import { Carousel } from 'react-bootstrap'
import classNames from 'classnames'
export const StyledVehicleDetailImage = styled.div.attrs(({ className }) => ({
  className: classNames('text-center text-sm-left pb-4 pb-sm-0', className)
}))`
  img {
    width: 100%;
    display: inline-block;
    border-radius: 0.3rem;
    max-width: 40rem;
    aspect-ratio: 4 / 3;
    object-fit: cover;
    cursor: pointer;

    @media (max-width: ${smallScreen}) {
      width: 100%;
    }
  }
  svg {
    width: 75%;
    height: 75%;
    display: block;
    margin: auto;
  }
  .carousel-item-broken-main-img {
    max-width: 25%;
  }

  @media print {
    .svfy_overlay-background {
      display: none !important;
    }
  }
`
export const StyledVehicleDetailCarousel = styled(Carousel)`
  svg {
    color: ${({ theme }) => theme.colors.primary};
    width: 100%;
  }
  width: 100%;
  .carousel-control-prev,
  .carousel-control-next {
    width: 8%;
    opacity: 1;
  }

  .carousel-control-prev:hover,
  .carousel-control-next:hover {
    opacity: 0.5;
  }
  .carousel-inner {
    margin: 0.5rem 10% 0 10%;
    width: auto;
  }

  img {
    cursor: pointer;
  }

  .carousel-item-img {
    max-width: 25%;
    max-height: 4rem;
    padding: 0;
  }
`
