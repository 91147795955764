export const AUTH_ERROR = 'Invalid User ID or Password'
export const AUTH_ERROR_2 = 'The login information is not correct. Please call the Level 1 support desk at 844-266-0437'
export const FORGOT_LABLE =
  'Please enter the User ID associated to your account and instructions for resetting your password will be sent to you.'
export const INVALID_USER = 'Invalid User'
export const RESET_PW =
  'An email was sent to your registered email account containing a link to reset your password. Once you reset your password, you can log in to the system.'
export const BMW_LOGIN_TITLE = 'WELCOME TO <br/> BMW GROUP DIRECT'
export const NISSAN_LOGIN_TITLE = 'WELCOME TO <br/> NISSAN'
export const FORGOT_USER_ID = 'To recover your User ID, please contact Customer Care at: 844-266-0437'
export const FORBIDDEN_PAGE = 'You are not allowed to access this page'
export const NO_COMMENT_FROM_API_MESSAGE = 'No Data from API'
