import { gridBreakPoints } from 'common/theme'
import styled from 'styled-components'

export const StyledUserMenu = styled.div<{ show: boolean }>`
  color: ${({ theme }) => theme.colors.fontColor};
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 0.8rem 0;

  .username {
    font-size: 1.6rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary};

    svg {
      display: inline-block;
    }
  }

  @media (max-width: ${gridBreakPoints.lg}) {
    display: ${({ show }) => (show ? 'flex' : 'none')};
    align-items: center;
  }
`

export const StyledDealerDropdown = styled.div`
  color: ${({ theme }) => theme.colors.fontColor};
  font-size: 1.4rem;
  cursor: pointer;

  .current-user {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }

  .current-user::after {
    display: none;
  }
  .dealership-select.dropdown-menu {
    box-shadow: 0 0.3rem 0.4rem rgba(0, 0, 0, 0.15);
    font-size: inherit;
    border: none;
    border-radius: 0.3rem;
    padding: 0;
    display: block;
    transition: all 167ms ease-in;
    transform: translate(0.0015625rem, 6.2rem);
    inset: 0 0 auto auto !important;

    &.show {
      opacity: 1;
    }

    hr {
      margin: 0;
    }

    .associated-dealerships {
      max-height: 50rem;
      overflow: auto;
    }
  }
  .dealership-item {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
    height: 2.5rem;
    display: flex;
    align-items: center;
    transition: all 167ms ease-in;
    position: relative;
    background-color: transparent;

    svg {
      visibility: hidden;
      margin-right: 1rem;
    }
    &:hover,
    &.active,
    &.show-icon {
      svg {
        visibility: visible;
      }
    }

    &.functionality {
      height: auto;
      margin: 0.625rem 0;
    }
  }
`

export const StyledCurrentDealership = styled.div`
  padding-left: 2rem;
  padding-right: 1.825rem;
  text-align: right;
  color: ${({ theme }) => theme.colors.fontColor};
`

export const RightActionContainer = styled.div<{ show: boolean }>`
  display: flex;

  @media (max-width: ${gridBreakPoints.lg}) {
    display: ${({ show }) => (show ? 'flex' : 'none')};
    flex-direction: column;

    & > div.nav {
      width: 100%;
      display: flex;
      flex-direction: column;

      .nav-item {
        width: 100%;
        border-top: 0.1rem solid ${({ theme }) => theme.colors.secondary};
        border-bottom: 0.1rem solid ${({ theme }) => theme.colors.secondary};

        &:hover > .nav-link {
          background-color: white;
        }

        > .nav-link {
          font-size: 1.8rem;
          height: 4rem;
          margin: unset;
          text-align: center;
          &:hover {
            color: ${({ theme }) => theme.colors.fontColor};
          }
          &.active {
            background: #d4d4d4;
            :after {
              content: none;
            }
          }
        }
      }
    }
  }

  & > div.nav {
    .nav-link {
      justify-content: center;
      font-size: 1.6rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.primary};
      padding: 0.8rem 0;
      margin-right: 1.5rem;
    }
  }
`
