import { useMemo, useReducer } from 'react'

interface IExpandAction {
  type: string
  value?: boolean
  index?: number
  totalGroup?: number
  defaultGroupOpen?: boolean[]
}

interface IExpandState {
  componentOpen: boolean //parent component
  groupOpen: boolean[] //small components in parent component
}

const reducer = (
  { componentOpen, groupOpen }: IExpandState,
  { type, value, index, totalGroup, defaultGroupOpen }: IExpandAction
) => {
  switch (type) {
    case 'INIT_GROUP':
      const groups = new Array<boolean>(totalGroup).fill(value)
      return { componentOpen, groupOpen: groups }
    case 'INIT_GROUP_WITH_DEFAULT_ARRAY':
      componentOpen = defaultGroupOpen.some((expanded) => expanded)
      return { componentOpen, groupOpen: defaultGroupOpen }
    case 'AFFECT_ALL':
      const newGroups = groupOpen?.fill(value)
      return { componentOpen: value, groupOpen: newGroups }
    case 'AFFECT_ONE':
      const i = index ?? 0
      groupOpen[i] = value
      if (value) {
        componentOpen = value
      } else if (groupOpen.every((o) => !o)) {
        componentOpen = false
      }
      return { componentOpen, groupOpen }
    default:
      return { componentOpen, groupOpen }
  }
}

export const useExpand = (initState: IExpandState) => {
  const [state, dispatch] = useReducer(reducer, initState)
  const expandAction = useMemo(
    () => ({
      initGroup: ({ totalGroup, initValue }) => {
        dispatch({ type: 'INIT_GROUP', value: initValue, totalGroup })
      },
      initGroupWithDefaultArray: (defaultGroupOpen: boolean[]) => {
        dispatch({ type: 'INIT_GROUP_WITH_DEFAULT_ARRAY', defaultGroupOpen })
      },
      setExpand: (value, index?: number) => {
        index >= 0 ? dispatch({ type: 'AFFECT_ONE', value, index }) : dispatch({ type: 'AFFECT_ALL', value })
      }
    }),
    [dispatch]
  )

  return {
    state,
    action: expandAction
  }
}
