import { largeScreen } from 'common/theme'
import { StyledHomeSectionHeaderTitle } from 'components/Header/HomeSectionHeader.styled'
import styled from 'styled-components'

export const StyledDashboardPlaceholder = styled.div`
  @media (min-width: ${largeScreen}) {
    ${StyledHomeSectionHeaderTitle} {
      justify-content: left;
      padding-left: calc(12.5vw - 80px);
    }
  }
`
export const StyledDashboardSlide = styled.div`
  margin: 1rem auto 3rem;
`
