import styled from 'styled-components'
import { zIndex } from 'common/constants'
import { StyledSubMenu } from './SubMenu.styled'
import { gridBreakPoints } from 'common/theme'

export const StyledMainNav = styled.div<{ show?: boolean }>`
  display: flex;
  justify-content: center;
  font-size: 1.6rem;

  z-index: ${zIndex.menu};

  .nav-item:hover ${StyledSubMenu} {
    opacity: 1;
    transform: translateY(0);
    pointer-events: initial;
  }

  .nav-link {
    margin: 0 1.5rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.headerFontColor};
    @media (max-width: ${gridBreakPoints.lg}) {
      color: ${({ theme }) => theme.colors.primary};
    }
    .active {
      color: ${({ theme }) => theme.colors.primary};
      @media (max-width: ${gridBreakPoints.lg}) {
        color: ${({ theme }) => theme.colors.primary};
      }
    }

    &:hover {
      color: ${({ theme }) => theme.colors.primary};

      @media (max-width: ${gridBreakPoints.lg}) {
        color: ${({ theme }) => theme.colors.primary}!important;
      }
    }
  }

  @media (max-width: ${gridBreakPoints.lg}) {
    background-color: ${({ theme }) => theme.colors.mobileMenuBackground};
    position: fixed;
    transform: ${({ show }) => (!!show ? 'translateX(0)' : 'translateX(100%)')};
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${zIndex.navbarMobile};
    min-width: 30rem;
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.15);
    transition: all 167ms ease-in;
    overflow-y: auto;
    ul {
      width: 100%;
      display: flex;
      flex-direction: column;

      .nav-item {
        width: 100%;
        border-top: 0.1rem solid ${({ theme }) => theme.colors.secondary};
        border-bottom: 0.1rem solid ${({ theme }) => theme.colors.secondary};

        &:hover > .nav-link {
          background-color: white;
        }

        > .nav-link {
          font-size: 1.8rem;
          height: 4rem;
          margin: unset;
          text-align: center;
          &:hover {
            color: ${({ theme }) => theme.colors.fontColor};
          }
          &.active {
            background: #d4d4d4;
            :after {
              content: none;
            }
          }
        }
      }
    }
  }
`

export const StyledUnderlineSpan = styled.span`
  position: relative;

  &.active,
  &:hover {
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      bottom: -0.5rem;
      width: 80%;
      height: 0.4rem;
      background: ${({ theme }) => theme.colors.primary};
      border-radius: 2.5rem;

      transform: translateX(-50%);
    }
  }

  @media (max-width: ${gridBreakPoints.lg}) {
    &.active,
    &:hover {
      &:after {
        background: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`
export const StyledNavBar = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`
export const StyledCloseMenuButton = styled.a`
  display: none;
  @media (max-width: ${gridBreakPoints.lg}) {
    display: flex;
    font-size: 2.4rem;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    right: 0;
    width: 3rem;
    height: 3rem;
    align-items: center;
    justify-content: center;
    margin: 0.3rem;
    cursor: pointer;
    font-weight: 600;
    transition: all 167ms ease-in;

    &:hover {
      color: ${({ theme }) => theme.colors.fontColor};
    }
  }
`
export const StyledSubNav = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;

  ul {
    display: none;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    gap: 1.2rem;
    color: rgba(0, 0, 0, 0.3);
    .nav-link {
      font-size: 1.4rem;
      color: ${({ theme }) => theme.colors.fontColor};
      font-weight: 600;
      color: ${({ theme }) => theme.colors.linkColor};
    }

    &.active {
      display: flex;
    }
  }

  @media (max-width: ${gridBreakPoints.lg}) {
    display: none;
  }
`
