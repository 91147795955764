import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { PropsWithChildren, useEffect, useState } from 'react'

import {
  StyledAccordion,
  StyledAccordionContent,
  StyledAccordionTitle,
  StyledCustomAccordion
} from './CustomAccordion.styled'
import Collapse from 'components/Collapse'

interface IProps extends PropsWithChildren<any> {
  defaultOpen?: boolean
  open?: boolean
  sectionTitle: React.ReactNode
}

export const CustomAccordion = ({ defaultOpen, open, sectionTitle, className, children }: IProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen ?? false)
  useEffect(() => {
    setIsOpen(defaultOpen)
  }, [defaultOpen])

  useEffect(() => {
    setIsOpen((s) => open ?? s)
  }, [open])

  return (
    <StyledCustomAccordion>
      <StyledAccordion onClick={() => setIsOpen((open) => !open)} className={classNames(className)}>
        <StyledAccordionTitle className="accordion-title">
          <span>{sectionTitle}</span>
          <span className="float-right">
            <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronRight} />
          </span>
        </StyledAccordionTitle>
      </StyledAccordion>
      <Collapse in={isOpen} unmountOnExit>
        <StyledAccordionContent className="accordion-content">{children}</StyledAccordionContent>
      </Collapse>
    </StyledCustomAccordion>
  )
}
