import { VehicleDetailsView } from 'common/constants'
import { VehicleContext } from 'modules/DealerVehicleManagement/VehicleContext'
import { useContext, useMemo } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { useGlobalStore } from 'store/useGlobalStore'
import { IVehicleInfo } from 'types/vehicleTypes'
import shallow from 'zustand/shallow'

import {
  StyledAddWatchListSpan,
  StyledRemovedWatchListPlaceHolder,
  StyledUnwatchPlaceholderAlert
} from './UnwatchPlaceholder.styled'

interface IProps {
  vehicleInfo: IVehicleInfo
  viewStyle?: VehicleDetailsView
}
export const UnwatchPlaceholder = ({ vehicleInfo, viewStyle = VehicleDetailsView.List }: IProps) => {
  const isListView = useMemo(() => viewStyle === VehicleDetailsView.List, [viewStyle])
  const getLocalText = useGlobalStore((state) => state.getLocalText, shallow)
  const {
    vehicleWatchListState: { isSaving, saveWatchList }
  } = useContext(VehicleContext)

  return (
    <StyledRemovedWatchListPlaceHolder>
      <Row>
        <Col md={isListView ? { span: 4, offset: 4 } : null}>
          <StyledUnwatchPlaceholderAlert>
            <span>{vehicleInfo.Title}</span>
            <span> {getLocalText('REMOVED_FROM_WATCHLIST', 'removed from Watch List!')}</span>
          </StyledUnwatchPlaceholderAlert>
        </Col>
      </Row>
      <Row>
        <Col md={isListView ? { span: 4, offset: 4 } : null} className="d-flex justify-content-end">
          {isSaving ? (
            <Spinner animation="border" role="status" />
          ) : (
            <StyledAddWatchListSpan onClick={saveWatchList}>
              {getLocalText('ADD_TO_WATCHLIST', 'Add to watchlist')}
            </StyledAddWatchListSpan>
          )}
        </Col>
      </Row>
    </StyledRemovedWatchListPlaceHolder>
  )
}
