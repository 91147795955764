import shallow from 'zustand/shallow'
import { useEffect, useMemo } from 'react'
import { selectVehiclesByIds } from 'store/selectors'
import { useSignalRStore } from 'store/useSignalRStore'
import { useVehicleStore } from 'store/useVehicleStore'
import { IVehicleData } from 'types/vehicleTypes'

export const useVehicles = (originalData: IVehicleData[]) => {
  const subscribe = useSignalRStore((state) => state.subscribe)
  const [vehiclesFromStore, addVehicles] = useVehicleStore((state) => [state.vehicles, state.addVehicles], shallow)

  const returnedVehicles = useMemo(
    () =>
      selectVehiclesByIds(
        originalData?.map((item) => item.vehicle.InstanceID),
        vehiclesFromStore
      ),
    [originalData, vehiclesFromStore]
  )
  useEffect(() => {
    if (!originalData?.length) {
      return
    }

    addVehicles(originalData)
  }, [addVehicles, originalData])

  useEffect(() => {
    if (!originalData?.length) {
      return
    }

    const instanceIds = originalData.map((item) => item.vehicle.InstanceID)
    subscribe(instanceIds)
  }, [originalData, subscribe])

  return { vehicles: returnedVehicles }
}
