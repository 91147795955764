import { Popover } from 'react-bootstrap'
import styled from 'styled-components'

export const StyledPopOverPagination = styled(Popover)`
  label {
    font-weight: 600;
    margin-right: 1rem;
  }

  width: 300px;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;

  input {
    width: 8rem;
    margin-right: 1rem;
  }

  button {
    padding: 0 1rem;
  }
`

export const StyledPagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1rem;
  }
  .pagination > div > li > a,
  .pagination > li > a,
  .pagination > li > span {
    border: none;
    border-radius: 0.3rem;
  }

  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: ${({ theme }) => theme.colors.primary};
    background-color: transparent;
  }
  .page-link:hover {
    z-index: 2;
    color: ${({ theme }) => theme.colors.fontColor};
    text-decoration: none;
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  .page-item.active .page-link {
    z-index: 1;
    color: ${({ theme }) => theme.colors.inputBackgroundColor};
    background-color: ${({ theme }) => theme.colors.primary};
  }

  .record-per-page {
    display: flex;
    align-items: center;
    gap: 1rem;

    color: ${({ theme }) => theme.colors.headerFontColor};
  }
`
