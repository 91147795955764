import { largeScreen, smallScreen } from 'common/theme'
import { VehicleLoader } from 'modules/VehicleList/VehicleLoader'
import { Col, Tooltip, Row, Button } from 'react-bootstrap'
import styled from 'styled-components'

export const StyledTitleSection = styled(Row)``

export const StyledVehicleDetailsCard = styled(VehicleLoader)`
  background-color: #fff;
  flex: 1;
  border: 0.1rem solid #ddd;
  padding: 1rem 1rem 1rem 1rem;
  margin-top: 0;
  margin-bottom: 1.6rem;
  min-height: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > .row {
    column-gap: 1.5rem;
  }

  .vehicle-detail-image-section {
    position: relative;

    .custom-control.custom-checkbox {
      position: absolute;
      margin: 0.5rem;
    }
    .nav-link {
      padding: 0;
    }

    .stockwave-light-bulb-container {
      margin-left: 4rem;
    }
  }
  .fyu {
    background: #fff !important;
  }

  .carfax-advanced-section {
    padding-bottom: 10px;
    margin-top: 0.8rem;
    @media (max-width: ${smallScreen}) {
      padding-left: inherit;
    }
  }

  ${StyledTitleSection} {
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    margin: 0;

    a {
      padding: 0;
      font-size: 2.4rem;
      color: ${({ theme }) => theme.colors.primary};

      margin-right: 0.8rem;
      font-weight: 600;

      &:active,
      &:hover {
        color: ${({ theme }) => theme.colors.linkColorLight};
      }
    }

    div[class^='col'] {
      padding-left: 0;
      padding-right: 0;
    }
    @media screen and (max-width: mediumScreen) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .link-col {
    color: ${(props) => props.theme.colors.primary};

    display: flex;
    align-items: center;
    gap: 1.2rem;

    a {
      text-decoration: none;
      font-size: 1.5rem;

      &:hover {
        cursor: pointer;
        opacity: 0.5;
      }
    }

    .icon {
      margin-right: 0.4rem;
    }
  }

  .vehicle-info {
    margin-bottom: 1rem;
    gap: 0.8rem 0;
    color: ${({ theme }) => theme.colors.headerFontColor};

    a {
      text-decoration: underline;
    }

    @media screen and (min-width: mediumScreen) {
      .vehicle-properties {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .true-frame-link {
    max-width: 8rem;

    &:empty {
      display: none;
    }
  }

  .carfax-link {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.linkColorLight};
    text-transform: uppercase;

    .icon {
      margin-right: 0;
    }
  }
  .vehicle-select {
    z-index: 3;
  }
`

export const StyledCarfaxCol = styled(Col)`
  display: flex;

  @media (max-width: ${largeScreen}) {
    width: 100%;
  }
`

export const StyledRibbonContainer = styled.div`
  & span:not(:last-child) .ribbon {
    margin-right: 1.5rem;
  }

  & span .ribbon {
    margin-left: 0;
  }

  .ribbon-title {
    filter: brightness(0.75);
  }
`

export const StyledInfoLabel = styled.span`
  font-weight: 700;

  margin-right: 0.375rem;
`
export const StyledToolTip = styled(Tooltip)`
  .arrow {
    display: none;
  }
  .tooltip-inner {
    background-color: #fff;
    color: black;
    border: 0.1rem solid #ddd;
    border-radius: 0.5rem;
    max-width: 30rem;
  }
`

export const VehicleVin = styled.div`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.headerFontColor};
  margin-bottom: 1rem;
`

export const PaperClipButton = styled(Button)`
  margin-left: 1rem;

  .fa-paperclip {
    color: ${({ theme }) => theme.colors.primary};
  }
`
export const TitleVDP = styled.span`
  padding: 0;
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.headerFontColor};

  margin-right: 0.8rem;
  font-weight: 600;
`
