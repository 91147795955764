import styled from 'styled-components'
import { Button, Container } from 'react-bootstrap'

import { gridBreakPoints } from 'common/theme'
export const StyledHomeSectionHeaderTitle = styled.div<{ clickable: boolean }>`
  padding: 1.6rem 0;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  font-size: 1.8rem;
  text-align: center;

  height: 100%;

  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.backgroundText};
  border-radius: 0.3rem;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.25);
  :hover {
    ${(props) => props.clickable && 'cursor: pointer'}
  }
  .icon {
    margin-right: 0.5rem;
  }
`
StyledHomeSectionHeaderTitle.displayName = 'StyledHomeSectionHeaderTitle'

export const StyledHomeSectionHeaderActionSection = styled.div`
  padding-right: 1.75rem;

  /* v For the divider between section v */
  &:not(:first-child) {
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 15%;
      width: 0.2rem;
      height: 70%;
      background: #d4d4d4;
      border-radius: 2.5rem;

      padding: 1rem 0;
    }

    & > .btn-link:first-child {
      margin-left: 2rem;
    }
  }

  &:not(:last-child) {
    & > .btn-link:last-child {
      margin-right: 2rem;
    }
  }
  /* ^ For the divider between section ^ */
`
StyledHomeSectionHeaderActionSection.displayName = 'StyledHomeSectionHeaderActionSection'

export const StyledHomeSectionHeaderBanner = styled.div`
  &,
  & > div {
    height: 100%;
  }

  display: flex;
  align-items: center;

  /* Styles for mobile view */
  @media (max-width: ${gridBreakPoints.md}) {
    margin: 0.25rem 0;
    padding: 0.75rem 0.75rem;
  }

  @media (max-width: ${gridBreakPoints.sm}) {
    flex-direction: column;
    gap: 1rem;

    ${StyledHomeSectionHeaderActionSection} {
      &:before {
        visibility: hidden;
      }

      &:not(:first-child) {
        & > .btn-link:first-child {
          margin-left: 0;
        }
      }

      &:not(:last-child) {
        & > .btn-link:last-child {
          margin-right: 0;
        }
      }
    }
  }
`
StyledHomeSectionHeaderBanner.displayName = 'StyledHomeSectionHeaderBanner'

export const StyledHomeSectionHeaderLink = styled(Button).attrs(({ className, variant, href }) => ({
  className: `d-flex align-items-center ${className ?? ''}`.trim(),
  variant: variant ?? 'link',
  href: href ?? null
}))`
  font-size: 1.6rem;
  font-weight: 350;
  color: ${({ theme }) => theme.colors.headerFontColor};

  &:hover,
  &:active {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    box-shadow: none;
    text-decoration: none;
  }

  &.active {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: -0.1rem;
      width: 100%;
      height: 0.5rem;
      background: ${({ theme }) => theme.colors.primary};
      border-radius: 2.5rem;
    }
  }
`

export const StyledHomeSectionHeader = styled(Container).attrs({
  fluid: true
})`
  border-radius: 0.3rem;

  box-shadow: 0 -0.025rem 0.2rem rgba(0, 0, 0, 0.15), 0 0.3rem 0.2rem rgba(0, 0, 0, 0.125);
  /* v Margin to create some space for the box-shadow v */
  margin-top: 0.1rem;
  margin-bottom: 0.3rem;
  /* ^ Margin to create some space for the box-shadow ^ */
  .header-col {
    height: 100%;
  }

  @media (min-width: ${gridBreakPoints.xl}) {
    .header-toggle {
      min-width: 20%;
    }

    ${StyledHomeSectionHeaderTitle} {
      padding-left: 1.4rem;
    }
  }

  @media print {
    -webkit-print-color-adjust: exact;

    a {
      text-decoration: none;
    }
  }
`

export const StyledHomeSectionBody = styled.div`
  padding: 2rem 5rem;
  background-color: ${({ theme }) => theme.colors.sectionContentBackgroundColor};
  min-height: 15rem;
  position: relative;
`
export const StyledRowInventory = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 5rem;
  row-gap: 2rem;
  @media (max-width: ${gridBreakPoints.md}) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: ${gridBreakPoints.sm}) {
    grid-template-columns: 1fr;
  }
`

export const StyledBuyerActivitySection = styled(StyledHomeSectionBody)`
  background-color: #fff;
`

export const StyledHomeSection = styled.div`
  overflow: hidden;
`

StyledHomeSection.displayName = 'StyledHomeSection'
