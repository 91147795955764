import { useTheme } from 'styled-components'

export const SaveSearchIcon = () => {
  const theme = useTheme()
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 390 390"
      enableBackground="new 0 0 390 390"
      xmlSpace="preserve"
      width="1em"
      height="1em"
      fill={theme.colors.primary}
    >
      <g id="XMLID_8_">
        <path
          id="XMLID_951_"
          d="M365,44.666H150v-5c0-13.807-11.193-25-25-25H25c-13.807,0-25,11.193-25,25v250c0,13.807,11.193,25,25,25
		h125l0.001-30H30v-240h90v30h240v210H240v30h125c13.807,0,25-11.193,25-25v-220C390,55.859,378.807,44.666,365,44.666z"
        />
        <path
          id="XMLID_952_"
          d="M210,263.235c36.964-7.044,65-39.585,65-78.569c-0.001-44.111-35.889-80-80-80.001
		c-21.369,0-41.458,8.321-56.569,23.433c-15.11,15.11-23.432,35.2-23.431,56.567c0,38.984,28.036,71.526,65.001,78.57L180,360.334
		c0,8.283,6.716,15,15,15c8.283,0,15-6.717,15-15V263.235z M145,184.665c-0.001-13.354,5.201-25.91,14.645-35.354
		c9.444-9.444,22.001-14.646,35.355-14.646c27.57,0.001,49.999,22.432,50.001,50.001c0,27.57-22.43,50-50,50
		S145,212.235,145,184.665z"
        />
      </g>
    </svg>
  )
}
