import { gridBreakPoints } from 'common/theme'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const RightContainer = styled(Col)`
  .main-img {
    max-height: 100vh;
    object-fit: cover;
    object-position: center;
  }
  .img-corner-right {
    position: absolute;
    border: 0;
    top: 0;
    right: 0;
    height: 6.5%;
    margin-right: 4%;
    margin-top: 4%;
  }

  .img-corner-left {
    position: absolute;
    border: 0;
    top: 0;
    left: 0;
    height: 9%;
    margin-left: 4%;
    margin-top: 4%;
  }
`
export const LayoutContainer = styled(Row)`
  font-family: ${(props) => props.theme.fonts.fontBase};
  font-size: 1.4rem;
  margin-right: 0;
  margin-left: 0;
  input {
    font-size: 1.4rem;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 3rem white inset;
    box-shadow: 0 0 0 3rem white inset;
    background: #fff;
  }
`
export const StyledFooter = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.fontColor};
  bottom: 0;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  padding-bottom: 2.5rem;
  padding-left: 3rem;
  padding-right: 0.5rem;
`

export const LinkBaseStyled = styled(Link)`
  text-align: center;
  display: block;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.linkColor};
  font-weight: 500;
  padding-bottom: 0.5rem;
`

export const BackToLoginLink = styled(LinkBaseStyled)``

export const ClickHereLink = styled(Link)`
  color: ${({ theme }) => theme.colors.linkColor};
`

export const StyledLoginFormFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
  align-items: center;
`

export const LoginTitle = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  font-size: 4.2rem;
  margin-bottom: 1.2rem;
  width: inherit;
`

export const ForgotLink = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 2rem;
  a {
    color: ${({ theme }) => theme.colors.linkColor};
  }
`

export const RightFooterContainer = styled(Col)`
  display: flex;
  justify-content: end;
  margin-right: 2rem;
`
export const LeftFooterContainer = styled(Col)`
  display: flex;
  align-items: center;
  span {
    font-size: 1rem;
  }
`

export const StyledLeftContainer = styled(Row)`
  justify-content: center;
`

export const FormLoginBody = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.fontColor};
  align-self: start;

  .btn-primary:disabled {
    background-color: ${({ theme }) => theme.colors.colorButtonDisabled};
    border-color: ${({ theme }) => theme.colors.colorButtonDisabled};
  }

  .text-input-password {
    margin-bottom: 0.1rem !important;
  }
`

export const LoginContainerColStyled = styled(Col)`
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  @media (max-width: ${gridBreakPoints.sm}) {
    min-height: 80vh;
  }
`
