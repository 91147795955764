import { EyeOff } from 'icons/EyeOff'
import { EyeSlash } from 'icons/EyeSlash'
import { useState } from 'react'
import { Form, FormControlProps, InputGroup } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { StyleInputEye, StyledFormControl } from './FormInput.styled'

interface IFormInput extends FormControlProps {
  name: string
  type: string
  placeholder?: string
  className?: string
  error?: any
  children?: any
  label?: string
}

export const FormInput = ({ name, type, placeholder, className, error, children, label, as, ...props }: IFormInput) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const isLoginPage = () => {
    return name === 'username' || name === 'password'
  }

  return (
    <>
      {type === 'password' ? (
        <Form.Group className={`mb-3 ${className}`} controlId={name}>
          <Form.Label>
            <FormattedMessage id={label} />
          </Form.Label>
          <InputGroup hasValidation>
            <StyledFormControl
              type={showPassword ? 'text' : 'password'}
              placeholder={placeholder}
              name={name}
              {...props}
            />
            {props?.value && (
              <StyleInputEye
                onClick={() => {
                  setShowPassword(!showPassword)
                }}
              >
                {showPassword ? (
                  <EyeOff height="2rem" width="2rem" color="#878787" />
                ) : (
                  <EyeSlash height="2rem" width="2rem" color="#878787" />
                )}
              </StyleInputEye>
            )}
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      ) : (
        <Form.Group className={`mb-3 ${className}`} as={as} controlId={name}>
          <Form.Label>{label}</Form.Label>
          {isLoginPage() ? (
            <StyledFormControl type={type} placeholder={placeholder} name={name} {...props} />
          ) : (
            <Form.Control type={type} placeholder={placeholder} name={name} {...props} />
          )}
          {children}
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>
      )}
    </>
  )
}
