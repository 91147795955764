import { IVehicleData } from 'types/vehicleTypes'
interface IProps {
  vehicleData: IVehicleData
}

export const StockwaveData = ({ vehicleData }: IProps) => {
  const stockwaveData = {
    Vin: vehicleData?.vehicle?.VIN,
    Year: vehicleData?.vehicle?.ModelYear,
    Make: vehicleData?.vehicle?.Make,
    Model: vehicleData?.vehicle?.Model,
    Subseries: vehicleData?.vehicle?.Derivative,
    Tranmission: vehicleData?.vehicle?.Transmission,
    Seller: vehicleData?.vehicle?.SaleChannelName,
    Mileage: vehicleData?.vehicle?.Mileage,
    Description: vehicleData?.vehicle?.Title,
    Trim: vehicleData?.vehicle?.Derivative,
    Color: vehicleData?.vehicle?.ExteriorColour,
    Interior_Color: vehicleData?.vehicle?.InteriorTrim,
    Facilitation_Location: vehicleData?.vehicle?.VehicleLocationCity,
    BodyStyle: vehicleData?.vehicle?.BodyStyle,
    Odometer: vehicleData?.vehicle?.Mileage,
    Source: vehicleData?.vehicle?.SourceCode,
    CRURL: `https://${window.location.hostname}/vehicle/${vehicleData?.vehicle?.InstanceID}`,
    VDPURL: `https://${window.location.hostname}/vehicle/${vehicleData?.vehicle?.InstanceID}`,
    Displacement: vehicleData?.vehicle?.EngineSize,
    CRSCORE: vehicleData?.vehicle?.Condition,
    PickupZip: vehicleData?.vehicle?.LocationPostCode,
    PickupAddress: `${vehicleData?.vehicle?.LocationAddressDetails}, ${vehicleData?.vehicle?.LocationPostCode}`,
    Engine: vehicleData?.vehicle.EngineDescription
  }

  return (
    <pre className="stockwave-vehicle-info" style={{ display: 'none' }}>
      {JSON.stringify(stockwaveData)}
    </pre>
  )
}
