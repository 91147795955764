import DatePicker from 'react-datepicker'
import StyledDateTimePicker from './DateTimePicker.styled'
import 'react-datepicker/dist/react-datepicker.css'
import { ComponentProps } from 'react'

export const DateTimePicker = ({ ...props }: ComponentProps<typeof DatePicker>) => {
  return (
    <StyledDateTimePicker>
      <DatePicker className="form-control" {...props} />
    </StyledDateTimePicker>
  )
}
export default DateTimePicker
