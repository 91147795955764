import { getBaseVehicle } from 'apis/vehicleApis'
import { IAuctionInfo } from 'types/auctionTypes'
import { GetBaseVehicleRequest, IVehicleData } from 'types/vehicleTypes'
import { mergeItems, updateItem } from 'utils/collectionUtils'
import create from 'zustand'
import { IDocumentSignInfor } from './../types/vehicleTypes'

export interface IVehicleState {
  vehicles: IVehicleData[]
  addVehicles: (items: IVehicleData[]) => void
  getBaseVehicle: (item: IVehicleData) => void
  updateVehicleAuction: (item: IAuctionUpdating) => void
  updateVehicleData: (item: IVehicleData) => void
  acceptVehicleDocumentSignInfo: (item: IDocumentSignInfoUpdating) => void
}

export interface IAuctionUpdating {
  vehicleInstanceID: Number
  auctionInfo: IAuctionInfo
}

export interface IDocumentSignInfoUpdating {
  vehicleInstanceID: number
  documentSignInfo: IDocumentSignInfor
}

export const useVehicleStore = create<IVehicleState>((set, get) => ({
  vehicles: [],
  addVehicles: (items: IVehicleData[]) =>
    set((state) => ({ ...state, vehicles: mergeItems(state.vehicles, items, (item) => item.vehicle.InstanceID) })),
  getBaseVehicle: async (item: IVehicleData) => {
    try {
      const payload = new GetBaseVehicleRequest([item])
      payload.IncludeAuctionData = true
      payload.IncludeBaseData = false
      payload.IncludeInspectionReport = false
      payload.VehicleBaseData = [item.vehicle]
      const {
        Items: [baseVehicle]
      } = await getBaseVehicle(payload)

      if (baseVehicle) {
        const updatedItem = {
          ...get().vehicles.find((v) => v.vehicle.InstanceID === item.vehicle.InstanceID),
          auctionInfo: baseVehicle.auctionInfo
        }
        set((state) => ({
          ...state,
          vehicles: updateItem(updatedItem, state.vehicles, (item) => item.vehicle.InstanceID)
        }))
      }
    } catch (error) {
      console.error('Error fetching base vehicle')
    }
  },
  updateVehicleAuction: (item: IAuctionUpdating) =>
    set((state) => {
      const { vehicleInstanceID, auctionInfo } = item
      const updatedVehicle = {
        ...state.vehicles.find((item) => item.vehicle.InstanceID === vehicleInstanceID),
        auctionInfo: auctionInfo
      }
      return { ...state, vehicles: updateItem(updatedVehicle, state.vehicles, (item) => item.vehicle.InstanceID) }
    }),
  updateVehicleData: (vehicleData: IVehicleData) =>
    set((state: IVehicleState) => {
      return { ...state, vehicles: updateItem(vehicleData, state.vehicles, (item) => item.vehicle.InstanceID) }
    }),
  acceptVehicleDocumentSignInfo: (item: IDocumentSignInfoUpdating) =>
    set((state) => {
      const { vehicleInstanceID, documentSignInfo } = item
      const updatedVehicle = {
        ...state.vehicles.find((item) => item.vehicle.InstanceID === vehicleInstanceID),
        documentSignInfo: { ...documentSignInfo, IsAccepted: true }
      }
      return { ...state, vehicles: updateItem(updatedVehicle, state.vehicles, (item) => item.vehicle.InstanceID) }
    })
}))
