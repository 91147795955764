import { ThemedSvg } from './ThemedSvg.styled'
import { IconProps } from 'core/typing/IconType'

export const ExteriorColor = ({ width = '16px', height = '16px', color = 'currentColor' }: IconProps) => {
  return (
    <ThemedSvg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 130 182">
      <g transform="translate(-31.043009,-31.162018)">
        <path d="M 94.0008,31.5764 C 88.5265,33.0797 85.4891,38.6686 82.4244,43 75.1265,53.3141 68.683,64.2365 62.0887,75 49.2857,95.8976 32.2277,121.676 31.1042,147 29.1306,191.482 75.268,222.067 116,210.572 c 9.036,-2.55 17.273,-7.035 24,-13.587 5.003,-4.872 10.218,-9.827 13.651,-15.985 C 173.658,145.113 151.718,108.197 133,77 125.666,64.7764 117.906,52.669 109.72,41 105.942,35.6142 101.553,29.5025 94.0008,31.5764 M 97,53 c 11.351,13.3182 20.373,29.656 28.691,45 7.389,13.629 17.441,29.234 19.135,45 5.437,50.597 -63.7764,73.942 -90.0621,30 -4.2363,-7.082 -6.2088,-15.831 -6.6705,-24 C 47.1152,131.693 60.6045,110.401 69.0116,96 77.2681,81.8568 85.2495,64.5567 97,53 m -28.9846,83.616 c -9.6333,2.278 -6.6424,15.88 -4.0887,22.384 5.1041,13 23.4845,29.51 39.0293,23.397 5.084,-2 6.447,-9.117 2.61,-12.963 -6.3314,-6.35 -16.6375,-3.919 -23.0081,-12.438 -4.5308,-6.058 -2.9998,-23.11 -14.5425,-20.38 z" />
      </g>
    </ThemedSvg>
  )
}
