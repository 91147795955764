import { ReactNode } from 'react'
import { StyledTag } from './Tag.styled'

export interface IReactTagProps {
  title: ReactNode
  className?: string
  onClick?: () => void
}

export const Tag = ({ title, onClick, className }: IReactTagProps) => {
  return (
    <StyledTag onClick={onClick} className={className}>
      {title}
    </StyledTag>
  )
}
