import { DateTime } from 'luxon'

export function formatDate(date: string, format: string) {
  return DateTime.fromISO(date).toFormat(format)
}

export const secondsDiff = (serverTime: Date, clientTime: Date, saleEndDate: Date) => {
  const adjustTime = serverTime.getTime() + (new Date().getTime() - clientTime.getTime())
  return Math.round((saleEndDate.getTime() - adjustTime) / 1000)
}

export const formatDateString = (date: Date) => {
  var dd = date.getDate()
  var mm = date.getMonth() + 1
  var yyyy = date.getFullYear()
  return mm + '/' + dd + '/' + yyyy
}
