import { faInbox } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StyledNoData } from './NoData.styled'

interface IProps {
  text?: string
  showInChart?: boolean
}

const DEFAULT_NO_DATA_LABEL = 'No Data'

export const NoData = ({ text = DEFAULT_NO_DATA_LABEL, ...props }: IProps) => {
  return (
    <StyledNoData {...props}>
      <FontAwesomeIcon icon={faInbox} />
      {text}
    </StyledNoData>
  )
}
