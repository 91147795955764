import { StyledDataTable } from 'components/DataTableHistory'
import { AttachFilePopoverStyled } from 'modules/PopoverAttachment.styled'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'

export const PurchaseHistoryListSectionStyled = styled(Container)`
  .link-history {
    color: ${({ theme }) => theme.colors.linkColorLight};
    &:hover {
      text-decoration: underline;
    }
  }

  ${StyledDataTable} {
    tbody tr td {
      min-height: 5rem;
    }
  }

  .documents {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 5rem;
  }

  .charges-link {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .price-str {
    font-size: 1.5rem !important;
    color: black !important;
    font-weight: 600 !important;
  }
`

export const AdditionalChargesPopover = styled(AttachFilePopoverStyled)`
  font-size: 1.6rem;
  .popover-header {
    padding-left: 1.9rem;
  }
  .charges-content {
    display: flex;
    justify-content: space-between;
  }
  .description {
    padding-left: 0.9rem;
    font-weight: 600;
  }

  .price-string {
    padding-right: 0.9rem;
    display: flex;
    align-items: flex-end;
  }
`
