import { useFetch } from './useFetch'
import { useGlobalStore } from 'store/useGlobalStore'
import shallow from 'zustand/shallow'

export const useFetchUserClaim = () => {
  const [userClaims, isAuthenticated, fetchUserClaim] = useGlobalStore(
    (state) => [state.userClaims, state.isAuthenticated, state.fetchUserClaim],
    shallow
  )

  const { loading, error } = useFetch(async () => {
    if (isAuthenticated && !userClaims?.UserId) {
      await fetchUserClaim()
    }
  }, [isAuthenticated])

  return { loading, error }
}
