import { gridBreakPoints } from 'common/theme'
import styled from 'styled-components'

export const StyledSlideOutSearchFilter = styled.div`
  .slide-out-close {
    margin-top: -1.5rem;
  }
  .slide-out-content {
    left: 0;
    @media (max-width: ${gridBreakPoints.xl}) {
      width: 50%;
    }
    @media (max-width: ${gridBreakPoints.sm}) {
      width: 100%;
    }
  }
`
