import styled from 'styled-components'

export const StyledTag = styled.div`
  font-size: 13px;
  height: auto;
  padding: 2px 8px 2px 12px;
  margin: 8px 8px 0 0;
  border-radius: 18px;
  background-color: ${({ theme }) => theme.colors.primary};
  overflow: visible;
  text-transform: none;
  max-width: 200px;
  transition: background-color 0.2s ease;
  border: 0;
  outline: 0;
  vertical-align: middle;
  color: #fff;
  display: flex;
  align-items: center;
`
