import { StockwaveData } from 'modules/Stockwave/StockwaveData'
import { StyledVehicleDetailsColumn } from 'modules/VehicleList'
import { UnwatchPlaceholder } from 'modules/Watchlist/UnwatchPlaceholder'
import { useContext, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { IVehicleData } from '../../types/vehicleTypes'
import { CarfaxAdvantage } from './CarfaxAdvantage'
import { VehicleContext } from './VehicleContext'
import { StyledCarfaxCol, StyledVehicleDetailsCard } from './VehicleDetails.styled'
import { VehicleInformation } from './VehicleInformation'
import { DSIImage } from './DSIImage'
import { useFetch } from 'hooks/useFetch'
import { getSellerDisclosureByID } from 'apis/vehicleApis'
import { FyusionDetailImage } from './FyusionDetailImage'
import { StyledVehicleDetailImage } from './VehicleDetailImage.style'
import BMWIconNew from 'images/icon/BMWIconNew'
import { useGlobalStore } from 'store/useGlobalStore'

export interface IVehicleDetailsProps {
  vehicleData: IVehicleData
  enableRemovedWatchlistPlaceholder: boolean
  onSelect?: (e: boolean) => void
  showCheckbox?: boolean
  isSelected?: boolean
  isShowRemoveBidButton?: boolean
}

export const VehicleListLayout = ({
  vehicleData,
  enableRemovedWatchlistPlaceholder,
  onSelect,
  isSelected,
  showCheckbox
}: IVehicleDetailsProps) => {
  const [imageError, setImageError] = useState<boolean>(false)
  const { vehicle: vehicleInfo } = vehicleData
  const {
    vehicleData: {
      vehicle: { WatchListID }
    }
  } = useContext(VehicleContext)
  const {
    appSettings: { ImageMissingUrl }
  } = useGlobalStore()

  const { data: selfInspection, loading: sellerDisclosureLoading } = useFetch(
    () => (vehicleData?.vehicle.IsSellerDisclosure ? getSellerDisclosureByID(+vehicleData?.vehicle?.InstanceID) : null),
    [vehicleData?.vehicle.IsSellerDisclosure, vehicleData?.vehicle.InstanceID]
  )

  if (!WatchListID && enableRemovedWatchlistPlaceholder) {
    return (
      <StyledVehicleDetailsCard>
        <UnwatchPlaceholder vehicleInfo={vehicleInfo}></UnwatchPlaceholder>
      </StyledVehicleDetailsCard>
    )
  }
  const handleCheckboxClick = (e: any) => {
    const { checked } = e.target as HTMLInputElement
    onSelect?.(checked)
  }

  let images = vehicleData.vehicle.Images?.filter((item) => item.ImageURL !== ImageMissingUrl)

  return (
    <StyledVehicleDetailsCard>
      <Row noGutters className="stockwave-vehicle-row stockwave-highlight-element">
        <Col sm={3} className="vehicle-detail-image-section">
          <div className="stockwave-light-bulb-container" id={`vehicle_${vehicleData.vehicle.VIN}`}></div>
          <StockwaveData vehicleData={vehicleData} />
          {showCheckbox && (
            <Form.Check
              id={`vehicle-details-check-${vehicleData.vehicle.InstanceID}`}
              className="vehicle-select"
              checked={isSelected}
              custom
              onChange={handleCheckboxClick}
            />
          )}
          {vehicleData?.vehicle.IsSellerDisclosure ? (
            !sellerDisclosureLoading && (
              <DSIImage
                key={selfInspection?.SellerMedia?.UploadID}
                mediaUploadId={selfInspection?.SellerMedia?.UploadID}
              />
            )
          ) : images?.length <= 0 ? (
            <StyledVehicleDetailImage>
              <div className="margin-top">
                <BMWIconNew />
              </div>
            </StyledVehicleDetailImage>
          ) : (
            <div
              onErrorCapture={(e) => {
                setImageError(true)
              }}
            >
              <FyusionDetailImage
                key={vehicleData?.vehicle?.InstanceID}
                lowRes={vehicleInfo.Images.map((image) => image.ImageURL)}
                thumbnail={vehicleInfo.ThumbImageURL}
                error={imageError}
                vin={vehicleData?.vehicle?.VIN}
              ></FyusionDetailImage>
            </div>
          )}
        </Col>
        <Col>
          <StyledVehicleDetailsColumn>
            <VehicleInformation vehicleData={vehicleData}></VehicleInformation>
            <Row className="carfax-advanced-section justify-content-end">
              <StyledCarfaxCol md={12}>
                <CarfaxAdvantage snapshotList={vehicleData.carfaxSnapshotList} />
              </StyledCarfaxCol>
            </Row>
          </StyledVehicleDetailsColumn>
        </Col>
      </Row>
    </StyledVehicleDetailsCard>
  )
}
