import { VehicleStatuses } from 'common/constants'
import { colorDanger, colorWinning } from 'common/theme'
import { StyledTag } from 'modules/BuyerActivity/VehicleAuction.styled'
import { useContext } from 'react'
import { useGlobalStore } from 'store/useGlobalStore'
import styled from 'styled-components'
import { VehicleContext } from './VehicleContext'
import classNames from 'classnames'

export const StyledVehicleTags = styled.div`
  ${StyledTag} {
    width: 10rem;
  }
`

interface IProps {
  direction?: 'left' | 'right'
  className?: string
}

export const VehicleTags = ({ className, direction = 'left' }: IProps) => {
  const {
    vehicleData: { vehicle: vehicleInfo, auctionInfo }
  } = useContext(VehicleContext)
  const getLocalText = useGlobalStore((state) => state.getLocalText)

  return (
    <StyledVehicleTags className={className}>
      {vehicleInfo.StatusID === VehicleStatuses.OnSale && auctionInfo && auctionInfo.HasPreviousBid && (
        <div>
          <StyledTag
            className={classNames({ right: direction === 'right' })}
            color={auctionInfo.IsHighestBidder ? colorWinning : colorDanger}
          >
            <span>
              {auctionInfo.IsHighestBidder ? getLocalText('WINNING', 'winning') : getLocalText('OUTBID', 'outbid')}
            </span>
          </StyledTag>
        </div>
      )}
      {vehicleInfo.StatusID !== VehicleStatuses.SoldNotFunded && Boolean(vehicleInfo.WatchListID) && (
        <div>
          <StyledTag className={classNames({ right: direction === 'right' })}>
            <span>watching</span>
          </StyledTag>
        </div>
      )}
    </StyledVehicleTags>
  )
}
