import styled from 'styled-components'

export const StyledNoData = styled.div<{ showInChart?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #b5b5b5;
  ${({ showInChart }) =>
    showInChart &&
    `
    position: relative;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  `}
  .svg-inline--fa {
    font-size: 42px;
  }
`
