import { IconSvg } from '@prism/icon'
import classNames from 'classnames'
import { DEFAULT_ERROR_MESSAGE, SessionStorageKey, VehicleDetailsView } from 'common/constants'
import { Loader, OverlayLoader } from 'components/Loader'
import { Paging } from 'components/Paging'
import ScrollButton from 'components/ScrollButton/ScrollButton'
import { Sort } from 'components/Sort/Sort'
import { useDealerManagedVehicles } from 'hooks/useDealerManagedVehicles'
import { useFetch } from 'hooks/useFetch'
import NoActivity from 'modules/BuyerActivity/NoActivity'
import { VehicleContextProvider } from 'modules/DealerVehicleManagement/VehicleContext'
import React, { ReactElement, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { Col, ColProps, Form, Row } from 'react-bootstrap'
import { IBaseCollectionRequest, IBaseCollectionResponse, ISortBy } from 'types/baseTypes'
import { GetBidVehiclesRequest, IGetBidVehiclesResponse, IVehicleData } from 'types/vehicleTypes'

import { IVehicleDetailsProps, VehicleListLayout } from 'modules/DealerVehicleManagement/VehicleDetails'
import { VehicleGridLayout } from 'modules/DealerVehicleManagement/VehicleDetailsGridCard'
import { StyledHeaderActions, StyledSelectAll, StyledStickyHeaderPage, StyledVehicleList } from './VehicleList.styled'
import { VehicleListContext } from './VehicleListContext'
import { SliderContext } from 'contexts/SliderContext'
import { VehicleStickyBar } from './VehicleStickyBar'

interface IProps {
  title: ReactNode
  queryFunction: (request: IBaseCollectionRequest) => Promise<IBaseCollectionResponse<IVehicleData>>
  queryRequest?: IBaseCollectionRequest
  countQueryFunction?: (request: IBaseCollectionRequest) => Promise<IBaseCollectionResponse<IVehicleData>>
  navigationLink?: (count: number) => ReactElement
  checkboxAction?: (selectedVehicles: number[], vehicles?: IVehicleData[]) => ReactElement
  enableRemovedWatchlistPlaceholder?: boolean
  noActivityMessage: string
  showCheckbox?: boolean
  isShowRemoveBidButton?: boolean
  bottomPagination?: boolean
  gridLayoutColProps?: ColProps
}
export const VehicleList = ({
  title,
  queryFunction,
  queryRequest,
  countQueryFunction,
  navigationLink,
  enableRemovedWatchlistPlaceholder = false,
  isShowRemoveBidButton = false,
  noActivityMessage,
  checkboxAction,
  showCheckbox,
  bottomPagination = true,
  gridLayoutColProps = { xs: 12, xl: 3, lg: 4, md: 6 }
}: IProps) => {
  const { currentPage, currentPageSize, currentSortBy, selectedVehicles, setVehiclesState } =
    useContext(VehicleListContext)
  const { setDisplaySlider } = useContext(SliderContext)
  const {
    loading,
    sortOptions,
    vehicles,
    refetch,
    totalRecords: totalRecordsForHeader,
    TotalPages
  } = useDealerManagedVehicles(
    queryFunction,
    useMemo(() => {
      if (!queryRequest) return new GetBidVehiclesRequest(currentPageSize, currentPage, currentSortBy)

      return {
        ...queryRequest,
        ...new GetBidVehiclesRequest(currentPageSize, currentPage, currentSortBy)
      }
    }, [queryRequest, currentPageSize, currentPage, currentSortBy])
  )

  const { data: { TotalRecords: totalRecordsForNavlink } = {} as IGetBidVehiclesResponse, loading: countLoading } =
    useFetch(() =>
      countQueryFunction
        ? countQueryFunction(new GetBidVehiclesRequest(currentPageSize, currentPage, currentSortBy))
        : Promise.resolve({} as IBaseCollectionResponse<IVehicleData>)
    )
  const handleSortChange = (selectedSortBy: ISortBy) => {
    setVehiclesState((state) => {
      state.currentSortBy = selectedSortBy
      state.selectedVehicles = []
    })
  }

  useEffect(() => {
    setVehiclesState((state) => {
      state.totalPages = TotalPages
    })
  }, [TotalPages, setVehiclesState])

  const [viewStyle, setViewStyle] = useState<VehicleDetailsView>(
    (sessionStorage.getItem(SessionStorageKey.VIEW_VEHICLE_LIST) as VehicleDetailsView) || VehicleDetailsView.List
  )
  useEffect(() => {
    sessionStorage.setItem(SessionStorageKey.VIEW_VEHICLE_LIST, viewStyle)
  }, [viewStyle, setViewStyle])

  const isSelectAll = vehicles?.every((item) =>
    selectedVehicles.find((selectedId) => selectedId === item.vehicle.InstanceID)
  )

  const handleSelectAll = () => {
    setVehiclesState((state) => {
      if (isSelectAll) {
        state.selectedVehicles = []
      } else {
        state.selectedVehicles = vehicles.map((item) => item.vehicle.InstanceID)
      }
    })
  }
  const handleSelectVehicle = (instanceID: number, checked: boolean) => {
    if (checked && selectedVehicles.indexOf(instanceID) < 0) {
      setVehiclesState((state) => {
        state.selectedVehicles = [...selectedVehicles, instanceID]
      })
    }

    if (!checked && selectedVehicles.indexOf(instanceID) >= 0) {
      setVehiclesState((state) => {
        state.selectedVehicles = selectedVehicles.filter((item) => item !== instanceID)
      })
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [viewStyle])

  let colProps: ColProps
  let Card: React.FunctionComponent<IVehicleDetailsProps>
  if (viewStyle === VehicleDetailsView.List) {
    colProps = { xs: 12 }
    Card = VehicleListLayout
  } else {
    colProps = gridLayoutColProps
    Card = VehicleGridLayout
  }

  const handlePageSizeChanged = (pageSizeSelected: number) => {
    setVehiclesState((state) => {
      state.currentPage = 0
      state.currentPageSize = Number(pageSizeSelected)
    })
  }

  const paginationClicked = (pageSelected: number) => {
    if (pageSelected === currentPage + 1) return

    setVehiclesState((state) => {
      state.currentPage = pageSelected - 1
    })
  }
  useEffect(() => {
    if (vehicles?.length > 0) {
      var vehicleIDs = vehicles.map((item) => item.vehicle.InstanceID)
      sessionStorage.setItem(SessionStorageKey.VEHICLE_INSTANCES, JSON.stringify(vehicleIDs))
    } else {
      sessionStorage.removeItem(SessionStorageKey.VEHICLE_INSTANCES)
    }
  }, [vehicles])

  useEffect(() => {
    setDisplaySlider(false, null, null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryRequest, currentPageSize, currentPage, currentSortBy])

  return (
    <>
      {loading && <OverlayLoader />}
      <StyledStickyHeaderPage>
        <VehicleStickyBar
          sectionTitle={title}
          itemCount={totalRecordsForHeader ?? 0}
          displayCollapse={false}
          rightActionSpan={9}
          rightActions={
            <StyledHeaderActions>
              {showCheckbox && checkboxAction?.(selectedVehicles, vehicles)}
              {countLoading ? (
                <Loader />
              ) : (
                navigationLink &&
                navigationLink(countQueryFunction ? totalRecordsForNavlink ?? 0 : totalRecordsForHeader ?? 0)
              )}
              {totalRecordsForHeader > 0 && sortOptions?.length > 0 && (
                <Sort currentSort={currentSortBy} sortOptions={sortOptions} onChange={handleSortChange} />
              )}
              <span className="view-icons">
                <IconSvg
                  glyph="list"
                  className={classNames({ active: viewStyle === VehicleDetailsView.List })}
                  onClick={() => setViewStyle(VehicleDetailsView.List)}
                />
                <IconSvg
                  glyph="grid"
                  className={classNames({ active: viewStyle === VehicleDetailsView.Grid })}
                  onClick={() => setViewStyle(VehicleDetailsView.Grid)}
                />
              </span>
            </StyledHeaderActions>
          }
        />

        {vehicles && (
          <StyledVehicleList className={classNames({ 'pt-2': showCheckbox && !!checkboxAction })}>
            {vehicles?.length > 0 ? (
              <>
                {showCheckbox && !!checkboxAction && (
                  <StyledSelectAll>
                    <Form.Check
                      custom
                      id="vehicle-details-select-all"
                      onChange={handleSelectAll}
                      checked={isSelectAll}
                      label={'Select All'}
                    />
                  </StyledSelectAll>
                )}
                <Row>
                  {vehicles.map((item, index) => (
                    <Col className="grid-card-col" {...colProps} key={`${viewStyle}-${index}-${item.vehicle.VIN}`}>
                      <VehicleContextProvider
                        vehicleData={item}
                        showRemoveBid={isShowRemoveBidButton}
                        onRemoveBid={refetch}
                        errorMessage={DEFAULT_ERROR_MESSAGE}
                        viewStyle={viewStyle}
                      >
                        <Card
                          showCheckbox={showCheckbox && !!checkboxAction}
                          onSelect={(checked) => handleSelectVehicle(item.vehicle.InstanceID, checked)}
                          enableRemovedWatchlistPlaceholder={enableRemovedWatchlistPlaceholder}
                          vehicleData={item}
                          isSelected={selectedVehicles.indexOf(item.vehicle.InstanceID) >= 0}
                        />
                      </VehicleContextProvider>
                    </Col>
                  ))}
                </Row>
                {bottomPagination && (
                  <Paging
                    currentPage={currentPage}
                    currentPageSize={currentPageSize}
                    handleChangedPage={paginationClicked}
                    handlePageSizeChanged={handlePageSizeChanged}
                    totalPages={TotalPages}
                  />
                )}
              </>
            ) : (
              <NoActivity message={noActivityMessage} />
            )}
          </StyledVehicleList>
        )}
      </StyledStickyHeaderPage>
      <ScrollButton></ScrollButton>
    </>
  )
}
