import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Row, useFilters, useTable, usePagination, useExpanded, useGlobalFilter } from 'react-table'
import { DataTableCell, DataTableHeader, StyledDataTable } from './DataTable.styled'
import { NoData } from 'components/NoData'
import { IPrismDataTableProps } from './PrismDataTable'
import classNames from 'classnames'
import { Paging } from 'components/Paging'
import { Loader } from 'components/Loader'
import { Form } from 'react-bootstrap'
import { theme } from 'core/skins/BMW/theme'
import { DEFAULT_PAGE_SIZE } from 'common/constants'

interface IDataTableProps<T extends object> extends IPrismDataTableProps<T> {
  rowClass?: (row: Row<T>, index?: number) => string
  isFilterSearch?: boolean
  pagingTimeOut?: number
  renderRowSubComponent?: (row: Row<T>) => React.ReactElement
  isDataPaginated?: boolean
  filterRows?: string
}

export function DataTable<T extends object>({
  columns,
  data,
  isDataPaginated = false,
  striped = true,
  height,
  emptyText,
  showNoData = data?.length,
  isFilterSearch = false,
  pagingTimeOut = 500,
  renderRowSubComponent,
  rowClass,
  filterRows = 'Title'
}: IDataTableProps<T>) {
  // Use the state and functions returned from useTable to build your UI
  const filteredColumns = useMemo(() => (columns || []).filter((c) => c.visible === undefined || c.visible), [columns])
  const tableData = useMemo(() => data || [], [data])
  const filterTypes = (rows, id, filterValue) => {
    return rows.filter((row) => {
      const rowValue = row.values[filterRows]
      return rowValue !== undefined ? String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase()) : true
    })
  }
  const {
    visibleColumns,
    gotoPage,
    setPageSize,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    setGlobalFilter,
    rows,
    page,
    prepareRow,
    state: { pageIndex, pageSize, globalFilter }
  } = useTable(
    {
      columns: filteredColumns,
      data: tableData,
      initialState: { pageIndex: 0, pageSize: DEFAULT_PAGE_SIZE },
      paginateExpandedRows: false,
      manualPagination: isDataPaginated,
      autoResetPage: false,
      globalFilter: filterTypes
    },
    useFilters,
    useGlobalFilter,
    useExpanded,
    usePagination
  )
  const [pagingLoading, setPagingLoading] = useState<boolean>(false)

  useEffect(() => {
    if (page && page.length <= 0) {
      gotoPage(0)
    }
  }, [page])

  // eslint-disable-next-line
  useEffect(() => {}, [tableData])
  const customGotoPage = useCallback(
    (index: number) => {
      if (index !== pageIndex + 1) {
        setPagingLoading(true)
        setTimeout(() => {
          setPageSize(pageSize)
          gotoPage(index - 1)
          window.scrollTo(0, 0)
          setPagingLoading(false)
        }, pagingTimeOut)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, pageIndex, pageSize]
  )

  const customSetPageSize = useCallback(
    (size: number) => {
      setPagingLoading(true)
      setTimeout(() => {
        setPageSize(+size)
        window.scrollTo(0, 0)
        setPagingLoading(false)
      }, pagingTimeOut)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, pageSize, pageIndex]
  )
  // Render the UI for your table
  return (
    <>
      {pagingLoading ? (
        <Loader overlay />
      ) : (
        <>
          <StyledDataTable className="prism-sandbox" striped={striped} maxHeight={height}>
            {!!showNoData && (
              <div>
                <div className="table-container">
                  <table className="table table-hover table-sm table-head-sticky" {...getTableProps()}>
                    <thead className="table-head">
                      {headerGroups.map((headerGroup, index) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={`tr-${index}`}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                              <DataTableHeader key={column.render('Header').toString()}>
                                {column.render('Header')}
                                <span>{column.canFilter && column.Filter ? column.render('Filter') : null}</span>
                              </DataTableHeader>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody className="table-body" {...getTableBodyProps()}>
                      {isFilterSearch && (
                        <tr className={classNames('table-row')}>
                          <td>
                            <DataTableCell>
                              <Form.Control
                                style={{ height: 'auto' }}
                                type="text"
                                value={globalFilter}
                                className="search-field"
                                placeholder="Description"
                                onChange={(e) => {
                                  setGlobalFilter(e.target.value)
                                  gotoPage(0)
                                }}
                              />
                            </DataTableCell>
                          </td>
                        </tr>
                      )}

                      {page.map((row, i) => {
                        prepareRow(row)
                        return (
                          <>
                            <tr className={classNames('table-row', rowClass?.(row, i))} {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    <DataTableCell>{cell.render('Cell')}</DataTableCell>
                                  </td>
                                )
                              })}
                            </tr>
                            {row.isExpanded && (
                              <tr>
                                <td
                                  style={{
                                    backgroundColor: theme.colors.sectionContentBackgroundColor,
                                    minHeight: '5rem'
                                  }}
                                  colSpan={visibleColumns.length}
                                >
                                  {renderRowSubComponent(row)}
                                </td>
                              </tr>
                            )}
                          </>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {!showNoData && <NoData text={emptyText} />}
          </StyledDataTable>
          {!isDataPaginated ? (
            <Paging
              currentPage={pageIndex}
              totalPages={Math.ceil(rows?.length / pageSize)}
              handleChangedPage={customGotoPage}
              currentPageSize={+pageSize}
              handlePageSizeChanged={customSetPageSize}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  )
}
