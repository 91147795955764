import { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { StyledDropdownList } from './DropdownList.styled'
import ChevronDown from 'icons/ChevronDown'

interface IProps {
  defaultValue: string
  items: IDropDownItemProps[]
  onChange?: (value) => void
}

interface IDropDownItemProps {
  id: string
  value: string
}

export const DropdownList = ({ defaultValue, items, onChange }: IProps) => {
  const [currentValue, setCurrentValue] = useState('')

  useEffect(() => {
    setCurrentValue(defaultValue)
  }, [defaultValue])

  const handleSelectChange = (id) => {
    setCurrentValue(id)
    onChange?.(id)
  }

  return (
    <StyledDropdownList className="dropdown-container">
      <Dropdown defaultValue={currentValue} onSelect={(id) => handleSelectChange(id)}>
        <Dropdown.Toggle
          as="button"
          type="button"
          onSelect={(id) => handleSelectChange(id)}
          className="dropdown-title"
          tabIndex={0}
        >
          <span>{items.find((i) => i.id === currentValue)?.value ?? 'Select'}</span>
          <ChevronDown />
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-select">
          {items.map((item) => (
            <Dropdown.Item
              tabIndex={0}
              className={`${currentValue === item.id && 'active'}`}
              key={item.id}
              eventKey={item.id}
            >
              {item.value}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </StyledDropdownList>
  )
}
