import React, { useEffect, useState } from 'react'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StyledScrollButton } from './ScrollButton.styled'

const ScrollButton = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop
      if (scrolled > 300) {
        setShow(true)
      } else if (scrolled <= 300) {
        setShow(false)
      }
    }
    window.addEventListener('scroll', toggleVisible)

    return () => window.removeEventListener('scroll', toggleVisible)
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <StyledScrollButton className="d-print-none" onClick={scrollToTop} show={show}>
      <div className="circle">
        <FontAwesomeIcon icon={faAngleUp}></FontAwesomeIcon>
      </div>
      <span>TOP</span>
    </StyledScrollButton>
  )
}

export default ScrollButton
