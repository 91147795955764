import { quickSearch } from 'apis/vehicleApis'
import { AVAILABLE_INVENTORY_INIT_QUERY } from 'common/constants'
import { ISearchFilterResult } from 'types/vehicleTypes'
import create from 'zustand'

export interface ISrpFiltersState {
  filters: ISearchFilterResult[]
  totalVehicles: number

  fetchFilters: () => Promise<void>
}

export const useSrpFiltersStore = create<ISrpFiltersState>((set, get) => ({
  filters: [],
  totalVehicles: 0,

  fetchFilters: async () => {
    const resp = await quickSearch({ ...AVAILABLE_INVENTORY_INIT_QUERY })

    if (resp) {
      set((state) => ({ ...state, filters: [...resp.Data] as ISearchFilterResult[], totalVehicles: resp.TotalRecords }))
    }
  }
}))
