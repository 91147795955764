import { ISRPSearchParams } from 'contexts/FilterSearchContext'
import produce from 'immer'
import { createContext, PropsWithChildren, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ISortBy } from 'types/baseTypes'
import { getDefaultSRPFilter, resetSRPFilter } from 'utils/menuUtils'
import { parseUrlSearchParams } from 'utils/urlParams'

export interface IVehicleListState {
  currentPage: number
  totalPages?: number
  currentSortBy: ISortBy
  currentPageSize: number
  selectedVehicles: number[]
}

type SetStateAction<T> = (state: T) => void

export interface IVehicleListContext extends IVehicleListState {
  setVehiclesState: (action: SetStateAction<IVehicleListState>) => void
}

export const VehicleListContext = createContext<IVehicleListContext>(null)

export const VehicleListContextProvider = ({ children }: PropsWithChildren<any>) => {
  const location = useLocation()

  useMemo(() => {
    const query = parseUrlSearchParams<ISRPSearchParams>(location.search)
    if (query?.clearSearch) {
      resetSRPFilter()
    }
  }, [location.search])

  const [state, setState] = useState<IVehicleListState>({
    ...getDefaultSRPFilter(),
    selectedVehicles: []
  })

  const setVehiclesState = (action: SetStateAction<IVehicleListState>) => {
    setState(produce(state, action))
  }
  return <VehicleListContext.Provider value={{ ...state, setVehiclesState }}>{children}</VehicleListContext.Provider>
}
