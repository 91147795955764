import { useEffect } from 'react'
import { StyledVehicleDetailImage } from './VehicleDetailImage.style'
import BMWIconNew from 'images/icon/BMWIconNew'

interface IProps {
  lowRes: string[]
  thumbnail: string
  error: boolean
  vin: string
}

export const FyusionDetailImage = ({ thumbnail, lowRes, error, vin }: IProps) => {
  const prismData = lowRes.map((lowResItem) => ({
    category: '',
    src: {
      thumbnail: lowResItem,
      lowRes: lowResItem
    },
    description: ''
  }))

  useEffect(() => {
    ;(window as any).SVFyusion.addViewer(prismData, `fyuse_viewer_${vin}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thumbnail])

  return (
    <StyledVehicleDetailImage>
      {error ? <BMWIconNew /> : <div id={`fyuse_viewer_${vin}`}></div>}
    </StyledVehicleDetailImage>
  )
}
