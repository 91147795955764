import { ComponentProps, useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'

const DEFAULT_DISMISSABLE_TIMEOUT = 3000
interface IProps extends ComponentProps<typeof Alert> {
  message: string
  variant?: 'danger' | 'success'
  autoDismissable?: boolean | number
  onClose?: () => void
}
export const DismissableAlert = ({ message, variant = 'danger', onClose, autoDismissable, ...props }: IProps) => {
  const [show, setShow] = useState(!!message)

  useEffect(() => {
    setShow(Boolean(message))
  }, [message])

  useEffect(
    () => {
      let timeoutHandler
      if (autoDismissable && show) {
        const timeout = typeof autoDismissable === 'number' ? autoDismissable : DEFAULT_DISMISSABLE_TIMEOUT

        timeoutHandler = setTimeout(() => {
          setShow(false)
          onClose?.()
        }, timeout)
      }

      return () => {
        if (timeoutHandler) {
          clearTimeout(timeoutHandler)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [autoDismissable, show]
  )

  return (
    show && (
      <Alert
        data-testid="dismisableAlert"
        variant={variant}
        onClose={() => {
          setShow(false)
          onClose?.()
        }}
        dismissible
        {...props}
      >
        {message}
      </Alert>
    )
  )
}
