import { Ribbon } from 'components/Ribbon/Ribbon'
import { IVDPSearchParams } from 'types/ISearchParams'
import { IVehicleInfo } from 'types/vehicleTypes'
import { parseUrlSearchParams } from 'utils/urlParams'
import { StyledRibbonContainer } from './VehicleDetails.styled'
import { useLocation } from 'react-router-dom'
import { useContext } from 'react'
import { VehicleContext } from './VehicleContext'
import FileSaver from 'file-saver'

interface IProps {
  vehicleInfo: IVehicleInfo
}

export const VehicleRibbons = ({ vehicleInfo }: IProps) => {
  const location = useLocation()
  const { retailView } = useContext(VehicleContext)
  const isVDPHistory = parseUrlSearchParams<IVDPSearchParams>(location.search).history === 'true'
  const showPriceReduction = !isVDPHistory && vehicleInfo.DecreasedPrice
  const showNewInventory = !isVDPHistory && vehicleInfo.NewInventory
  const showPromotions = !isVDPHistory && !retailView
  const { vehicleData } = useContext(VehicleContext)

  return (
    <StyledRibbonContainer>
      {showPriceReduction && <Ribbon variant="danger" label="Price Reduction" key="DecreasedPrice" />}
      {showNewInventory && <Ribbon variant="success" label="New Inventory" key="NewInventory" />}
      {vehicleInfo.DisclosurePresent && (
        <Ribbon
          variant="warning"
          label="Disclosure"
          onClick={
            vehicleData.documentSignInfo
              ? () =>
                  FileSaver.saveAs(
                    '/resource/GetVehicleInstanceDocumentByID?documentID=' +
                      vehicleData.documentSignInfo.VehicleInstanceDocumentsID
                  )
              : null
          }
          title={vehicleData.documentSignInfo ? 'Click Here to View Disclosure' : ''}
        />
      )}
      {showPromotions &&
        vehicleInfo.Incentives?.map((i, idx) => <Ribbon variant="info" label={i.Name} key={`${idx}-${i.Name}`} />)}
    </StyledRibbonContainer>
  )
}
