import { DEFAULT_PAGE_SIZE, DefaultSortBy, SessionStorageKey } from 'common/constants'
import { IMenuInfo, IPortalMenuResponse } from 'types/userTypes'
import { parseJson } from './utils'
export const findMenuParent = (menus: IMenuInfo[], path: string): IMenuInfo[] => {
  const firstNode = menus.find((menu) => {
    return menu.path === path
  })

  if (firstNode) {
    return [...findMenuParent(menus, path.substring(0, path.lastIndexOf('/'))), firstNode]
  }

  return []
}

export const mapChildMenus = (topLevelMenu: IMenuInfo[], allMenus: IMenuInfo[]): IMenuInfo[] => {
  return topLevelMenu.map((item) => {
    const children = allMenus.filter((child) => child.parentID === item.id)
    return { ...item, children }
  })
}

export const resetSRPFilter = () => {
  sessionStorage.removeItem(SessionStorageKey.QUERY_FILTERS)
  sessionStorage.removeItem(SessionStorageKey.SEARCH_FILTERS)
  sessionStorage.removeItem(SessionStorageKey.PURCHASE_HISTORY_SEARCH_FILTER)
  sessionStorage.removeItem(SessionStorageKey.SEARCH_DESCRIPTION)
  sessionStorage.removeItem(SessionStorageKey.PAGE_SELECTED)
  sessionStorage.removeItem(SessionStorageKey.PAGE_SIZE)
  sessionStorage.removeItem(SessionStorageKey.SESSION_SORT)
  sessionStorage.removeItem(SessionStorageKey.SCROLL_TO_VIN)
  sessionStorage.removeItem(SessionStorageKey.VIEW_VEHICLE_LIST)
}

export const getDefaultSRPFilter = () => {
  return {
    currentPage: parseInt(sessionStorage.getItem(SessionStorageKey.PAGE_SELECTED)) || 0,
    currentPageSize: parseInt(sessionStorage.getItem(SessionStorageKey.PAGE_SIZE)) || DEFAULT_PAGE_SIZE,
    currentSortBy: parseJson(sessionStorage.getItem(SessionStorageKey.SESSION_SORT)) || DefaultSortBy
  }
}

export const handlePortalMenuItemClick = (item: IPortalMenuResponse) => {
  if (item.MenuItem.Name === 'RDP_MyProfile') {
    resetSRPFilter()
  }
}
