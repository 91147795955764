export const roundUpToNearest = (newValue: number, oldValue: number, roundUpTo) => {
  const diff = roundUpTo - ((newValue - oldValue) % roundUpTo)
  return diff === roundUpTo ? newValue : newValue + diff
}

export const formatNumber = (value: number, options?: Intl.NumberFormatOptions) => {
  if (!value && value !== 0) {
    return ''
  }

  const formatOptions = {
    minimumFractionDigits: 0,
    ...options
  }

  const format = new Intl.NumberFormat('en-US', formatOptions)

  return format.format(value)
}
