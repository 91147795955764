import { zIndex } from 'common/constants'
import {
  BuyItNowButtonContainer,
  BuyItNowButtonGroup,
  StyledAuctionButton
} from 'modules/BuyerActivity/VehicleAuction.styled'
import { StyledVehicleBidBuyInformation } from 'modules/BuyerActivity/VehicleAuction.styled'
import { StyledRemovedWatchListPlaceHolder } from 'modules/Watchlist/UnwatchPlaceholder.styled'
import styled from 'styled-components'
import { StyledVehicleAction } from './VehicleActions'
import { StyledTitleSection, StyledVehicleDetailsCard } from './VehicleDetails.styled'
import { StyledVehicleNote } from './VehicleNote.styled'

export const StyledBidBuyInfoSection = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledVehicleDetailsGridCard = styled(StyledVehicleDetailsCard)`
  ${StyledVehicleBidBuyInformation} {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  ${StyledAuctionButton} {
    width: 100%;
    margin: 1rem 0 0 0;
  }

  row-gap: 0.25rem;

  .vehicle-detail-image-section {
    position: relative;

    .vehicle-select {
      z-index: ${zIndex.pageHeader};
    }
  }

  .auction-info {
    flex-grow: 2;
  }

  .vehicle-info {
    gap: initial;
  }

  .stockwave-light-bulb-container {
    margin-left: 4rem;
  }

  ${StyledVehicleNote} {
    .vehicle-note {
      max-width: 20rem;
    }
  }

  ${BuyItNowButtonContainer} {
    flex-direction: column;
    row-gap: 0.5rem;
  }

  ${StyledTitleSection} {
    flex-direction: column;
    align-items: flex-start;
  }

  ${BuyItNowButtonGroup} {
    width: 100%;
  }

  ${StyledRemovedWatchListPlaceHolder} {
    .alert {
      flex-direction: column;
    }
  }

  ${StyledVehicleAction} {
    justify-content: space-between;
  }

  ${StyledTitleSection} {
    padding: 0;
  }
`
