import { FORBIDDEN_PAGE } from 'common/messages'
import { Rules } from 'common/rules'
import { Loader } from 'components/Loader'
import ScrollToTop from 'components/Share/ScrollToTop'
import { LayoutContextProvider } from 'contexts/LayoutContext'
import { PortalThemeProvider } from 'core/theme/ThemeProvider'
import { useFetchUserClaim } from 'hooks/useFetchUserClaim'
import useNotification from 'hooks/useNotification'
import { AlertNotification } from 'components/AlertNotification/AlertNotification'
import { ContactUs } from 'pages/ContactUs'
import { Dashboard } from 'pages/Dashboard'
import { ErrorPage } from 'pages/ErrorPage'
import { ForgotPassword } from 'pages/ForgotPassword'
import { Login } from 'pages/Login'
import { Privacy } from 'pages/Privacy'
import { TermCondition } from 'pages/TermCondition'
import { Fragment, lazy, PropsWithChildren, Suspense, useEffect, useState } from 'react'
import { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { IntlProvider } from 'react-intl'
import { BrowserRouter, Redirect, Route, RouteProps, Switch } from 'react-router-dom'
import { useGlobalStore } from 'store/useGlobalStore'
import { DtmPageName, setPageInfo } from 'utils/analyticsUtils'
import shallow from 'zustand/shallow'
import { WatchListPage } from 'pages/WatchListPage'
import { CurrentBidsPage } from 'pages/CurrentBidsPage'
import { SearchResultsPage } from 'pages/SearchResultsPage'
import { AppRoutes } from './common/routes'
import { SliderContextProvider } from 'contexts/SliderContext'
import { DefaultErrorBoundary } from 'modules/DefaultErrorBoundary'
import { BidHistory } from 'pages/BidHistory'
import { AuctionHistoryProvider } from 'contexts/BidHistoryContext'
import { PurchaseHistory } from 'pages/PurchaseHistory'
import { FilterSearchContextProvider } from 'contexts/FilterSearchContext'
import { VehicleListContextProvider } from 'modules/VehicleList'

interface IAuthenticatedRouteProps extends PropsWithChildren<RouteProps> {
  allowedRules?: Rules[]
  pageName?: string
}

interface IAnalyticsRouteProps extends RouteProps {
  pageName?: string
}

const AnalyticsRoute = ({ pageName, ...props }: IAnalyticsRouteProps) => {
  useMemo(() => {
    if (pageName) {
      setPageInfo(pageName)
    }
  }, [pageName])

  return <Route {...props} />
}

const AuthenticatedRoute = ({ pageName, allowedRules, children, ...props }: IAuthenticatedRouteProps) => {
  const [rules, isInRule] = useGlobalStore((state) => [state.rules, state.isInRule], shallow)
  const [allow, setAllow] = useState<boolean>(false)

  useNotification()

  useEffect(() => {
    allowedRules?.forEach((rule) => {
      if (isInRule(rule)) {
        setAllow(true)
        return
      }
    })
  }, [rules, allowedRules, isInRule])

  return (
    <AnalyticsRoute pageName={pageName} {...props}>
      {!allow ? (
        <ErrorPage message={FORBIDDEN_PAGE} />
      ) : (
        <Suspense fallback={<Loader />}>
          <DefaultErrorBoundary>{children}</DefaultErrorBoundary>
        </Suspense>
      )}
    </AnalyticsRoute>
  )
}

const PublicRoute = ({ children, ...props }: RouteProps) => {
  return (
    <Route path={props.path}>
      <Suspense fallback={<Loader />}>
        <DefaultErrorBoundary>{children}</DefaultErrorBoundary>
      </Suspense>
    </Route>
  )
}

export const App = () => {
  const [localText, isAuthenticated] = useGlobalStore((state) => [state.localText, state.isAuthenticated])
  const { loading: userClaimLoading } = useFetchUserClaim()
  if (userClaimLoading) {
    return <Loader overlay />
  }
  const Faq = lazy(() => import('./pages/Faq'))
  const VehicleDetailsPage = lazy(() => import('./pages/VehicleDetailsPage'))
  return (
    <div>
      {localText && (
        <IntlProvider textComponent={Fragment} locale={'en-GB'} messages={localText}>
          <PortalThemeProvider>
            <Helmet titleTemplate="%s | BMW Dealer Portal"></Helmet>
            <LayoutContextProvider>
              <AlertNotification />
              <BrowserRouter>
                <ScrollToTop />
                <Switch>
                  <Route path={[AppRoutes.Login]}>
                    <Login />
                  </Route>
                  <Route path={[AppRoutes.Forgot]}>
                    <ForgotPassword />
                  </Route>
                  <Route path={[AppRoutes.Terms]}>
                    <TermCondition />
                  </Route>
                  <Route path={[AppRoutes.Privacy]}>
                    <Privacy />
                  </Route>
                  <PublicRoute path={[AppRoutes.FAQs]}>
                    <Faq />
                  </PublicRoute>
                  <AnalyticsRoute pageName={DtmPageName.ContactUs} path={[AppRoutes.ContactUs]}>
                    <ContactUs />
                  </AnalyticsRoute>
                  {!userClaimLoading && isAuthenticated ? (
                    <SliderContextProvider>
                      <Switch>
                        <AuthenticatedRoute
                          pageName={DtmPageName.CurrentBids}
                          path={[AppRoutes.ManageBids]}
                          allowedRules={[Rules.PAGE_RDP_CurrentBids]}
                          exact
                        >
                          <CurrentBidsPage />
                        </AuthenticatedRoute>
                        <AuthenticatedRoute
                          pageName={DtmPageName.WatchList}
                          path={[AppRoutes.WatchList]}
                          allowedRules={[Rules.VIEW_WATCH_LIST]}
                          exact
                        >
                          <WatchListPage />
                        </AuthenticatedRoute>
                        <AuthenticatedRoute
                          pageName={DtmPageName.VDP}
                          path={[AppRoutes.VDP]}
                          allowedRules={[Rules.PAGE_RDP_Dashboard]}
                        >
                          <VehicleDetailsPage></VehicleDetailsPage>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute
                          pageName={DtmPageName.Dashboard}
                          path={[AppRoutes.Dashboard, '/']}
                          allowedRules={[Rules.PAGE_RDP_Dashboard]}
                          exact
                        >
                          <Dashboard />
                        </AuthenticatedRoute>
                        <AuthenticatedRoute
                          pageName={DtmPageName.BidHistory}
                          path={[AppRoutes.BidHistory]}
                          allowedRules={[Rules.RDP_PURCHASE_HISTORY]}
                          exact
                        >
                          <AuctionHistoryProvider>
                            <BidHistory />
                          </AuctionHistoryProvider>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute
                          pageName={DtmPageName.PurchaseHistory}
                          path={[AppRoutes.PurchaseHistory]}
                          allowedRules={[Rules.RDP_PURCHASE_HISTORY]}
                          exact
                        >
                          <VehicleListContextProvider>
                            <FilterSearchContextProvider>
                              <PurchaseHistory />
                            </FilterSearchContextProvider>
                          </VehicleListContextProvider>
                        </AuthenticatedRoute>
                        <AuthenticatedRoute
                          pageName={DtmPageName.SRP}
                          path={[AppRoutes.SearchResult]}
                          allowedRules={[Rules.PAGE_RDP_SRP]}
                          exact
                        >
                          <SearchResultsPage />
                        </AuthenticatedRoute>
                        <Redirect to="/" />
                      </Switch>
                    </SliderContextProvider>
                  ) : (
                    <Redirect
                      to={{
                        pathname: '/login',
                        search: `?returnUrl=${window.location.pathname}${window.location.search}`
                      }}
                    />
                  )}
                </Switch>
              </BrowserRouter>
            </LayoutContextProvider>
          </PortalThemeProvider>
        </IntlProvider>
      )}
    </div>
  )
}
