import { DashboardGrounding, exportViews } from 'common/constants'

export function parseJson<T extends object>(json: string, defaultValue?: T) {
  try {
    return JSON.parse(json)
  } catch (error) {
    return defaultValue
  }
}

export function isPendingPurchase(groupId: number) {
  return (
    groupId === DashboardGrounding.GROUNDING_EXPIRING_SOON || groupId === DashboardGrounding.GROUNDING_EXPIRING_TODAY
  )
}

export function validateVin(vin: string) {
  return vin?.length === 17
}

export function urlDownloadViewVehicles(vehicleInstanceIDs: number[], option: number) {
  return `/resource/${
    Number(option) === exportViews.Excel ? 'DownloadWatchListReportInExcel' : 'DownloadWatchListReportInPDF'
  }?vehicleInstanceIds=${vehicleInstanceIDs.join()}`
}

export function getMainImageUrl(images: any[]) {
  let mainImageURL =
    images.find((p) => p.VehicleImageTypeDescription === 'Vehicle Image')?.LocalThumbImageURL ??
    images[0]?.VehicleImageTypeDescription
      ? images[0].LocalThumbImageURL
      : ''
  return mainImageURL
}

export function formatDistanceLabel(value1, value2) {
  return `${value1} miles of ${value2}`
}
