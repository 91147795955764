import React from 'react'
import { DataTable } from '@prism/library'
import { useFormatDateTime, useFormatNumber, useFormatDate } from 'hooks/useIntl'
import { CellProps, Column, IdType } from 'react-table'
import { formatCurrency } from 'utils/formatUtils'
import { StyledDataTable } from './DataTable.styled'
import { NoData } from 'components/NoData'

interface ISortByProps {
  id: string
  desc?: boolean
}

interface IPaginationProps {
  label?: string
  pageSizes?: number[]
  pageSize?: number
}

type HeaderStyle = 'color' | 'light' | 'dark'

type ColumnWrapper<T extends object = {}> = Column<T> & { visible?: boolean }

export interface IPrismDataTableProps<T extends object = {}> {
  responsive?: boolean
  condensed?: boolean
  sortBy?: ISortByProps
  scrollable?: boolean
  sortable?: boolean
  pagination?: IPaginationProps
  headerSticky?: boolean
  headerStyle?: HeaderStyle
  height?: number
  columns: ColumnWrapper<T>[]
  data: T[]
  className?: string
  striped?: boolean
  emptyText?: string
  showNoData?: number
}
export const DEFAULT_VISIBLE_ROWS = 10
export const HEADER_HEIGHT = 39
export const ROW_HEIGHT = 36.5
export const DEFAULT_TABLE_HEIGHT = DEFAULT_VISIBLE_ROWS * ROW_HEIGHT + HEADER_HEIGHT

export function PrismDataTable<T extends object = {}>({
  striped = true,
  height,
  emptyText,
  data,
  showNoData = data?.length,
  columns,
  ...props
}: IPrismDataTableProps<T>) {
  return (
    <StyledDataTable className="prism-sandbox" maxHeight={height} striped={striped}>
      {!!showNoData && (
        <DataTable
          headerSticky={true}
          condensed={true}
          scrollable={true}
          columns={(columns || []).filter((c) => c.visible === undefined || c.visible)}
          data={data}
          manualSortBy={true}
          {...props}
        ></DataTable>
      )}
      {!showNoData && <NoData text={emptyText} />}
    </StyledDataTable>
  )
}

export const HtmlCell = ({ value }: any) => <div dangerouslySetInnerHTML={{ __html: value }}></div>
export const DateCell = ({ value, datetimeOptions }: any) => {
  const formatDateTime = useFormatDateTime(datetimeOptions)
  return <>{formatDateTime(value)}</>
}

export const DateWithoutTimeCell = ({ value, datetimeOptions }: any) => {
  const formatDate = useFormatDate(datetimeOptions)
  return <>{formatDate(value)}</>
}

export const DecimalCell = ({ value }: any) => {
  const formatDecimal = useFormatNumber()
  return <>{formatDecimal(value)}</>
}

export const CurrencyCell = ({ value }: any) => {
  return <>{formatCurrency(value)}</>
}

interface CustomizableEditableCellProps<D extends object = {}, V = any> extends CellProps<D, V> {
  onCellUpdate?: (index: number, columnId: IdType<D>, value: V) => void
  children: ({ value, onChange, onBlur }: React.InputHTMLAttributes<HTMLInputElement>) => React.ReactElement<any, any> //Renderer<CellProps<D, V>>
  inputValidator?: (value: string) => boolean
}

export const CustomizableEditableCell = <D extends object = {}, V = string | number>({
  children,
  value: initialValue,
  row: { index },
  column: { id },
  onCellUpdate, // This is a custom function that we supplied to our table instance,
  inputValidator
}: CustomizableEditableCellProps<D, V>): React.ReactElement<any, any> => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = (e) => {
    const inputValue = e.target.value

    if (inputValidator && inputValue) {
      if (inputValidator(inputValue)) {
        setValue(inputValue)
      }
    } else {
      setValue(inputValue)
    }
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    onCellUpdate?.(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return children({ value: value as any, onChange, onBlur })
}
