import { Form, InputGroup } from 'react-bootstrap'
import styled from 'styled-components'

export const StyledFormControl = styled(Form.Control)`
  background-color: ${({ theme }) => theme.colors.inputBackgroundColor};
  font-size: 1.4rem;
  border-radius: 0;
  padding: 1.5rem;
  height: 5rem;
  border: ${({ theme }) => theme.colors.borderInput};
  &:focus {
    box-shadow: 0 0 0 0.1rem rgb(192 191 190);
    border-color: #d4d4d4;
  }
`
export const StyledPasswordInput = styled(InputGroup)`
  background-color: ${({ theme }) => theme.colors.inputBackgroundColor};
  border-radius: 0;
  border: ${({ theme }) => theme.colors.borderInput};
  button {
    background-color: ${({ theme }) => theme.colors.inputBackgroundColor};
    border: none;
  }
  input {
    border: none;
  }
`

export const StyleInputEye = styled.i`
  align-self: center;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  position: absolute;
  z-index: 5;
  margin-left: 32rem;
`
