import { borderColorSecondary } from 'common/theme'
import styled from 'styled-components'

export const StyledDropdownList = styled.div`
  & {
    user-select: none;
    background-color: white;
  }

  &:hover {
    cursor: pointer;
  }

  .dropdown:focus,
  .dropdown:focus-visible,
  .dropdown:hover {
    background-color: #fff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 112 210 / 25%);

    .dropdown-title {
      border-color: #53afff;
    }
  }

  .dropdown-title {
    color: rgba(0, 0, 0, 0.5);
    font-weight: 600;
    width: 100%;
    text-align: left;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    font-size: 1.4rem;

    /* Keep the number equal bootstrap's default number */
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    border: 1px solid ${borderColorSecondary};
  }

  .dropdown-title:focus-visible {
    border-color: #53afff;
    background-color: #fff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 112 210 / 25%);
  }

  .dropdown-title:after {
    display: none;
  }

  .dropdown-select {
    padding-bottom: 0px;
    min-width: 100%;
    font-size: 1.4rem;
  }

  .dropdown-item {
    font-weight: 600;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);
    background-color: transparent;
    transition: all 167ms ease-in;
    position: relative;
    border-bottom: 2px solid white;
  }

  .dropdown-item.active,
  .dropdown-item:focus-visible,
  .dropdown-item:hover {
    outline: 0;
    border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  }
`
