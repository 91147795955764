import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

interface IProps {
  carouselSize: number
}

export const StyledSlideshowGallery = styled(Modal)<IProps>`
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  padding-left: 0 !important;

  .modal-dialog {
    max-width: unset;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .modal-content {
    height: 100%;
    border: 0;
    .modal-body {
      padding: 0;
    }
    .close-btn {
      position: absolute;
      right: 2rem;
      top: 0;
      z-index: 999999;
      background: #fff;
      border: 0;
      border-radius: 0 0 0.5rem 0.5rem;
      margin: 0;
      font-size: 2rem;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.headerFontColor};
      height: 5rem;
      width: 6rem;
    }
  }

  .modal-title.h4 {
    font-weight: 600;
  }

  .image-container {
    background-color: black;
    height: calc(100vh - 8rem);

    .transform-area,
    .react-transform-wrapper {
      width: 100%;
      height: 100%;
      .react-transform-component {
        position: absolute;
      }
      @media screen and (orientation: portrait) {
        img {
          max-width: 100vw;
        }
      }
      @media screen and (orientation: landscape) {
        img {
          height: calc(100vh - 8rem);
          width: auto;
        }
      }
    }

    .zoom-tools {
      position: absolute;
      top: 2rem;
      left: 2rem;
      .zoom-in-btn,
      .zoom-out-btn {
        font-size: 2rem;
        font-weight: bold;
        width: 4rem;
        z-index: 999999;
        border-radius: 0.5rem;
        background-color: transparent;
        opacity: 0.8;
        color: white;
        display: block;
        margin-bottom: 1rem;
      }

      .zoom-out-btn:hover,
      .zoom-in-btn:hover {
        cursor: pointer;
        opacity: 1;
      }
    }

    .previous-btn,
    .next-btn {
      position: absolute;
      width: 4rem;
      height: 4rem;
      text-align: center;
      padding: 0.5rem 0;
      border: 0.2rem solid white;
      border-radius: 0.5rem;
      opacity: 0.5;
      color: white;
      background-color: black;
      top: 40%;
    }

    .previous-btn {
      left: 2rem;
    }

    .next-btn {
      right: 2rem;
    }

    .previous-btn:hover,
    .next-btn:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
  .img-description {
    position: absolute;
    opacity: 0.5;
    color: white;
    font-size: 1.6rem;
    background: black;
    bottom: 15%;
    left: 2rem;
  }
  .slider-container {
    background-color: white;
    height: 10rem;
    bottom: 0;
    text-align: center;
    svg {
      color: ${({ theme }) => theme.colors.primary};
      width: 100%;
    }

    img {
      height: 7rem;
      max-width: 9rem;
      object-fit: cover;
      padding: 0.5rem;
      border: 2px solid ${({ theme }) => theme.colors.inputBorder};
      &.selected {
        border: 2px solid ${({ theme }) => theme.colors.primary};
      }
      :hover {
        cursor: pointer;
      }
    }
    .carousel-control-prev,
    .carousel-control-next {
      width: 8%;
      opacity: 1;
      top: 1rem;
    }

    .carousel-control-prev:hover,
    .carousel-control-next:hover {
      opacity: 0.5;
    }
    .carousel-inner {
      margin: 0.5rem 10% 0 10%;
      width: auto;
      align-items: center;
    }
    .carousel-item {
      .col {
        width: calc(100% / ${({ carouselSize }) => carouselSize});
        flex-grow: unset;
        flex-basis: unset;
      }
    }
  }
`
