import { apiRootPath, fetchAsync, postAsync } from 'common/fetch'
import { IContactUsReponse } from 'types/contactTypes'
import { IBuyerAuthorizationResponse, ISecretQuestionResponse } from 'types/rmsAuthModel'
import { IUserClaimReponse } from 'types/userTypes'
import { toUrlSearchParams } from 'utils/urlParams'
import { IContactUsData } from './../types/contactTypes'
import { IAuctionHistory, IAuctionHistoryRequest, IBaseResponse, IBaseCollectionResponse } from 'types/baseTypes'
import {
  ICustomerRetailInfo,
  IOrderHubStatus,
  IOrderHubStatusRequest,
  IPurchaseHistoryRequest,
  IPurchaseHistoryResult
} from 'types/purchaseHistoryTypes'

export const login = (username: string, password: string) => {
  const loginData = {
    grant_type: 'password',
    username: username,
    password: password
  }
  return postAsync<{ access_token: string; isFirstLogin: string }>('/token', {
    body: new URLSearchParams(loginData).toString(),
    type: 'form-data'
  })
}

export const validateBuyer = () => {
  return postAsync<IBuyerAuthorizationResponse>(`${apiRootPath}/auth/validatebuyer`)
}

export const getSecretQuestion = (username: string) => {
  return fetchAsync<ISecretQuestionResponse>(`${apiRootPath}/account/getsecretquestion?username=${username}`)
}
export const sendPasswordReset = (username: string, answer: string, questionId: number) => {
  const passwordReset = {
    username: username,
    answer: answer,
    questionCode: questionId
  }
  return fetchAsync<string>(`${apiRootPath}/account/sendpasswordreset?${toUrlSearchParams(passwordReset)}`)
}

export const getUserClaims = () => {
  return fetchAsync<IUserClaimReponse>(`${apiRootPath}/user/claims`)
}

export const getCsrfToken = () => {
  return fetchAsync<string>(`${apiRootPath}/auth/csrftoken`)
}

export const acceptNews = async (newsID: number, markAccept: boolean) => {
  return fetchAsync<boolean>(`/api/v1/account/acceptNews?ID=${newsID}&markAsAccept=${markAccept}`)
}

export const getContactUsDropDown = async (vendorId: number) => {
  return fetchAsync<IContactUsReponse>(`/api/dashboard/GetContactUsDropDowns?vendorId=${vendorId}`)
}

export const getValidatedVin = async (vendorId: number, vin: string) => {
  return postAsync<boolean>(`/api/dashboard/GetValidatedVin?vendorId=${vendorId}&vin=${vin}`)
}

export const sendContactUsEmail = async (request: IContactUsData) => {
  return postAsync<boolean>(`/api/dashboard/SendContactUsEmail`, { body: request })
}

export const getAuctionHistory = async (request: IAuctionHistoryRequest) => {
  return postAsync<IBaseCollectionResponse<IAuctionHistory>>(`api/account/GetUserAuctionHistory`, { body: request })
}
export const getOrderHubStatuses = async (request: IOrderHubStatusRequest) => {
  return postAsync<IBaseCollectionResponse<IOrderHubStatus>>(`api/account/GetOrderHubStatuses`, {
    body: request
  })
}

export const getPurchaseHistory = async (request: IPurchaseHistoryRequest) => {
  return postAsync<IBaseCollectionResponse<IPurchaseHistoryResult>>(`api/account/GetPurchaseHistory`, {
    body: request
  })
}

export const getManheimCheckoutURL = async () => {
  return postAsync<string>(`api/account/GetManheimCheckoutURL`)
}

export const saveDealerPurchaseRetailInfo = async (request: ICustomerRetailInfo) => {
  return postAsync<IBaseResponse<number>>(`/api/account/SaveDealerPurchaseRetailInfo`, {
    body: request
  })
}
