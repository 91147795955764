import { CSRF_HEADER, CSRF_TOKEN } from './constants'

interface IRequestInit<T> extends RequestInit {
  body?: T | any
  type?: 'json' | 'blob' | 'form-data'
}

export const apiRootPath = '/api/v1'

/**
 * Fetching a resource from the network
 * @param url
 * @param input
 * @param timeout - Set timeout for fetch() function, in miliseconds. No timeout will be set if timeout value is null, undefined or 0.
 * @returns A promise of type T.
 */
export const fetchAsync = async <T>(url: string, input?: IRequestInit<T>, timeout?: number): Promise<T> => {
  let { body, type = 'json', headers } = input || {}
  let response: Response
  // Construct headers
  headers = { ...headers, [CSRF_HEADER]: localStorage.getItem(CSRF_TOKEN) }

  if (type === 'json') {
    headers = {
      ...headers,
      'Content-Type': 'application/json'
    }
  }
  if (type === 'form-data') {
    headers = {
      ...headers,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
  }

  // Construct body
  if (body && type === 'json') {
    body = JSON.stringify(body)
  }

  // Handle timeout
  if (timeout) {
    const abortController = new AbortController()

    const timer = setTimeout(() => abortController.abort(), timeout)

    try {
      response = await fetch(url, { ...input, body, headers, signal: abortController.signal })
    } catch (error) {
      throw error
    } finally {
      clearTimeout(timer)
    }
  } else {
    response = await fetch(url, { ...input, body, headers })
  }

  // Handle session expired
  if (response.status === 401 && window.location.pathname.toUpperCase() !== '/LOGIN') {
    window.location.href = '/login?returnUrl=' + encodeURIComponent(window.location.pathname) + '&errorCode=401'
    return
  }

  // Determine return type
  let result: T
  const contentType = response.headers.get('content-type')
  if (/application\/json/.test(contentType)) {
    result = (await response.json()) as T
  } else if (type === 'blob') {
    result = {
      ...response,
      body: await response.blob()
    } as any
  } else {
    result = {
      ...response,
      body: await response.text()
    } as any
  }

  if (!response.ok) {
    throw result
  }

  return result
}

export const postAsync = async <TResponse, TBody = void>(
  url: string,
  input?: IRequestInit<TBody>,
  timeout?: number
): Promise<TResponse> => {
  return fetchAsync<TResponse>(url, { ...input, method: 'POST' }, timeout)
}
