import { StyledSlideOutElement } from './SlideOut.styled'
import { useGlobalStore } from 'store/useGlobalStore'
import parse from 'html-react-parser'
import { HTMLText } from 'components/Share/HtmlText'
import { Spinner, OverlayTrigger, Popover } from 'react-bootstrap'
import { IIncentive, IVehicleCharges } from 'types/vehicleTypes'
import { useRef } from 'react'

interface IProps {
  feeLoading: boolean // loader for generic buyer premium
  buyerFeeString: string
  deliveryCharge: number
  vehicleCharges: IVehicleCharges[]
  isIncentiveAccumulate: boolean
  incentives: IIncentive[]
  estimatedGrossPrice: number
}

export const SlideOutPricingDetails = ({
  buyerFeeString,
  deliveryCharge,
  estimatedGrossPrice,
  vehicleCharges,
  isIncentiveAccumulate,
  incentives,
  feeLoading
}: IProps) => {
  const getLocalText = useGlobalStore((state) => state.getLocalText)
  const slideOutElementRef = useRef(null)
  return (
    <StyledSlideOutElement ref={slideOutElementRef}>
      <p>
        <b>Total Transaction Charges:</b>
        &nbsp;
        {feeLoading ? <Spinner size="sm" animation="border"></Spinner> : buyerFeeString || 'N/A'}
        &nbsp;
        <OverlayTrigger
          rootClose={true}
          container={slideOutElementRef}
          trigger="click"
          placement="bottom"
          overlay={
            <Popover id="popover-basic">
              <Popover.Title as="h3">{getLocalText('VIEW_FEES_TEXT')}</Popover.Title>
              <Popover.Content>{getLocalText('VIEW_FEES_INFORMATION')}</Popover.Content>
            </Popover>
          }
        >
          <span className="fee-info">({getLocalText('VIEW_FEES_TEXT')})</span>
        </OverlayTrigger>
      </p>
      <p>
        <b>Total Delivery Charge:</b> {deliveryCharge || 'N/A'}
      </p>
      {vehicleCharges?.map((charge, index) => (
        <p key={index}>
          <b>{charge.VehicleChargeTypeDescription}:</b> {charge.PriceString}
        </p>
      ))}
      {!isIncentiveAccumulate &&
        incentives?.map((incentive, index) => (
          <p key={index}>
            <b>{incentive.Name}:</b> {incentive.AmountString}
          </p>
        ))}
      <p>
        <b>Estimated Gross Price:</b>&nbsp;
        {isNaN(estimatedGrossPrice) ? (
          'N/A'
        ) : feeLoading ? (
          <Spinner size="sm" animation="border"></Spinner>
        ) : (
          '$' + new Intl.NumberFormat().format(estimatedGrossPrice)
        )}
      </p>
      <HTMLText value={parse(getLocalText('BiddingSidePanelAgreeTermsAndConditions'))}></HTMLText>
    </StyledSlideOutElement>
  )
}
