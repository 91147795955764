import { SELECTED_SEARCH_FILTERS_LIMIT, SessionStorageKey } from 'common/constants'
import { Tag } from 'components/Tag/Tag'
import { ISearchFilterResult } from 'types/vehicleTypes'
import { useLayoutEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { StyledSelectedSearchFilters, StyledTagContainer } from './SelectedSearchFilters.styled'

interface IProps {
  filters: ISearchFilterResult[]
  onRemoveFilter?: (fieldId: number, filterKey: string) => void
  onCollapsed?: (show: boolean) => void
}
export const SelectedSearchFilters = ({ filters, onRemoveFilter, onCollapsed }: IProps) => {
  const [showMore, setShowMore] = useState(false)
  const hiddenFiltersLength = filters?.length - SELECTED_SEARCH_FILTERS_LIMIT
  const displayShowMore = filters.length > SELECTED_SEARCH_FILTERS_LIMIT && !showMore
  const disPlayShowLess = filters.length > SELECTED_SEARCH_FILTERS_LIMIT && showMore

  const handleCollapse = () => {
    setShowMore(!showMore)
  }

  useLayoutEffect(() => {
    onCollapsed?.(showMore)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMore])

  return (
    <StyledSelectedSearchFilters>
      <div className="selected-filter-label">
        {sessionStorage.getItem(SessionStorageKey.SEARCH_DESCRIPTION) || 'SELECTED FILTERS:'}
      </div>
      <StyledTagContainer>
        {filters.slice(0, displayShowMore ? SELECTED_SEARCH_FILTERS_LIMIT : filters.length).map((filter) => (
          <Tag
            key={filter.FilterKey}
            title={
              <>
                <span>{filter.FriendlyValue}</span> &nbsp;
                <FontAwesomeIcon
                  className="tag-icon"
                  onClick={() => onRemoveFilter(filter.FieldId, filter.FilterKey)}
                  icon={faTimesCircle}
                />
              </>
            }
          />
        ))}

        {displayShowMore && (
          <Tag
            className="show-more"
            onClick={handleCollapse}
            title={
              <>
                <span>Show {hiddenFiltersLength} more </span> &nbsp;
                <FontAwesomeIcon icon={faPlus} />
              </>
            }
          />
        )}
        {disPlayShowLess && (
          <Tag
            className="show-more"
            onClick={handleCollapse}
            title={
              <>
                <span>Show less </span> &nbsp;
                <FontAwesomeIcon icon={faMinus} />
              </>
            }
          />
        )}
      </StyledTagContainer>
    </StyledSelectedSearchFilters>
  )
}
