import { chunk } from 'lodash'
import { Fragment, useEffect, useState, useLayoutEffect, useContext } from 'react'
import { Carousel, Col, Modal, Row } from 'react-bootstrap'
import { IVehicleImage } from 'types/vehicleTypes'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { StyledSlideshowGallery } from './SlideShowGallery.styled'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useWindowSize from 'hooks/useWindowSize'
import { BIG_SLIDER_ITEM_SIZE, fullScreenIcon, HUGE_SLIDER_ITEM_SIZE, MEDIUM_SLIDER_ITEM_SIZE } from 'common/constants'
import { SliderContext } from 'contexts/SliderContext'
import { extraExtraLargeScreenSize, mediumScreenSize } from 'common/theme'

interface ISlideshowGalleryProps {
  show: boolean
  onHide: any
  images: IVehicleImage[]
  defaultID: number
  showCarousel: boolean
}

export const SlideshowGallery = ({ show, onHide, images, defaultID, showCarousel }: ISlideshowGalleryProps) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const [pageActive, setPageActive] = useState(0)
  const [pageSize, setPageSize] = useState(BIG_SLIDER_ITEM_SIZE)
  const [pageItems, setPageItems] = useState<IVehicleImage[][]>(null)
  const { setCurrentImageID } = useContext(SliderContext)
  const { width } = useWindowSize()

  useLayoutEffect(() => {
    if (width <= mediumScreenSize) {
      setPageSize(MEDIUM_SLIDER_ITEM_SIZE)
    } else if (width <= extraExtraLargeScreenSize) {
      setPageSize(BIG_SLIDER_ITEM_SIZE)
    } else setPageSize(HUGE_SLIDER_ITEM_SIZE)
    setPageItems(chunk(images, pageSize))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images, pageSize, width])

  useEffect(() => {
    const imageIndex = images?.findIndex((x) => x.ID === defaultID)
    const pageItemsIndex = pageItems?.findIndex((x) => x.find((y) => y.ID === defaultID))
    setSelectedImageIndex(imageIndex === -1 ? 0 : imageIndex)
    setPageActive(pageItemsIndex === -1 ? 0 : pageItemsIndex)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultID, pageItems])

  const handleImageChange = (imageIndex: number) => {
    setSelectedImageIndex(pageActive * pageSize + imageIndex)
    setCurrentImageID(images[pageActive * pageSize + imageIndex]?.ID)
  }

  const handleActivePage = (selectedIndex: number) => {
    setPageActive(selectedIndex)
  }

  const handleGoNext = () => {
    if (selectedImageIndex + 1 >= images.length) {
      setSelectedImageIndex(0)
      setPageActive(0)
    } else {
      setSelectedImageIndex(selectedImageIndex + 1)
      const imageID = images[selectedImageIndex + 1].ID
      setPageActive(pageItems.findIndex((x) => x.find((y) => y.ID === imageID)))
      setCurrentImageID(imageID)
    }
  }

  const handleGoPrevious = () => {
    if (selectedImageIndex - 1 < 0) {
      setPageActive(pageItems.length - 1)
      setSelectedImageIndex(images.length - 1)
      setCurrentImageID(images[images.length - 1]?.ID)
    } else {
      setSelectedImageIndex(selectedImageIndex - 1)
      const imageID = images[selectedImageIndex - 1].ID
      setPageActive(pageItems.findIndex((x) => x.find((y) => y.ID === imageID)))
      setCurrentImageID(imageID)
    }
  }

  return (
    <StyledSlideshowGallery
      show={show}
      onHide={() => onHide()}
      backdrop="static"
      carouselSize={pageSize}
      isShowCarousel={showCarousel}
    >
      <button className={'close-btn'} onClick={() => onHide()}>
        X
      </button>
      <Modal.Body>
        <div className="image-container">
          <TransformWrapper
            centerOnInit={true}
            initialScale={1}
            alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
            zoomAnimation={{
              disabled: true
            }}
          >
            {({ zoomIn, zoomOut, resetTransform }) => (
              <Fragment>
                <div className="transform-area" onLoad={() => resetTransform(0)}>
                  <TransformComponent>
                    <img
                      src={images[selectedImageIndex]?.ImageURL ?? images[selectedImageIndex]?.LocalImageURL}
                      alt=""
                    />
                  </TransformComponent>
                </div>
                <div className="zoom-tools">
                  <img
                    src={fullScreenIcon.zoomInLogo}
                    alt="zoom in"
                    className="zoom-in-btn"
                    onClick={(e) => zoomIn()}
                  />
                  <img
                    src={fullScreenIcon.zoomOutLogo}
                    alt="zoom out"
                    className="zoom-out-btn"
                    onClick={() => zoomOut()}
                  />
                </div>
                <span
                  className="previous-btn"
                  onClick={() => {
                    handleGoPrevious()
                    resetTransform(0)
                  }}
                >
                  <FontAwesomeIcon icon={faChevronLeft} size="2x" />
                </span>
                <span
                  className="next-btn"
                  onClick={() => {
                    handleGoNext()
                    resetTransform(0)
                  }}
                >
                  <FontAwesomeIcon icon={faChevronRight} size="2x" />
                </span>
                <span className="img-description">
                  {images[selectedImageIndex]?.DamageImageDescription || images[selectedImageIndex]?.Description}
                </span>
              </Fragment>
            )}
          </TransformWrapper>
        </div>
        {images?.length > 0 && showCarousel && (
          <div className="slider-container">
            <Carousel
              activeIndex={pageActive}
              indicators={false}
              interval={null}
              onSelect={handleActivePage}
              nextIcon={<FontAwesomeIcon icon={faChevronRight} size="2x" />}
              prevIcon={<FontAwesomeIcon icon={faChevronLeft} size="2x" />}
            >
              {pageItems.map((images, pageIndex) => (
                <Carousel.Item key={pageIndex}>
                  <Row noGutters>
                    {images.map((item, imageIndex) => (
                      <Col key={imageIndex}>
                        <LazyLoadImage
                          className={selectedImageIndex === pageIndex * pageSize + imageIndex ? 'selected' : ''}
                          src={item.ImageURL ? item.ImageURL : item.LocalImageURL}
                          effect="blur"
                          alt={item.Description}
                          onClick={() => {
                            handleImageChange(imageIndex)
                          }}
                          title={item.DamageImageDescription || item.Description}
                        />
                      </Col>
                    ))}
                  </Row>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        )}
      </Modal.Body>
    </StyledSlideshowGallery>
  )
}
