import { BidsHistoryModal } from 'modules/BuyerActivity/BidsHistoryModal'
import { StyledAuctionText, StyledNumberOfBids } from 'modules/BuyerActivity/VehicleAuction.styled'
import { useContext, useState } from 'react'
import { VehicleContext } from './VehicleContext'

export const VehicleBidHistoryLink = () => {
  const [showBidsHistory, setShowBidsHistory] = useState(false)
  const {
    vehicleData: { vehicle: vehicleInfo, auctionInfo }
  } = useContext(VehicleContext)

  return (
    <>
      {auctionInfo && !auctionInfo.IsDirectSale && (
        <>
          <StyledAuctionText>
            <StyledNumberOfBids count={auctionInfo.NumberOfBids} onClick={() => setShowBidsHistory(true)}>
              {auctionInfo.NumberOfBids}
            </StyledNumberOfBids>
            {' Bids '}
          </StyledAuctionText>
          <BidsHistoryModal
            show={showBidsHistory}
            onHide={() => setShowBidsHistory(false)}
            size="lg"
            centered
            auctionInfo={auctionInfo}
            vehicleInfo={vehicleInfo}
            onClose={() => {
              setShowBidsHistory(false)
            }}
          />
        </>
      )}
    </>
  )
}
