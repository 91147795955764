import styled from 'styled-components'
import { Col, Button, Form } from 'react-bootstrap'

export const SaveClearAllFilterGroupAction = styled.div`
  display: flex;
  justify-content: center;

  .btn {
    font-size: 1.6rem;
    padding-right: 0;
    padding-left: 0;

    &:disabled {
      color: ${({ theme }) => theme.colors.headerFontColor};
    }
  }
`
export const SaveCancelGroupAction = styled(Col)`
  display: flex;
  justify-content: center;

  .btn {
    font-size: 1.4rem;
  }
`

export const SaveLink = styled(Button)`
  color: ${({ theme }) => theme.colors.primary};

  &:active,
  &:hover {
    color: ${({ theme }) => theme.colors.linkColorLight};
  }

  padding-right: 0.5rem;
  padding-left: 0;
`

export const SaveSearchContainer = styled.div`
  background: #fff;
  padding: 1rem 0;

  .btn-link {
    color: ${({ theme }) => theme.colors.primary};

    &:active,
    &:hover {
      color: ${({ theme }) => theme.colors.linkColorLight};
    }
  }

  .form-check-input {
    margin-left: -2rem;
  }

  .btn {
    font-weight: 600;
  }

  .btn:is(:hover, :focus, :active) {
    box-shadow: none;
    text-decoration: none;
  }
`
export const NotifyContainer = styled(Col)`
  display: flex;
  gap: 2rem;
`

export const SaveSearchFormBody = styled(Form)<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
`

export const ActionSeparator = styled.span`
  font-weight: 600;
  font-size: 1.6rem;
  padding: 0.375rem 0.75rem;

  &::before {
    content: ' | ';
  }
`
