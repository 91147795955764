import styled from 'styled-components'

export const StyledAccordionTitle = styled.div``
export const StyledAccordionContent = styled.div`
  padding: 1rem;
`

export const StyledAccordion = styled.div`
  padding: 1rem;

  &.normal-title ${StyledAccordionTitle} {
    text-transform: none;
  }
  ${StyledAccordionTitle} {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const StyledCustomAccordion = styled.div`
  border-bottom: 0.1rem solid #e5e5e5;
  &:hover {
    border-bottom: 0.25rem solid ${({ theme }) => theme.colors.primary};
  }
`
