import { faFile, faFileImage, faFilePdf, faFileVideo } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getMultipleVehicleDocuments } from 'apis/vehicleApis'
import { Loader } from 'components/Loader'
import { forwardRef } from 'react'
import { Popover, PopoverProps } from 'react-bootstrap'

import { useFetch } from '../hooks/useFetch'
import { AttachFilePopoverStyled } from './PopoverAttachment.styled'
import { DocumentFileType } from 'types/purchaseHistoryTypes'

interface IProps extends Omit<PopoverProps, 'id'> {
  vehicleInstanceID: number
  VIN: string
}

export const PopoverAttachment = forwardRef(({ vehicleInstanceID, VIN, ...rest }: IProps, ref) => {
  const { loading, data: documents } = useFetch(() => getMultipleVehicleDocuments(vehicleInstanceID))

  return (
    <AttachFilePopoverStyled id="attachment-popover" ref={ref} {...rest}>
      <Popover.Title>Attached Files</Popover.Title>
      <Popover.Content>
        VIN: {VIN}
        {loading && <Loader />}
        {!loading && documents?.length > 0 && (
          <div className="attachments-list">
            {documents.map(({ ID, DocumentDescription, FileName, DocumentURL }) => (
              <div key={`file${ID}`} className="attachment-item">
                <div className="download-link" onClick={() => window.open(DocumentURL, "_parent'")}>
                  {!!DocumentDescription && (
                    <FontAwesomeIcon
                      icon={
                        DocumentDescription === DocumentFileType.PDF
                          ? faFilePdf
                          : DocumentDescription === DocumentFileType.Image
                          ? faFileImage
                          : DocumentDescription === DocumentFileType.Video
                          ? faFileVideo
                          : faFile
                      }
                    />
                  )}{' '}
                  {FileName}
                </div>
              </div>
            ))}
          </div>
        )}
      </Popover.Content>
    </AttachFilePopoverStyled>
  )
})
