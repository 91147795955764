import { quickSearch } from 'apis/vehicleApis'
import { SessionStorageKey, SRP_FILTERS_QUERY, SRP_INIT_QUERY } from 'common/constants'
import { useFetch } from 'hooks/useFetch'
import { useState, createContext } from 'react'
import {
  ISearchFilterResult,
  ISearchResponseData,
  ISearchVehicleResponse,
  ISearchVehiclesRequest
} from 'types/vehicleTypes'
import { parseUrlSearchParams } from 'utils/urlParams'
import { useLocation } from 'react-router-dom'
import { parseJson } from 'utils/utils'
import { useContext } from 'react'
import { VehicleListContext } from 'modules/VehicleList'

export interface IVehicleSearchState {
  queryRequest: ISearchVehiclesRequest
  updateFilters: (filters: ISearchResponseData[]) => void
  filtersData: ISearchResponseData[]
  showSlideOutFilterSearch: boolean
  setShowSlideOutFilterSearch: (value: boolean) => void
  closeShowSlideOutFilterSearch: () => void
}
export interface ISRPSearchParams {
  clearSearch?: string
}

export const FilterSearchContext = createContext<IVehicleSearchState>(null)

export const FilterSearchContextProvider = ({ children }) => {
  const location = useLocation()
  const query = parseUrlSearchParams<ISRPSearchParams>(location.search)
  const { setVehiclesState } = useContext(VehicleListContext)
  const [showSlideOutFilterSearch, setShowSlideOutFilterSearch] = useState(false)

  const sessionSearchFilters: ISearchFilterResult[] = query.clearSearch
    ? []
    : parseJson(window.sessionStorage.getItem(SessionStorageKey.SEARCH_FILTERS))

  const [queryRequest, setQueryRequest] = useState(
    sessionSearchFilters
      ? {
          ...SRP_INIT_QUERY,
          SearchQuery: { ...SRP_INIT_QUERY.SearchQuery, SearchFilters: sessionSearchFilters }
        }
      : SRP_INIT_QUERY
  )

  const [filterRequest, setFilterRequest] = useState(
    sessionSearchFilters
      ? {
          ...SRP_FILTERS_QUERY,
          SearchQuery: { ...SRP_FILTERS_QUERY.SearchQuery, SearchFilters: sessionSearchFilters }
        }
      : SRP_FILTERS_QUERY
  )

  const updateFilters = (filters: ISearchResponseData[]) => {
    setQueryRequest(
      (req) => ({ ...req, SearchQuery: { ...req.SearchQuery, SearchFilters: filters } } as ISearchVehiclesRequest)
    )
    setFilterRequest(
      (req) => ({ ...req, SearchQuery: { ...req.SearchQuery, SearchFilters: filters } } as ISearchVehiclesRequest)
    )

    sessionStorage.setItem(SessionStorageKey.SEARCH_FILTERS, JSON.stringify(filters))
    setVehiclesState((state) => {
      state.selectedVehicles = []
      state.currentPage = 0
    })
    setShowSlideOutFilterSearch(false)
  }

  const { data: { Data: filtersData } = {} as ISearchVehicleResponse } = useFetch(
    () => quickSearch(filterRequest),
    [filterRequest]
  )

  const closeShowSlideOutFilterSearch = () => {
    setShowSlideOutFilterSearch(false)
  }

  return (
    <FilterSearchContext.Provider
      value={{
        queryRequest,
        updateFilters,
        filtersData,
        showSlideOutFilterSearch,
        setShowSlideOutFilterSearch,
        closeShowSlideOutFilterSearch
      }}
    >
      {children}
    </FilterSearchContext.Provider>
  )
}
