import { isReactRoutes } from 'common/routes'
import React, { PropsWithChildren } from 'react'
import { NavLink as BsNavLink } from 'react-bootstrap'
import { Link as ReactLink } from 'react-router-dom'
const classNames = require('classnames')

interface IProps extends PropsWithChildren<{}> {
  to: string
  className?: string
  onClick?: (e?: any) => void
  target?: string
}
export const NavLink = ({ to, children, className, ...props }: IProps) => {
  const isReactPath = isReactRoutes(to)

  return isReactPath ? (
    <ReactLink to={to} className={classNames('nav-link', className)} {...props}>
      {children}
    </ReactLink>
  ) : (
    <BsNavLink href={to} className={classNames(className)} {...props}>
      {children}
    </BsNavLink>
  )
}

export const Link = ({ to, children, className, ...props }: IProps) => {
  const isReactPath = isReactRoutes(to)

  return isReactPath ? (
    <ReactLink to={to} className={classNames(className)} {...props}>
      {children}
    </ReactLink>
  ) : (
    <a href={to} className={classNames(className)} {...props}>
      {children}
    </a>
  )
}
