import { faExternalLinkAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Rules } from 'common/rules'
import { SystemSettingsCode, isSystemSettingsEnabled } from 'common/systemSettingsCode'
import { PopupLink } from 'components/Share/PopupLink'
import { useDtmAnalytics } from 'hooks/useDtmAnalytics'
import { WatchlistLink } from 'modules/Watchlist/WatchlistLink'
import { useContext } from 'react'
import { Button } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { useGlobalStore } from 'store/useGlobalStore'
import styled from 'styled-components'
import { IVDPSearchParams } from 'types/ISearchParams'
import { IVehicleData } from 'types/vehicleTypes'
import { parseUrlSearchParams } from 'utils/urlParams'
import { VehicleContext } from './VehicleContext'

export const StyledVehicleAction = styled.div``
interface IProps {
  vehicleData: IVehicleData
}

export const VehicleActions = ({ vehicleData }: IProps) => {
  const {
    vehicleData: {
      vehicle: { VehicleNote }
    },
    setEditingNote,
    retailView
  } = useContext(VehicleContext)
  const isInRule = useGlobalStore((state) => state.isInRule)
  const showCarfax = isInRule(Rules.ENABLE_CARFAX)
  const location = useLocation()
  const hideBuyOptions = parseUrlSearchParams<IVDPSearchParams>(location.search).history === 'true'
  const showTrue360 = isSystemSettingsEnabled(SystemSettingsCode.SHOW_TRUE_360) && !hideBuyOptions && !retailView

  const { userInteraction } = useDtmAnalytics()

  const handleAnalyticCarfax = () => {
    userInteraction(`${window.webData.pageName} : carfax`, [
      { auctionInfo: vehicleData.auctionInfo, VIN: vehicleData.vehicle.VIN }
    ])
  }

  const handleEditNote = () => {
    setEditingNote(true)
    userInteraction(`${window.webData.pageName} : ${!!VehicleNote ? 'Edit Note' : 'Add Note'}`, [
      { auctionInfo: vehicleData.auctionInfo, VIN: vehicleData.vehicle.VIN }
    ])
  }

  return (
    <StyledVehicleAction className="link-col">
      {showTrue360 && (
        <div
          className="true-frame-link"
          key={vehicleData?.vehicle?.VIN}
          data-tf-vin={`${vehicleData?.vehicle?.VIN}`}
          data-tf-type="tf360srp"
        ></div>
      )}

      {showCarfax && (
        <PopupLink
          onClick={handleAnalyticCarfax}
          href={`/external/getcarfaxreport?vin=${vehicleData.vehicle.VIN}`}
          title={'Click to open CARFAX details'}
        >
          CARFAX&nbsp;
          <FontAwesomeIcon className="icon" icon={faExternalLinkAlt} />
        </PopupLink>
      )}
      <Button as={'a'} variant={'link'} onClick={() => handleEditNote()}>
        <FontAwesomeIcon className="icon" icon={faPencilAlt} />
        {!!VehicleNote ? 'Edit Note' : 'Add Note'}
      </Button>
      {!hideBuyOptions && <WatchlistLink />}
    </StyledVehicleAction>
  )
}
