import { Row, Col } from 'react-bootstrap'
import { StyledDatePicker } from './DatePicker.styled'
import DateTimePicker from 'components/DateTimePicker/DateTimePicker'

interface IProps {
  fromDate: Date
  setFromDate: (fromDate: Date) => void
  toDate: Date
  setToDate: (toDate: Date) => void
  disabledMaxDate?: boolean
}

export const DateRangePicker = ({ fromDate, setFromDate, toDate, setToDate, disabledMaxDate }: IProps) => {
  const dateFromChangeHandler = (date: Date) => {
    if (date) {
      setFromDate(date)
    }
  }

  const dateToChangeHandler = (date: Date) => {
    if (date) {
      setToDate(date)
    }
  }

  return (
    <StyledDatePicker className="date-picker">
      <Row>
        <Col className="date-picker-from">
          <div className="date-picker-container">
            <DateTimePicker
              className="date-picker-input form-control"
              selected={fromDate}
              onChange={(e) => dateFromChangeHandler(e)}
              maxDate={disabledMaxDate ? false : new Date()}
            />
          </div>
        </Col>
        <Col className="date-picker-to">
          <div className="date-picker-container">
            <DateTimePicker
              className="date-picker-input form-control"
              selected={toDate}
              onChange={(date) => dateToChangeHandler(date)}
              maxDate={disabledMaxDate ? false : new Date()}
            />
          </div>
        </Col>
      </Row>
    </StyledDatePicker>
  )
}
