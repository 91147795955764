import { saveVehicleLevelWatchList } from 'apis/dashboardApis'
import { VehicleDetailsView } from 'common/constants'
import { useDtmAnalytics } from 'hooks/useDtmAnalytics'
import { createContext, PropsWithChildren, useState } from 'react'
import { useVehicleStore } from 'store/useVehicleStore'
import { SellerDisclosure } from 'types/inspectionTypes'
import { IVehicleData } from 'types/vehicleTypes'

export interface IVehicleContextProps {
  vehicleData: IVehicleData
  showRemoveBid?: boolean
  onRemoveBid?: () => void
  errorMessage?: string
  isVDP?: boolean
  loading?: boolean
  selfInspection?: SellerDisclosure
  retailView?: boolean
  viewStyle?: VehicleDetailsView
}

export interface IVehicleContext extends IVehicleContextProps {
  showRemoveBid?: boolean
  editingNote: boolean
  setEditingNote: (editing: boolean) => void
  vehicleWatchListState: IVehicleWatchListState
}

export const VehicleContext = createContext<IVehicleContext>(null)

interface IVehicleWatchListState {
  isSaving?: boolean
  saveWatchList: () => void
}

export const VehicleContextProvider = ({
  vehicleData,
  showRemoveBid,
  onRemoveBid,
  errorMessage,
  children,
  isVDP,
  selfInspection,
  loading,
  retailView,
  viewStyle
}: PropsWithChildren<IVehicleContextProps>) => {
  const [editingNote, setEditingNote] = useState<boolean>(false)
  const [isSavingWatchList, setIsSavingWatchList] = useState(false)
  const { updateVehicleData } = useVehicleStore((state) => state)
  const { userInteraction } = useDtmAnalytics()

  const saveWatchList = async () => {
    try {
      setIsSavingWatchList(true)
      const response = await saveVehicleLevelWatchList({
        VehicleInstanceID: vehicleData.vehicle.InstanceID,
        RemoveVehicle: !!vehicleData.vehicle.WatchListID,
        SaleChannelID: vehicleData.vehicle.SaleChannelID,
        SaleEventID: vehicleData.vehicle.SaleEventID
      })
      vehicleData.vehicle.WatchListID = response.Value
      const customName = vehicleData.vehicle.WatchListID ? 'Add to Watchlist' : 'Remove from Watchlist'
      userInteraction(`${window.webData.pageName} : ${customName}`, [
        { auctionInfo: vehicleData.auctionInfo, VIN: vehicleData.vehicle.VIN }
      ])

      updateVehicleData(vehicleData)
    } catch (error) {
      console.log('SAVE WATCHLIST ERROR: ' + error)
    } finally {
      setIsSavingWatchList(false)
    }
  }

  return (
    <VehicleContext.Provider
      value={{
        vehicleData,
        showRemoveBid,
        editingNote,
        setEditingNote,
        vehicleWatchListState: {
          isSaving: isSavingWatchList,
          saveWatchList
        },
        onRemoveBid,
        errorMessage,
        isVDP,
        selfInspection,
        retailView,
        loading,
        viewStyle
      }}
    >
      {children}
    </VehicleContext.Provider>
  )
}
