import { faPhone, faQuestionCircle, faSearch, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconSvg } from '@prism/icon'
import { RDPAccountSettingIcon } from 'images/icon/AccountSettingIcon'
import { CurrentBidIcon } from 'images/icon/CurrentBidIcon'
import { SaveSearchIcon } from 'images/icon/SaveSearchIcon'
import { AdvanceSearchIcon } from '../images/icon/AdvanceSearchIcon'

export const iconDict = {
  RDP_SearchResults: <FontAwesomeIcon icon={faSearch} />,
  RDP_SearchResults_2: <FontAwesomeIcon icon={faSearch} />,
  RDP_AdvancedSearch: <AdvanceSearchIcon />,
  RDP_SavedSearch: <SaveSearchIcon />,
  RDP_WatchList: <IconSvg glyph="eye" />,
  RDP_CurrentBids: <CurrentBidIcon />,
  RDP_WatchList_2: <IconSvg glyph="eye" />,
  RDP_CurrentBids_2: <CurrentBidIcon />,
  RDP_MyProfile: <FontAwesomeIcon icon={faUser} />,
  RDP_ContactUs: <FontAwesomeIcon flip="horizontal" icon={faPhone} />,
  RDP_AccountSettings: <RDPAccountSettingIcon />,
  FAQ: <FontAwesomeIcon icon={faQuestionCircle} />,
  Logout: <FontAwesomeIcon icon={faSignOutAlt} />
}
