import { PageSizeOptions, PAGINATION_ITEM_SIZE } from 'common/constants'
import { DropdownList } from 'layouts/DropdownList'
import { useState } from 'react'
import { Pagination, PaginationProps } from 'react-bootstrap'
import { generatePageItems } from 'utils/pagingUtils'
import { StyledPagination } from './Paging.styled'
import { OverlayEllipsis } from './OverlayEllipsis'

interface IProps extends PaginationProps {
  currentPageSize?: number
  totalPages: number
  currentPage: number
  showRecordsPerPage?: boolean
  handlePageSizeChanged?: (pageSizeSelected: number) => void
  handleChangedPage?: (pageNum: number) => void
}

export const Paging = ({
  currentPageSize,
  handlePageSizeChanged,
  totalPages,
  currentPage,
  showRecordsPerPage = true,
  handleChangedPage,
  ...props
}: IProps) => {
  const [goToPageValue, setGoToPageValue] = useState<number>(currentPage + 1)

  const onChangedPageValue = (pageNum: number) => {
    if (pageNum > totalPages) setGoToPageValue(totalPages)
    else if (pageNum < 1) setGoToPageValue(1)
    else setGoToPageValue(pageNum)
  }

  const pageCount = generatePageItems(totalPages, currentPage + 1, PAGINATION_ITEM_SIZE).map((item) =>
    typeof item === 'number' ? (
      <Pagination.Item
        key={item}
        active={item === currentPage}
        onClick={() => {
          handleChangedPage?.(item + 1)
        }}
      >
        {item + 1}
      </Pagination.Item>
    ) : (
      <OverlayEllipsis
        key="ellipsis"
        toPage={goToPageValue}
        handleChangedPage={handleChangedPage}
        onChangedPageValue={onChangedPageValue}
        totalPages={totalPages}
      />
    )
  )

  return (
    <StyledPagination>
      {showRecordsPerPage && (
        <div className="record-per-page">
          <strong>Records per page:</strong>
          <DropdownList
            defaultValue={currentPageSize?.toString()}
            items={PageSizeOptions?.map((size) => ({ id: size.toString(), value: size.toString() }))}
            onChange={handlePageSizeChanged}
          />
        </div>
      )}
      <Pagination as="div" {...props}>
        {pageCount}
      </Pagination>
    </StyledPagination>
  )
}

export * from './Paging.styled'
