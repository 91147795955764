import { zIndex } from 'common/constants'
import { gridBreakPoints } from 'common/theme'
import styled, { keyframes } from 'styled-components'

const slideInFromRightEffect = keyframes`
  0% {transform: translate(100%);}
  100% { transform: translateX(0%); }
`

const slideInFromLeftEffect = keyframes`
  0% {transform: translate(-100%);}
  100% { transform: translateX(0%); }
`
export const StyledSlideOut = styled.div`
  .close_button {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
    border: hidden;
    background-color: white;
  }
  .close_icon:hover {
    fill: black;
  }
  .slide-out-wrapper {
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    height: 100%;
    right: 0;
    opacity: 1;
    position: fixed;
    top: 0;
    transition: opacity 0.25s linear;
    width: 100%;
    z-index: ${zIndex.modal};
  }

  .slide-out-content {
    background-color: #fff;
    border: 1px solid #4a4a4a;
    bottom: 0;
    max-width: 100vw;
    padding: 4rem 2.4rem 2.4rem;
    position: fixed;
    top: 0;
    width: 30vw;

    @media (max-width: ${gridBreakPoints.xl}) {
      width: 40%;
    }

    @media (max-width: ${gridBreakPoints.sm}) {
      width: 100%;
    }

    right: 0;
    z-index: ${zIndex.modal};
    overflow: auto;
    .slide-out-body {
      height: 100%;
    }
    box-shadow: rgb(0 0 0 / 20%) -1px 4px 9px 6px;
    .form {
      height: 100%;
      display: flex;
      flex-direction: column;
      .form-check {
        margin-bottom: 0.3rem;
        .form-check-input {
          top: -1px;
        }
      }
    }
    .action-buttons {
      margin-top: auto;
      display: flex;
      flex-direction: column;
      .button-group {
        margin-top: 0.4rem;
        display: flex;
        .btn {
          width: 16rem;
        }
      }
    }
  }

  &.place-left .slide-out-content {
    animation: ${slideInFromLeftEffect} 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
  &.place-right .slide-out-content {
    animation: ${slideInFromRightEffect} 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
`

export const StyledSlideOutElement = styled.div`
  margin-bottom: 1.6rem;
  .form-group {
    margin-bottom: 0;
  }
  .form-btn {
    padding: 0.6rem 3rem;
  }
  .form-check-label {
    margin-left: 0.4rem;

    @media (max-width: ${gridBreakPoints.sm}) {
      margin-left: 0.8rem;
    }
  }
  .dropdown-alert {
    padding: 0.5rem 1.25rem;
    .alert-heading {
      font-weight: 700;
      margin-bottom: 0;
      font-size: 1.1rem;
      white-space: pre-line;
    }
    .close {
      padding: 0.2rem 1.25rem;
    }
  }

  .read-only-input:not(:disabled),
  .react-select__control--is-disabled {
    background-color: #fff;
  }
  .required::after {
    content: '*';
    color: ${({ theme }) => theme.colors.dangerColor};
    font-weight: bold;
    margin-left: 0.2rem;
  }
  .read-only-input {
    padding: 1.8rem 0.75rem;
  }
  .currency-input {
    padding: 1.8rem 1.75rem;
  }

  .react-select__menu {
    margin-top: 0;
  }
  .react-select__input-container {
    padding: 0.5rem;
  }
  .react-select__control--is-disabled .react-select__indicators {
    display: none;
  }
  .fee-info {
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }
`
