/* eslint-disable import/first */
import 'react-app-polyfill/ie11'
import 'core-js/es/array'
import 'core-js/es/object'
import React from 'react'

import ReactDOM from 'react-dom'
import { App } from 'App'
if ((process.env.NODE_ENV as any) === 'mock') {
  const { worker } = require('./mocks/browser')
  worker.start()
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
