import create from 'zustand'

interface IBiddingSidePanel {
  isOpen: () => boolean
  biddingVehicleInstanceId?: number
  setBiddingVehicleInstanceId: (vehicleInstanceId: number) => void
}

export const useBiddingSidePanel = create<IBiddingSidePanel>((set, get) => ({
  biddingVehicleInstanceId: null,
  setBiddingVehicleInstanceId(vehicle) {
    set((state) => ({
      ...state,
      biddingVehicleInstanceId: vehicle
    }))
  },
  isOpen: () => !!get().biddingVehicleInstanceId
}))
