import { borderColorSecondary, colorBackgroundCarfaxItem, mediumScreen, smallScreen } from 'common/theme'
import styled from 'styled-components'

export const StyledCarfaxAdvantage = styled.div`
  font-family: 'BMWGroup Regular', sans-serif;
  font-size: 1.1rem;

  display: flex;
  flex: 1;

  .carfax-advanced-icon {
    cursor: none;
  }

  @media (max-width: ${smallScreen}) {
    width: 100%;
    font-size: 1rem;
    flex-wrap: wrap;
  }

  @media (max-width: ${mediumScreen}) {
    min-width: auto;
    flex-wrap: wrap;
    width: 100%;
  }
`

export const StyledCarfaxAdvantageTitleImage = styled.div`
  padding: 1rem 1rem;
  height: 4.5rem;
  flex-basis: 11.8rem;
  background-color: ${({ theme }) => theme.colors.carfaxBackground};

  @media print {
    background-color: ${({ theme }) => theme.colors.carfaxBackground};
    -webkit-print-color-adjust: exact;
  }
  img {
    width: 10rem;
  }

  @media (max-width: ${mediumScreen}) {
    width: 100%;
    text-align: center;
    flex-basis: auto;
  }
`

export const StyledCarfaxAdvantageItem = styled.div`
  width: calc((100% - 11.8rem) / 4);
  float: left;
  background-color: ${colorBackgroundCarfaxItem};
  border: 0.1rem solid ${borderColorSecondary};
  height: 4.5rem;
  padding-left: 1rem;
  overflow-y: hidden;
  text-overflow: ellipsis;

  img {
    width: 2.5rem;
    height: 2.5rem;
    float: left;
    margin: 1rem 0.5rem;
  }
  .snapshot-item-content {
    display: table-cell;
    height: 4.5rem;
    vertical-align: middle;
    line-height: 2rem;
  }

  .carfax-item-count {
    background: orange;
    color: #fff;
    border-radius: 50%;
    padding: 0 1rem;
    top: -2.5rem;
    margin-left: -0.5rem;
    float: left;
    position: relative;
  }

  @media (max-width: ${mediumScreen}) {
    width: 50%;
    border: 0.1rem solid ${borderColorSecondary};
  }

  @media print {
    background-color: ${colorBackgroundCarfaxItem};
    -webkit-print-color-adjust: exact;
  }
`
export const StyledNoCarfaxItem = styled.div`
  background-color: ${colorBackgroundCarfaxItem};
  line-height: 4.5rem;
  padding-left: 1rem;
  flex: 1;

  span {
    white-space: nowrap;
  }

  @media (max-width: ${smallScreen}) {
    width: 100%;
  }

  @media print {
    background-color: ${colorBackgroundCarfaxItem};
    -webkit-print-color-adjust: exact;
  }
`
