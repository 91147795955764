import { StyledAuctionText } from 'modules/BuyerActivity/VehicleAuction.styled'
import { useContext } from 'react'
import { VehicleContext } from './VehicleContext'

export const UserMaxBid = () => {
  const {
    vehicleData: { auctionInfo }
  } = useContext(VehicleContext)

  return (
    <>
      {auctionInfo?.CanBid && auctionInfo.UserMaxBid !== 0 && (
        <StyledAuctionText>
          <span>Your Max Bid: </span>
          {auctionInfo.UserMaxBidString}
        </StyledAuctionText>
      )}
    </>
  )
}
