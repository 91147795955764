import { IconSvg } from '@prism/icon'
import { DriveTrain } from 'icons/DriveTrain'
import { Transmission } from 'icons/Transmission'
import { SaleChannel } from 'icons/SaleChannel'
import { ExteriorColor } from 'icons/ExteriorColor'
import { InteriorColor } from 'icons/InteriorColor'
import { Calendar } from 'icons/Calendar'
import { isEmpty } from 'lodash'
import { Door } from 'icons/Door'

export const getVehicleListingInfoIcon = (name: string) => {
  if (isEmpty(name)) return ''

  switch (name) {
    case 'DD_CONDITION':
      return 'CR:'
    case 'MILEAGE':
      return <IconSvg glyph="odometer" height="18px" />
    case 'VEHICLE_LOCATION':
      return <IconSvg glyph="location" height="18px" />
    case 'EXTERIOR_COLOUR':
      return <ExteriorColor height="18px" />
    case 'INTERIOR_TRIM':
      return <InteriorColor height="18px" />
    case 'DRIVETRAIN':
      return <DriveTrain height="18px" width="18px" />
    case 'TRANSMISSION':
      return <Transmission height="18px" width="18px" />
    case 'SALE_CHANNEL':
      return <SaleChannel height="18px" width="18px" />
    case 'IN_SERVICE_DATE':
      return <Calendar height="18px" width="18px" />
    case 'DOORS':
      return <Door height="18px" width="18px" />

    default:
      return ''
  }
}
