import { getBidVehicles, getWatchListVehicles } from 'apis/dashboardApis'
import { Link, useLocation } from 'react-router-dom'
import { VehicleList } from '../modules/VehicleList'
import { IconSvg } from '@prism/icon'
import { BUY_VEHICLE_NO_ACTIVITY } from 'common/constants'
import { useGlobalStore } from 'store/useGlobalStore'
import { urlDownloadViewVehicles } from 'utils/utils'
import { downloadURI } from 'utils/exportFileUtil'
import { useCallback, useEffect, useState } from 'react'
import { AlertDialog } from 'components/AlertDialog/AlertDialog'
import { Dropdown } from 'react-bootstrap'
import ChevronDown from 'icons/ChevronDown'
import { FileEarMarkArrowDown } from 'images/icon/FileEarMarkArrowDown'
import { exportViews } from 'common/constants'
import { BiddingSidePanel } from 'modules/BiddingSidePanel'
import { AppRoutes } from 'common/routes'
import { setListingBreadcrumb } from 'utils/breadcrumbUtils'
import { Layout } from '../layouts/Layout'
import { GetBidVehiclesRequest, IVehicleData } from 'types/vehicleTypes'
import { VehicleListContextProvider } from 'modules/VehicleList/VehicleListContext'
import { useDtmAnalytics } from 'hooks/useDtmAnalytics'
import { DefaultErrorBoundary } from 'modules/DefaultErrorBoundary'
import { setCustomClick } from 'utils/analyticsUtils'

export const WatchListPageContent = () => {
  const getLocalText = useGlobalStore((state) => state.getLocalText)
  const [modalShow, setModalShow] = useState(false)
  const location = useLocation()

  const { endPageLoad } = useDtmAnalytics()

  const exportView = (selectedVehicles: number[], vehicles: IVehicleData[], value: number) => {
    const validIds = selectedVehicles.filter((id) =>
      vehicles.find((item) => item.vehicle.InstanceID === id && Boolean(item.vehicle.WatchListID))
    )
    if (validIds?.length > 0) {
      const url = urlDownloadViewVehicles(validIds, value)
      downloadURI(url)
    } else {
      setModalShow(true)
    }
  }
  const handleGetWatchList = useCallback(async (request: GetBidVehiclesRequest) => {
    const response = await getWatchListVehicles(request)
    endPageLoad(response?.Items, { searchData: { sortBy: response.SortBy, sortOptions: response.SortOptions } })
    return response
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const pageTitle = getLocalText('MY_WATCHLIST', 'My Watch List')

    setListingBreadcrumb(pageTitle, location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout title={'Watch List'}>
      <AlertDialog
        title="Excel/PDF View"
        description="Please select one or more vehicles."
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <VehicleList
        title="Watch List"
        showCheckbox
        queryFunction={handleGetWatchList}
        countQueryFunction={getBidVehicles}
        enableRemovedWatchlistPlaceholder={true}
        navigationLink={(count) => (
          <Link
            className="navigation-link"
            onClick={() => setCustomClick('Manage Bids', { usePageName: true })}
            to={AppRoutes.ManageBids}
          >
            <span className="right-action">
              <IconSvg glyph="dollar-o" color="primary" />
              &nbsp;Manage Bids&nbsp;<b>({count})</b>
            </span>
          </Link>
        )}
        checkboxAction={(selectedVehicles, vehicles) => (
          <div>
            <Dropdown onSelect={(value) => exportView(selectedVehicles, vehicles, value as never)}>
              <Dropdown.Toggle as="div">
                <span className="right-action select-format ">
                  <FileEarMarkArrowDown />
                  <span className="export-view">&nbsp; Excel/PDF View</span>

                  <ChevronDown width="20" height="20" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="select-format">
                <Dropdown.Item eventKey={exportViews.Excel}>Excel</Dropdown.Item>
                <Dropdown.Item eventKey={exportViews.PDF}>PDF</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        noActivityMessage={getLocalText('NO_WATCHLIST_VEHICLES', BUY_VEHICLE_NO_ACTIVITY.WATCH_LIST)}
      />

      <DefaultErrorBoundary>
        <BiddingSidePanel isDealerShipSelectable={false}></BiddingSidePanel>
      </DefaultErrorBoundary>
    </Layout>
  )
}

export const WatchListPage = () => {
  return (
    <VehicleListContextProvider>
      <WatchListPageContent />
    </VehicleListContextProvider>
  )
}
