import { StyledAccordion, StyledAccordionTitle } from 'components/CustomAccordion/CustomAccordion.styled'
import { StyledRibbon } from 'components/Ribbon/Ribbon.styled'
import styled from 'styled-components'
import { CustomAccordion } from 'components/CustomAccordion/CustomAccordion'

export const StyledBuyerSearchHeader = styled.div``
export const StyledBuyerSearchAction = styled.div`
  padding-bottom: 2.5rem;
  border-bottom: 0.1rem solid #e5e5e5;
`
export const StyledBuyerSearchContent = styled.div`
  height: 100%;

  .see-more {
    font-size: 1.6rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary};
  }

  .selected-filter-label {
    text-transform: uppercase;
    color: #999;
    display: block;
    padding-bottom: 0.2rem;
    padding-left: 1rem;
    border-top: none;
    background-color: #f8f8f8;
  }

  ${StyledAccordion} {
    &.selected-filter {
      border-color: #fff;
    }
  }
`
export const StyledMultiSelectFilterSection = styled.div<{ noOfCols?: number }>`
  display: flex;
  grid-template-columns: repeat(${({ noOfCols }) => noOfCols ?? 3}, 1fr);
  gap: 1rem;
  max-width: 100%;
  flex-wrap: wrap;

  .custom-control-label {
    width: 100%;
  }
`
export const StyledRibbonLabel = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;

  ${StyledRibbon} {
    flex-grow: 2;

    .ribbon {
      width: 100%;
      text-align: center;

      .ribbon-title {
        filter: brightness(0.75);
      }
    }
  }
`
export const StyledSliderFilterSection = styled.div``
export const StyledCarfaxFilterItemContainer = styled.div`
  & + & {
    margin-top: 0.75rem;
  }
`
export const StyledCarfaxFilterItemHeader = styled.div`
  text-transform: uppercase;

  color: #999;
  display: block;
  margin-bottom: 0.5rem;
  padding-bottom: 0.2rem;
  border-bottom: 1px solid #ccc;
  border-top: none;
  font-weight: bold;
`
export const StyledCarfaxFilterItemContent = styled.div`
  margin-left: 5px;
`
export const StyledCarfaxAccordion = styled(CustomAccordion)`
  background-color: rgb(253, 251, 225);
  ${StyledAccordionTitle} img {
    width: 10rem;
  }
`

export const BUYER_SEARCH_OFFSET_HEIGHT = 10

export const StyledBuyerSearch = styled.div`
  position: sticky;
  top: ${BUYER_SEARCH_OFFSET_HEIGHT}px;
`

export const StyledFilterSection = styled.div`
  max-height: calc(100vh - 28rem);
  overflow: auto;

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  ::-webkit-scrollbar {
    width: 0.4rem;
  }
`
