import styled from 'styled-components'
import { StyledSlideOutElement } from './SlideOut.styled'

export const StyledSlideOutHeading = styled(StyledSlideOutElement)`
  color: ${({ theme }) => theme.colors.headerFontColor};
  .title {
    font-size: 2.6rem;
  }
  .vin {
    font-size: 1.8rem;
  }
`
