import { colorWatching, colorWinning, smallScreen, textColor } from 'common/theme'
import { StyledVDPCardStyle } from 'components/Share/Card.styled'
import { Button, Card, Popover, Row } from 'react-bootstrap'
import styled, { css } from 'styled-components'

export const BuyItNowButtonGroup = styled.div``

export const StyledAuctionCardRow = styled(Row).attrs({
  className: 'no-gutters'
})``

export const StyledAuctionImage = styled.div.attrs({
  className: 'text-center text-sm-left pb-4 pb-sm-0'
})`
  img {
    width: 90%;
    display: inline-block;
    border-radius: 0.3rem;

    @media (max-width: ${smallScreen}) {
      width: 100%;
    }
  }
`

export const StyledAuctionTitle = styled(Card.Text)`
  font-weight: 600;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.primary};
  min-height: 4.6rem;
  line-height: 1.3;
`

export const StyledAuctionText = styled.div`
  font-size: inherit;
  color: ${textColor};

  span,
  .invoice-field {
    font-weight: 600;
  }
  a {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }
`

export const StyledAuctionPrice = styled.div`
  font-size: 2.4rem;
  font-weight: bold;
`

const StyleBaseTag = css`
  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  background: #999;
  display: inline-block;

  padding: 0.4rem;
  position: relative;

  span {
    padding-right: 0.5rem;
  }

  ::before,
  ::after {
    content: '';
    height: 100%;

    position: absolute;
    top: 0;

    border-top: 1.3rem solid transparent;
    border-bottom: 1.3rem solid transparent;
  }

  ::before {
    left: -1.3rem;
    display: inline-block;

    border-right: 1.3rem solid #999;
  }

  ::after {
    right: -1.3rem;
    display: none;

    border-left: 1.3rem solid #999;
  }
`

export const StyledTag = styled.div<{ color?: string }>`
  ${StyleBaseTag};
  background: ${({ color }) => color || colorWatching};
  padding: 0.3rem 2rem;
  margin: 0.2rem 0;

  ::before {
    border-right: 2rem solid ${({ color }) => color || colorWatching};
    left: -2rem;
    border-top: 1.2rem solid transparent;
    border-bottom: 1.2rem solid;
    color: transparent;
  }

  &.right {
    margin-right: 1.5rem;
    &::before {
      display: none;
    }
    &::after {
      display: inline-block;
      border-left: 2rem solid ${({ color }) => color || colorWatching};
      right: -2rem;
      border-top: 1.2rem solid transparent;
      border-bottom: 1.2rem solid;
      color: transparent;
    }
  }

  @media print {
    ::before {
      padding-left: 0.1rem;
    }
    background-color: ${({ color }) => color || colorWatching};
    -webkit-print-color-adjust: exact;
  }
`

export const StyledEasyBidPopover = styled(Popover)`
  max-width: none;
`
interface IStyledNumberOfBidsProps {
  count?: number
}

export const StyledNumberOfBids = styled.a<IStyledNumberOfBidsProps>`
  ${({ count }) =>
    count === 0 &&
    `
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: ${textColor} !important; 
  `}
`
interface IStyledReserveMetLabelProps {
  isReserveMet?: number
}

export const StyledReserveMetLabel = styled.label<IStyledReserveMetLabelProps>`
  ${({ isReserveMet }) =>
    isReserveMet === 1 &&
    `
  color: ${colorWinning}; 
  font-weight: 600;
`}
`
export const StyledAuctionButton = styled(Button)`
  font-size: 1.6rem;
  min-width: 16rem;
  margin: 0.5rem 0 0 0.5rem;
  @media (max-width: ${smallScreen}) {
    margin: 1rem 0 0 0;
  }
`

export const BuyItNowButtonContainer = styled.div`
  column-gap: 0.5rem;
  padding-top: 1rem;
`

export const BidSectionContainer = styled.div`
  margin-top: 0.5rem;
`

export const StyleCurrentBidSection = styled.div`
  margin-top: 0.5em;
`

export const StyledVehicleBidBuyInformation = styled.div`
  text-align: right;
  font-size: 1.4rem;

  @media (max-width: ${smallScreen}) {
    button {
      width: 100%;
    }
  }

  ${BidSectionContainer}, .v-timer, .details-grid {
    @media print {
      display: none;
    }
  }
`
export const StyledVehicleAuctionInfo = styled(StyledVDPCardStyle)`
  min-height: 17rem;
  padding: 2.2rem;
  margin-bottom: 2.4rem;
  background-color: white;

  .dashboard-auction-button button {
    max-width: 45%;
  }
  .dashboard-reserver-bid {
    ${StyledAuctionText} {
      display: inline;
    }
  }
  ${StyledAuctionPrice} {
    font-size: 2rem;
  }
  .dashboard-reserver-bid {
    ${StyledAuctionText} {
      display: inline;
    }
  }
`

export const StyledNoAvailable = styled.div`
  font-size: 2.4rem;
  font-weight: bold;
  color: red;
`
