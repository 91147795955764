import { StyledMark, StyledSlider, StyledThumb, StyledTrack } from './Slider.styled'
import { ReactSliderProps } from 'react-slider'

const renderThumb = (textRenderFn?: (value: number) => string) => (props, state) =>
  (
    <StyledThumb {...props}>
      <div className="thumb-text">{textRenderFn ? textRenderFn(state.valueNow) : state.valueNow}</div>
    </StyledThumb>
  )
const renderTrack = (props, state) => <StyledTrack {...props} value={[state.value].flat()} index={state.index} />
const renderMark = (props) => <StyledMark {...props} />

interface IProps extends ReactSliderProps<any> {
  textRenderFn?: (value: number) => string
}

export const Slider = ({ textRenderFn, ...props }: IProps) => {
  return (
    <StyledSlider
      renderTrack={renderTrack}
      renderThumb={renderThumb(textRenderFn)}
      renderMark={renderMark}
      {...props}
    />
  )
}
