/* eslint-disable no-script-url */
import { useSignalRStore } from 'store/useSignalRStore'
import shallow from 'zustand/shallow'
import { StyledAlertNotification } from './AlertNotification.styled'

export const AlertNotification = () => {
  const { showConnectionAlert, setShowConnectionAlert } = useSignalRStore((state) => state, shallow)
  return (
    <>
      {showConnectionAlert && (
        <StyledAlertNotification
          variant="danger"
          onClose={() => setShowConnectionAlert(false)}
          dismissible
          className="mb-0 sticky-top"
        >
          <p className="m-auto text-center">
            Connection to real time bid information has been lost. Please
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="javascript:void(0);" onClick={() => window.location.reload()}>
              &nbsp;click here&nbsp;
            </a>
            to reload this page
          </p>
        </StyledAlertNotification>
      )}
    </>
  )
}
