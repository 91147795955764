export const textColor = '#3a3a3a'
export const textColorLight = '#494949'
export const textTableHeaderColor = '#fff'
export const textMenuColor = '#173B68'
export const borderColorSecondary = '#E3E4E5'
export const borderColorSecondaryLight = '#f4f4f4'
export const borderColorPrimary = '#173B68'
export const colorDanger = '#b11926'
export const colorWinning = '#26ad77'
export const colorWatching = '#999'
export const colorBackgroundMobileMenu = 'rgb(244, 244, 244)'
export const gridBreakPoints = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px'
}

export const zIndex = {
  inputGroup: 3,
  elevated: 10,
  stickyHeader: 1020,
  menu: 1010,
  dropdown: 1000,
  modal: 1050,
  loader: 1400
}

export const extraExtraLargeScreen = gridBreakPoints.xxl
export const extraLargeScreen = gridBreakPoints.xl
export const smallScreen = gridBreakPoints.sm
export const mediumScreen = gridBreakPoints.md
export const largeScreen = gridBreakPoints.lg
export const colorPrimaryDarken = '#004786'
export const colorWarning = '#f90'
export const colorBackgroundCarfaxItem = '#fdfbe1'
export const commonButtonWidth = '140px'
export const extraExtraLargeScreenSize = 1400
export const extraLargeScreenSize = 1200
export const largeScreenSize = 992
export const mediumScreenSize = 768
export const smallScreenSize = 576
export const mobileScreenSize = 375

export const screen_xs = 0
export const screen_sm = '576px'
export const screen_med = '768px'
export const screen_lg = '992px'
export const screen_xl = '1200px'
export const screen_xxl = '1400px'
