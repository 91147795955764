import PrismSearchable from '@prism/searchable'

export interface ISearchListItem {
  label: string
  value: unknown
  count?: number
  checked?: boolean
}

const Searchable = (props: any) => {
  return (
    <div className="prism-sandbox">
      <PrismSearchable>
        <PrismSearchable.List {...props} />
      </PrismSearchable>
    </div>
  )
}

export default Searchable
