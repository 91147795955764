import { camelCase, startCase } from 'lodash'
import parse from 'html-react-parser'
import log from './logger'

export const parseNumberWithCommas = (inputText: string) => {
  return parseFloat(inputText.replace(/,/g, ''))
}
export const capitalize = (title: string) => {
  return startCase(camelCase(title))
}
export const parseHtml = (html: string) => {
  try {
    return parse(html)
  } catch (error) {
    log.error(error)
  }

  return null
}
