import { INewsModel } from './../types/rmsGlobalTypes'
import { rmsAuthModel } from 'common/rmsGlobal'
import { IRmsGlobal, IVendorInfo } from 'types/rmsGlobalTypes'
import { IUserClaimReponse } from 'types/userTypes'
import { addDefaultAttributes } from 'utils/newrelicUtilts'
import { parseJson } from 'utils/utils'
import create from 'zustand'
import { getUserClaims } from './../apis/userApis'
import { IAppSettings } from './../types/rmsAuthModel'
import { ISystemSetting } from '../types/baseTypes'

export interface IUserClaimState {
  isAuthenticated: boolean
  userClaims: IUserClaimReponse
  vendor: IVendorInfo
  appSettings: IAppSettings
  localText: Record<string, string>
  rules: string[]
  systemSettings: Record<string, string | number | ISystemSetting>
  errorMessages: Record<string, string>
  newsModel: INewsModel
  serverTime: Date
  clientTime: Date

  fetchUserClaim: () => Promise<void>
  getLocalText: (key: string, defaultText?: string) => string
  isInRule: (key: string) => boolean
  getSystemSetting: (key: string) => string | number | ISystemSetting
  clearError: () => void
}

export const useGlobalStore = create<IUserClaimState>((set, get) => ({
  isAuthenticated: rmsAuthModel?.isAuthenticated as boolean,
  userClaims: {} as IUserClaimReponse,
  vendor: rmsAuthModel?.vendor as IVendorInfo,
  appSettings: rmsAuthModel?.appSettings as IAppSettings,
  localText: rmsAuthModel?.localText as Record<string, string>,
  rules: [] as string[],
  systemSettings: {} as Record<string, string | number>,
  errorMessages: rmsAuthModel?.errorMessages as Record<string, string>,
  newsModel: rmsAuthModel?.newsModel,
  // In the case when the server time is not fetched yet
  serverTime: new Date(),
  clientTime: new Date(),

  fetchUserClaim: async () => {
    const userClaim = await getUserClaims()

    if (userClaim) {
      const rmsGlobal = parseJson(userClaim.RmsGlobal) as IRmsGlobal
      addDefaultAttributes(userClaim)
      set((state) => ({
        ...state,
        userClaims: userClaim,
        localText: { ...state.localText, ...rmsGlobal.localText },
        rules: [...state.rules, ...rmsGlobal.rules, ...userClaim.Rules].map((rule) => rule.toLowerCase()),
        systemSettings: rmsGlobal.systemSettings,
        serverTime: new Date(userClaim.ServerUTCTime)
      }))
    }
  },
  getLocalText: (key, defaultText) => {
    const localText = get().localText
    if (localText && key) {
      return localText[key] || defaultText
    }
    return defaultText
  },
  isInRule: (key) => {
    const rules = get().rules as string[]
    if (rules) {
      return rules.includes(key.toLocaleLowerCase())
    }
    return false
  },
  getSystemSetting: (key) => {
    const systemSettings = get().systemSettings
    if (systemSettings) {
      return systemSettings[key] || ''
    }
    return ''
  },

  clearError: () => {
    set((state) => ({
      ...state,
      errorMessages: undefined
    }))
  }
}))
