import { BidAgainFormValues } from './../types/formikTypes'
import { useEffect } from 'react'
import { FormikHelpers } from 'formik'
import { useBiddingSidePanel } from 'store/useBiddingSidePanelStore'
import { useVehicleStore } from 'store/useVehicleStore'

// when dealership changed by dealership dropdown manage bids page
// we want all clients of that user (eg: watchlist side panel, other tabs of manage bid) follow the updated BiddingDealerID
// this hook also activate when we open side panel
export const useSidePanelRealTimeUpdateDealer = (
  formikHelper: FormikHelpers<BidAgainFormValues>,
  dealershipUpdatedCallback: () => void
) => {
  const biddingVehicleInstanceId = useBiddingSidePanel((state) => state.biddingVehicleInstanceId)
  const biddingVehicle = useVehicleStore((state) =>
    state.vehicles?.find((vehicleData) => vehicleData.vehicle.InstanceID === biddingVehicleInstanceId)
  )
  useEffect(() => {
    formikHelper.setFieldValue('BiddingDealerID', biddingVehicle?.auctionInfo.BuyerID, false)
    formikHelper.setFieldValue('BidAmount', biddingVehicle?.auctionInfo.NextBidAmount, false)

    // fetchDetailedDeliveryInfo()
    dealershipUpdatedCallback()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [biddingVehicle?.auctionInfo.BuyerID])
}
