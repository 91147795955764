import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { StyledSlideOutElement } from './SlideOut.styled'

export const StyledSlideOutBidAmountInput = styled(StyledSlideOutElement)`
  display: flex;
  flex-direction: column;
  width: 80%;

  .dollar {
    position: absolute;
    transform: translate(110%, 50%);
  }
  .amount-input {
    position: relative;
    display: flex;
    flex: 1;
  }

  // Hide arrow on input type number
  /* Chrome, Safari, Edge, Opera */
  .currency-input::-webkit-outer-spin-button,
  .currency-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Hide arrow on input type number
  /* Firefox */
  .currency-input {
    -moz-appearance: textfield;
  }
`

export const StyledBidAmountButton = styled(Button)`
  background: #fff;
  border: ${({ theme }) => theme.colors.borderInput};
  padding-left: 1.2rem;
  padding-right: 1.2rem;
`
