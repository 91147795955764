import styled from 'styled-components'

export const StyledNoActivity = styled.div`
  display: flex;

  img {
    width: 14rem;
    height: 12rem;
  }

  div {
    width: 100%;
    margin-left: 2.4rem;

    span {
      font-size: 1.6rem;
      color: gray;
    }
  }
`
