import { useGlobalStore } from 'store/useGlobalStore'
import { IPurchaseHistory } from 'types/accountTypes'
import { formatDate } from 'utils/dateUtils'
import { StyledVDPHistory } from './VDPHistory.styled'

interface IProps {
  vehiclePurchase: IPurchaseHistory
}
export const VDPHistory = ({ vehiclePurchase }: IProps) => {
  const { getLocalText } = useGlobalStore((state) => state)

  return (
    <>
      {vehiclePurchase && (
        <StyledVDPHistory>
          <div className="item">
            <label>{getLocalText('TOTAL_COST') ?? 'Total Cost'}: </label>
            {vehiclePurchase.TotalPriceString}
          </div>
          <div className="item">
            <label>{getLocalText('PRICE') ?? 'Price'}: </label>
            {vehiclePurchase.PreAdjustmentPriceString}
          </div>
          <div className="item">
            <label>{getLocalText('DATE') ?? 'Date'}: </label>
            {formatDate(vehiclePurchase.PurchaseDate, 'DD t')}
          </div>
          <div className="item">
            <label>{getLocalText('ORDER_NUMBER') ?? 'Order #'}: </label>
            {vehiclePurchase.InvoiceNumber}
          </div>
          <div className="item">
            <label>{getLocalText('PAYMENT_METHOD') ?? 'Payment Method'}: </label>
            {vehiclePurchase.PaymentType}
          </div>
          <div className="item">
            <label>{getLocalText('VEHICLE_LOCATION') ?? 'Vehicle Location'}: </label>
            {vehiclePurchase.Location}
          </div>
        </StyledVDPHistory>
      )}
    </>
  )
}
