import React, { useContext } from 'react'
import BurgerIcon from 'images/icon/BurgerIcon'
import { LayoutContext } from 'contexts/LayoutContext'
import { StyledBurgerButton } from './Layout.styled'
import { useDtmAnalytics } from 'hooks/useDtmAnalytics'

interface IProps {
  isSticky?: boolean
}
const BurgerButton = ({ isSticky }: IProps) => {
  const { setShowMenuSidebar } = useContext(LayoutContext)
  const { userInteraction } = useDtmAnalytics()
  return (
    <StyledBurgerButton isSticky={isSticky}>
      <button
        onClick={() => {
          userInteraction('Ham Menu Clicked')
          setShowMenuSidebar(true)
        }}
      >
        <BurgerIcon />
      </button>
    </StyledBurgerButton>
  )
}

export default BurgerButton
