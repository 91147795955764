import styled, { css } from 'styled-components'

export const cardStyle = css`
  width: 0.1rem;
  font-size: 1.4rem;
  margin: 1.2rem 0;
  padding: 2rem;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3rem;
  transition: 167ms ease-in-out;

  &:hover {
    box-shadow: 0 0.4rem 2.4rem rgba(0, 0, 0, 0.1);
  }
`
export const StyledCard = styled.div`
  ${cardStyle}
`
export const VDPCardStyle = css`
  font-size: 1.4rem;
  margin: 1.2rem 0;
  padding: 2rem;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3rem;
  transition: 167ms ease-in-out;

  &:hover {
    box-shadow: 0 0.4rem 2.4rem rgba(0, 0, 0, 0.1);
  }
`
export const StyledVDPCardStyle = styled.div`
  ${VDPCardStyle}
`
