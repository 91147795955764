import { smallScreen, largeScreen, extraLargeScreen, commonButtonWidth } from 'common/theme'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'

export const SearchSectionStyled = styled(Container)`
  font-size: 1.3rem;
  .history-search-section {
    padding-top: 1rem;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 0.1rem solid #e3e4e5;

    .header-label {
      font-size: 1.6rem;
      font-weight: 600;
      white-space: nowrap;
      margin-top: 0.2rem;
    }

    .btn-search {
      float: right;
      font-size: 1.3rem;
      height: 3rem;
      min-width: ${commonButtonWidth};
    }

    .input-vin {
      font-size: 1.4rem;
      height: 3rem;
    }

    .search-date-contain .row {
      max-width: 65rem;
    }

    .search-vin-contain .row {
      min-width: 58rem;
    }

    .search-vin-main {
      float: right;
      min-width: 75%;
    }

    .date-picker-from {
      padding-right: 0.5rem;
    }

    .date-picker-to {
      padding-left: 0.5rem;
    }

    .input-vin-contain {
      padding-left: 0;
      padding-right: 3rem;
    }
    @media (max-width: ${extraLargeScreen}) {
      .input-vin-contain {
        padding: 0 1.8rem;
      }

      .search-vin-contain .row {
        min-width: 50rem;
      }
    }
    @media (max-width: ${largeScreen}) {
      .date-picker-container {
        width: 100%;
      }

      input {
        width: 100%;
      }

      .search-date-contain {
        margin-bottom: 1rem;
      }
      .search-vin-main {
        float: none;
        min-width: unset;
      }

      .search-date-contain .row {
        max-width: unset;
      }

      .search-vin-contain .row {
        min-width: unset;
      }

      .date-picker-from {
        padding-right: 1.5rem;
      }

      .date-picker-to {
        padding-left: 1.5rem;
      }
    }
    @media (max-width: ${smallScreen}) {
      .btn-search {
        width: 100%;
      }

      input {
        margin-bottom: 0.7rem;
      }
    }
  }
`
