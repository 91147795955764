import { ThemedSvg } from './ThemedSvg.styled'
import { IconProps } from 'core/typing/IconType'

export const InteriorColor = ({ width = '16px', height = '16px', color = 'currentColor' }: IconProps) => {
  return (
    <ThemedSvg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 130 182">
      <g transform="translate(-42.04211,-32.230217)">
        <path d="M 105.001,32.6674 C 99.5555,34.2399 96.491,39.6662 93.4244,44 86.3632,53.9789 79.9498,64.5072 73.6914,75 60.9029,96.441 42.5563,123.05 42.054,149 c -0.8524,44.043 44.279,74.049 84.946,62.572 9.036,-2.55 17.273,-7.035 24,-13.587 5.317,-5.178 10.521,-10.408 14.1,-16.985 C 184.39,145.552 162.441,108.735 144,78 136.666,65.7764 128.906,53.669 120.72,42 117.016,36.7197 112.445,30.5179 105.001,32.6674 M 69.1088,143.604 c 2.9619,-1.207 6.8601,-0.459 8.3272,2.544 4.6534,9.525 2.8346,18.613 12.6034,25.913 5.9977,4.482 17.7326,3.599 22.2186,8.503 2.658,2.906 1.994,8.16 -1.406,10.118 C 95.1629,199.718 73.4147,177.227 68.2847,165 c -2.1694,-5.171 -6.4543,-18.43 0.8241,-21.396 z" />
      </g>
    </ThemedSvg>
  )
}
