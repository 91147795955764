import { SavedSearchReceive } from './../types/vehicleTypes'
import { IAuctionInfo } from './../types/auctionTypes'
import { fetchAsync, postAsync } from 'common/fetch'
import {
  BuyerBaseVehicleSearchCriteria,
  DeliveryInformation,
  IBidOnVehicleRequest,
  IDeliveryQuote,
  IDocumentSignInfor,
  ISearchVehicleResponse,
  ISearchVehiclesRequest,
  IVehicleFeeRequest,
  IVehicleFeeResponse,
  IVehicleNote,
  IVehicleSearchDocument,
  SavedSearchData,
  WidgetConfigurationDetails
} from 'types/vehicleTypes'
import { DocumentType, IBaseCollectionResponse, IBaseResponse } from 'types/baseTypes'
import { GetBaseVehicleRequest, IVehicleData } from 'types/vehicleTypes'
import { SellerDisclosure } from 'types/inspectionTypes'
import { toUrlSearchParams } from 'utils/urlParams'
import { VehicleDocument } from 'types/purchaseHistoryTypes'

export const quickSearch = async (request: ISearchVehiclesRequest) => {
  return postAsync<ISearchVehicleResponse>(`/api/vehicle/QuickSearch`, {
    body: request
  })
}

export const searchAvailableInventory = async (request: ISearchVehiclesRequest) => {
  return postAsync<IVehicleSearchDocument[]>(`/api/vehicle/SearchAvailableInventory`, {
    body: request
  })
}

export const searchVehicles = async (request: ISearchVehiclesRequest) => {
  return postAsync<ISearchVehicleResponse>(`/api/vehicle/SearchVehicles`, {
    body: request
  })
}

export const getBaseVehicle = async (request: GetBaseVehicleRequest, biddingDealerId?: number) => {
  return postAsync<IBaseCollectionResponse<IVehicleData>>('/api/vehicle/GetBaseVehicle', {
    body: request,
    headers: {
      'X-Actuos-BuyerID': biddingDealerId?.toString()
    }
  })
}

export const saveDisclosure = async (request: IDocumentSignInfor) => {
  return postAsync<IBaseResponse<number>>('/api/vehicle/SaveDisclosure', {
    body: request
  })
}

export const addVehicleNotes = async (request: IVehicleNote) => {
  return postAsync('/api/vehicle/AddVehicleNotes', {
    body: request
  })
}

export const getDetailedDeliveryInfo = async (request: IDeliveryQuote, biddingDealerId?: number) => {
  return postAsync<DeliveryInformation>('/api/vehicle/GetDetailedDeliveryInfo', {
    body: request,
    headers: {
      'X-Actuos-BuyerID': biddingDealerId?.toString()
    }
  })
}

export const getGenericPremium = async (request: IVehicleFeeRequest, biddingDealerId?: number) => {
  return postAsync<IVehicleFeeResponse>('/api/vehicle/GetGenericPremium', {
    body: request,
    headers: {
      'X-Actuos-BuyerID': biddingDealerId?.toString()
    }
  })
}

export const bidOnVehicle = async (request: IBidOnVehicleRequest, biddingDealerId?: number) => {
  return postAsync<IBaseCollectionResponse<IVehicleData>>('/api/vehicle/BidOnVehicle', {
    body: request,
    headers: {
      'X-Actuos-BuyerID': biddingDealerId?.toString()
    }
  })
}
export const hideVehicleBidRow = async (request: IAuctionInfo) => {
  return postAsync<IBaseResponse<boolean>>('/api/vehicle/HideVehicleBidRow', {
    body: request
  })
}

export const getVehicleInformation = async (vehicleInstanceID: number) => {
  return postAsync<IVehicleData>('/api/vehicle/GetVehicleByID', {
    body: vehicleInstanceID
  })
}

export const loadDayFiles = async (searchID?: number | string) => {
  return postAsync<SavedSearchData>(`/api/vehicle/LoadDayFiles?${toUrlSearchParams({ searchID: searchID })}`)
}

export const saveSearch = async (data: SavedSearchReceive) => {
  return postAsync<BuyerBaseVehicleSearchCriteria>('/api/vehicle/SaveSearch', {
    body: data
  })
}

export const getSellerDisclosureByID = async (vehicleInstanceID: number) => {
  return postAsync<SellerDisclosure>('/api/vehicle/GetSellerDisclosureByID', {
    body: vehicleInstanceID
  })
}

export const getVDPConfigurationDetails = () => {
  return fetchAsync<WidgetConfigurationDetails[]>(`/api/vehicle/GetVDPConfigurationDetails`)
}

export const getVehicleHistoryByID = async (vehicleInstanceID: number) => {
  return postAsync<IVehicleData>('/api/vehicle/GetVehicleHistoryByID', {
    body: vehicleInstanceID
  })
}

export const getDeliveryInfo = async (request: IDeliveryQuote) => {
  return postAsync<DeliveryInformation>('/api/vehicle/GetDeliveryInfo', {
    body: request
  })
}

export const getMultipleVehicleDocuments = async (...vehicleInstanceIDs: number[]) => {
  return fetchAsync<VehicleDocument[]>(
    `/api/vehicle/GetMultipleVehicleInstanceDocuments?${vehicleInstanceIDs
      .map((id) => `vehicleInstanceIDs[]=${id}`)
      .join('&')}&documentTypeID=${DocumentType.ExternalNoteAttachment}`
  )
}
