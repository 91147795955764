import { gridBreakPoints } from 'common/theme'
import { Button, Form } from 'react-bootstrap'
import styled from 'styled-components'

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 3rem;
  margin-bottom: 1.2rem;
`

export const ContactHeader = styled.h4`
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  margin-bottom: 1.2rem;
  width: inherit;
  text-align: center;
`
export const ContactBody = styled.p`
  font-size: 1.6rem;
`

export const ContactUsBody = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundText};
  margin-left: 2.4rem;
  margin-right: 2.4rem;
  margin-bottom: 2.4rem;
  flex: 1;

  .alert {
    width: 100%;
    p {
      margin-bottom: 0;
    }
    button {
      width: 5rem;
    }
  }

  textarea {
    resize: none;
  }
`

export const FormContainer = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;

  .custom-select {
    background: none;
  }

  .custom-select-lg {
    height: calc(1.5em + 0.75rem + 0.2rem);
    padding: 0.375rem 0.75rem;
    font-size: 1.3rem;
  }
`

export const FormBody = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.headerFontColor};
  font-size: 1.4rem;

  a {
    display: inline-block;
  }

  button {
    display: inline-block;
    width: 10rem;
    margin-top: 0;
  }

  .was-validated .form-control:valid,
  .custom-select:valid,
  .custom-select.is-valid .form-control.is-valid {
    border-color: #ced4da;
    background-image: none;
  }

  .was-validated .custom-select:invalid,
  .custom-select.is-invalid {
    background: none;
  }
`

export const GroupButtonStyled = styled(Form.Group)`
  float: right;
`

export const SubmitButton = styled(Button)`
  height: 3rem;
  font-size: 1.4rem;
  width: 100%;
  margin-top: 1rem;
  line-height: 0.5;
`

export const CancelButton = styled.div`
  margin-top: auto;
  margin-right: 2rem;
  display: inline-block;
  vertical-align: bottom;
`

export const LinkNameStyled = styled(Form.Group)`
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  margin: auto;
  margin-top: 3rem;

  @media (max-width: ${gridBreakPoints.sm}) {
    margin-top: 0;
  }
`

export const LinkNameText = styled.a`
  float: left;
`
