import { IconSvg } from '@prism/icon'
import { getBidVehicles, getWatchListVehicles } from 'apis/dashboardApis'
import { BUY_VEHICLE_NO_ACTIVITY } from 'common/constants'
import { AppRoutes } from 'common/routes'
import { useDtmAnalytics } from 'hooks/useDtmAnalytics'
import { BiddingSidePanel } from 'modules/BiddingSidePanel'
import { DefaultErrorBoundary } from 'modules/DefaultErrorBoundary'
import { VehicleListContextProvider } from 'modules/VehicleList/VehicleListContext'
import { useCallback, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { GetBidVehiclesRequest } from 'types/vehicleTypes'
import { setCustomClick } from 'utils/analyticsUtils'
import { setListingBreadcrumb } from 'utils/breadcrumbUtils'
import { Layout } from '../layouts/Layout'
import { VehicleList } from '../modules/VehicleList'

export const CurrentBidsPageContent = () => {
  const location = useLocation()
  const { endPageLoad } = useDtmAnalytics()
  useEffect(() => {
    setListingBreadcrumb('Manage Bids', location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleGetBidVehicles = useCallback(async (request: GetBidVehiclesRequest) => {
    const response = await getBidVehicles(request)
    endPageLoad(response?.Items, { searchData: { sortBy: response.SortBy, sortOptions: response.SortOptions } })
    return response
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout title="Manage Bids">
      <VehicleList
        title="Manage Bids"
        navigationLink={(count) => (
          <Link
            className="navigation-link"
            onClick={() => setCustomClick('Watch List', { usePageName: true })}
            to={AppRoutes.WatchList}
          >
            <span className="right-action">
              <IconSvg glyph="eye" color="primary" />
              &nbsp;Watch List&nbsp;<b>({count})</b>
            </span>
          </Link>
        )}
        queryFunction={handleGetBidVehicles}
        countQueryFunction={getWatchListVehicles}
        noActivityMessage={BUY_VEHICLE_NO_ACTIVITY.CURRENT_BIDS}
        isShowRemoveBidButton={true}
      ></VehicleList>

      <DefaultErrorBoundary>
        <BiddingSidePanel isDealerShipSelectable={true}></BiddingSidePanel>
      </DefaultErrorBoundary>
    </Layout>
  )
}

export const CurrentBidsPage = () => {
  return (
    <VehicleListContextProvider>
      <CurrentBidsPageContent />
    </VehicleListContextProvider>
  )
}
