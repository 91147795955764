import { SessionStorageKey } from 'common/constants'
import { srpFilters } from 'common/srpFilters'
import { getSystemSetting, isSystemSettingsEnabled, SystemSettingsCode } from 'common/systemSettingsCode'
import { SortOrder } from 'types/baseTypes'
import { IDtmSearchParams, IListingModel, ISearchData, QueryCriteria, VehicleList } from 'types/dtmTypes'
import { ISearchFilterResult } from 'types/vehicleTypes'

export const AnalyticsStorageKey = {
  LOAD_START_TIME: 'LOAD_START_TIME',
  USER_LOCATION: 'UserLocation',
  GEO_LOCATION: 'GeoLocationValue',
  CUSTOM_CLICK_NAME: 'customClickName',
  MOBILE_HEADER: 'MobileHeaderValue'
}

export const DtmPageName = {
  Dashboard: 'My Dashboard',
  SRP: 'Search Results Page',
  News: 'News',
  AdvancedSearch: 'Advanced Search',
  SavedSearch: 'Saved Search',
  WatchList: 'Watch list',
  CurrentBids: 'Current Bids',
  VDP: 'Vehicle Detail Page',
  PurchaseConfirmation: 'Purchase intent confirmation',
  BidHistory: 'Bid History',
  VehicleRetail: 'Vehicle Retail Page',
  PurchaseHistory: 'Purchase History',
  ContactUs: 'Contact Us',
  MyProfile: 'My Profile',
  AccountSettings: 'Account Settings'
}

export const DtmComponentName = {
  VDPDetails: 'VDP Vehicle Details',
  VDPVehicleHistory: 'VDP Vehicle History',
  VDPBuildData: 'VDP Build Data',
  VDPTires: 'VDP Tires and Wheels',
  VDPSellerDisclaimer: 'VDP Seller Disclaimer',
  VDPDamageSummary: 'VDP Damage Summary',
  DashboardInventory: 'My Dashboard Inventory View',
  DashboardWatchList: 'My Dashboard Watchlist',
  DashboardCurrentBids: 'My Dashboard Current Bids'
}

export const DtmPageDetails = {
  VDP: 'VDP',
  SRP: 'SRP'
}

export const DtmPageRollup = {
  HomePage: 'HomePage'
}

export const enum CustomClickNames {
  AvailableInventory = 'Available Inventory'
}

export const getPageDetails = (pathName: string, pageName: string) => {
  let parent = ''
  switch (pageName) {
    case DtmPageName.VDP:
    case DtmPageName.VehicleRetail:
      break

    default:
      switch (pathName?.toLowerCase()) {
        case '/vehicles':
          parent = 'Buy A Vehicle'
          break
        case '/':
          parent = 'Dashboard'
          break
        case '/contactus':
          parent = 'My Account'
          break
      }
      break
  }

  let pageDetails = pageName
  if (pageName === DtmPageName.SRP) {
    pageDetails = DtmPageDetails.SRP
  } else if (pageName === DtmPageName.VDP) {
    pageDetails = DtmPageDetails.VDP
  }

  const parts = [parent, pageDetails].filter((item) => Boolean(item))
  return parts.join(' - ')
}

export const getPageRollup = (pageName: string): string => {
  switch (pageName) {
    case DtmPageName.Dashboard:
    case DtmPageName.News:
      return DtmPageRollup.HomePage

    default:
      return pageName
  }
}

export const getTabName = (pageName: string): string => {
  const customClickName = sessionStorage.getItem(AnalyticsStorageKey.CUSTOM_CLICK_NAME)
  switch (pageName) {
    case DtmPageName.ContactUs:
      return `My Account: ${pageName}`
    case DtmPageName.Dashboard:
      return customClickName == null ? 'Dashboard: Landing Page' : 'Dashboard: My Dashboard'
    default:
      return pageName
  }
}

export const getApplicationRevisionId = () => {
  var userAgent = navigator.userAgent || navigator.vendor
  var device = 'web'
  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
    device = 'iOS'
  } else if (userAgent.match(/Android/i)) {
    device = 'Android'
  }
  return device + ':' + navigator.appVersion
}

interface ICustomClickOptions {
  usePageName?: boolean
}

export const setCustomClick = (customClickName: string, { usePageName }: ICustomClickOptions = {}) => {
  if (usePageName) {
    const { pageName } = AnalyticsStore
    customClickName = `${pageName}: ${customClickName}`
  }

  sessionStorage.setItem(AnalyticsStorageKey.CUSTOM_CLICK_NAME, customClickName)
  AnalyticsStore.customClickName = customClickName
}

export interface IAnalyticsState {
  enableDtmAnalytics: boolean
  environment: string
  customClickName?: string
  pageName?: string
  pageDetails?: string
  pageRollup?: string
  tabName?: string
  loadStart?: Date
}

export const AnalyticsStore: IAnalyticsState = {
  enableDtmAnalytics: isSystemSettingsEnabled(SystemSettingsCode.ENABLE_DTM_ANALYTICS_TRACE),
  environment: getSystemSetting(SystemSettingsCode.DTM_ANALYTICS_ENVIRONMENT),
  customClickName: sessionStorage.getItem(AnalyticsStorageKey.CUSTOM_CLICK_NAME)
}

if (AnalyticsStore.enableDtmAnalytics) {
  getGeoLocation()
}

export const setPageInfo = (pageName: string) => {
  AnalyticsStore.pageName = pageName
  AnalyticsStore.pageDetails = getPageDetails(window.location.pathname, pageName)
  AnalyticsStore.pageRollup = getPageRollup(pageName)
  AnalyticsStore.tabName = getTabName(pageName)
  AnalyticsStore.loadStart = new Date()
}

const geolocationErrorMap = {
  '1': 'PERMISSION_DENIED',
  '2': 'POSITION_UNAVAILABLE',
  '3': 'TIMEOUT'
}

export function getGeoLocation() {
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  }

  function success(pos) {
    var geoString = {
      timestamp: pos['timestamp'],
      coords: {
        latitude: pos['coords']['latitude'],
        longitude: pos['coords']['longitude'],
        accuracy: pos['coords']['accuracy'],
        altitude: pos['coords']['altitude'],
        speed: pos['coords']['speed']
      }
    }
    sessionStorage.setItem(AnalyticsStorageKey.GEO_LOCATION, JSON.stringify(geoString))

    sessionStorage.setItem(
      SessionStorageKey.USER_LOCATION,
      JSON.stringify({
        lat: pos['coords']['latitude'],
        long: pos['coords']['longitude']
      })
    )
  }

  function error(err) {
    var errorStr = geolocationErrorMap[err.code.toString()] || 'Error'
    sessionStorage.setItem(AnalyticsStorageKey.GEO_LOCATION, JSON.stringify({ error: errorStr }))
  }

  navigator.geolocation.getCurrentPosition(success, error, options)
}

export const getInventoryClickName = (ft?: ISearchFilterResult[]) => {
  if (!ft) {
    return ''
  }

  const modelFilter =
    ft.find((item) => item.FieldName === srpFilters.Series)?.ChildrenFilter?.find((item) => item.Selected)?.Value || ''

  const yearFilter =
    ft.find((item) => item.FieldName === srpFilters.Year)?.ChildrenFilter?.find((item) => item.Selected)?.Value || ''

  return `:${[modelFilter, yearFilter].filter(Boolean).join(':')}`
}

export function getListings(listModel: IListingModel[], siteCode: string, vendorCode: string) {
  let listedVehicle: VehicleList[] = []
  listModel?.forEach((model) => {
    const auctionID = model.auctionInfo?.ID
    const vin = model.vehicle?.VIN || model.Vin || model.VIN

    if (auctionID) {
      listedVehicle.push({ id: `${siteCode}.${vendorCode}.${auctionID}`, vin: vin })
    } else {
      listedVehicle.push({ vin: vin })
    }
  })
  return listedVehicle
}

export function getSearchParameters(response: ISearchData): IDtmSearchParams {
  if (!response) {
    return {}
  }

  const allCirteria = []
  response.filters?.forEach((filterParent) => {
    filterParent.ChildrenFilter.forEach((filter) => {
      if (filter.Selected) {
        allCirteria.push({ [filter.FieldName]: filter.FriendlyValue })
      }
    })
  })

  const query: QueryCriteria = {
    allCriteria: allCirteria,
    id: '',
    resultsCount: `${response.TotalRecords}`,
    resultsPerPage: `${response.PageSize}`
  }

  const sort = response.sortOptions?.find((item) => item.Value === response.sortBy?.Value)
  const sortName = sort?.Name.split(' -')[0]
  const sortOrder = sort?.SortOrder === SortOrder.ASC ? 'ASC' : 'DESC'

  return { sortBy: `${sortName}:${sortOrder}`, query }
}
