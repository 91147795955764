import { ThemedSvg } from './ThemedSvg.styled'
import { IconProps } from 'core/typing/IconType'

export const Transmission = ({ width = '16px', height = '16px', color = 'currentColor' }: IconProps) => {
  return (
    <ThemedSvg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 270 240">
      <path d="m 31.516347,232.71358 c -13.084621,-4.66453 -20.547812,-18.97977 -16.919641,-32.45377 1.704343,-6.32945 7.892051,-14.07651 13.601219,-17.02883 l 3.802074,-1.96613 V 133.33333 85.401811 L 28.197925,83.435682 C 22.488757,80.483357 16.301049,72.736301 14.596706,66.406853 8.392049,43.364515 32.762453,24.512183 54.343317,35.659875 c 12.184299,6.293853 17.297552,23.591881 10.742049,36.340123 -2.010356,3.909465 -5.266819,7.393691 -9.346305,10 l -6.260938,4 L 49.405723,105 49.333332,124 H 86.666664 124 V 104.79837 85.596734 l -4.48093,-2.643811 c -17.29629,-10.205061 -17.8239,-34.44839 -1.00693,-46.2678 5.0061,-3.518417 6.85039,-4.018457 14.82119,-4.018457 7.9708,0 9.81509,0.50004 14.82119,4.018457 16.74064,11.765758 16.21085,36.109063 -1.00694,46.2678 l -4.48092,2.643811 V 104.79837 124 L 173,123.99506 c 27.73306,-0.005 30.69925,-0.22809 34.60193,-2.6077 6.57964,-4.01184 8.83458,-9.64974 9.35404,-23.387312 l 0.45374,-11.999999 -6.37152,-4 c -17.00346,-10.674652 -16.26578,-37.274113 1.28515,-46.340123 10.815,-5.586537 22.85383,-4.138815 31.64752,3.805754 5.01711,4.532663 9.37128,13.735207 9.35503,19.771888 -0.024,8.93087 -6.79135,20.027174 -14.71196,24.123081 -3.60344,1.863414 -3.66478,2.110797 -4.19775,16.931891 -0.46736,12.99648 -1.01388,15.94698 -4.02087,21.70751 -4.37012,8.37189 -9.23113,13.0135 -17.72865,16.92852 -6.26499,2.88641 -8.57467,3.09681 -38.33333,3.49204 l -31.66667,0.42056 v 19.11441 19.1144 l 4.48092,2.64381 c 16.09454,9.496 17.81883,33.70862 3.18715,44.75401 -9.93469,7.49968 -23.17176,7.83286 -32.84351,0.82667 -15.83831,-11.47321 -14.7616,-35.67466 2.02785,-45.58068 L 124,181.06993 V 161.86829 142.66666 H 86.666664 49.333332 l 0.0724,19 0.0724,19 6.260939,4 c 8.774502,5.60587 12.586766,13.31282 11.959678,24.17791 -0.636803,11.03337 -5.306159,18.16373 -14.784566,22.57684 -7.777393,3.6211 -13.838167,3.9871 -21.397826,1.29217 z" />
    </ThemedSvg>
  )
}
