import { BMW_VENDOR_CODE, NISSAN_VENDOR_CODE } from 'common/constants'
import { BMWCustomStyle } from 'core/skins/BMW/custom'
import { theme as BMWTheme } from 'core/skins/BMW/theme'
import { NissanCustomStyle } from 'core/skins/NISSAN/custom'
import { theme as NISSANTheme } from 'core/skins/NISSAN/theme'
import { PropsWithChildren } from 'react'
import { useGlobalStore } from 'store/useGlobalStore'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import shallow from 'zustand/shallow'

const getVendorTheme = (code: string) => {
  switch (code) {
    case BMW_VENDOR_CODE:
      return BMWTheme
    case NISSAN_VENDOR_CODE:
      return NISSANTheme
    default:
      return BMWTheme
  }
}

const GlobalStyle = createGlobalStyle`
`

export const PortalThemeProvider = ({ children }: PropsWithChildren<any>) => {
  const vendor = useGlobalStore((state) => state.vendor, shallow)

  const theme = getVendorTheme(vendor?.Code)
  let RootComponent = BMWCustomStyle

  switch (vendor?.Code) {
    case 'NISUS001':
      RootComponent = NissanCustomStyle
      break
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <RootComponent>{children}</RootComponent>
    </ThemeProvider>
  )
}
