import {
  NotifyContainer,
  SaveSearchContainer,
  SaveClearAllFilterGroupAction,
  SaveCancelGroupAction,
  SaveLink,
  SaveSearchFormBody,
  ActionSeparator
} from './SaveSearch.styled'
import { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { loadDayFiles, saveSearch } from 'apis/vehicleApis'
import { ISearchResponseData, SavedSearch, SavedSearchData } from 'types/vehicleTypes'
import { Formik } from 'formik'
import { useFetch } from 'hooks/useFetch'
import { Operation } from 'types/baseTypes'
import { TextDanger } from 'components/Forms'
import { DEFAULT_ERROR_MESSAGE, SessionStorageKey } from 'common/constants'
import { Rules } from 'common/rules'
import { useGlobalStore } from 'store/useGlobalStore'
import { useDtmAnalytics } from 'hooks/useDtmAnalytics'
import { HTMLText } from 'components/Share/HtmlText'
import { parseHtml } from 'utils/stringUtils'

interface IProps {
  filtersData: ISearchResponseData[]
  onClearFilterClicked?: () => void
  onToggleSaveSearch?: (isOpen: boolean) => void
}

export const SaveSearch = ({ filtersData, onClearFilterClicked, onToggleSaveSearch }: IProps) => {
  const [showSaveSearch, setShowSaveSearch] = useState<boolean>(false)
  const [showErrorMsg, setShowErrorMsg] = useState<boolean>(false)

  const { isInRule } = useGlobalStore()
  const canSaveSearch = isInRule(Rules.VIEW_SAVED_SEARCH_PAGE)
  const { userInteraction } = useDtmAnalytics()
  const getLocalText = useGlobalStore((state) => state.getLocalText)

  const { data: { search: currrentSearch, days, frequency, durations } = {} as SavedSearchData } = useFetch(() =>
    loadDayFiles()
  )

  const handleSaveSearch = async (searchData: SavedSearch) => {
    filtersData.forEach((item) => {
      if (item.DBFieldName === 'VIN' && item.Value !== '') {
        item.FriendlyValue = item.Value
        item.Selected = true
        item.ChildrenFilter = []
        item.Operator = Operation.Contains
      }
    })
    userInteraction(`BuyerSearch: SaveSearch`)
    try {
      await saveSearch({ filters: filtersData, searchData })
      setShowSaveSearch(false)
    } catch (error) {
      setShowErrorMsg(true)
    }
  }

  const descriptionChange = (event, props) => {
    props.setFieldValue('Description', event.target.value)
  }

  useEffect(() => {
    onToggleSaveSearch(showSaveSearch)
  }, [onToggleSaveSearch, showSaveSearch])

  return (
    <SaveSearchContainer>
      <SaveClearAllFilterGroupAction>
        {canSaveSearch && (
          <>
            <Button
              variant="link"
              onClick={() => {
                setShowSaveSearch(true)
              }}
            >
              Save Search
            </Button>
            <ActionSeparator />
          </>
        )}
        <Button variant="link" onClick={onClearFilterClicked} disabled={showSaveSearch}>
          Clear All Filters
        </Button>
      </SaveClearAllFilterGroupAction>

      {canSaveSearch && (
        <Formik
          initialValues={{
            ...currrentSearch,
            Description: sessionStorage.getItem(SessionStorageKey.SEARCH_DESCRIPTION)
          }}
          enableReinitialize={true}
          onSubmit={(values) => handleSaveSearch(values)}
        >
          {(props) => (
            <SaveSearchFormBody show={showSaveSearch} noValidate onSubmit={props.handleSubmit} className="container">
              <Form.Group className="mb-3" controlId="Description">
                <Form.Control
                  name="Description"
                  onChange={(e) => descriptionChange(e, props)}
                  value={props.values.Description}
                  placeholder="Enter Save Search Title"
                />
                <Form.Control name="verified" hidden placeholder="Enter Save Search Title" />
              </Form.Group>
              <Row>
                <NotifyContainer>
                  Notify Me via Email?
                  <Form.Group className="mb-3" controlId="NotifyMe">
                    <Form.Check
                      type="radio"
                      name="NotifyMe"
                      label="Yes"
                      checked={props.values.NotifyMe === true}
                      onChange={(event) => props.setFieldValue('NotifyMe', event.target.checked)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="NotifyMe">
                    <Form.Check
                      type="radio"
                      name="NotifyMe"
                      label="No"
                      checked={!props.values.NotifyMe}
                      onChange={(event) => props.setFieldValue('NotifyMe', !event.target.checked)}
                    />
                  </Form.Group>
                </NotifyContainer>
              </Row>
              {props.values.NotifyMe && (
                <>
                  <Row className="mb-1">
                    <Col>
                      <HTMLText value={parseHtml(getLocalText('NOTIFICATION_COMMENT'))}></HTMLText>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Group className="mb-3" as={Col} xs={12} sm={6} lg={4} controlId="EmailRecurrance">
                      <Form.Control
                        as="select"
                        custom
                        name="EmailRecurrance"
                        onChange={props.handleChange}
                        value={props.values.EmailRecurrance}
                      >
                        {frequency?.map((option) => {
                          return (
                            <option key={option.ID} value={option.ID}>
                              {option.Description}
                            </option>
                          )
                        })}
                      </Form.Control>
                    </Form.Group>
                    {props.values.EmailRecurrance > 1 && (
                      <Form.Group className="mb-3" as={Col} xs={12} sm={6} lg={4} controlId="EmailDay">
                        <Form.Control
                          as="select"
                          custom
                          name="EmailDay"
                          onChange={props.handleChange}
                          value={props.values.EmailDay}
                        >
                          {days?.map((option) => {
                            return (
                              <option key={option.ID} value={option.ID}>
                                {option.Description}
                              </option>
                            )
                          })}
                        </Form.Control>
                      </Form.Group>
                    )}

                    <Form.Group className="mb-3" as={Col} xs={12} sm={6} lg={4} controlId="EmailRecurranceDuration">
                      <Form.Control
                        as="select"
                        custom
                        name="EmailRecurranceDuration"
                        onChange={props.handleChange}
                        value={props.values.EmailRecurranceDuration}
                      >
                        {durations?.map((option) => {
                          return (
                            <option key={option.ID} value={option.ID}>
                              {option.Description}
                            </option>
                          )
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Row>
                </>
              )}

              <Row>
                <SaveCancelGroupAction>
                  <SaveLink type="submit" disabled={!props.values.Description} variant="link">
                    Save
                  </SaveLink>
                  <Button variant="link" onClick={() => setShowSaveSearch(false)}>
                    Cancel
                  </Button>
                </SaveCancelGroupAction>
              </Row>
              <Row>
                <Col>{showErrorMsg && <TextDanger>{DEFAULT_ERROR_MESSAGE}</TextDanger>}</Col>
              </Row>
            </SaveSearchFormBody>
          )}
        </Formik>
      )}
    </SaveSearchContainer>
  )
}
