import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { zIndex } from 'common/constants'

interface IShowProps {
  show: boolean
}
export const StyledScrollButton = styled(Button)<IShowProps>`
  position: fixed;
  bottom: 7rem;
  right: 5rem;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: #fff;
  border: 0.1rem solid #ccc;
  box-shadow: 0 0 0.5rem 0.2rem rgb(0 0 0 / 10%);
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: bold;
  z-index: ${zIndex.stickyHeader};

  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover .fa-angle-up {
    color: ${({ theme }) => theme.colors.primary};
  }
  .circle {
    border-radius: 50%;
    background-color: #fff;
    border: 0.2rem solid ${({ theme }) => theme.colors.primary};
    width: 2rem;
    height: 2rem;
  }
`
