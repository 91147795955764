import { Row, Image } from 'react-bootstrap'
import {
  LayoutContainer,
  StyledLeftContainer,
  LeftFooterContainer,
  StyledLoginFormFooter,
  RightContainer,
  RightFooterContainer,
  StyledFooter,
  LoginContainerColStyled,
  LinkBaseStyled
} from './LoginLayout.styled'
import leftIcon from '../images/PRIVATE_STORE_BY_MANHEIM.png'
import { MultiLanguageCode } from 'common/languageCodes'
import { FormattedMessage, useIntl } from 'react-intl'
import parse from 'html-react-parser'
import { HTMLText } from 'components/Share/HtmlText'
import { rmsAuthModel } from 'common/rmsGlobal'
import { FAQ, TERM_AND_CONDITION, vendorImages } from 'common/constants'

export const LoginLayout = ({ children }) => {
  const intl = useIntl()
  return (
    <LayoutContainer>
      <LoginContainerColStyled xs={{ span: 12, order: 2 }} lg={{ span: 6, order: 1 }} xl={{ span: 4 }}>
        <Row />
        <StyledLeftContainer>{children}</StyledLeftContainer>
        <Row>
          <StyledFooter>
            <Row>
              <LeftFooterContainer>
                <HTMLText value={parse(intl.formatMessage({ id: MultiLanguageCode.COPYRIGHT_TEXT }))} />
              </LeftFooterContainer>
              <RightFooterContainer>
                <Image src={leftIcon} />
              </RightFooterContainer>
            </Row>
          </StyledFooter>
        </Row>
      </LoginContainerColStyled>
      <RightContainer
        className="d-md-flex justify-content-end p-0  mh-100"
        xs={{ span: 12, order: 1 }}
        lg={{ span: 6, order: 2 }}
        xl={{ span: 8 }}
      >
        <Image src={rmsAuthModel?.loginBackgroundUrl} fluid className="main-img max-vh-100 w-100" />
        <Image src={vendorImages.leftLogo} className="img-corner-left" fluid />
        <Image src={vendorImages.rightLogo} className="img-corner-right" fluid />
      </RightContainer>
    </LayoutContainer>
  )
}

export const LoginFormFooter = () => {
  return (
    <StyledLoginFormFooter>
      <LinkBaseStyled to="/terms">{TERM_AND_CONDITION}</LinkBaseStyled>
      <LinkBaseStyled to="/privacy">
        <FormattedMessage id={MultiLanguageCode.PRIVACY_POLICY_HEADER} />
      </LinkBaseStyled>
      <LinkBaseStyled to="/contactUs">
        <FormattedMessage id={MultiLanguageCode.CONTACT_US} />
      </LinkBaseStyled>
      <LinkBaseStyled to="/faqs">{FAQ}</LinkBaseStyled>
    </StyledLoginFormFooter>
  )
}
