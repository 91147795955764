import { LayoutContext } from 'contexts/LayoutContext'
import { useScrollPosition } from 'hooks/useScrollPosition'
import { AcceptenceTerm } from 'modules/AcceptenceTerm'
import GenericMessageWarning from 'modules/GenericMessageWarning'
import { ReactNode, useContext, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { useGlobalStore } from 'store/useGlobalStore'
import shallow from 'zustand/shallow'

import { Footer } from './Footer'
import { Header } from './Header'
import { StyledBody, StyledLayout, StyledMenuHeader } from './Layout.styled'
import { NavBar } from './NavBar'
import { UserMenu } from './UserMenu'

interface IProps {
  title?: string
  children: ReactNode
}

export const Layout = ({ title, children }: IProps) => {
  const [userClaims, isAuthenticated] = useGlobalStore((state) => [state.userClaims, state.isAuthenticated], shallow)
  const { setIsSticky } = useContext(LayoutContext)

  const isShowFooter = useMemo(
    () => ((userClaims.IsTermAccepted && userClaims.Username) || !userClaims.Username ? true : false),
    [userClaims.IsTermAccepted, userClaims.Username]
  )

  const location = useLocation()

  useScrollPosition(
    ({ currPos }) => {
      const headerHeight = document.querySelector<HTMLElement>('.main-nav').offsetHeight
      if (currPos.y <= -headerHeight) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
    },
    [location.pathname]
  )

  return (
    <StyledLayout>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header className="main-nav" />
      <Header enableSticky />
      <StyledMenuHeader>
        <NavBar />
        <UserMenu showInMobile={false} />
      </StyledMenuHeader>
      <StyledBody>{isAuthenticated && !userClaims.IsTermAccepted ? <AcceptenceTerm /> : children}</StyledBody>
      {isShowFooter && <Footer />}
      <GenericMessageWarning />
    </StyledLayout>
  )
}
