import { getSecretQuestion, sendPasswordReset } from 'apis/userApis'
import { LoginFormFooter, LoginLayout } from 'layouts/LoginLayout'
import { useRef, useState } from 'react'
import { Form, Row } from 'react-bootstrap'
import {
  StyledForgotUserLink,
  StyledForgotPassword,
  LoginLinkWrapper,
  StyledResetPassword,
  StyledLable
} from './ForgotPassword.styled'
import { ISecretQuestionResponse } from 'types/rmsAuthModel'
import { FORGOT_LABLE, INVALID_USER, RESET_PW } from 'common/messages'
import { BackToLoginLink, LoginTitle } from 'layouts/LoginLayout.styled'
import { CONTINUE, SUBMIT } from 'common/constants'
import { OverlayLoader } from 'components/Loader'
import { FormInput, TextDanger } from 'components/Forms'
import { useFormik } from 'formik'
import { Helmet } from 'react-helmet'
import { StyledSubmitButton } from './Login.styled'

export const ForgotPassword = () => {
  const [secretQuestion, setSecretQuestion] = useState<ISecretQuestionResponse>()
  const [resetPassword, setResetPassword] = useState<string>()
  const [showAlert, setShowAlert] = useState<boolean>()
  const [isLoading, setIsLoading] = useState<boolean>()

  const forgotForm = useRef<HTMLFormElement>()
  const getSecretQuestionHandler = async (values) => {
    setResetPassword(null)
    setIsLoading(true)
    setShowAlert(false)

    try {
      const result = await getSecretQuestion(values.userID.toString())
      setSecretQuestion(result)
    } catch (error) {
      formik.setFieldError('userID', error.Message)
    } finally {
      setIsLoading(false)
    }
  }
  const sendPasswordResetHandler = async (values, { setStatus, setErrors }) => {
    setResetPassword(null)
    setIsLoading(true)
    try {
      const result = await sendPasswordReset(
        values.userID.toString(),
        values.answer.toString(),
        secretQuestion?.QuestionId
      )
      setResetPassword(result)
    } catch (error) {
      error && error.Message === INVALID_USER
        ? formik.setFieldError('userID', error.Message)
        : formik.setFieldError('answer', error.Message)
    } finally {
      setIsLoading(false)
    }
  }

  const validate = (values) => {
    const errors: any = {}
    if (!values.userID) {
      errors.userID = 'Please Enter a User ID'
    }
    if (!values.answer && secretQuestion) {
      errors.answer = 'Please Enter answer'
    }

    return errors
  }

  const formik = useFormik({
    initialValues: {
      userID: '',
      answer: ''
    },
    validate,
    onSubmit: secretQuestion?.QuestionId ? sendPasswordResetHandler : getSecretQuestionHandler
  })
  return (
    <LoginLayout>
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <StyledForgotPassword>
        <Row>
          <LoginTitle>Forgot Your Password?</LoginTitle>
        </Row>
        <Row>
          {resetPassword ? (
            <StyledResetPassword>{RESET_PW}</StyledResetPassword>
          ) : (
            <Form ref={forgotForm} onSubmit={formik.handleSubmit}>
              <StyledLable>{FORGOT_LABLE}</StyledLable>
              <FormInput
                name="userID"
                type="text"
                error={formik.errors.userID}
                label="User ID"
                onChange={formik.handleChange}
                value={formik.values.userID}
                isInvalid={!!formik.errors.userID}
                disabled={secretQuestion ? true : false}
                className="forgot-user-id"
              />
              {!secretQuestion && !showAlert && (
                <StyledForgotUserLink onClick={() => setShowAlert(true)}>Forgot User ID?</StyledForgotUserLink>
              )}
              {showAlert && (
                <TextDanger>
                  To recover your User ID, please contact Customer Care at: <br />
                  844-266-0437
                </TextDanger>
              )}
              {secretQuestion && (
                <FormInput
                  name="answer"
                  type="text"
                  error={formik.errors.answer}
                  label={secretQuestion.Question}
                  onChange={formik.handleChange}
                  value={formik.values.answer}
                  isInvalid={!!formik.errors.answer}
                />
              )}
              <StyledSubmitButton type="submit" disabled={isLoading}>
                {!secretQuestion ? CONTINUE : SUBMIT}
              </StyledSubmitButton>
            </Form>
          )}
          {isLoading && <OverlayLoader />}
          <LoginLinkWrapper>
            <BackToLoginLink className="login-link" to={'/login'}>
              Back to Login
            </BackToLoginLink>
          </LoginLinkWrapper>
          <LoginFormFooter />
        </Row>
      </StyledForgotPassword>
    </LoginLayout>
  )
}
