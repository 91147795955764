import { forwardRef } from 'react'
import { AdditionalChargesPopover } from './PurchaseHistoryListSection.styled'
import { Popover, PopoverProps } from 'react-bootstrap'
import { IVehicleCharges } from 'types/purchaseHistoryTypes'
interface IProps extends Omit<PopoverProps, 'id'> {
  charges: IVehicleCharges[]
}
export const ChargePopover = forwardRef(({ charges, ...rest }: IProps, ref) => {
  return (
    <AdditionalChargesPopover id="charges-popover" ref={ref} {...rest}>
      <Popover.Title>Additional Charges</Popover.Title>
      <Popover.Content>
        {charges.map((c) => (
          <div className="charges-content mb-3" key={c.VehicleChargeTypeDescription}>
            <div className="description">{`${c.VehicleChargeTypeDescription}:`}</div>
            <div className="price-string">{c.PriceString}</div>
          </div>
        ))}
      </Popover.Content>
    </AdditionalChargesPopover>
  )
})
