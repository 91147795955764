import { Button, Col, Form, Row } from 'react-bootstrap'
import { SearchSectionStyled } from './SearchSection.styled'
import { useContext, useState } from 'react'
import { DateRangePicker } from 'components/DateRangePicker/DatePicker'
import { AuctionHistoryContext } from 'contexts/BidHistoryContext'
import { IAuctionHistoryRequest } from 'types/baseTypes'

export const SearchSection = () => {
  const { searchFilter, updateSearchFilter } = useContext(AuctionHistoryContext)
  const [tempSearchFilter, setTempSearchFilter] = useState<IAuctionHistoryRequest>(searchFilter)
  return (
    <SearchSectionStyled>
      <Row className="history-search-section">
        <Col lg={6} sm={12} className="search-date-contain">
          <div className="search-date-main">
            <Row>
              <Col sm={12} md={3} xl={3} lg={12}>
                <label className="header-label">Choose a Date Range</label>
              </Col>
              <Col>
                <DateRangePicker
                  fromDate={new Date(tempSearchFilter?.FromDate)}
                  setFromDate={(date) => setTempSearchFilter({ ...tempSearchFilter, FromDate: date, VIN: null })}
                  toDate={new Date(tempSearchFilter?.ToDate)}
                  setToDate={(date) => setTempSearchFilter({ ...tempSearchFilter, ToDate: date, VIN: null })}
                  disabledMaxDate={false}
                />
              </Col>
              <Col sm={4} md={3} xl={3} lg={4}>
                <Button
                  type="submit"
                  className="btn-search"
                  variant="outline-primary"
                  disabled={!!!tempSearchFilter?.FromDate || !!!tempSearchFilter?.ToDate}
                  onClick={() => {
                    if (tempSearchFilter?.PageNumber === 0) {
                      updateSearchFilter({ ...tempSearchFilter, VIN: null })
                    } else {
                      updateSearchFilter({ ...tempSearchFilter, VIN: null, PageNumber: 0 })
                    }
                    setTempSearchFilter({ ...tempSearchFilter, VIN: null })
                  }}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
        <Col lg={6} sm={12} className="search-vin-contain">
          <div className="search-vin-main">
            <Row>
              <Col sm={12} lg={12} xl={2} md={3}>
                <label className="header-label">Enter VIN</label>
              </Col>
              <Col sm={8} lg={8} xl={7} md={6} className="input-vin-contain">
                <Form.Control
                  type="text"
                  value={tempSearchFilter?.VIN ? tempSearchFilter?.VIN : ''}
                  placeholder="VIN #"
                  className="input-vin"
                  onChange={({ target }) => setTempSearchFilter({ ...tempSearchFilter, VIN: target.value })}
                />
              </Col>

              <Col sm={4} lg={4} xl={3} md={3} className="search-vin-btn">
                <Button
                  type="submit"
                  className="btn-search"
                  variant="outline-primary"
                  disabled={!!!tempSearchFilter?.VIN}
                  onClick={() => {
                    if (tempSearchFilter?.PageNumber === 0) {
                      updateSearchFilter({ ...tempSearchFilter })
                    } else {
                      updateSearchFilter({ ...tempSearchFilter, PageNumber: 0 })
                    }
                  }}
                >
                  Search VIN
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </SearchSectionStyled>
  )
}
