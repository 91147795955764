import { SlideshowGallery } from 'modules/VDP/SlideShowGallery'
import React, { createContext, useState } from 'react'
import { IVehicleImage } from 'types/vehicleTypes'
export interface ISliderContext {
  setDisplaySlider: (isShow: boolean, imageID: number, vehicleInstance: number) => void
  setSliderData: (images: IVehicleImage[], isShowCarousel?: boolean) => void
  setCurrentImageID: (id: number) => void
  selectedImageID: number
  selectedVehicle: number
  showCarousel: boolean
}

export const SliderContext = createContext<ISliderContext>({} as ISliderContext)

export const SliderContextProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [showSlider, setShowSlider] = useState<boolean>(false)
  const [images, setImages] = useState<IVehicleImage[]>(null)
  const [selectedImageID, setSelectedImageID] = useState<number>()
  const [selectedVehicle, setSelectedVehicle] = useState<number>()
  const [showCarousel, setShowCarousel] = useState<boolean>(true)

  const setDisplaySlider = (isShow: boolean, imageID: number, vehicleInstance: number) => {
    setShowSlider(isShow)
    setSelectedImageID(imageID)
    setSelectedVehicle(vehicleInstance)
  }

  const setSliderData = (images: IVehicleImage[], isShowCarousel: boolean = true) => {
    setImages(images)
    setShowCarousel(isShowCarousel)
  }
  const setCurrentImageID = (id: number) => {
    setSelectedImageID(id)
  }

  return (
    <SliderContext.Provider
      value={{
        setDisplaySlider,
        setSliderData,
        setCurrentImageID,
        selectedImageID,
        selectedVehicle,
        showCarousel
      }}
    >
      <SlideshowGallery
        show={showSlider}
        onHide={() => {
          setShowSlider(false)
          setImages([])
        }}
        images={images}
        defaultID={selectedImageID}
        showCarousel={showCarousel}
      ></SlideshowGallery>
      {children}
    </SliderContext.Provider>
  )
}
