import classNames from 'classnames'
import { vendorImages } from 'common/constants'
import { NavLink } from 'components/Share/Link'
import { LayoutContext } from 'contexts/LayoutContext'
import { useContext } from 'react'
import Image from 'react-bootstrap/Image'

import BurgerButton from './BurgerButton'
import { StyledBranding, StyledHeader } from './Layout.styled'

interface IProps {
  enableSticky?: boolean
  className?: string
}

export const Header = ({ enableSticky = false, className }: IProps) => {
  const { isSticky: sticky } = useContext(LayoutContext)

  const isSticky = sticky && enableSticky
  return (
    <StyledHeader
      style={{
        position: enableSticky ? 'sticky' : null,
        display: enableSticky && !sticky ? 'none' : null
      }}
      isSticky={isSticky}
      className={classNames('d-print-none', className)}
    >
      <StyledBranding isSticky={isSticky}>
        <NavLink to="/">
          <Image className="img-fluid" src={isSticky ? vendorImages.leftLogo : vendorImages.leftLogoDark} alt="" />
        </NavLink>
        <NavLink to="/">
          <Image
            className="img-fluid fixed-height"
            src={isSticky ? vendorImages.rightLogo : vendorImages.rightLogoDark}
            alt=""
          />
        </NavLink>
      </StyledBranding>
      <BurgerButton isSticky={isSticky} />
    </StyledHeader>
  )
}
