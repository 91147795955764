import { Button, Col } from 'react-bootstrap'
import styled from 'styled-components'

export const StyledFormContainer = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledSubTitle = styled.div`
  margin-bottom: 3rem;
  font-size: 1.8rem;
`

export const StyledCol = styled(Col)`
  max-width: 36rem;
`

export const StyledSubmitButton = styled(Button)`
  height: 5rem;
  font-size: 1.8rem;
  width: 100%;
  margin-top: 1rem;
`
