import { SessionStorageKey } from 'common/constants'
import log from 'utils/logger'
import create from 'zustand'

export interface ISignalRState {
  connectionID?: string
  showConnectionAlert?: boolean
  setShowConnectionAlert: (show?: boolean) => void
  connect: () => void
  susbcribeEvents: () => void
  subscribe: (ids: number[]) => Promise<void>
}

export const useSignalRStore = create<ISignalRState>((set, get) => ({
  connect: async () => {
    try {
      await $.connection.hub.start().promise()

      // SignalR connection id might not be available at this point
      if ($.connection.hub.id) {
        set((state) => ({ ...state, connectionID: $.connection.hub.id }))
      }
    } catch (err) {
      console.error('SignalR: Could not connect to server')
    }
  },
  susbcribeEvents: async () => {
    $.connection.hub.starting(() => {
      log.info('SignalR: Connection starting')
      set((state) => ({ ...state, showConnectionAlert: false }))
    })

    $.connection.hub.connectionSlow(() => {
      log.info('SignalR: Slow connection detected')
    })

    $.connection.hub.stateChanged((state) => {
      const connectionStates = {
        0: 'connecting',
        1: 'connected',
        2: 'reconnecting',
        4: 'disconnected'
      }
      log.info(
        'SignalR: Connection state changed from: ' +
          connectionStates[state.oldState] +
          ' to: ' +
          connectionStates[state.newState]
      )
      if (state.newState === 1) {
        sessionStorage.removeItem(SessionStorageKey.SIGNAL_R_CONNECTION_TRY_RECOUNT)
        set((state) => ({ ...state, showConnectionAlert: false }))

        // SignalR connection id is available at this point
        if ($.connection.hub.id) {
          set((state) => ({ ...state, connectionID: $.connection.hub.id }))
        }
      }
    })

    $.connection.hub.reconnected(() => {
      log.info('SignalR: Reconnected')
      set((state) => ({ ...state, showConnectionAlert: false }))
    })

    $.connection.hub.disconnected(() => {
      var signalRConnectionRetryCount = sessionStorage.getItem(SessionStorageKey.SIGNAL_R_CONNECTION_TRY_RECOUNT)
      if (signalRConnectionRetryCount == null) {
        signalRConnectionRetryCount = '0'
      }
      var retryCount = parseInt(signalRConnectionRetryCount)
      log.info('SignalR: Reconnecting retry count:' + retryCount)
      if (retryCount < 3) {
        retryCount++
        sessionStorage.setItem(SessionStorageKey.SIGNAL_R_CONNECTION_TRY_RECOUNT, retryCount.toString())
        get().connect()
      } else {
        set((state) => ({ ...state, showConnectionAlert: true }))
      }
    })
  },
  setShowConnectionAlert: (show?: boolean) => set((state) => ({ ...state, showConnectionAlert: show })),
  subscribe: async (ids: number[]) => {
    if (get().connectionID) {
      await $.connection.notificationHub.server.subscribe(ids).promise()
    }
  }
}))
