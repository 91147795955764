import { useCountdown } from 'hooks/useCountdown'
import { StyledAuctionTimeRemaining } from './CountDown.styled'
import { useGlobalStore } from 'store/useGlobalStore'

interface IProps {
  endDate: string | Date
  color?: string
}

export const CountDown = ({ endDate, color }: IProps) => {
  const getSystemSettings = useGlobalStore((state) => state.getSystemSetting)
  const minuteToShowSecond = (getSystemSettings('SHOW_SECONDS_AT_MINUTE') as number) ?? 5

  const { seconds } = useCountdown(endDate)
  const d = Math.floor(seconds / (3600 * 24))
  const h = Math.floor((seconds % (3600 * 24)) / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor(seconds % 60)

  let displayTime = ''
  if (d) {
    displayTime += `${d}d `
  }
  if (h) {
    displayTime += `${h}h `
  }
  if (m) {
    displayTime += `${m}m `
  }
  if (seconds < minuteToShowSecond * 60) displayTime += `0${s}s`.slice(-3)

  return (
    seconds > 0 && (
      <StyledAuctionTimeRemaining color={color}>
        <strong>{displayTime} left</strong>
      </StyledAuctionTimeRemaining>
    )
  )
}
