function BurgerButton(props) {
  return (
    <svg
      stroke="currentColor"
      fill="rgba(0, 0, 0, 0.5)"
      strokeWidth={0}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M32 96v64h448V96H32zm0 128v64h448v-64H32zm0 128v64h448v-64H32z" stroke="none" />
    </svg>
  )
}

export default BurgerButton
