import { IAuctionInfo } from 'types/auctionTypes'
import { IVehicleInfo } from 'types/vehicleTypes'
import { colorDanger, colorWarning, colorWinning, textColor } from 'common/theme'
import { VehicleStatuses } from 'common/constants'
import { CountDown } from './CountDown'

interface IProps {
  vehicleInfo: IVehicleInfo
  auctionInfo: IAuctionInfo
}
export const CountDownWithColor = ({ vehicleInfo, auctionInfo }: IProps) => {
  const timeRemainingColor = () => {
    if (auctionInfo.HasPreviousBid && auctionInfo.IsHighestBidder) return colorWinning
    if (auctionInfo.HasPreviousBid && !auctionInfo.IsHighestBidder) return colorDanger
    if (auctionInfo.IsDirectSale && vehicleInfo.OfferID === 0) return colorWarning
    return textColor
  }

  return (
    <>
      {vehicleInfo.StatusID !== VehicleStatuses.SoldNotFunded && auctionInfo && (
        <CountDown endDate={auctionInfo.EndDate} color={timeRemainingColor()} />
      )}
    </>
  )
}
