import { getAuctionHistory } from 'apis/userApis'
import { AUCTION_HISTORY_INIT_QUERY } from 'common/constants'
import { createContext, useEffect, useState } from 'react'
import { IAuctionHistory, IAuctionHistoryRequest, IBaseCollectionResponse } from 'types/baseTypes'

export interface IAuctionHistoryContext {
  searchFilter: IAuctionHistoryRequest
  result: IBaseCollectionResponse<IAuctionHistory>
  updateSearchFilter: (filters: IAuctionHistoryRequest) => void
  handleSearch: (isVinSearch: boolean) => void
  loading: boolean
}
export const AuctionHistoryContext = createContext<IAuctionHistoryContext>(null)

export const AuctionHistoryProvider = ({ children }) => {
  const [loading, isLoading] = useState<boolean>(false)
  const [result, setResult] = useState<IBaseCollectionResponse<IAuctionHistory>>(null)
  const [searchFilter, setSearchFilter] = useState<IAuctionHistoryRequest>(AUCTION_HISTORY_INIT_QUERY)

  useEffect(() => {
    if (searchFilter) {
      handleSearch(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter])

  const updateSearchFilter = (filters: IAuctionHistoryRequest) => {
    setSearchFilter(filters)
  }

  const handleSearch = async (isVinsearch: boolean) => {
    isLoading(true)
    let result = null
    if (isVinsearch && searchFilter.VIN !== null) {
      result = await getAuctionHistory({
        ...searchFilter,
        FromDate: null,
        ToDate: null,
        SortBy: { ...searchFilter.SortBy, Value: searchFilter.SortBy.Value.split(' ')[0] }
      })
    } else {
      result = await getAuctionHistory({
        ...searchFilter,
        VIN: null,
        SortBy: { ...searchFilter.SortBy, Value: searchFilter.SortBy.Value.split(' ')[0] }
      })
    }
    setResult(result)
    isLoading(false)
  }

  return (
    <AuctionHistoryContext.Provider
      value={{
        result,
        searchFilter,
        updateSearchFilter,
        handleSearch,
        loading
      }}
    >
      {children}
    </AuctionHistoryContext.Provider>
  )
}
