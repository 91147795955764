import { Row, Col } from 'react-bootstrap'
import { HomeSectionHeader } from 'components/Header/HomeSectionHeader'
import { CurrentBids } from './CurrentBids'
import { WatchList } from './WatchList'
import { StyledBuyerActivitySection, StyledHomeSection } from 'components/Header/HomeSectionHeader.styled'
import { useExpand } from 'hooks/useExpand'
import { useDtmAnalytics } from 'hooks/useDtmAnalytics'

const initExpand = {
  componentOpen: true
}

export const BuyerActivity = () => {
  const { state: expandState, action: expandAction } = useExpand({
    componentOpen: initExpand.componentOpen,
    groupOpen: new Array(2).fill(initExpand.componentOpen)
  })
  const { componentOpen, groupOpen } = expandState
  const { setExpand } = expandAction
  const { userInteraction } = useDtmAnalytics()

  return (
    <StyledHomeSection>
      <HomeSectionHeader
        sectionTitle="Buyer Activity"
        onClick={() => {
          setExpand(!componentOpen)
          userInteraction(`Dashboard:InventoryView: BuyerActivity: ${componentOpen ? 'Collapse' : 'Expand'}`)
        }}
        isExpand={componentOpen}
      />
      <StyledBuyerActivitySection>
        <Row>
          <Col xs={0} lg={1}></Col>
          <Col xs={12} lg={5}>
            <CurrentBids isExpand={groupOpen[0]} setExpand={() => setExpand(!groupOpen[0], 0)} />
          </Col>
          <Col xs={12} lg={5}>
            <WatchList isExpand={groupOpen[1]} setExpand={() => setExpand(!groupOpen[1], 1)} />
          </Col>
          <Col xs={0} lg={1}></Col>
        </Row>
      </StyledBuyerActivitySection>
    </StyledHomeSection>
  )
}
