import {
  StyledCarfaxAdvantage,
  StyledCarfaxAdvantageItem,
  StyledCarfaxAdvantageTitleImage,
  StyledNoCarfaxItem
} from './CarfaxAdvantage.styled'
import { ICarFaxSnapshotItem } from 'types/vehicleTypes'
import { carfaxIcon } from 'common/constants'
import { useGlobalStore } from 'store/useGlobalStore'
import { Rules } from 'common/rules'
interface IProps {
  snapshotList: ICarFaxSnapshotItem[]
}

export const CarfaxAdvantage = ({ snapshotList }: IProps) => {
  const isInRule = useGlobalStore((state) => state.isInRule)

  const viewCarfaxAdvantage = isInRule(Rules.VIEW_CARFAX_ADVANCED)

  if (!viewCarfaxAdvantage) {
    return <></>
  }

  return (
    <StyledCarfaxAdvantage className="carfax">
      <StyledCarfaxAdvantageTitleImage>
        <img src={carfaxIcon.carfaxTitleLogo} alt="" />
      </StyledCarfaxAdvantageTitleImage>
      {snapshotList?.length > 0 ? (
        snapshotList.map((item, index) => (
          <StyledCarfaxAdvantageItem key={index} title={item.Value}>
            {item.Icon && (
              <img
                src={carfaxIcon.carfaxBaseUrl + item.Icon}
                alt="carfax-snapshot"
                className={'carfax-snapshot-item'}
              />
            )}
            <span className="snapshot-item-content">{item.Value}</span>
            {item.Value !== 'N/A' && item.ValueCount >= 0 && (
              <span className="carfax-item-count">{item.ValueCount}</span>
            )}
          </StyledCarfaxAdvantageItem>
        ))
      ) : (
        <StyledNoCarfaxItem>
          <span className="snapshot-content">No CARFAX Snapshot items available</span>
        </StyledNoCarfaxItem>
      )}
    </StyledCarfaxAdvantage>
  )
}
