import { cardStyle } from 'components/Share/Card.styled'
import { VehicleContext } from 'modules/DealerVehicleManagement/VehicleContext'
import { PropsWithChildren, useContext } from 'react'
import LazyLoad from 'react-lazyload'
import styled from 'styled-components'
import { StockwaveData } from '../Stockwave/StockwaveData'

interface IProps {
  className?: string
}

export const StyledVehicleLoader = styled(LazyLoad as any)`
  ${cardStyle}
`

export const VehicleLoader = ({ children, className }: PropsWithChildren<IProps>) => {
  const { vehicleData } = useContext(VehicleContext)
  const {
    vehicle: { VIN }
  } = vehicleData

  return (
    <StyledVehicleLoader
      className={className}
      offset={100}
      height={300}
      placeholder={
        <div
          id={`vehicle_${VIN}`}
          style={{ height: 300 }}
          className="stockwave-vehicle-row stockwave-highlight-element"
        >
          {VIN}
          <StockwaveData vehicleData={vehicleData} />
        </div>
      }
      once
    >
      {children}
    </StyledVehicleLoader>
  )
}
