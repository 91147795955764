import { PropsWithChildren } from 'react'

interface IProps extends PropsWithChildren<React.HTMLProps<HTMLAnchorElement>> {
  href: string
  displayText?: string
}

export const PopupLink = ({ href, displayText, children, onClick, ...props }: IProps) => {
  const openPopup = (e, url: string) => {
    e.preventDefault()

    var width = window.screen.width / 2
    var height = window.screen.height / 2

    window.open(
      url,
      '_blank',
      `directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=yes,width=${width},height=${height}`
    )
  }

  return (
    <a
      href={href}
      onClick={(e) => {
        onClick?.(e)
        openPopup(e, href)
      }}
      {...props}
    >
      {children}
    </a>
  )
}
