import { VehicleDetailsView } from 'common/constants'
import { UnwatchPlaceholder } from 'modules/Watchlist/UnwatchPlaceholder'
import { useContext, useState } from 'react'
import { Form } from 'react-bootstrap'
import { StyledBidBuyInfoSection, StyledVehicleDetailsGridCard } from './VehicleDetailsGridCard.styled'
import { VehicleNote } from './VehicleNote'
import { StyledVehicleDetailsColumn } from 'modules/VehicleList'
import { VehicleTitleSection } from './VehicleTitleSection'
import { VehicleProperties } from './VehicleProperties'
import { VehicleActions } from './VehicleActions'
import { VehicleVin } from './VehicleDetails.styled'
import { IVehicleDetailsProps } from './VehicleDetails'
import { VehicleContext } from './VehicleContext'
import { StyledVehicleBidBuyInformation } from 'modules/BuyerActivity/VehicleAuction.styled'
import { VehiclePriceSection } from './VehiclePriceSection'
import { VehicleTags } from './VehicleTags'
import { VehicleBidHistoryLink } from './VehicleBidHistoryLink'
import { VehicleTimer } from './VehicleTimer'
import { VehicleBidBuyActions } from './VehicleBidBuyActions'
import { UserMaxBid } from './UserMaxBid'
import { useGlobalStore } from 'store/useGlobalStore'
import { StockwaveData } from 'modules/Stockwave/StockwaveData'
import { useFetch } from 'hooks/useFetch'
import { getSellerDisclosureByID } from 'apis/vehicleApis'
import { DSIImage } from './DSIImage'
import { FyusionDetailImage } from './FyusionDetailImage'
import { StyledVehicleDetailImage } from './VehicleDetailImage.style'
import BMWIconNew from 'images/icon/BMWIconNew'

export const VehicleGridLayout = ({
  vehicleData,
  enableRemovedWatchlistPlaceholder,
  onSelect,
  isSelected,
  showCheckbox
}: IVehicleDetailsProps) => {
  const { getSystemSetting } = useGlobalStore()
  const { vehicle: vehicleInfo } = vehicleData
  const {
    vehicleData: {
      vehicle: { WatchListID }
    }
  } = useContext(VehicleContext)

  const {
    appSettings: { ImageMissingUrl }
  } = useGlobalStore()

  const { data: selfInspection, loading: sellerDisclosureLoading } = useFetch(
    () => (vehicleData?.vehicle.IsSellerDisclosure ? getSellerDisclosureByID(vehicleData?.vehicle?.InstanceID) : null),
    [vehicleData?.vehicle.IsSellerDisclosure, vehicleData?.vehicle.InstanceID]
  )
  const [imageError, setImageError] = useState<boolean>(false)

  const fieldLimit = (getSystemSetting('BGD_GRIDVIEW_VEHICLE_DETAILS_DISPLAY_FIELDS_LIMIT') as number) || null

  if (!WatchListID && enableRemovedWatchlistPlaceholder) {
    return (
      <StyledVehicleDetailsGridCard>
        <UnwatchPlaceholder vehicleInfo={vehicleInfo} viewStyle={VehicleDetailsView.Grid} />
      </StyledVehicleDetailsGridCard>
    )
  }

  const handleCheckboxClick = (e: any) => {
    const { checked } = e.target as HTMLInputElement
    onSelect?.(checked)
  }

  let images = vehicleData.vehicle.Images?.filter((item) => item.ImageURL !== ImageMissingUrl)

  return (
    <StyledVehicleDetailsGridCard>
      <StyledVehicleDetailsColumn className="stockwave-vehicle-row stockwave-highlight-element">
        <div className="vehicle-detail-image-section">
          <div className="stockwave-light-bulb-container" id={`vehicle_${vehicleData.vehicle.VIN}`}></div>
          <StockwaveData vehicleData={vehicleData} />
          {showCheckbox && (
            <Form.Check
              id={`vehicle-details-check-${vehicleData.vehicle.InstanceID}`}
              className="vehicle-select"
              checked={isSelected}
              custom
              onChange={handleCheckboxClick}
            />
          )}
          {vehicleData?.vehicle.IsSellerDisclosure ? (
            !sellerDisclosureLoading && (
              <DSIImage
                key={selfInspection?.SellerMedia?.UploadID}
                mediaUploadId={selfInspection?.SellerMedia?.UploadID}
              />
            )
          ) : images?.length <= 0 ? (
            <StyledVehicleDetailImage>
              <div className="margin-top">
                <BMWIconNew />
              </div>
            </StyledVehicleDetailImage>
          ) : (
            <div
              onErrorCapture={(e) => {
                setImageError(true)
              }}
            >
              <FyusionDetailImage
                key={vehicleData?.vehicle?.InstanceID}
                lowRes={vehicleInfo.Images.map((image) => image.ImageURL)}
                thumbnail={vehicleInfo.ThumbImageURL}
                error={imageError}
                vin={vehicleData?.vehicle?.VIN}
              ></FyusionDetailImage>
            </div>
          )}
        </div>
        <VehicleActions vehicleData={vehicleData} />
        <VehicleTitleSection vehicleData={vehicleData} />
        <VehicleVin>{vehicleData.vehicle.VIN}</VehicleVin>
        <VehicleProperties vehicleInfo={vehicleData.vehicle} fieldLimit={fieldLimit} />
        <VehicleNote />
        <StyledVehicleBidBuyInformation>
          <StyledBidBuyInfoSection>
            <div className="text-left d-flex flex-column justify-content-end">
              <VehicleTags direction="right" />
              <VehicleTimer />
            </div>
            <div>
              <VehicleBidHistoryLink />
              <UserMaxBid />
              <VehiclePriceSection />
            </div>
          </StyledBidBuyInfoSection>
          <VehicleBidBuyActions />
        </StyledVehicleBidBuyInformation>
      </StyledVehicleDetailsColumn>
    </StyledVehicleDetailsGridCard>
  )
}
