import { MultiLanguageCode } from 'common/languageCodes'
import { HTMLText } from 'components/Share/HtmlText'
import { useIntl } from 'react-intl'
import parse from 'html-react-parser'
import { BreadcrumbMenu } from 'layouts/BreadcrumbMenu'
import { Col, Row } from 'react-bootstrap'
import { Layout } from 'layouts/Layout'
import { PrivacyBody, Title } from './Privacy.styled'

export const Privacy = () => {
  const intl = useIntl()
  return (
    <Layout title="Privacy Policy">
      <PrivacyBody>
        <Row>
          <Col>
            <BreadcrumbMenu />
          </Col>
        </Row>
        <Row>
          <Col>
            <Title>
              <HTMLText value={parse(intl.formatMessage({ id: MultiLanguageCode.PRIVACY_POLICY_HEADER }))} />
            </Title>
          </Col>
        </Row>
        <HTMLText value={parse(intl.formatMessage({ id: MultiLanguageCode.PRIVACY_POLICY }))} />
      </PrivacyBody>
    </Layout>
  )
}
