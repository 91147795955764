import { rmsAuthModel } from './rmsGlobal'
import { matchPath } from 'react-router'

export enum AppRoutes {
  Login = '/login',
  Home = '/',
  ContactUs = '/contactUs',
  Terms = '/terms',
  Forgot = '/forgot',
  Privacy = '/privacy',
  FAQs = '/faqs',
  WatchList = '/watchlist',
  ManageBids = '/manageBids',
  VDP = '/vehicle/:vehicleInstanceId',
  SearchResult = '/search',
  Dashboard = '/dashboard',
  BidHistory = '/bidHistory',
  PurchaseHistory = '/purchaseHistory'
}

const routes = Object.keys(AppRoutes)
  .filter((key) => AppRoutes[key].path !== AppRoutes.Home)
  .map((key) => {
    return {
      path: AppRoutes[key],
      name: key
    }
  })

if (rmsAuthModel?.appSettings?.UseNewDashboardPage) {
  routes.push({
    path: AppRoutes.Home,
    name: 'Home'
  })
}
export const REACT_ROUTES = routes

export const isReactRoutes = (pathname: string) => {
  const [path] = pathname.split(/[?#]/)
  return REACT_ROUTES.some((item) =>
    matchPath(path, {
      exact: true,
      path: item.path
    })
  )
}
