import { StyledSlideOut } from './SlideOut.styled'
import { default as PrismSlideOut } from '@prism/slideout'
import { PropsWithChildren } from 'react'

interface IProps extends PropsWithChildren<{}> {
  isOpen: boolean
  onCloseButtonClick: () => void
  placement?: 'left' | 'right'
}
export const SlideOut = ({ isOpen, onCloseButtonClick, children, placement = 'right' }: IProps) => {
  return (
    <StyledSlideOut data-testid="biddingSidePanel" className={`place-${placement}`}>
      <PrismSlideOut placement="right" open={isOpen} actionable onCloseButtonClick={onCloseButtonClick}>
        {children}
      </PrismSlideOut>
    </StyledSlideOut>
  )
}
