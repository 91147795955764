import { getPurchaseHistory } from 'apis/userApis'
import { PURCHASE_HISTORY_INIT_QUERY, SessionStorageKey } from 'common/constants'
import { createContext, useEffect, useState } from 'react'
import { IBaseCollectionResponse } from 'types/baseTypes'
import { IPurchaseHistoryRequest, IPurchaseHistoryResult } from 'types/purchaseHistoryTypes'
import { parseJson } from 'utils/utils'

export interface IPurchaseHistoryContext {
  searchFilter: IPurchaseHistoryRequest
  result: IBaseCollectionResponse<IPurchaseHistoryResult>
  updateSearchFilter: (filters: IPurchaseHistoryRequest) => void
  handleSearch: (isVinSearch: boolean) => void
  loading: boolean
}
export const PurchaseHistoryContext = createContext<IPurchaseHistoryContext>(null)

export const PurchaseHistoryProvider = ({ children }) => {
  const [loading, isLoading] = useState<boolean>(false)
  const [result, setResult] = useState<IBaseCollectionResponse<IPurchaseHistoryResult>>(null)
  const [searchFilter, setSearchFilter] = useState<IPurchaseHistoryRequest>(
    window.sessionStorage.getItem(SessionStorageKey.PURCHASE_HISTORY_SEARCH_FILTER)
      ? parseJson<IPurchaseHistoryRequest>(
          window.sessionStorage.getItem(SessionStorageKey.PURCHASE_HISTORY_SEARCH_FILTER)
        )
      : PURCHASE_HISTORY_INIT_QUERY
  )

  useEffect(() => {
    if (searchFilter) {
      handleSearch(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter])

  const updateSearchFilter = (filters: IPurchaseHistoryRequest) => {
    setSearchFilter(filters)
  }

  const handleSearch = async (isVinsearch: boolean) => {
    isLoading(true)
    let result = null
    if (isVinsearch && searchFilter.Vin !== null) {
      result = await getPurchaseHistory({ ...searchFilter, FromDate: null, ToDate: null })
    } else {
      result = await getPurchaseHistory({ ...searchFilter, Vin: null })
    }

    setResult(result)
    isLoading(false)
  }

  return (
    <PurchaseHistoryContext.Provider
      value={{
        result,
        searchFilter,
        updateSearchFilter,
        handleSearch,
        loading
      }}
    >
      {children}
    </PurchaseHistoryContext.Provider>
  )
}
