export enum Rules {
  PAGE_RDP_Dashboard = 'PAGE_RDP_Dashboard',
  PAGE_RDP_SRP = 'PAGE_RDP_SRP',
  PAGE_RDP_CurrentBids = 'PAGE_RDP_CurrentBids',
  VIEW_WATCH_LIST = 'ViewWatchList',
  VIEW_SAVED_SEARCH_PAGE = 'PAGE_RDP_SavedSearch',
  VIEW_ADVANCED_SEARCH_PAGE = 'PAGE_RDP_AdvancedSearch',
  VIEW_SEARCH_RESULT_PAGE = 'PAGE_RDP_SRP',
  VIEW_GROUNDING_ACTIVITY = 'ViewBuyerGroundingConfiguartion',
  VIEW_GROUNDING_QUICK_START = 'ViewGroundingQuickStart',
  VIEW_DASHBOARD_GROUNDING_STATUS = 'ViewDashboardGroundingStatus',
  VIEW_CARFAX_ADVANCED = 'Carfax Advantage',
  VIEW_CHECKOUT_AT_MANHEIMCOM = 'viewCheckoutAtManheimCom',
  ENABLE_CARFAX = 'ENABLE_CARFAX',
  RDP_PURCHASE_HISTORY = 'PAGE_RDP_PurchaseHistory'
}
