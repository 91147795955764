import { colorDanger, gridBreakPoints, textColor, textTableHeaderColor } from 'common/theme'
import { Modal, Table } from 'react-bootstrap'
import styled from 'styled-components'

export const StyleBidsHistory = styled(Modal)`
  margin: 2rem 0;
`

export const StyledModalContent = styled(Modal.Body)`
  width: 100%;
  height: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  h5 {
    padding: 0.5rem 0 0 1rem;
  }
  button {
    float: right;
    color: ${({ theme }) => theme.colors.primary};
    background-color: transparent;
    border-color: transparent;
  }
`

export const StyleModalHeader = styled(Modal.Header)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  font-size: 1.8rem;
  .close span {
    font-size: 2rem;
    font-weight: normal;
    color: white;
  }
`

export const StyledLabel = styled.span`
  font-weight: 600;
`

export const StyledBidsHistoryTable = styled(Table)`
  tbody {
    position: relative;
  }
  th {
    background-color: ${({ theme }) => theme.colors.modalHeader};
    color: ${textTableHeaderColor};
    font-size: 1.6rem;
    font-weight: 400;
    padding: 1rem;
    text-align: left !important;
  }
  th:last-child {
    width: 18rem;
  }
  tr {
    &:nth-child(odd) {
      background-color: #f2f2f2;
    }
    &.highest-row {
      font-weight: bold;
    }
  }
  td {
    font-size: 1.6rem;
    color: ${textColor};
    padding: 0.5rem 1rem;
    border-top: 0;
  }
  th {
    text-align: center;
  }

  .type-of-bid {
    position: relative;
    min-width: 19rem;
    .winning-bid-icon {
      height: 4rem;
      position: absolute;
      right: -1.4rem;
      top: 0;
    }
  }
  .proxy-bid {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 900;
  }
  @media (max-width: ${gridBreakPoints.sm}) {
    .type-of-bid {
      position: unset;
      .winning-bid-icon {
        position: relative;
        left: 2.1rem;
      }
    }
  }
`

export const StyleBidsHistoryNoteSpan = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 1.2rem;
  color: ${colorDanger};
  font-weight: 700;
`
