import { zIndex } from 'common/constants'
import { PropsWithChildren } from 'react'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'

interface IProps {
  variant?: string
  overlay?: boolean
}

interface IStyledLoaderProps {
  overlay?: boolean
}

export const StyledLoader = styled.div<IStyledLoaderProps>`
  ${({ overlay }) =>
    overlay &&
    ` position: absolute;
      left: calc(50% - 1rem);
      top: calc(50% - 1rem);
    `}
`

export const Loader = ({ children, overlay, variant = 'primary' }: PropsWithChildren<IProps>) => {
  return (
    <StyledLoader overlay={overlay}>
      <Spinner animation="border" variant={variant}>
        {children}
      </Spinner>
    </StyledLoader>
  )
}

export const StyledPageLoader = styled.div<IProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${zIndex.loader};
`

export const OverlayLoader = () => {
  return (
    <StyledPageLoader>
      <Loader overlay />
    </StyledPageLoader>
  )
}
