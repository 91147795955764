import styled from 'styled-components'

export const StyledBreadCrumbICon = styled.span`
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  &:first-child {
    margin-left: 0;
  }
`

export const StyledBreadCrumbMenu = styled.div`
  display: flex;
  margin-bottom: 2.5rem;
  align-items: self-end;

  a {
    font-size: 1.4rem;
  }
`
