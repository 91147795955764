import { VehicleStatuses } from 'common/constants'
import { StyledAuctionPrice, StyledNoAvailable, StyledTag } from 'modules/BuyerActivity/VehicleAuction.styled'
import { useContext } from 'react'
import { VehicleContext } from './VehicleContext'

export const VehiclePriceSection = () => {
  const {
    vehicleData: { vehicle: vehicleInfo, auctionInfo, IsAvailable }
  } = useContext(VehicleContext)

  return (
    <>
      {IsAvailable ? (
        <>
          {vehicleInfo.StatusID === VehicleStatuses.SoldNotFunded && (
            <>
              <StyledAuctionPrice>
                {vehicleInfo.BuyItNowPrice !== 0 && vehicleInfo.BuyItNowPriceString}
              </StyledAuctionPrice>
              <StyledTag>
                <span>SOLD</span>
              </StyledTag>
            </>
          )}

          {vehicleInfo.StatusID !== VehicleStatuses.SoldNotFunded &&
            vehicleInfo.StatusID !== VehicleStatuses.OnSale && (
              <StyledTag>
                <span>{vehicleInfo.StatusDescription}</span>
              </StyledTag>
            )}

          {vehicleInfo.StatusID === VehicleStatuses.OnSale && (
            <div>
              {auctionInfo && <StyledAuctionPrice>{auctionInfo.CurrentPriceString}</StyledAuctionPrice>}
              {!auctionInfo && <StyledAuctionPrice>{vehicleInfo.FinalPriceString}</StyledAuctionPrice>}
            </div>
          )}
        </>
      ) : (
        <StyledNoAvailable>
          <span>NO LONGER AVAILABLE</span>
        </StyledNoAvailable>
      )}
    </>
  )
}
