import { gridBreakPoints } from 'common/theme'
import styled from 'styled-components'
import { IToggleSwitchProps } from 'types/IToggleSwitchProps'

export const StyledToggleSwitchWrapper = styled.div.attrs({
  className: 'switch-wrapper'
})`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: 1rem;
  margin-right: 2rem;

  @media (max-width: ${gridBreakPoints.sm}) {
    margin: auto 0;
  }

  label {
    margin-bottom: 0;
  }
`
StyledToggleSwitchWrapper.displayName = 'StyledToggleSwitchWrapper'

export const StyledToggleSwitch = styled.label.attrs({
  className: 'switch'
})`
  cursor: pointer;

  position: relative;
  min-width: 5rem;
  height: 2.4rem;
  background: ${({ theme }) => theme.colors.switchBackgroundColor};
  border-radius: 2rem;
  padding: 0.4rem;
  transition: 200ms all;

  & > span {
    color: ${({ theme }) => theme.colors.switchBackgroundColor};
    display: flex;
    justify-content: center;
    align-items: center;

    transition: 200ms all;
    position: absolute;
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    top: 50%;
    left: 0.2rem;
    background: ${({ theme }) => theme.colors.backgroundText};
    transform: translate(0, -50%);
  }
`
StyledToggleSwitch.displayName = 'StyledToggleSwitch'

export const StyledToggleStatus = styled.label.attrs(({ className }) => ({
  className: `switch-status-text ${className}`.trim()
}))`
  font-size: 1rem;
`
StyledToggleStatus.displayName = 'StyledToggleStatus'

export const StyledToggleInput = styled.input.attrs(
  ({ className }) =>
    ({
      className: `switch-input ${className ?? ''}`.trim(),
      type: 'checkbox'
    } as IToggleSwitchProps)
)`
  opacity: 0;
  position: absolute;

  &:checked {
    & + ${StyledToggleSwitch} {
      background: ${({ theme }) => theme.colors.primary};

      & > span {
        color: ${({ theme }) => theme.colors.primary};
        font-weight: bold;
        transform: translate(2.6rem, -50%);
      }
    }

    & ~ ${StyledToggleStatus}.with-color {
      color: ${({ theme }) => theme.colors.primary};
      font-weight: bold;
    }
  }

  &:disabled {
    & + ${StyledToggleSwitch} {
      cursor: default;
      background: ${({ theme }) => theme.colors.switchBackgroundColor} !important;

      & > span {
        color: ${({ theme }) => theme.colors.fontColor} !important;
      }
    }

    & ~ ${StyledToggleStatus}.with-color {
      color: ${({ theme }) => theme.colors.fontColor} !important;
    }
  }
`
StyledToggleInput.displayName = 'StyledToggleInput'
