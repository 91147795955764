import { isEmpty } from 'lodash'
import { IVehicleProperties, IVehicleInfo } from '../types/vehicleTypes'
import { ISystemSetting } from '../types/baseTypes'
import { VEHICLE_PROPERTIES_TO_HIDE } from 'common/constants'

export const getVehicleDetailProperties = (vehicleInfo: IVehicleInfo, retailView: boolean = false) =>
  retailView
    ? vehicleInfo?.Properties?.filter((p) => p.ID === 1 && !VEHICLE_PROPERTIES_TO_HIDE.includes(p.UIConstantName))
    : vehicleInfo?.Properties?.filter((p) => p.ID === 1)

export const processInServiceDateProperty = (
  properties: IVehicleProperties[],
  vehicleSrcCode: string,
  setting: ISystemSetting
) => {
  return properties?.filter((p) => {
    if (p.UIConstantName !== 'IN_SERVICE_DATE') return true

    /**
     * setting
     *   Value:
     *     0       : Hide
     *     1       : Show (check setting.ValueString)
     *
     *   ValueString:
     *       BMWFS   : ShowInServiceDateForOffleaseCar
     *       BMWNA   : ShowInServiceDateForCompanyCar
     *       <blank> : ShowInServiceDateForBothOffleaseAndCompanyCars
     */

    return (
      setting.Value === '1' &&
      (isEmpty(setting.ValueString) || setting.ValueString.toLowerCase() === vehicleSrcCode.toLowerCase())
    )
  })
}
