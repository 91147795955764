import { Button, Modal } from 'react-bootstrap'
import styled from 'styled-components'

export const ModalSelectView = styled(Modal)`
  .modal-header {
    border-bottom: none;
    color: white;
    background-color: ${({ theme }) => theme.colors.primary};
    font-weight: 200;
    font-size: 1.85rem;
  }
  .modal-body {
    padding-bottom: 1rem;
    padding-top: 1.5rem;
  }

  .container-fluid {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const ButtonPopup = styled(Button)`
  padding: 1rem;
  font-size: 1.4rem;
  width: 70px;
  margin-top: 1rem;
  line-height: 0.5;
`

export const ConfirmationGroupButton = styled.div`
  display: flex;
  gap: 1rem;
`
