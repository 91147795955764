import { mediumScreen, smallScreen } from 'common/theme'
import { StyledHomeSectionBody } from 'components/Header/HomeSectionHeader.styled'
import { StyledVDPCardStyle } from 'components/Share/Card.styled'
import { Col, Row } from 'react-bootstrap'
import { renderToStaticMarkup } from 'react-dom/server'
import { IconSvg } from '@prism/icon'
import styled from 'styled-components'
const svgString = encodeURIComponent(renderToStaticMarkup(<IconSvg glyph="calendar" />))

export const PurchaseHistoryDetailStyled = styled.div`
  font-size: 1.6rem;

  @media (max-width: ${mediumScreen}) {
    font-size: 1.3rem;
  }

  @media (max-width: ${smallScreen}) {
    font-size: 1rem;
  }

  color: ${({ theme }) => theme.colors.fontColor};
  padding-bottom: 0.5rem;

  .document {
    p {
      margin-bottom: 0;
    }
  }
  .bs-stepper-circle {
    background-color: ${({ theme }) => theme.colors.primary};
    min-width: 3rem;
    min-height: 3rem;
    max-width: 3rem;
    max-height: 3rem;
    border-radius: 50%;
    line-height: normal;
  }

  .bs-stepper-label {
    word-break: break-all;
  }

  .ready-auto-link {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.linkColorLight};
  }

  .step {
    color: ${({ theme }) => theme.colors.fontColor};
    display: flex;
    align-items: center;

    .info {
      display: flex;
      flex-direction: column;
    }
  }

  .bs-stepper .step-trigger {
    padding: 0px;
    font-size: 1.3rem;
    font-weight: 600;
  }

  .bs-stepper-line {
    flex: 1 0 2rem;
  }

  .detail-header {
    border: 1px solid #ccc;
    border-radius: 0.2rem;
    min-height: 8rem;
    display: flex;
    align-items: center;
    align-content: center;
    font-weight: 600;
  }

  .action :hover {
    cursor: pointer;
  }
  .link-history {
    color: ${({ theme }) => theme.colors.linkColorLight};
  }
`

export const StyledPurchaseHistoryDetailSection = styled(StyledHomeSectionBody)`
  padding: 1.6rem 2rem;
  background-color: #fff;
`

export const PurchaseHistoryCard = styled(StyledVDPCardStyle)`
  padding: 3rem;
  background-color: #fff;
  row-gap: 1rem;
  @media (max-width: ${mediumScreen}) {
    padding: 1.5rem;
  }
  position: relative;

  .detail-row-section {
    min-height: 35rem;
  }

  .vehicle-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .detail-col-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .bs-stepper-circle {
    background-color: ${({ theme }) => theme.colors.primary};
    min-width: 3rem;
    min-height: 3rem;
    max-width: 3rem;
    max-height: 3rem;
    border-radius: 50%;
    line-height: normal;
  }

  .bs-stepper-label {
    word-break: break-all;
  }

  .ready-auto-link {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.linkColorLight};
  }

  .step {
    color: ${({ theme }) => theme.colors.fontColor};
    display: flex;
    align-items: center;

    .info {
      display: flex;
      flex-direction: column;
    }
  }

  .bs-stepper .step-trigger {
    padding: 0px;
    font-size: 1.3rem;
    font-weight: 600;
  }

  .bs-stepper-line {
    flex: 1 0 2rem;
  }
`

export const PurchaseHistoryCardCol = styled(Col)`
  font-size: 1.6rem;
  .row {
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
  }

  .ready-auto-title {
    display: flex;
    justify-content: center;
  }
  .ready-auto-img,
  .vehicle-info,
  .ready-auto-info,
  .ready-auto-footer {
    padding-bottom: 1rem;
  }
  .vehicle-info {
    display: flex;
    flex-direction: column;
  }

  .vehicle-info-wrapper {
    display: flex;
    gap: 1rem;
    flex-direction: row;
  }

  .ready-auto-info {
    font-weight: bold;
    max-height: 21rem;
    font-size: 1.3rem;
    width: 100%;
    overflow-y: scroll;
  }

  .ready-auto-info-step {
    display: flex;
    white-space: break-spaces;
    line-break: auto;
  }

  .vehicle-info {
    font-weight: bold;
    width: 100%;
  }
  .ready-auto-content {
    font-size: 1.3rem;
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
    width: 100%;
  }
`
export const PurchaseHistoryFormRow = styled(Row)`
  font-size: 1.6rem;
  background-color: #f6f6f6;
  width: 100%;
  min-height: 56%;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.fontColor};
  .container {
    width: 100%;
    margin: auto;
  }
  .dealer-purchase-retail-info {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  .retail-date {
    display: flex;
    flex-direction: column;
  }
  .submit-button {
    display: flex;
    align-items: center;
    .btn {
      width: 100%;
    }
  }
  .form-row {
    width: 100%;
  }
  .date-picker-custom {
    width: 100%;
    border: none;
  }

  .react-datepicker__triangle::before,
  .react-datepicker__triangle::after {
    position: absolute;
    right: 0px;
    transform: translate3d(-60px, 0px, 0px);
  }

  .date-picker-container svg {
    margin-left: -30px;
    transform: scale(0.8);
    opacity: 0.7;
    margin-top: 1px;
    line-height: 1.9em;
  }

  .date-picker-input {
    background: url('data:image/svg+xml,${svgString}') no-repeat 98% 50%;
    background-size: 20px;
    border: 1px solid ${({ theme }) => theme.colors.borderInput};
    border-radius: 0.25rem;
    background-color: white;
  }
`
