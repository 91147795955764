import { CountDownWithColor } from 'modules/BuyerActivity/CountDownWithColor'
import { useContext } from 'react'
import { VehicleContext } from './VehicleContext'

export const VehicleTimer = () => {
  const {
    vehicleData: { vehicle: vehicleInfo, auctionInfo }
  } = useContext(VehicleContext)

  return <CountDownWithColor vehicleInfo={vehicleInfo} auctionInfo={auctionInfo}></CountDownWithColor>
}
