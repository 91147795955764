import { iconDict } from 'common/menuIcons'
import { NavLink } from 'components/Share/Link'
import { setCustomClick } from 'utils/analyticsUtils'
import { resetSRPFilter } from 'utils/menuUtils'
import { IPortalMenuResponse } from '../types/userTypes'
import { StyledSubMenu, StyledSubMenuItem } from './SubMenu.styled'
interface IProps {
  items: IPortalMenuResponse[]
  onClick?: () => void
}

export const SubMenu = ({ items, onClick }: IProps) => {
  const handleSubMenuClick = (e: any) => {
    onClick?.()
    resetSRPFilter()
    setCustomClick(`Ham menu: ${e.target.textContent.trim()}`)
  }

  return (
    <StyledSubMenu>
      {items.map((item) => (
        <StyledSubMenuItem key={item.MenuItem.ID}>
          {item.URL && (
            <NavLink to={item.URL} onClick={handleSubMenuClick}>
              {iconDict[item.MenuItem.Name]}
              {item.MenuItem.Text}
            </NavLink>
          )}
        </StyledSubMenuItem>
      ))}
    </StyledSubMenu>
  )
}
