import { Layout } from 'layouts/Layout'
import { IconSvg } from '@prism/icon'
import shallow from 'zustand/shallow'
import { StyledHeaderActions, StyledStickyHeaderPage } from 'modules/VehicleList'
import { useFetch } from 'hooks/useFetch'
import { purchaseHistorySortOptions } from 'common/constants'
import { VehicleStickyBar } from 'modules/VehicleList/VehicleStickyBar'
import { PurchaseHistoryContext, PurchaseHistoryProvider } from 'contexts/PurchaseHistoryContext'
import { useContext, useEffect } from 'react'
import { SearchSection } from 'modules/PurchaseHistory/SearchSection'
import { Loader, OverlayLoader } from 'components/Loader'
import { PurchaseHistoryListSection } from 'modules/PurchaseHistory/PurchaseHistoryListSection'
import { Link } from 'components/Share/Link'
import { formatDateString } from 'utils/dateUtils'
import { getManheimCheckoutURL } from 'apis/userApis'
import { Sort } from 'components/Sort/Sort'
import { ISortBy } from 'types/baseTypes'
import { setListingBreadcrumb } from 'utils/breadcrumbUtils'
import { useGlobalStore } from 'store/useGlobalStore'
import { useLocation } from 'react-router-dom'
import { Rules } from 'common/rules'

export const PurchaseHistoryContent = () => {
  const { loading, result, searchFilter, updateSearchFilter } = useContext(PurchaseHistoryContext)
  const getLocalText = useGlobalStore((state) => state.getLocalText)
  const location = useLocation()
  useEffect(() => {
    const pageTitle = getLocalText('PURCHASE_HISTORY', 'Purchase History')

    setListingBreadcrumb(pageTitle, location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [isInRule] = useGlobalStore((state) => [state.isInRule], shallow)

  const getDownloadReportUrl = () => {
    var startDateValue = formatDateString(new Date(searchFilter?.FromDate))
    var endDateValue = formatDateString(new Date(searchFilter?.ToDate))
    var downloadReportUrl = `/resource/DownloadPurchaseHistory?startDate=${startDateValue}&endDate=${endDateValue}&sortBy=${searchFilter.SortBy.Value}&sortOrder=${searchFilter.SortBy.SortOrder}`
    return downloadReportUrl
  }

  const handleSortChange = (selectedSortBy: ISortBy) => {
    updateSearchFilter({ ...searchFilter, SortBy: selectedSortBy })
  }

  const { loading: manHeimLoading, data: manHeimLink } = useFetch(getManheimCheckoutURL)

  return (
    <Layout title={'Purchase History'}>
      {loading && <OverlayLoader />}
      <StyledStickyHeaderPage>
        <VehicleStickyBar
          sectionTitle={'Purchase History'}
          itemCount={result?.TotalRecords ? result.TotalRecords : 0}
          displayCollapse={false}
          rightActionSpan={8}
          rightActions={
            <StyledHeaderActions>
              <div className="view-icons">
                <Link to={getDownloadReportUrl()}>
                  <span className="right-action">
                    <IconSvg glyph="download" color="primary" />
                    &nbsp;Download Report&nbsp;
                  </span>
                </Link>
                {isInRule(Rules.VIEW_CHECKOUT_AT_MANHEIMCOM) && (
                  <>
                    {manHeimLoading ? (
                      <Loader />
                    ) : (
                      <Link to={manHeimLink} target="_blank">
                        <span className="right-action">
                          <IconSvg glyph="link" color="primary" />
                          &nbsp;Check out at Manheim&nbsp;
                        </span>
                      </Link>
                    )}
                  </>
                )}
              </div>
              <Sort
                currentSort={searchFilter.SortBy}
                sortOptions={purchaseHistorySortOptions}
                onChange={handleSortChange}
              />
            </StyledHeaderActions>
          }
        />
        <SearchSection />
        <PurchaseHistoryListSection />
      </StyledStickyHeaderPage>
    </Layout>
  )
}

export const PurchaseHistory = () => {
  return (
    <PurchaseHistoryProvider>
      <PurchaseHistoryContent />
    </PurchaseHistoryProvider>
  )
}
