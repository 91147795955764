import React from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import { useGlobalStore } from 'store/useGlobalStore'
import shallow from 'zustand/shallow'
import { StyledWarningModal } from './GenericMessageWarning.styled'

const GenericMessageWarning = (props) => {
  const [errorMessages, clearError] = useGlobalStore((state) => [state.errorMessages, state.clearError], shallow)

  return errorMessages && errorMessages['ShowGenericMessage'] ? (
    <StyledWarningModal show={true} fullscreen={true} onHide={clearError} {...props}>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <Col>
              <p>{errorMessages['ShowGenericMessage']}</p>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </StyledWarningModal>
  ) : (
    <></>
  )
}
export default React.memo(GenericMessageWarning)
