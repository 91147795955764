import { Modal } from 'react-bootstrap'
import { HeaderStyled } from './LinkNameModal.styled'

interface ILinkNameModal {
  show: boolean
  handleClose: () => void
  bodyText: string
}

export const LinkNameModal = ({ show, handleClose, bodyText }: ILinkNameModal) => {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <HeaderStyled closeButton>{bodyText}</HeaderStyled>
      </Modal>
    </>
  )
}
