import { SlideOut } from 'components/SlideOut/SlideOut'
import { BuyerSearch } from './BuyerSearch'
import { StyledSlideOutSearchFilter } from './SlideOutSearchFilter.styled'
interface IProps {
  isOpen: boolean
  closeFilter?: () => void
}
export const SlideOutSearchFilter = ({ isOpen, closeFilter }: IProps) => {
  return (
    <StyledSlideOutSearchFilter>
      <SlideOut placement="left" isOpen={isOpen} onCloseButtonClick={() => closeFilter()}>
        <BuyerSearch></BuyerSearch>
      </SlideOut>
    </StyledSlideOutSearchFilter>
  )
}
