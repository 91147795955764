/* eslint-disable no-script-url */
import { FAQ, FooterMenuExclusionList, TERM_AND_CONDITION } from 'common/constants'
import { MultiLanguageCode } from 'common/languageCodes'
import { HTMLText } from 'components/Share/HtmlText'
import { NavLink } from 'components/Share/Link'
import parse from 'html-react-parser'
import privatestorebymanheim from 'images/PRIVATE_STORE_BY_MANHEIM.png'
import _ from 'lodash'
import { Col, Container, Row } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useGlobalStore } from 'store/useGlobalStore'
import { handlePortalMenuItemClick } from 'utils/menuUtils'
import shallow from 'zustand/shallow'
import {
  LayoutLeftFooterContainer,
  StyledFooter,
  StyledFooterLogo,
  StyledFooterRow,
  StyledLogoContainer,
  StyledMainNavItem,
  StyledNavItem,
  StyledSubNavSmallScreen
} from './Footer.styled'

export const Footer = () => {
  const [{ Menu: menu, IsSSO: isSSO }, getLocalText] = useGlobalStore(
    (state) => [state.userClaims, state.getLocalText],
    shallow
  )

  const intl = useIntl()
  return (
    <StyledFooter>
      <Container>
        <Row>
          <Col md={10} className="d-none d-md-block">
            <nav id="nav-footer" className="nav footer-nav  d-print-none">
              <ul className="nav-list active w-100 justify-content-end">
                {menu?.Children.filter(
                  (item) => item.IsWebMenu && (item.Children.length > 0 || item.URL !== 'javascript:')
                ).map((item) => (
                  <StyledMainNavItem key={item.MenuItem.Name}>
                    <StyledNavItem href={item.URL === 'javascript:' ? null : item.URL}>
                      {item.MenuItem.Text}
                    </StyledNavItem>
                    {item.Children && (
                      <ul className="subnav">
                        {item.Children.filter((c) => !FooterMenuExclusionList.includes(c.MenuItem.Name)).map(
                          (childItem) => (
                            <li key={childItem.MenuItem.Name}>
                              <NavLink to={childItem.URL} onClick={() => handlePortalMenuItemClick(childItem)}>
                                {childItem.MenuItem.Text}
                              </NavLink>
                            </li>
                          )
                        )}
                      </ul>
                    )}
                  </StyledMainNavItem>
                ))}
                <StyledMainNavItem>
                  <StyledNavItem>More</StyledNavItem>
                  <ul className="subnav">
                    <li>
                      <NavLink to="/faqs">{FAQ}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/terms">{TERM_AND_CONDITION}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/privacy">
                        {parse(intl.formatMessage({ id: MultiLanguageCode.PRIVACY_POLICY_HEADER }))}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/contactUs">
                        {parse(intl.formatMessage({ id: MultiLanguageCode.CONTACT_US }))}
                      </NavLink>
                    </li>
                    {!_.isNil(isSSO) && !isSSO && (
                      <li>
                        <a href="/logout">{getLocalText && getLocalText('LOGOUT', 'Logout')}</a>
                      </li>
                    )}
                  </ul>
                </StyledMainNavItem>
              </ul>
            </nav>
          </Col>
          <Col className="d-md-none" xs={12}>
            <StyledSubNavSmallScreen>
              <ul className="subnav">
                <li>
                  <NavLink to="/terms">{TERM_AND_CONDITION}</NavLink>
                </li>
                {!_.isNil(isSSO) && !isSSO && (
                  <li>
                    <NavLink to="/logout">{getLocalText && getLocalText('LOGOUT', 'Logout')}</NavLink>
                  </li>
                )}
                <li>
                  <NavLink to="/contactUs">{parse(intl.formatMessage({ id: MultiLanguageCode.CONTACT_US }))}</NavLink>
                </li>
              </ul>
            </StyledSubNavSmallScreen>
          </Col>
        </Row>
      </Container>
      <StyledFooterRow>
        <LayoutLeftFooterContainer xs md={9}>
          <HTMLText value={parse(intl.formatMessage({ id: MultiLanguageCode.COPYRIGHT_TEXT }))} />
        </LayoutLeftFooterContainer>
        <StyledLogoContainer xs md={3} className="logo-container">
          <nav id="sub-nav-footer" className="nav footer-nav footer-sub-nav d-print-none"></nav>
          <StyledFooterLogo>
            <img src={privatestorebymanheim} alt="" />
          </StyledFooterLogo>
        </StyledLogoContainer>
      </StyledFooterRow>
    </StyledFooter>
  )
}
