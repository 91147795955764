import styled from 'styled-components'

export const StyledActivityTitle = styled.div`
  color: ${({ theme }) => theme.colors.headerFontColor};
  font-size: 1.8rem;
  font-weight: 600;
  cursor: pointer;

  .total {
    display: inline;
    color: ${({ theme }) => theme.colors.primary};
  }
  .icon {
    margin-bottom: 0.4rem;
    margin-right: 0.5rem;
  }
  .icon:hover {
    cursor: pointer;
  }
`
