import { PurchaseHistoryListSectionStyled } from './PurchaseHistoryListSection.styled'
import { Button, OverlayTrigger } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext } from 'react'
import { PurchaseHistoryContext } from 'contexts/PurchaseHistoryContext'
import NoActivity from 'modules/BuyerActivity/NoActivity'
import { DataTable } from 'components/DataTableHistory'
import { Link } from 'components/Share/Link'
import CaretDownFill from 'images/icon/CaretDownFill'
import CaretRightFill from 'images/icon/CaretRightFill'
import PurchaseHistoryInfoSection from './PurchaseHistoryInfoSection'
import { PopoverAttachment } from 'modules/PopoverAttachment'
import { PaperClipButton } from 'modules/DealerVehicleManagement/VehicleDetails.styled'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { IPurchaseHistoryResult } from 'types/purchaseHistoryTypes'
import { DateTime } from 'luxon'
import { ChargePopover } from './ChargesPopOver'
import { Paging } from 'components/Paging'

export const PurchaseHistoryListSection = () => {
  const { result, searchFilter, updateSearchFilter } = useContext(PurchaseHistoryContext)
  const handlePageSizeChanged = (pageSizeSelected: number) => {
    updateSearchFilter({ ...searchFilter, PageSize: pageSizeSelected, PageNumber: 0 })
  }

  const paginationClicked = (pageSelected: number) => {
    if (pageSelected === searchFilter.PageNumber + 1) return

    updateSearchFilter({ ...searchFilter, PageNumber: pageSelected - 1 })
  }
  return (
    <PurchaseHistoryListSectionStyled>
      {result && result?.Items?.length > 0 ? (
        <>
          <DataTable
            data={result?.Items}
            isFilterSearch={false}
            // filterColumnAcesscor="Title"
            isDataPaginated={true}
            columns={[
              {
                id: 'Title',
                Header: 'Description',
                accessor: (x: IPurchaseHistoryResult) => x,
                Cell: ({ value }) => (
                  <Link className="link-history" to={`vehicle/${value.VehicleInstanceID}?history=true`}>
                    {value.Title}
                  </Link>
                )
              },
              {
                id: 'Vin',
                Header: 'VIN',
                accessor: (x: IPurchaseHistoryResult) => x,
                Cell: ({ value }) => (
                  <>
                    {value.Vin}
                    {value?.ExternalNoteAttachmentDocuments?.length > 0 && (
                      <OverlayTrigger
                        placement="bottom"
                        trigger="click"
                        rootClose
                        overlay={(props) => (
                          <PopoverAttachment VIN={value.Vin} vehicleInstanceID={value.VehicleInstanceID} {...props} />
                        )}
                      >
                        <PaperClipButton variant="link">
                          <FontAwesomeIcon icon={faPaperclip} title="Click Here to View Attachments" />
                        </PaperClipButton>
                      </OverlayTrigger>
                    )}
                  </>
                )
              },
              {
                Header: 'Sale Price',
                accessor: 'Charges',
                Cell: ({ value }) => (
                  <> {value.find((x) => x.VehicleChargeTypeDescription === 'Sale Price')?.PriceString} </>
                )
              },
              {
                id: 'Buy Fee',
                Header: 'Buy Fee',
                accessor: 'Charges',
                Cell: ({ value }) => (
                  <> {value.find((x) => x.VehicleChargeTypeDescription === 'Buy Fee')?.PriceString} </>
                )
              },
              {
                id: 'Total Cost',
                Header: 'Total Cost',
                accessor: (x: IPurchaseHistoryResult) => x,
                Cell: ({ value }) => (
                  <div>
                    {value?.Charges?.filter(
                      (x) =>
                        x.VehicleChargeTypeDescription !== 'Buy Fee' && x.VehicleChargeTypeDescription !== 'Sale Price'
                    ).length > 0 ? (
                      <OverlayTrigger
                        placement="top-end"
                        trigger="click"
                        rootClose
                        overlay={(props) => <ChargePopover charges={value?.Charges} {...props} />}
                      >
                        <Button variant="link" className="charges-link">
                          {value.TotalPriceString}
                        </Button>
                      </OverlayTrigger>
                    ) : (
                      <Button variant="link" className="price-str" disabled>
                        {value.TotalPriceString}
                      </Button>
                    )}
                  </div>
                )
              },
              {
                Header: 'Purchase Date',
                accessor: 'PurchaseDate',
                Cell: ({ value }) => <>{DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT)}</>
              },
              {
                Header: 'Payment Method',
                accessor: 'PaymentType'
              },
              {
                Header: 'Document',
                accessor: 'Documents',
                Cell: ({ value }) => {
                  return (
                    <div className="documents">
                      {value?.length > 0
                        ? value.map((doc) => (
                            <Link to={doc.DocumentURL} className="link-history">
                              {doc.display}
                            </Link>
                          ))
                        : 'NA'}
                    </div>
                  )
                }
              },
              {
                Header: () => null,
                id: 'expander',
                Cell: ({ row }) => (
                  <span className="action" {...row.getToggleRowExpandedProps()}>
                    {row.isExpanded ? <CaretDownFill /> : <CaretRightFill />}
                  </span>
                )
              }
            ]}
            renderRowSubComponent={(row) => (
              <PurchaseHistoryInfoSection VIN={row?.original?.Vin} isLoadApi={row.isExpanded} />
            )}
          />
          <Paging
            currentPage={searchFilter.PageNumber}
            currentPageSize={searchFilter.PageSize}
            handleChangedPage={paginationClicked}
            handlePageSizeChanged={handlePageSizeChanged}
            totalPages={result.TotalPages}
          />
        </>
      ) : (
        <NoActivity message={'No Purchase Vehicle Found'} />
      )}
    </PurchaseHistoryListSectionStyled>
  )
}
