import { BMW_VENDOR_CODE } from 'common/constants'
import {
  AnalyticsStore,
  getApplicationRevisionId,
  AnalyticsStorageKey,
  setCustomClick,
  getListings,
  getSearchParameters
} from 'utils/analyticsUtils'
import { useCallback } from 'react'
import { useGlobalStore } from 'store/useGlobalStore'
import { DtmSettings, DtmAnalyticsEvent, SRPDtmInfo, IListingModel, ICustomOptions } from 'types/dtmTypes'
import log from 'utils/logger'

const dtmSettings: DtmSettings = {
  events: 'rTEA,SRPView',
  siteCode: 'BMW Direct',
  platform: 'SinglePageApp',
  signature: '#analytics_om'
}

export const useDtmAnalytics = () => {
  const userData = useGlobalStore((state) => state.userClaims)

  const dtmTraceWebEvent = (
    event: DtmAnalyticsEvent = 'pageView',
    { componentName, customClickName: alternativeClickName, listModel, userNote, searchData }: ICustomOptions = {}
  ) => {
    const { enableDtmAnalytics, environment, pageDetails, customClickName, pageName, pageRollup, tabName, loadStart } =
      AnalyticsStore
    if (!enableDtmAnalytics) {
      return
    }

    const { query, sortBy } = getSearchParameters(searchData)
    window.appEventData = window['appEventData'] || []
    window.webData = window['webData'] || {}
    const siteCode = userData.VendorCode === BMW_VENDOR_CODE ? dtmSettings.siteCode : 'RMSUS'
    const applicationCode = event === 'componentLoad' ? `${userData.VendorCode}:${componentName}` : userData.VendorCode
    // geolocation is handled by Adobe analytics framework, so we just need to ask for geolocation permission and not need to send any data
    let eventData: SRPDtmInfo = {
      event: event,
      events: dtmSettings.events,
      siteCode: siteCode,
      platform: dtmSettings.platform,
      signature: dtmSettings.signature,
      application: applicationCode,
      loginName: userData.Username,
      pageDetails: userData.VendorCode === BMW_VENDOR_CODE ? 'BMW Direct - ' + pageDetails : pageDetails,
      repID: userData.RepAuctionAccess?.toString(),
      sessionId: userData.SessionID,
      environment,
      applicationRevisionId: getApplicationRevisionId(),
      accountID: userData.CurrentBuyerID?.toString(),
      accountName: userData.DealershipName,
      pageName: componentName || pageName,
      pageRollup: pageRollup,
      tabName: tabName,
      customClickName: alternativeClickName || customClickName,
      loadTime: new Date().getTime() - loadStart.getTime(),
      pageType: event === 'componentLoad' ? 'component' : 'page',
      language: userData.LanguageCode,
      userNotes: userNote,
      query,
      sortBy
    } as any
    window.webData = eventData
    const listings = getListings(listModel, siteCode, userData.VendorCode)
    const appEventData = { ...eventData, listings }
    window.appEventData.push(appEventData)

    log.debug(new Date().toISOString(), appEventData)
    if (event === 'pageView' && sessionStorage.getItem(AnalyticsStorageKey.CUSTOM_CLICK_NAME)) {
      sessionStorage.removeItem(AnalyticsStorageKey.CUSTOM_CLICK_NAME)
      delete AnalyticsStore.customClickName
    }
  }

  const userInteraction = useCallback(
    (customClickName?: string, listModel?: IListingModel[], { userNote }: ICustomOptions = {}) => {
      if (customClickName) {
        setCustomClick(customClickName)
      }
      dtmTraceWebEvent('userInteraction', {
        customClickName,
        listModel,
        userNote
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const endPageLoad = useCallback((listModel?: IListingModel[], { searchData }: ICustomOptions = {}) => {
    dtmTraceWebEvent('pageView', {
      listModel,
      searchData
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const endComponentLoad = useCallback((componentName?: string, listModel?: any[]) => {
    dtmTraceWebEvent('componentLoad', {
      componentName,
      listModel
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { endPageLoad, endComponentLoad, userInteraction }
}
