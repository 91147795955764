import classNames from 'classnames'
import { getVehicleListingInfoIcon } from 'common/vehicleListingInfoIcons'
import { PopupLink } from 'components/Share/PopupLink'
import { isEmpty } from 'lodash'
import { useContext, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useGlobalStore } from 'store/useGlobalStore'
import styled from 'styled-components'
import { IVehicleInfo } from 'types/vehicleTypes'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { buildMMRUrl } from 'utils/urlUtils'

import { StyledInfoLabel } from './VehicleDetails.styled'
import { ISystemSetting } from 'types/baseTypes'
import { getVehicleDetailProperties, processInServiceDateProperty } from '../../utils/vehicleUtils'
import { VehicleContext } from './VehicleContext'

interface IProps {
  vehicleInfo: IVehicleInfo
  fieldLimit?: number
}

export const StyledVehicleProperties = styled.div``

export const VehicleProperties = ({ vehicleInfo, fieldLimit }: IProps) => {
  const {
    appSettings: { MMRUrl },
    getSystemSetting
  } = useGlobalStore()

  const showInServiceDateSetting = getSystemSetting('SHOW_INSERVICEDATE_VECHILESOURCE') as ISystemSetting
  const { retailView } = useContext(VehicleContext)

  const [showAll, setShowAll] = useState<boolean>(false)
  const properties = processInServiceDateProperty(
    getVehicleDetailProperties(vehicleInfo, retailView),
    vehicleInfo.SourceCode,
    showInServiceDateSetting
  )

  const limit = fieldLimit > 0 ? fieldLimit : properties?.length

  const toggleDisplay = () => {
    setShowAll((show) => !show)
  }

  return (
    <StyledVehicleProperties>
      <Row className="vehicle-info">
        <>
          {properties?.slice(0, showAll ? properties?.length : limit).map((x, idx) => (
            <Col key={`${idx}-${x.Code}`} xs={6} className={classNames('vehicle-properties')}>
              <StyledInfoLabel title={x.Code}>
                {!isEmpty(getVehicleListingInfoIcon(x.UIConstantName)) ? (
                  <>{getVehicleListingInfoIcon(x.UIConstantName)}</>
                ) : (
                  `${x.Code}:`
                )}
              </StyledInfoLabel>
              {x.Code === 'MMR' ? (
                <PopupLink href={buildMMRUrl(MMRUrl, vehicleInfo)} title={'Click to open MMR details'}>
                  {x.Description}
                </PopupLink>
              ) : (
                <span title={x.Description}>{`${!isEmpty(x.Description) ? x.Description : 'N/A'}`}</span>
              )}
            </Col>
          ))}
        </>

        {limit < properties?.length && (
          <Col xs={6} className={classNames('vehicle-properties')}>
            <Button as="a" variant="link" className="p-0 text-decoration-none" onClick={toggleDisplay}>
              <span className="mr-1">{`Show ${showAll ? 'less' : 'more'}`}</span>
              <FontAwesomeIcon icon={showAll ? faChevronUp : faChevronDown} />
            </Button>
          </Col>
        )}
      </Row>
    </StyledVehicleProperties>
  )
}
