/* eslint-disable no-script-url */
import { MenuExclusionList } from 'common/constants'
import { NavLink } from 'components/Share/Link'
import { LayoutContext } from 'contexts/LayoutContext'
import { useContext } from 'react'
import { Nav } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { useGlobalStore } from 'store/useGlobalStore'
import { setCustomClick } from 'utils/analyticsUtils'
import shallow from 'zustand/shallow'
import { IPortalMenuResponse } from '../types/userTypes'
import { StyledCloseMenuButton, StyledMainNav, StyledNavBar, StyledUnderlineSpan } from './NavBar.styled'
import { UserMenu } from './UserMenu'
import { SubMenu } from './SubMenu'
import { resetSRPFilter } from 'utils/menuUtils'

const isActiveItem = (item: IPortalMenuResponse, pathname: string): boolean => {
  if (item.URL && (item.URL === pathname || item.URL.slice(2) === pathname)) {
    return true
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return Boolean(item.Children?.find((child) => isActiveItem(child, pathname)))
}
interface IProps {}

export const NavBar = (props: IProps) => {
  const { pathname } = useLocation()
  const { showMenuSidebar, setShowMenuSidebar } = useContext(LayoutContext)
  const [isTerm, menu] = useGlobalStore((state) => [state.userClaims.IsTermAccepted, state.userClaims.Menu], shallow)

  const handleMenuClick = (e: any) => {
    resetSRPFilter()
    setCustomClick(`Tab Menu: ${e.target.textContent.trim()}`)
  }

  return (
    <StyledNavBar>
      <StyledMainNav show={showMenuSidebar}>
        <StyledCloseMenuButton onClick={() => setShowMenuSidebar(false)}>x</StyledCloseMenuButton>
        <Nav as="ul">
          {isTerm &&
            menu?.Children.filter(
              (item) => item.IsWebMenu && (item.Children.length > 0 || item.URL !== 'javascript:')
            ).map((item) => (
              <Nav.Item key={item.MenuItem.Text} as="div">
                <NavLink to={item.URL === 'javascript:' ? null : item.URL} onClick={handleMenuClick}>
                  <StyledUnderlineSpan
                    className={item.URL === pathname || item.URL.slice(2) === pathname ? 'active' : ''}
                  >
                    {item.MenuItem.Text}
                  </StyledUnderlineSpan>
                </NavLink>
                {item.Children && (
                  <SubMenu
                    onClick={() => setShowMenuSidebar(false)}
                    items={item.Children.filter(
                      (c) =>
                        !MenuExclusionList.find((e) => e.name === item.MenuItem.Name)?.childrens?.includes(
                          c.MenuItem.Name
                        ) ?? true
                    )}
                  />
                )}
              </Nav.Item>
            ))}
          {showMenuSidebar && <UserMenu showInMobile={showMenuSidebar} />}
        </Nav>
      </StyledMainNav>
    </StyledNavBar>
  )
}
