import { DEFAULT_SMALL_PAGE_SIZE } from 'common/constants'
import { useFetch } from 'hooks/useFetch'
import { useVehicles } from 'hooks/useVehicles'
import { IBaseCollectionRequest, IBaseCollectionResponse } from 'types/baseTypes'
import { GetBidVehiclesRequest, IVehicleData } from 'types/vehicleTypes'

export const useDealerManagedVehicles = <
  TRequest extends IBaseCollectionRequest,
  TResponse extends IBaseCollectionResponse<IVehicleData>
>(
  queryFunction: (request: TRequest) => Promise<TResponse>,
  getBidVehicleRequest?: TRequest
) => {
  const {
    data: {
      Items,
      TotalRecords: totalRecords,
      SortOptions: sortOptions,
      SortBy: sortBy,
      TotalPages,
      PageNumber
    } = {} as TResponse,
    loading,
    refetch
  } = useFetch(
    () => queryFunction(getBidVehicleRequest || (new GetBidVehiclesRequest(DEFAULT_SMALL_PAGE_SIZE) as TRequest)),
    [queryFunction, getBidVehicleRequest]
  )
  const { vehicles } = useVehicles(Items)

  return { refetch, loading, totalRecords, vehicles, sortOptions, sortBy, TotalPages, PageNumber }
}
