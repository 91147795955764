import styled from 'styled-components'
import ReactSlider from 'react-slider'

export const StyledThumb = styled.div`
  height: 2.4rem;
  width: 2.4rem;
  text-align: center;
  background-color: #fff;
  color: #000;
  border: 0.4rem solid ${({ theme }) => theme.colors.primary};

  // TODO: Find some ways to intervene this instead!!
  outline-color: ${({ theme }) => theme.colors.primary};
  outline-width: 0;

  border-radius: 50%;
  cursor: grab;

  .thumb-text {
    vertical-align: middle;
    margin-top: 2.5rem;
    display: flex;
    justify-content: center;
  }
`

export const StyledTrack = styled.div<{ index: number; value: number[] }>`
  top: 1rem;
  height: 0.5rem;
  background: ${({ index, value, theme }) =>
    (value.length === 1 && index === 0) || (value.length === 2 && index === 1) ? theme.colors.primary : '#ddd'};
  border-radius: 999px;
`

export const StyledMark = styled.span`
  padding: inherit;

  width: 1px;
  height: 10px;
  background-color: #ccc;
  cursor: pointer;
  vertical-align: middle;
`

export const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 5rem;

  ${StyledMark} {
    margin: 2rem 1.125rem;
  }
`
