import { AppRoutes } from 'common/routes'
import { Loader } from 'components/Loader'
import { Link } from 'react-router-dom'
import { IconSvg } from '@prism/icon'
import { setCustomClick } from 'utils/analyticsUtils'

interface Iprops {
  loading: boolean
  count: number
}

export const WatchListCount = ({ loading, count }: Iprops) => {
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Link
          className="navigation-link"
          onClick={() => setCustomClick('Watch List', { usePageName: true })}
          to={AppRoutes.WatchList}
        >
          <span className="right-action">
            <IconSvg glyph="eye" color="primary" />
            &nbsp;Watch List&nbsp;<b>({count ?? 0})</b>
          </span>
        </Link>
      )}
    </>
  )
}
