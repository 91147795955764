export const enum srpFilters {
  NewInventory = 'New Inventory',
  Promotion = 'Promotion',
  AdditionalBodyStyle = 'Additional Body Style',
  BodyStyle = 'Body Style',
  FuelType = 'Fuel Type',
  Make = 'Make',
  Series = 'Series',
  Year = 'Year'
}
