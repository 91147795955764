import { useState, useEffect } from 'react'
import { useInterval } from './useInterval'
import { useGlobalStore } from 'store/useGlobalStore'
import { secondsDiff } from 'utils/dateUtils'

export const useCountdown = (endDate: Date | string) => {
  const { serverTime, clientTime } = useGlobalStore()

  const [seconds, setSeconds] = useState<number>(secondsDiff(serverTime, clientTime, new Date(endDate)))
  const [delay, setDelay] = useState<number>(endDate ? 1000 : null)

  useEffect(() => {
    if (endDate) {
      setDelay(1000)
    } else {
      setDelay(null)
    }
  }, [endDate])
  useInterval(() => {
    const diff = secondsDiff(serverTime, clientTime, new Date(endDate))

    if (diff > 0) {
      setSeconds(diff)
    } else {
      setSeconds(0)
      setDelay(null)
    }
  }, delay)

  return {
    seconds
  }
}
